import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress } from "@material-ui/core";
import HostService from "../../../services/HostService";
import { useAuth0 } from "@auth0/auth0-react";
import BookingDisplay from "../../booking/BookingDisplay";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from "react-responsive";
import moment from 'moment';
import BookingService from "../../../services/BookingService";
import * as CONSTS from '../../const/constants';
import SpaceTitle from "../../publication/SpaceTitle";
import { useParams } from "react-router-dom";
import useDynamicRefs from "use-dynamic-refs";

const HostBookings = () => {
    const { user } = useAuth0();
    const [bookings, setBookings] = useState([]);
    const [bookingsLoading, setBookingsLoading] = useState(false);
    let { bookingId } = useParams();
    const [expanded, setExpanded] = useState(+bookingId);

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [getBookingRef, setBookingRef] = useDynamicRefs();


    const getBookings = () => {
        setBookingsLoading(true);
        HostService.getBookings(HostService.getHostId(user))
            .then(resp => {
                setBookings(resp.data);
                setBookingsLoading(false);
                if (bookingId) scrollToRef(getBookingRef(+bookingId));
            });
    };

    const scrollToRef = (ref) => {
        window.scrollTo(0, ref.current?.offsetTop);
    }

    useEffect(() => {
        getBookings();
        // eslint-disable-next-line
    }, []);

    const handleExpandChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return <Box>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <span>Mis Reservas ({bookings.length})</span>
            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            {bookingsLoading ? <CircularProgress /> :
                <div className="hostBookings">
                    {bookings.sort(sortByStatusAndDate).map(b => <Accordion expanded={expanded === b.id} onChange={handleExpandChange(b.id)} key={b.id + `_booking`} style={{ width: '100%' }} ref={setBookingRef(b.id)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
                                <b style={{ width: isMobile ? 'unset' : '100px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{b.multiple_date ?
                                    `${moment(b.dates[0]?.date).format('DD/MM/YYYY')} al ${moment(b.dates[b.dates.length - 1]?.date).format('DD/MM/YYYY')}`
                                    :
                                    moment(b.date).format('DD/MM/YYYY')
                                }</b>
                                <b style={{ width: isMobile ? 'unset' : '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{b.user?.name + ' ' + b.user?.lastname}</b>
                                <Box style={{ width: isMobile ? 'unset' : '40%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}><SpaceTitle space={b.space} /></Box>
                                <Box style={{ width: isMobile ? 'unset' : '200px', alignItems: 'center', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                                    <Box style={{
                                        height: '18px',
                                        color: '#ffffff',
                                        padding: '5px 10px',
                                        fontWeight: 500,
                                        borderRadius: 5,
                                        fontSize: 12,
                                        width: 'fit-content',
                                        textTransform: 'uppercase',
                                        backgroundColor: bookingStatusHostColor[BookingService.getHostStatus(b)]
                                    }}>
                                        {bookingStatusHostLabels[BookingService.getHostStatus(b)]}
                                    </Box>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <BookingDisplay booking={b} reloadBookings={getBookings} isHost={true} />
                        </AccordionDetails>
                    </Accordion>)}
                </div>
            }
        </Box>
    </Box>;
};

export default HostBookings;

const bookingStatusHostLabels = {
    [CONSTS.USER_STATUS.PAYMENT_PENDING]: 'Pago pendiente',
    [CONSTS.USER_STATUS.PAYMENT_ERROR]: 'Error de Pago',
    [CONSTS.HOST_STATUS.PENDING_APPROVAL]: 'Aprobación pendiente',
    [CONSTS.HOST_STATUS.APPROVED]: 'Confirmada',
    [CONSTS.HOST_STATUS.REJECTED]: 'Rechazada',
    [CONSTS.HOST_STATUS.PENDING_REVIEW]: 'Pendiente de Calificacón',
    [CONSTS.HOST_STATUS.REVIEWED]: 'Calificada',
    [CONSTS.HOST_STATUS.CANCELLED_BY_USER]: 'Cancelada por usuario',
}

const bookingStatusHostColor = {
    [CONSTS.USER_STATUS.PAYMENT_PENDING]: 'rgb(255, 102, 102)',
    [CONSTS.USER_STATUS.PAYMENT_ERROR]: 'rgb(255, 102, 102)',
    [CONSTS.HOST_STATUS.PENDING_APPROVAL]: '#7E2D87',
    [CONSTS.HOST_STATUS.APPROVED]: '#00D1BF',
    [CONSTS.HOST_STATUS.REJECTED]: 'rgb(255, 102, 102)',
    [CONSTS.HOST_STATUS.PENDING_REVIEW]: '#7E2D87',
    [CONSTS.HOST_STATUS.REVIEWED]: '#00D1BF',
    [CONSTS.HOST_STATUS.CANCELLED_BY_USER]: 'rgb(255, 102, 102',
}

const sortByStatusAndDate = (b1, b2) => {
    const b1StatusOrder = getStatusOrder[BookingService.getHostStatus(b1)];
    const b2StatusOrder = getStatusOrder[BookingService.getHostStatus(b2)];

    if (b1StatusOrder > b2StatusOrder) return 1;
    if (b1StatusOrder < b2StatusOrder) return -1;

    if (moment(b1.date).valueOf() > moment(b2.date).valueOf()) return 1;
    if (moment(b1.date).valueOf() < moment(b2.date).valueOf()) return -1;
}

const getStatusOrder = {
    'pending-approve': 1,
    'approved': 2,
    'pending-review': 3,
    'payment-pending': 4,
    'payment-error': 5,
    'reviewed': 6,
    'rejected': 7,
    'cancelled-user': 8,
}