import axios from 'axios';

const SpaceService = {

    feed: () => {
        console.log(`SpaceService.search()`);
        const url = `${process.env.REACT_APP_API_URL}/space/feed/search`;
        return axios.post(url, {
            required_spaces: 1,
            page_size: 100,
            page_number: 0
        });
    },

    homeSearch: () => {
        console.log(`SpaceService.homeSearch()`);
        const url = `${process.env.REACT_APP_API_URL}/space/home/search`;
        return axios.post(url);
    },

    feedSearch: ({
        required_spaces = 1,
        date,
        date_from,
        date_to,
        latitude,
        longitude,
        space_type,
        price_from,
        price_to,
        page_size = 300,
        page_number = 0
    }) => {
        console.log(`SpaceService.feedSearch()`);
        const url = `${process.env.REACT_APP_API_URL}/space/feed/search`;
        return axios.post(url, {
            required_spaces,
            date,
            date_from,
            date_to,
            latitude,
            longitude,
            space_type,
            price_from,
            price_to,
            page_size,
            page_number
        });
    },

    getById: (spaceId) => {
        console.log(`SpaceService.getById(${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/space/${spaceId}`;
        return axios.get(url);
    },

    getAvailabilities: (spaceId, quantity) => {
        console.log(`SpaceService.getAvailabilities(${spaceId}, ${quantity})`);
        const url = `${process.env.REACT_APP_API_URL}/space/${spaceId}/availability/search`;
        const today = new Date();
        const threeMonthFromToday = new Date(new Date().setMonth(new Date().getMonth() + 3));
        return axios.post(url, {
            date_from: today,
            date_to: threeMonthFromToday,
            available_space: quantity
        });
    },

    getReviews: (spaceId) => {
        console.log(`SpaceService.getReviews(${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/space/${spaceId}/reviews`;
        return axios.get(url);
    },
}

export default SpaceService;