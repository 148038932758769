import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const StyledInputBase = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '14px',
    },
    '& label': {
      color: 'red',
    },
    borderBottom: '1px solid #979797',
    '&:focus': {
      borderBottom: '2px solid #00D1BF',
      marginBottom: -1
    },
    '&:hover': {
      borderBottom: '2px solid #383839',
      marginBottom: -1
    },
  },
  input: {
    position: 'relative',
  },
}))(InputBase);

export default StyledInputBase;



