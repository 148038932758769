import React from "react";
import { Link } from "react-router-dom";

const Footer = () => <div style={{ backgroundColor: '#F3F3F3', marginTop: '100px', padding: '50px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-around', textAlign: 'left', flexWrap: 'wrap-reverse' }}>
        <div style={{ marginTop: '30px', width: '200px', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}><img src={require('../assets/images/logo.png')} style={{ height: '50px' }} alt="" /></div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px', padding: '0 30px 0 30px' }}>
                <a href="https://www.instagram.com/worknmates/" target="_blank" rel="noopener noreferrer"><img src={require('../assets/images/ig.png')} alt="" /></a>
                <a href="https://www.facebook.com/Worknmates" target="_blank" rel="noopener noreferrer"><img src={require('../assets/images/fb.png')} alt="" /></a>
                <a href="https://www.linkedin.com/company/worknmates/" target="_blank" rel="noopener noreferrer"><img src={require('../assets/images/linkedin.png')} style={{ height: '22px', opacity: 0.8 }} alt="" /></a>
            </div>
        </div>
        <div style={{ marginTop: '30px', width: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            <div>Sobre la compañia</div>
            <Link to="/terms" className="footerLink" style={{ color: '#818181', textDecoration: 'none' }}>Términos de Uso</Link>
            <Link to="/privacy" className="footerLink" style={{ color: '#818181', textDecoration: 'none' }}>Política de Privacidad</Link>
        </div>
        <div style={{ marginTop: '30px', width: '200px', display: 'flex', flexDirection: 'column' }}>
            <div>Consultas</div>
            <a className="footerLink" style={{ color: '#818181', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href="mailto:contacto@worknmates.com">contacto@worknmates.com</a>
            <div stlye={{ display: 'flex' }}>
                <img src={require("../assets/images/whatsapp.png")} alt="" height="14" />
                <a className="footerLink" style={{ marginLeft: 5, color: '#818181', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href="https://wa.me/5491122556618">54 9 11 2255-6618</a>
            </div>
        </div>
    </div>
</div>;

export default Footer;