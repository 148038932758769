import { useAuth0 } from '@auth0/auth0-react';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BookingService from '../../../services/BookingService';
import UserService from '../../../services/UserService';
import BookingDisplay from '../../booking/BookingDisplay';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import * as CONSTS from '../../const/constants';
import SpaceTitle from '../../publication/SpaceTitle';

const Bookings = () => {
    const [bookings, setBookings] = useState([]);
    const [bookingsLoading, setBookingsLoading] = useState(false);
    const [expanded, setExpanded] = useState(0);

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { user } = useAuth0();

    const getBookings = () => {
        setBookingsLoading(true);
        UserService.getBookings(UserService.getUserId(user)).then(resp => {
            setBookings(resp.data);
            setBookingsLoading(false);
        }).catch(e => console.log(e));
    }

    const handleExpandChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        getBookings();
        // eslint-disable-next-line
    }, []);

    return <Box>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <span>Mis Reservas ({bookings.length})</span>
            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <Box className="userBookings" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {bookingsLoading ? <CircularProgress /> :
                <>
                    {bookings.sort(sortByStatusAndDate).map(b => <Accordion expanded={expanded === b.id} onChange={handleExpandChange(b.id)} key={b.id + `_booking`} style={{ width: '100%' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}  >
                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
                                <b style={{ width: isMobile ? 'unset' : '100px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{b.multiple_date ?
                                    `${moment(b.dates[0]?.date).format('DD/MM/YYYY')} al ${moment(b.dates[b.dates.length - 1]?.date).format('DD/MM/YYYY')}`
                                    :
                                    moment(b.date).format('DD/MM/YYYY')
                                }</b>
                                <b style={{ width: isMobile ? 'unset' : '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{b.space?.host?.name + ' ' + b.space?.host?.lastname}</b>
                                <Box style={{ width: isMobile ? 'unset' : '40%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}><SpaceTitle space={b.space} /></Box>
                                <Box style={{ width: isMobile ? 'unset' : '200px', alignItems: 'center', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                                    <Box style={{
                                        height: '18px',
                                        color: '#ffffff',
                                        padding: '5px 10px',
                                        fontWeight: 500,
                                        borderRadius: 5,
                                        fontSize: 12,
                                        width: 'fit-content',
                                        textTransform: 'uppercase',
                                        backgroundColor: bookingStatusUserColor[BookingService.getUserStatus(b)]
                                    }}>
                                        {bookingStatusUserLabels[BookingService.getUserStatus(b)]}
                                    </Box>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <BookingDisplay booking={b} reloadBookings={getBookings} isHost={false} />
                        </AccordionDetails>
                    </Accordion>)}
                </>
            }
        </Box>
    </Box>;
}

export default Bookings;

const bookingStatusUserLabels = {
    [CONSTS.USER_STATUS.PAYMENT_PENDING]: 'Pago pendiente',
    [CONSTS.USER_STATUS.PAYMENT_ERROR]: 'Error de Pago',
    [CONSTS.USER_STATUS.PENDING_APPROVAL]: 'Pendiente de aprobación',
    [CONSTS.USER_STATUS.APPROVED]: 'Confirmada',
    [CONSTS.USER_STATUS.REJECTED]: 'Rechazada',
    [CONSTS.USER_STATUS.PENDING_REVIEW]: 'Pendiente de calificación',
    [CONSTS.USER_STATUS.REVIEWED]: 'Calificada',
    [CONSTS.USER_STATUS.CANCELLED_BY_USER]: 'Cancelada por usuario',
}

const bookingStatusUserColor = {
    [CONSTS.USER_STATUS.PAYMENT_PENDING]: 'rgb(255, 102, 102)',
    [CONSTS.USER_STATUS.PAYMENT_ERROR]: 'rgb(255, 102, 102)',
    [CONSTS.USER_STATUS.PENDING_APPROVAL]: '#7E2D87',
    [CONSTS.USER_STATUS.APPROVED]: '#00D1BF',
    [CONSTS.USER_STATUS.REJECTED]: 'rgb(255, 102, 102)',
    [CONSTS.USER_STATUS.PENDING_REVIEW]: '#7E2D87',
    [CONSTS.USER_STATUS.REVIEWED]: '#00D1BF',
    [CONSTS.USER_STATUS.CANCELLED_BY_USER]: 'rgb(255, 102, 102)',
}

const sortByStatusAndDate = (b1, b2) => {
    const b1StatusOrder = getStatusOrder[BookingService.getUserStatus(b1)];
    const b2StatusOrder = getStatusOrder[BookingService.getUserStatus(b2)];

    if (b1StatusOrder > b2StatusOrder) return 1;
    if (b1StatusOrder < b2StatusOrder) return -1;

    if (moment(b1.date).valueOf() > moment(b2.date).valueOf()) return 1;
    if (moment(b1.date).valueOf() < moment(b2.date).valueOf()) return -1;
}

const getStatusOrder = {
    'approved': 1,
    'payment-pending': 2,
    'payment-error': 3,
    'pending-approval': 4,
    'rejected': 5,
    'pending-review': 6,
    'reviewed': 7,
    'cancelled-user': 8,
}