import authAxios from '../helpers/authAxios';
import * as CONSTS from '../components/const/constants';

const BookingService = {

    create: (bookingData) => {
        console.log(`BookingService.create('${bookingData}')`);
        const url = `${process.env.REACT_APP_API_URL}/booking`;
        return authAxios.post(url, bookingData);
    },

    getByIdAndUserId: (userId, bookingId) => {
        console.log(`BookingService.getByIdAndUserId(${userId}, ${bookingId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/booking/${bookingId}`;
        return authAxios.get(url);
    },

    getByIdAndHostId: (hostId, bookingId) => {
        console.log(`BookingService.getByIdAndHostId(${hostId}, ${bookingId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/${bookingId}`;
        return authAxios.get(url);
    },

    review: (userId, bookingId, comment, rating,user_type) => {
        console.log(`BookingService.review('${userId}, ${bookingId}, ${comment}, ${rating}')`);
        const url = `${process.env.REACT_APP_API_URL}/booking/${bookingId}/review`;
        return authAxios.post(url, { user_id: userId, comment, rating, user_type });
    },

    getHostStatus: (booking) => {
        const { host_status, user_status, payment_method } = booking;

        if (host_status === 3)
            return CONSTS.HOST_STATUS.REJECTED;

        if (user_status === 4)
            return CONSTS.HOST_STATUS.CANCELLED_BY_USER;

        if (user_status === 1 && payment_method?.id !== 1)
            return CONSTS.HOST_STATUS.PAYMENT_PENDING;

        if (user_status === 2)
            return CONSTS.HOST_STATUS.PAYMENT_ERROR;

        if ((user_status === 3 || (user_status === 1 && payment_method?.id === 1)) && host_status === 1)
            return CONSTS.HOST_STATUS.PENDING_APPROVAL;

        if (([1, 3].includes(user_status) && host_status === 2) || host_status === 4)
            return CONSTS.HOST_STATUS.APPROVED;

        if (host_status === 5)
            return CONSTS.HOST_STATUS.PENDING_REVIEW;

        if (host_status === 6)
            return CONSTS.HOST_STATUS.REVIEWED;

        return 'EMPTY';
    },

    getUserStatus: (booking) => {
        const { host_status, user_status, payment_method } = booking;

        if (host_status === 3)
            return CONSTS.USER_STATUS.REJECTED;

        if (user_status === 4)
            return CONSTS.USER_STATUS.CANCELLED_BY_USER;

        if (user_status === 1 && payment_method?.id !== 1)
            return CONSTS.USER_STATUS.PAYMENT_PENDING;

        if (user_status === 2)
            return CONSTS.USER_STATUS.PAYMENT_ERROR;

        if ((user_status === 3 || (user_status === 1 && payment_method?.id === 1)) && host_status === 1)
            return CONSTS.USER_STATUS.PENDING_APPROVAL;

        if ([1, 3].includes(user_status) && host_status === 2)
            return CONSTS.USER_STATUS.APPROVED;

        if ([1, 3].includes(user_status))
            return CONSTS.USER_STATUS.PENDING_REVIEW;

        if (user_status === 5)
            return CONSTS.USER_STATUS.REVIEWED;



        return 'EMPTY';
    },

    addPromoCode: (promoCode) => {
        console.log(`BookingService.addPromoCode('${promoCode}')`);
        const url = `${process.env.REACT_APP_API_URL}/booking/promocode/${promoCode}`;
        return authAxios.get(url);
    }

}

export default BookingService;