import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import { useAuth0 } from "@auth0/auth0-react";

const Policy = (props) => {
    const isLessTnan400pxWidth = useMediaQuery({ query: '(max-width: 600px)' });
    const { context } = props;
    const { styles: contextStyles, stepUtils } = useContext(context);
    const { user } = useAuth0();

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        confirmText: {
            color: '#383839',
            marginLeft: '12px',
            fontSize: isLessTnan400pxWidth ? '16px' : '18px'
        },
        confirmBox: {
            display: 'flex',
            alignItems: 'end',
            marginBottom: '8px'
        },
        ...contextStyles
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Política de cancelación</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Podrás cancelar las reservas de tu espacio hasta 24 horas antes de cada Check-In, ofreciendo un reembolso del 100% a cada usuario.</div>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>No se podrán realizar cancelaciones dentro de las 24 horas de cada Check-In</div>
                </Box>
                <Box style={{ textAlign: 'left' }}>
                    <Box style={styles.confirmBox}>
                        <img src={require('../../../assets/images/policy/confirm.png')} alt="" style={{}}></img>
                        <div style={styles.confirmText}><b>Creación del espacio</b></div>
                    </Box>
                    <Box style={styles.confirmBox}>
                        <img src={require('../../../assets/images/policy/green-line.png')} alt="" style={{ marginLeft: '12px' }}></img>
                    </Box>
                    <Box style={styles.confirmBox}>
                        <img src={require('../../../assets/images/policy/orange-confirm.png')} alt="" style={{}}></img>
                        <div style={styles.confirmText}><b>Reserva confirmada</b></div>
                    </Box>
                    <Box style={styles.confirmBox}>
                        <img src={require('../../../assets/images/policy/orange-line.png')} alt="" style={{ marginLeft: '12px' }}></img>
                    </Box>
                    <Box style={styles.confirmBox}>
                        <img src={require('../../../assets/images/policy/orange-confirm.png')} alt="" style={{}}></img>
                        <div style={styles.confirmText}><b>24 horas antes:</b> reembolso total</div>
                    </Box>
                    <Box style={styles.confirmBox}>
                        <img src={require('../../../assets/images/policy/orange-line.png')} alt="" style={{ marginLeft: '12px' }}></img>
                    </Box>
                    <Box style={styles.confirmBox}>
                        <img src={require('../../../assets/images/policy/orange-confirm.png')} alt="" style={{}}></img>
                        <div style={styles.confirmText}><b>Dentro de las 24 horas del check-in:</b> Opción de cancelación desactivada</div>
                    </Box>
                </Box>
            </Box>
        </Box>
        <OnboardingButtons stepUtils={stepUtils}
            nextFn={() => {
                stepUtils.nextStep();
                sessionStorage.removeItem(user?.sub + '_space');
            }}
            prevFn={() => stepUtils.previousStep()}
            closeFn={() => null /* go to host dashboard */} />
    </Box>;
};

export default Policy;