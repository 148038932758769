import React, { useState, useEffect, useContext } from "react";
import { Box, FormControl, FormHelperText, Checkbox } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import DataService from "../../../services/DataService";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OnboardingButtons from "./OnboardingButtons";
import EditionButtons from "../dashboard/EditionButtons";
import TextField from '../../styled/StyledTextField';
import NumberFormat from "react-number-format";
import is from "is_js";

const NO_SERVICE_ERROR = 'Debes seleccionar al menos un servicio.';
const MISSING_SERVICE_PRICE = 'Debes ingresar el valor del servicio.';

const Service = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { context, isOnboarding } = props;
    const [services, setServices] = useState([]);
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const [selectedServices, setSelectedServices] = useState([]);
    const [paidServicesPrices, setPaidServicesPrices] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let mounted = true;
        DataService.getSpaceServices().then(resp => {
            mounted && setServices(resp.data.sort((a, b) => a.order - b.order));
        });
        return () => mounted = false;
    }, []);

    useEffect(() => {
        setSelectedServices(space?.services?.map(s => ({ service_id: s.service.id, free: s.free })) || []);
        let prices = {};
        if (space?.services) space.services.filter(s => !s.free).forEach(s => prices[s.service.id] = s.price);
        setPaidServicesPrices(prices);
    }, [space]);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        ...contextStyles
    }

    const serviceSelected = (checked, s) => {
        if (checked) {
            let spaceService = {
                service_id: s.id,
                price: 0,
                free: true
            };

            // avoid duplicates
            let isAlreadySelected = selectedServices.filter(serv => (serv.service_id === s.id && serv.free)).length > 0;
            if (isAlreadySelected) return;

            // logic to uncheck from 'paid services' column when checking same service on 'free services' column
            setSelectedServices(currentSelection => [
                ...currentSelection.filter(i => (i.service_id !== s.id && !i.free) || i.free),
                spaceService
            ]);
            setPaidServicesPrices(psp => ({ ...psp, [s.id]: '' }));
        } else {
            // remove service by id and by free === true
            setSelectedServices(currentSelection => currentSelection.filter(i =>
                (i.service_id !== s.id && i.free) || !i.free
            ));
        }
    }

    const paidServiceSelected = (checked, s) => {
        if (checked) {
            let spaceService = {
                service_id: s.id,
                price: 0,
                free: false
            };

            // avoid duplicates
            let isAlreadySelected = selectedServices.filter(serv => (serv.service_id === s.id && !serv.free)).length > 0;
            if (isAlreadySelected) return;

            // logic to uncheck from 'free services' column when checking same service on 'paid services' column
            setSelectedServices(currentSelection => [
                ...currentSelection.filter(i => (i.service_id !== s.id && i.free) || !i.free),
                spaceService
            ]);
        }
        else {
            // remove service by id and by free === false
            setSelectedServices(currentSelection => currentSelection.filter(i =>
                (i.service_id !== s.id && !i.free) || i.free
            ));
            setPaidServicesPrices(psp => ({ ...psp, [s.id]: '' }));
        }
    }

    const selectAllPaid = (isChecked) => {
        services.forEach(s => !s.included && paidServiceSelected(isChecked, s));
    }

    const selectAllFree = (isChecked) => {
        services.forEach(s => serviceSelected(isChecked, s));
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(selectedServices)) errorsAcum.service = NO_SERVICE_ERROR;
        let paidServices = selectedServices.filter(s => !s.free);
        paidServices.forEach(ps => {
            if (is.falsy(paidServicesPrices[ps.service_id])) errorsAcum[ps.service_id] = MISSING_SERVICE_PRICE;
        });

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getSpaceData = () => ({
        services: selectedServices.map(s => {
            s.space_id = space.id;
            s.price = paidServicesPrices[s.service_id] || 0;
            return s;
        })
    });

    const updatePaidServicePrice = (s, price) => {
        setPaidServicesPrices(psp => ({ ...psp, [s.id]: price }));
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Servicios</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <div style={{ display: isMobile ? 'block' : 'flex', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', margin: isMobile ? '0' : '0 25px', }}>
                <FormControl style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', flexDirection: 'column', }} error={!!errors.service}>
                    <FormControlLabel
                        control={<Checkbox onChange={e => selectAllFree(e.target.checked)} checked={services.length === selectedServices.filter(s => s.free).length} />}
                        label={<div style={{ textAlign: 'left', fontSize: '15px', fontFamily: 'PoppinsBold'  }}>Servicios incluídos en la tarifa</div>}
                        style={{ marginBottom: '10px', fontSize: '15px', fontFamily: 'PoppinsBold' }} />
                    {services.map(s =>
                        <FormControl key={`service_${s.id}`} style={{ marginLeft: '10px'}}>
                            <FormControlLabel key={`service_${s.id}`}
                                control={<>
                                    <Checkbox onChange={(e) => serviceSelected(e.target.checked, s)} checked={selectedServices.filter(serv => serv.free).map(serv => serv.service_id).indexOf(s.id) !== -1} />
                                    <img src={s.image.url} style={{ margin: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} alt="" />
                                </>}
                                label={s.name} />
                        </FormControl>
                    )}
                    <FormHelperText>{errors.service}</FormHelperText>
                </FormControl>
                <br />
                <FormControl style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', flexDirection: 'column', }} error={!!errors.service}>
                    <FormControlLabel
                        control={<Checkbox onChange={e => selectAllPaid(e.target.checked)} checked={services.filter(s => !s.included).length === selectedServices.filter(s => !s.free).length} />}
                        label={<div style={{ textAlign: 'left', fontSize: '15px', fontFamily: 'PoppinsBold'  }}>Servicios adicionales (por día)</div>}
                        style={{ marginBottom: '10px' }} />
                    {services.map(s => !s.included &&
                        <FormControl key={`service_${s.id}`} className="servicesPriceInput" error={!!errors[s.id]}  style={{ marginLeft: '10px'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <FormControlLabel
                                    key={`service_${s.id}`}
                                    control={<>
                                        <Checkbox onChange={(e) => paidServiceSelected(e.target.checked, s)}
                                            checked={selectedServices.filter(serv => !serv.free).map(serv => serv.service_id).indexOf(s.id) !== -1} />
                                        <img src={s.image.url} style={{ margin: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} alt="" />
                                    </>}
                                    label={s.name} />
                                <NumberFormat
                                    onValueChange={e => updatePaidServicePrice(s, e.floatValue)}
                                    required
                                    value={paidServicesPrices[s.id]}
                                    placeholder="$00"
                                    customInput={TextField}
                                    decimalSeparator={','}
                                    variant="outlined" style={{ width: '80px' }}
                                    thousandSeparator={'.'}
                                    prefix={'$ '} />
                            </div>
                            <FormHelperText>{errors[s.id]}</FormHelperText>
                        </FormControl>
                    )}
                    <FormHelperText>{errors.service}</FormHelperText>
                </FormControl>
            </div>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ? <OnboardingButtons stepUtils={stepUtils}
            nextFn={() => validateFormData() && stepUtils.saveSpaceAndNext(getSpaceData())}
            prevFn={() => stepUtils.previousStep()}
            closeFn={() => validateFormData() && stepUtils.saveSpaceAndClose(getSpaceData())} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={() => validateFormData() && stepUtils.updateSpace(getSpaceData())} />
        }
    </Box>;
};
export default Service;