import { Box } from '@material-ui/core';
import React from 'react';

const SpaceCalification = ({ space, showText, mini, byHost }) => {

    const getStatusLabel = rating => {
        if (rating < 6) return '';
        if (rating <= 7) return 'Bueno';
        if (rating <= 9) return 'Muy Bueno';
        if (rating <= 10) return 'Excelente';
        return '';
    }

    if (!space || !space.rating || parseInt(space.rating) <= 0) return null;

    const styles = {
        byHost: {
            padding: '0 5px', borderRadius: 5, 
            backgroundColor: '#00D1BF', 
            color: '#ffffff', 
            width: 'fit-content', 
            height: 'fit-content', 
            fontSize: mini ? 12 : 16 
        },
        byUser: {
            padding: '0 5px', borderRadius: 5, 
            backgroundColor: '#7E2D87', 
            color: '#ffffff', 
            width: 'fit-content', 
            height: 'fit-content', 
            fontSize: mini ? 12 : 16 
        }
    }

    return <Box>
        <Box style={{ display: 'flex' }}>
            <Box style={byHost ? styles.byHost : styles.byUser}>{parseFloat(space.rating).toFixed(1)} </Box>
            {showText && getStatusLabel(parseInt(space.rating)) && <Box style={{ marginLeft: 5, fontWeight: 900, fontSize: mini ? 12 : 16 }}>{getStatusLabel(parseInt(space.rating))}</Box>}
            {mini && space.reviews_count && <Box style={{ fontSize: 12, marginLeft: 5 }}>{`(${space.reviews_count})`}</Box>}
        </Box>
        {!mini && space.reviews_count && <Box style={{ fontSize: 12, marginTop: mini ? 0 : 5, textAlign: 'center' }}>{`${space.reviews_count} opini${space.reviews_count > 1 ? 'ones' : 'ón'}`}</Box>}
    </Box>
}

export default SpaceCalification;