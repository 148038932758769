import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, MenuItem, Select } from "@material-ui/core";
import CountryBudget from "./CountryBudget";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useMediaQuery } from "react-responsive";

const countryBudgetsData = [
    {
        country: 'Argentina',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg',
        currency: 'ARS',
        monthBudget: 100000,
        employeeBudget: 5000,
        usedBudget: 45000,
        employeesCount: 32
    },
    {
        country: 'Colombia',
        flag: 'https://cdn.countryflags.com/thumbs/colombia/flag-400.png',
        currency: 'COP',
        monthBudget: 2000000,
        employeeBudget: 50000,
        usedBudget: 1850000,
        employeesCount: 120
    },
    {
        country: 'Estados Unidos',
        flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1200px-Flag_of_the_United_States.svg.png',
        currency: 'USD',
        monthBudget: 5000,
        employeeBudget: 700,
        usedBudget: 3200,
        employeesCount: 12
    },
]

const Budgets = () => {
    const [expanded, setExpanded] = useState('');

    const handleExpandChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [selectedMonth, setSelectedMonth] = useState('Noviembre 2020')

    return <Box style={{ padding: '15px', marginBottom: '20px' }}>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <span>Topes</span>
            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
            <Select style={{ marginLeft: 10, fontWeight: 900 }}
                id="currentMonth"
                value={selectedMonth}
                variant="outlined"
                onChange={e => setSelectedMonth(e.target.value)}>
                {['Noviembre 2020', 'Octubre 2020', 'Septiembre 2020', 'Agosto 2020'].map(c => <MenuItem key={c + '_curr'} value={c}>{c}</MenuItem>)}
            </Select>
            <Button style={{
                backgroundColor: '#00D1BF',
                color: '#ffffff',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                boxShadow: 'none',
                width: '208px',
                height: '40px',
                fontWeight: 'bold',
                marginBottom: 20
            }} variant="contained"><AddBoxIcon style={{ marginRight: 10 }} />Agregar Pais</Button>
        </Box>
        <Accordion expanded={expanded === 'Total'} onChange={handleExpandChange('Total')} key={'Total'} style={{ width: '100%', marginBottom: 50 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Box style={{ marginLeft: 20, fontWeight: 900 }}>Total</Box>
            </AccordionSummary>
            <AccordionDetails>
                <CountryBudget country={{
                    country: 'Total',
                    flag: '',
                    currency: 'COP',
                    monthBudget: 25245000,
                    employeeBudget: 272207,
                    usedBudget: 15029000,
                    employeesCount: 164
                }} readOnly={true} />
            </AccordionDetails>
        </Accordion>


        {countryBudgetsData.map(c => <Accordion expanded={expanded === c.country} onChange={handleExpandChange(c.country)} key={c.country} style={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={c.flag} alt="" style={{ height: 20, width: 35 }} />
                    <Box style={{ marginLeft: 20 }}>{c.country}</Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <CountryBudget country={c} />
            </AccordionDetails>
        </Accordion>)
        }

    </Box >;
};

export default Budgets;


