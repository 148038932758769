import React, { useState, useContext } from "react";
import { Avatar, Box, Button, Chip, CircularProgress, FormHelperText } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import TextField from '../../styled/StyledTextField';
import InputLabel from '@material-ui/core/InputLabel';
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import { useAuth0 } from "@auth0/auth0-react";
import is from "is_js";
import HostService from "../../../services/HostService";
import DropzoneArea from "../../uploader/DropzoneArea";
import UserContext from "../../../helpers/UserContext";

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const CompanyInfo = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { isOnboarding, context } = props;
    const { styles: contextStyles, stepUtils } = useContext(context);
    const { user } = useAuth0();

    // form data
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [errors, setErrors] = useState({});


    const [updateHostLoading, setUpdateHostLoading] = useState(false);
    const [isPhotoHover, setPhotoHover] = useState(false);

    const { fullUser } = useContext(UserContext);

    // host data
    const [avatar, setAvatar] = useState({ url: 'https://scontent-eze1-1.xx.fbcdn.net/v/t1.0-0/s526x395/28660933_2042456426010805_8950231625594219578_n.jpg?_nc_cat=107&ccb=2&_nc_sid=09cbfe&_nc_ohc=T_zZlu2MhtkAX8mHb2u&_nc_ht=scontent-eze1-1.xx&tp=7&oh=a9d959215cbec5ff8ef6705de1cfc12f&oe=5FD96E4F' });
    const [name, setName] = useState('CONTOSO LTD.');
    const [fiscalNumber, setFiscalNumber] = useState('33-44555666-3');
    const [summary, setSummary] = useState('The Contoso Corporation is a multinational business with its headquarters in Paris. The company is a manufacturing, sales, and support organization with more than 100,000 products.');

    const avatarSelected = (images) => {
        if (images[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(images[0]);
            reader.onload = async function () {
                setAvatarLoading(true);
                let resp = await HostService.postImage(user, reader.result)
                setAvatar(resp.data);
                setAvatarLoading(false);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    const AvatarPreview = () => {
        let resp = 'agregar logo';
        if (is.empty(avatar)) resp = avatarLoading ? '' : 'agregar logo';
        if (is.string(avatar) && is.not.empty(avatar)) resp = null;
        return resp;
    }

    const updateHost = async (host) => {
        try {
            setUpdateHostLoading(true);
            await HostService.updateHost(HostService.getHostId(user), host);
        } catch (e) {
            console.log(e);
        } finally {
            setUpdateHostLoading(false)
        }
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(name)) errorsAcum.name = REQUIERED_FIELD_ERROR;
        if (is.empty(fiscalNumber)) errorsAcum.fiscalNumber = REQUIERED_FIELD_ERROR;
        if (is.empty(avatar)) errorsAcum.avatar = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getCompanyData = () => {
        let hostData = {
            name,
            fiscal_number: fiscalNumber,
            summary
        };

        if (is.string(avatar) && is.not.empty(avatar))
            hostData.avatar = avatar;

        return hostData;
    };

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        ...contextStyles
    }

    return <Box style={{ ...styles.stepsView, background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px' }}>
        <Box className="onboardingForm personalInfoForm">
            <div style={styles.onboardingTitle}>
                <span>Datos de la Empresa</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', justifyContent: isMobile ? 'center' : 'unset' }}>
                <FormControl error={!!errors.avatar} style={{ display: 'flex', alignItems: 'center' }}>
                    <Box onMouseEnter={() => setPhotoHover(true)}
                        onMouseLeave={() => setPhotoHover(false)}
                        style={{
                            height: '135px',
                            width: '135px',
                            borderRadius: '135px',
                            backgroundColor: 'lightgrey',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#383839',
                            textTransform: 'none',
                            overflow: 'hidden',
                            position: 'relative',
                            opacity: isPhotoHover ? 0.5 : 1,
                            boxShadow: '0px 0px 46px -11px rgba(0,0,0,0.75)'
                        }}>
                        {avatarLoading && <div style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 99,
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#ffffff',
                            opacity: 0.5
                        }}><CircularProgress /></div>}
                        <DropzoneArea onChange={avatarSelected}
                            showAlerts={false}
                            previewGridProps={{ item: { style: { padding: '0' } } }}
                            filesLimit={1}
                            maxFileSize={5000000}
                            dropzoneText={(avatar?.url) ? <img src={avatar?.url} alt="" style={{ width: '160px', height: '160px' }} /> : <AvatarPreview />} />
                    </Box>
                    <FormHelperText>{errors.avatar}</FormHelperText>
                </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between' }}>
                <FormControl style={{ ...styles.formControl, order: 1 }}>
                    <TextField
                        id="name"
                        label="Razón social"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        error={!!errors.name}
                        helperText={errors.name}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>



                <FormControl style={{ ...styles.formControl, order: 2 }} error={!!errors.fiscalNumber}>
                    <TextField
                        id="fiscalNumber"
                        required
                        label="CUIT"
                        value={fiscalNumber}
                        onChange={event => setFiscalNumber(event.target.value)}
                        inputProps={{ maxLength: 360 }}
                    />
                    <FormHelperText>{errors.fiscalNumber}</FormHelperText>
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 3 }}>
                    <InputLabel shrink id="summary-label" style={{ marginLeft: '8px', marginTop: '5px' }}>Descripción de la compañia</InputLabel>
                    <TextField
                        style={{ marginTop: '20px' }}
                        id="summary"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={summary}
                        onChange={event => setSummary(event.target.value)}
                        inputProps={{ maxLength: 360 }}
                    />
                    <div style={{ textAlign: 'right', color: '#818181', fontFamily: 'Poppins', fontSize: '14px', marginTop: '5px' }}>{summary ? summary.length : 0}/360 caracteres</div>
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 4 }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Box style={{ fontWeight: 600, marginRight: 5 }}>Administrador: </Box>
                        <Chip avatar={<Avatar src={fullUser.avatar.url}>{`${fullUser.name[0]}${fullUser.lastname[0]}`}</Avatar>} label={`${fullUser.name} ${fullUser.lastname}`} />
                    </Box>
                </FormControl>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ?
            <OnboardingButtons stepUtils={stepUtils}
                nextFn={() => validateFormData() && stepUtils.saveHostAndNext(getCompanyData())}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => validateFormData() && stepUtils.saveHostAndClose(getCompanyData())} />
            : <div style={{ width: '100%', textAlign: 'end', marginBottom: 20 }}>
                <Button onClick={() => validateFormData() && updateHost(getCompanyData())}
                    variant="contained"
                    disabled={updateHostLoading}
                    style={{
                        backgroundColor: '#00D1BF',
                        color: '#ffffff',
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: isMobile ? '100%' : '168px',
                        height: '50px',
                        fontWeight: 'bold',
                        marginBottom: isMobile ? '10px' : '0'
                    }}>{updateHostLoading ? <CircularProgress color="secondary" /> : 'Guardar'}</Button>
            </div>}
    </Box>
};

export default CompanyInfo;