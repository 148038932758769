import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Container } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import HostService from "../services/HostService";
const queryString = require('query-string');

const EmailVerified = () => {
    const history = useHistory();
    const { user } = useAuth0();
    const redirectTo = HostService.hasHostRole(user) ? "/host/dashboard" : "/dashboard";

    let params = queryString.parse(window.location.search);

    return <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px 0', marginBottom: '25px' }}>
        {params?.success === 'true' ?
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={require('../assets/images/verified.png')} alt="" style={{ height: '200px', width: '200px' }} />
                <Box style={{ margin: '25px' }}>Su email ha sido verificado correctamente.</Box>
            </Box>
            :
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={require('../assets/images/error.png')} alt="" style={{ height: '200px', width: '200px' }} />
                <Box style={{ margin: '25px' }}>Hubo un error. No se pudo verificar su correo.</Box>
            </Box>
        }
        <Button style={{
            backgroundColor: HostService.hasHostRole(user) ? '#00D1BF' : '#7E2D87',
            color: '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: '168px',
            height: '50px',
            fontWeight: 'bold',
            marginBottom: '0'
        }} onClick={() => history.push(redirectTo)}>Ir al Dashboard</Button>
    </Container>
};

export default EmailVerified;