import { TextField } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#383839',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00D1BF',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#979797',
        },
        '&:hover fieldset': {
          borderColor: '#383839',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00D1BF',
        },
      },
    },
  })(TextField);

export default StyledTextField;