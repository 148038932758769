import React, { useState } from "react";
import { Box, Button, createMuiTheme, MuiThemeProvider, Switch, withStyles } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import TextField from '../styled/StyledTextField';
import { useMediaQuery } from 'react-responsive';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { DatePicker, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const googleMapsLibraries = ['places'];

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#7E2D87'
        }
    }
});

const SearchSpacesBox = ({ preSearchAction, currentSearchData }) => {
    const isLessThan500pxWitdth = useMediaQuery({ query: '(max-width: 500px)' });
    const isLessThan800pxWitdth = useMediaQuery({ query: '(max-width: 800px)' });
    const isLessThan1600pxWitdth = useMediaQuery({ query: '(max-width: 1600px)' });

    const history = useHistory();

    const [date, setDate] = useState(currentSearchData?.date ? moment(currentSearchData?.date) : null);
    const [quantity, setQuantity] = useState(currentSearchData?.quantity || 1);
    const [lat, setLat] = useState(currentSearchData?.lat || 0);
    const [long, setLong] = useState(currentSearchData?.long || 0);
    const [locationName, setLocationName] = useState(currentSearchData?.locationName || '');

    const [isMultiDate, setIsMultiDate] = useState(currentSearchData?.dateFrom && currentSearchData?.dateTo);
    const [dateRange, setDateRange] = useState([isMultiDate ? moment(currentSearchData?.dateFrom) : null, isMultiDate ? moment(currentSearchData?.dateTo) : null]);

    const [locationAutocomplete, setLocationAutocomplete] = useState(null);
    const locationAutocompleteChanged = () => {
        if (locationAutocomplete) {
            const location = locationAutocomplete.getPlace?.()?.geometry?.location;
            if (location) {
                setLocationName(locationAutocomplete.getPlace?.()?.formatted_address);
                setLat(location.lat());
                setLong(location.lng());
            }
        }
    };
    const locationAutocompleteLoad = ac => setLocationAutocomplete(ac);

    const { isLoaded: isScriptLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: googleMapsLibraries
    })

    const styles = {
        title: {
            width: '300%',
            height: '5vw',
            fontFamily: 'PoppinsBold',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '4vw',
            color: '#383839',
            zIndex: 99,
            marginLeft: '-40%'
        },
        titleMobile: {
            fontFamily: 'PoppinsBold',
            fontSize: '26px',
            marginTop: '10%',
            color: '#383839',
        },
        formControl: {
            flexGrow: 1,
            padding: '10px',
            marginBottom: '20px',
            flexShrink: 1,
            width: isLessThan800pxWitdth ? '45%' : '22%',
            minWidth: '135px'
        },
        homeText: {
            color: '#ffffff',
            fontSize: isLessThan500pxWitdth ? '28px' : (isLessThan1600pxWitdth ? '4vw' : '64px'),
            fontFamily: 'PoppinsBold'
        },
        startButton: {
            backgroundColor: '#6B2D87',
            color: '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: '100%',
            height: '50px',
            minWidth: '170px',
            fontWeight: 'bold',
        },
        tutorial: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around'
        },
        tutorialMobile: {
            display: 'flex',
            flexDirection: 'column'
        },
        paymentAdvice: {
            flexGrow: 1,
            maxWidth: '20%',
            minWidth: '275px',
            textAlign: 'center',
            color: '#383839',
            margin: '30px 5px 5px 5px',
        },
        paymentAdviceMobile: {
            flexGrow: 1,
            maxWidth: '95%',
            minWidth: '275px',
            textAlign: 'left',
            color: '#383839',
            marginTop: '40px',
        }
    };

    const searchPublications = () => {
        preSearchAction && preSearchAction();
        let dateParam = `${date?.format('YYYY-MM-DD') || 'YYYY-MM-DD'}/YYYY-MM-DD`;
        if (isMultiDate)
            dateParam = `${dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : 'YYYY-MM-DD'}/${dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : 'YYYY-MM-DD'}`
        history.replace(`/results/${dateParam}/${quantity}/${lat}/${long}/${locationName}`);
    }

    return <Box>
        <div style={{ border: '1px solid #D8D8D8', borderRadius: '20px', padding: isLessThan800pxWitdth ? '0 10px' : '0 30px' }}>
            <div className="hostHomeForm" style={{ padding: '10px', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', flexDirection: isLessThan800pxWitdth ? 'column' : 'row' }}>
                {isScriptLoaded &&
                    <FormControl className="placesAutocomplete" style={{ ...styles.formControl, maxWidth: isLessThan800pxWitdth ? '95%' : '33%', width: '95%' }}>
                        <Autocomplete onLoad={locationAutocompleteLoad} onPlaceChanged={locationAutocompleteChanged}>
                            <TextField
                                onBlur={() => (lat === 0 && long === 0) && setLocationName('')}
                                value={locationName}
                                onChange={e => setLocationName(e.target.value)}
                                id="locationName"
                                placeholder=""
                                label="Ubicación"
                                type="text"
                                InputLabelProps={{ shrink: true, }} />
                        </Autocomplete>
                    </FormControl>
                }
                <FormControl style={{ ...styles.formControl, textAlign: 'left', minWidth: isLessThan800pxWitdth ? 200 : 300, maxWidth: isLessThan800pxWitdth ? '95%' : '33%', width: '95%' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Box style={{ width: '100%' }}>
                            <MuiThemeProvider theme={muiTheme}>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <div className="birthInput searchDateInput">
                                        {isMultiDate ? <DateRangePicker
                                            id="reservationDate"
                                            format="DD/MM/yyyy"
                                            disableHighlightToday={true}
                                            value={dateRange}
                                            onChange={d => setDateRange(d)}
                                            autoOk
                                            variant="inline"
                                            disablePast={true}
                                            startText="Fecha desde"
                                            endText="Fecha hasta"
                                            renderInput={(startProps, endProps) => <>
                                                <TextField InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }} {...startProps} helperText={''} variant="standard" style={isLessThan500pxWitdth ? { marginBottom: 10, width: '95%' } : { marginLeft: 10, width: '40%' }} />
                                                <Box style={{ fontWeight: 600 }}> a </Box>
                                                <TextField InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }} {...endProps} helperText={''} variant="standard" style={isLessThan500pxWitdth ? { marginTop: 10, width: '95%' } : { marginLeft: 10, width: '40%' }} />
                                            </>}
                                        />
                                            :
                                            <DatePicker
                                                id="date"
                                                label="Fecha"
                                                format="DD/MM/yyyy"
                                                emptyLabel={' '}
                                                invalidDateMessage={' '}
                                                disableHighlightToday={true}
                                                value={date}
                                                onChange={d => d?._isValid ? setDate(d) : setDate(null)}
                                                autoOk
                                                variant="inline"
                                                disablePast={true}
                                                renderInput={(props) => <TextField InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap', width: '100%' } }} {...props} error={false} helperText={''} />}
                                            />}
                                    </div>
                                </LocalizationProvider>
                            </MuiThemeProvider>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', width: 80 }}>
                            <div style={{ fontSize: 12, color: 'grey' }}>Rango</div>
                            <StyledSwitch
                                checked={isMultiDate}
                                onChange={e => { setIsMultiDate(e.target.checked); setDate(null); setDateRange([null, null]) }}
                                color="primary"
                                name="active"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Box>
                    </Box>
                </FormControl>
                <FormControl style={{ ...styles.formControl, maxWidth: isLessThan800pxWitdth ? '100%' : '100px', width: '95%' }}>
                    <TextField id="people" label="Personas" type="number" value={quantity} onChange={e => setQuantity(e.target.value)} inputProps={{ min: 0 }} InputLabelProps={{ shrink: true, }} />
                </FormControl>
                <FormControl style={{ ...styles.formControl, maxWidth: isLessThan800pxWitdth ? '100%' : '158px', }}>
                    <Button onClick={searchPublications} variant="contained" style={styles.startButton}>Buscar Espacio</Button>
                </FormControl>
            </div>
        </div>
    </Box>;
}

export default SearchSpacesBox;

const StyledSwitch = withStyles({
    switchBase: {
        color: 'lightgray',
        '&$checked': {
            color: '#61257D',
        },
        '&$checked + $track': {
            backgroundColor: '#61257D',
        },
    },
    checked: {},
    track: {},
})(Switch);