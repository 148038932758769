import { Backdrop, Box } from "@material-ui/core";
import React from "react";
import SearchSpacesBox from "./SearchSpacesBox";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const SearchEditForm = ({ searchCriteria, setShow }) => {
    return <Backdrop open={true} style={{ zIndex: 9997 }}>
        <ClickAwayListener onClickAway={e => setShow(false)} touchEvent={false}>
            <Box style={{ position: 'absolute', top: 0, height: 'fit-content', zIndex: 9998, backgroundColor: '#ffffff', width: '97vw', padding: '25px' }}>
                <Box style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "20px",
                    lineHeight: "40px",
                    color: "#383839",
                    textAlign: 'left',
                    marginBottom: '15px'
                }}>Editar búsqueda</Box>
                <SearchSpacesBox currentSearchData={searchCriteria} preSearchAction={() => setShow(false)} />
            </Box>
        </ClickAwayListener>
    </Backdrop >;
}

export default SearchEditForm;