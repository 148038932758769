import authAxios from '../helpers/authAxios';

const UserService = {

    getUserId: user => {
        let userId = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}user_id`];
        if (!userId) userId = 0;
        return userId;
    },

    getById: (id) => {
        console.log(`UserService.getById(${id})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${id}`;
        return authAxios.get(url);
    },

    updateUser: (userId, userData) => {
        console.log(`UserService.updateUser(${userId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}`;
        return authAxios.patch(url, userData);
    },

    postImage: (userId, base64) => {
        console.log(`UserService.postImage('${userId}')`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/image`;
        return authAxios.post(url, { base64 });
    },

    resendEmailVerification: (userId) => {
        console.log(`UserService.resendEmailVerification(${userId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/email/verification/resend`;
        return authAxios.post(url, { user_id: userId });
    },

    addFavourite: (userId, spaceId) => {
        console.log(`UserService.addFavourite(${userId}, ${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/favourite`;
        return authAxios.post(url, { user_id: userId, space_id: spaceId });
    },

    getFavourites: (userId) => {
        console.log(`UserService.getFavourites(${userId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/favourite`;
        return authAxios.get(url);
    },

    removeFavourite: (userId, spaceId) => {
        console.log(`UserService.removeFavourite(${userId}, ${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/favourite/space/${spaceId}`;
        return authAxios.delete(url);
    },

    getBookings: (userId) => {
        console.log(`UserService.getBookings(${userId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/booking`;
        return authAxios.get(url);
    },

    cancelBooking: (userId, bookingId) => {
        console.log(`UserService.cancelBooking(${userId}, ${bookingId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/bookings/${bookingId}/cancellation`;
        return authAxios.patch(url);
    },

    getReviews: (userId) => {
        console.log(`UserService.getReviews(${userId})`);
        const url = `${process.env.REACT_APP_API_URL}/user/${userId}/review`;
        return authAxios.get(url);
    }

}

export default UserService;