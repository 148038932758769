import React from "react";
import NumberFormat from "react-number-format";

export const PriceDisplay = ({ value, currency = 'ARS' }) => <NumberFormat
    prefix={`${currency} `}
    value={+value}
    decimalSeparator={','}
    thousandSeparator={'.'}
    decimalScale={0}
    displayType={'text'}
    renderText={value => <span>{value}</span>}
/>;
