import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';
import HostService from '../../services/HostService';

const LogoutButton = () => {
    const { logout } = useAuth0();
    const { isHostSection } = HostService;
    const buttonColor = isHostSection() ? '#00D1BF' : '#61257D';

    return <Button onClick={() => logout({ returnTo: window.location.origin })} variant="outlined" style={{
        borderRadius: '10px',
        borderColor: buttonColor,
        color: buttonColor,
        width: '140px',
        height: '50px',
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    }}>Salir</Button>;
};

export default LogoutButton;