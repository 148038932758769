import { Box, IconButton } from "@material-ui/core";
import React from "react";
import moment from 'moment';

const { useMediaQuery } = require('react-responsive');

const SearchBar = ({ searchCriteria, setShow }) => {
    const isLessThan500pxWitdth = useMediaQuery({ query: '(max-width: 500px)' });
    const isLessThan900pxWitdth = useMediaQuery({ query: '(max-width: 900px)' });

    const { date, dateFrom, dateTo, quantity, locationName } = searchCriteria;

    return <Box style={{
        border: '1px solid #D8D8D8',
        display: 'flex'
    }}>
        <Box style={{
            padding: '15px',
            flexDirection: isLessThan500pxWitdth ? 'column' : 'row',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: isLessThan500pxWitdth ? 'left' : 'center',
            flexBasis: isLessThan900pxWitdth ? '80%' : '50%'
        }}>
            <Box style={{
                margin: '0 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: isLessThan500pxWitdth ? 'space-between' : ''
            }}>
                <span>{locationName}</span>
                <img src={require('../../assets/images/icon/location.png')} alt="" style={{
                    marginLeft: '10px',
                    paddingRight: '4px'
                }} />
            </Box>
            <Box style={{
                margin: '0 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: isLessThan500pxWitdth ? 'space-between' : ''
            }}>
                {dateFrom && dateTo ?
                    <span>{moment(dateFrom).format('DD/MM/YYYY')} a {moment(dateTo).format('DD/MM/YYYY')}</span>
                    :
                    <span>{date? moment(date).format('DD/MM/YYYY') : null}</span>
                }
                <img src={require('../../assets/images/icon/calendar.png')} alt="" style={{
                    marginLeft: '10px',
                    paddingRight: '2px'
                }} />
            </Box>
            <Box style={{
                margin: '0 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: isLessThan500pxWitdth ? 'space-between' : ''
            }}>
                <span>{quantity}</span>
                <img src={require('../../assets/images/icon/profile.png')} alt="" style={{
                    marginLeft: '10px'
                }} />
            </Box>
            {!isLessThan500pxWitdth && <EditSearchButton clickAction={() => setShow(true)} />}
        </Box>
        {isLessThan500pxWitdth && <EditSearchButton clickAction={() => setShow(true)} />}
    </Box>;
};

export default SearchBar;

const EditSearchButton = ({ clickAction }) => {

    return <Box style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }}>
        <IconButton className="editSearchButton" onClick={clickAction} style={{ height: '40px', width: '40px', marginRight: '15px' }}>
            <img src={require('../../assets/images/edit-search.png')} alt="" />
        </IconButton>
    </Box>;
}