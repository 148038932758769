import React, { useContext, useState, useEffect } from "react";
import { Box, InputBase, FormHelperText, Checkbox } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '../../styled/StyledTextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import StyledInputBase from '../../styled/StyledInputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import OnboardingButtons from "./OnboardingButtons";
import DataService from "../../../services/DataService";
import EditionButtons from "../dashboard/EditionButtons";
import is from "is_js";
import SpaceTitle from "../../publication/SpaceTitle";
import { PriceDisplay } from "../../styled/PriceDisplay";

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Pricing = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context, isOnboarding } = props;
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const [price, setPrice] = useState(space?.price || '');
    const [currency, setCurrency] = useState('');

    const [discountCheck, setDiscountCheck] = useState(false);
    const [discount, setDiscount] = useState(0);

    const [weekDiscountCheck, setWeekDiscountCheck] = useState(false);
    const [weekDiscount, setWeekDiscount] = useState(0);

    const [monthDiscountCheck, setMonthDiscountCheck] = useState(false);
    const [monthDiscount, setMonthDiscount] = useState(0);

    const [currencyOptions, setCurrencyOptions] = useState([])
    const [errors, setErrors] = useState({});
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (!init) {
            setInit(true)
            if (space?.discount_percentage > 0) {
                setDiscountCheck(true);
                setDiscount(space?.discount_percentage);
            }
            if (space?.discount_week_percentage > 0) {
                setWeekDiscountCheck(true);
                setWeekDiscount(space?.discount_week_percentage);
            }
            if (space?.discount_month_percentage > 0) {
                setMonthDiscountCheck(true);
                setMonthDiscount(space?.discount_month_percentage);
            }
        }
        // eslint-disable-next-line
    }, [init])

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getCurrencyOptions = async () => {
            let resp = await DataService.getCurrencies();
            mounted && setCurrencyOptions(resp.data);
        }
        getCurrencyOptions().then(() => {
            mounted && setCurrency(space?.currency?.id || '');
        });

        return () => mounted = false;
        // eslint-disable-next-line
    }, []);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '70%'
        },
        ...contextStyles
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(price) || is.falsy(price)) errorsAcum.price = REQUIERED_FIELD_ERROR;
        if (is.empty(currency) || is.falsy(currency)) errorsAcum.currency = REQUIERED_FIELD_ERROR;
        if (monthDiscountCheck && is.empty(monthDiscount)) errorsAcum.monthDiscount = REQUIERED_FIELD_ERROR;
        if (weekDiscountCheck && is.empty(weekDiscount)) errorsAcum.weekDiscount = REQUIERED_FIELD_ERROR;
        if (discountCheck && is.empty(discount)) errorsAcum.discount = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getSpaceData = (active = space.active) => ({
        price,
        currency_id: currency,
        discount_percentage: discountCheck ? discount : 0,
        discount_week_percentage: weekDiscountCheck ? weekDiscount : 0,
        discount_month_percentage: monthDiscountCheck ? monthDiscount : 0,
        active
    });

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Valor de tu espacio</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Establecé un precio de base por defecto</div>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>El precio será por el uso durante una jornada diaria, por persona</div>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>
                        <SpaceTitle space={space} />
                    </div>
                    <FormControl style={{ ...styles.formControl, order: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                        <FormControl
                            error={!!errors.price}
                            style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <NumberFormat
                                    style={{ flexGrow: 4 }}
                                    value={price}
                                    onValueChange={e => setPrice(e.floatValue)}
                                    id="currency" label="Valor" required
                                    customInput={TextField}
                                    autoComplete="false"
                                    decimalSeparator={','}
                                    thousandSeparator={'.'}
                                    prefix={'$ '} />
                            </div>
                            <FormHelperText>{errors.price}</FormHelperText>
                        </FormControl>
                        <FormControl
                            error={!!errors.currency}
                            style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 0 0 10px', height: '48px' }}>
                            <InputLabel shrink id="sex-label">Moneda</InputLabel>
                            <Select id="sex" value={currency} onChange={e => setCurrency(e.target.value)} displayEmpty input={<StyledInputBase />}>
                                {currencyOptions.map(c => <MenuItem key={'currency_' + c.id} value={c.id}>{`${c.name} (${c.code})`}</MenuItem>)}
                            </Select>
                            <FormHelperText>{errors.currency}</FormHelperText>
                        </FormControl>
                    </FormControl>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Podés ofrecer un descuento <b>mensual</b></div>
                    <FormControl error={!!errors.monthDiscount}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel control={<>
                                <Checkbox value={monthDiscountCheck} onChange={e => {
                                    setMonthDiscountCheck(e.target.checked);
                                    setMonthDiscount(0);
                                }} checked={monthDiscountCheck} />
                                <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                    <NumberFormat value={monthDiscount}
                                        onValueChange={e => {
                                            let value = e.value;
                                            if (value >= 101) value = 100;
                                            if (value <= 0) {
                                                value = ''
                                                if (value < 0) setMonthDiscountCheck(false);
                                            } else {
                                                setMonthDiscountCheck(true);
                                            }
                                            setMonthDiscount(value);
                                        }}
                                        id="monthDiscount" label="" required
                                        isNumericString={true}
                                        placeholder="0% OFF"
                                        customInput={InputBase}
                                        suffix={'% OFF'} />
                                </div>
                            </>} />
                            {monthDiscountCheck && price && <Box><PriceDisplay value={price} />{` x 22 días - ${monthDiscount}% = `} <b><PriceDisplay value={price * 22 - (price * 22 * monthDiscount / 100)} /></b></Box>}
                        </Box>
                        <FormHelperText>{errors.monthDiscount}</FormHelperText>
                    </FormControl>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Podés ofrecer un descuento <b>semanal</b></div>
                    <FormControl error={!!errors.weekDiscount}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel control={<>
                                <Checkbox value={weekDiscountCheck} onChange={e => {
                                    setWeekDiscountCheck(e.target.checked);
                                    setWeekDiscount(0);
                                }} checked={weekDiscountCheck} />
                                <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                    <NumberFormat value={weekDiscount}
                                        onValueChange={e => {
                                            let value = e.value;
                                            if (value >= 101) value = 100;
                                            if (value <= 0) {
                                                value = ''
                                                if (value < 0) setWeekDiscountCheck(false);
                                            } else {
                                                setWeekDiscountCheck(true);
                                            }
                                            setWeekDiscount(value);
                                        }}
                                        id="weekDiscount" label="" required
                                        isNumericString={true}
                                        placeholder="0% OFF"
                                        customInput={InputBase}
                                        suffix={'% OFF'} />
                                </div>
                            </>} />
                            {weekDiscountCheck && price && <Box><PriceDisplay value={price} />{` x 5 días - ${weekDiscount}% = `} <b><PriceDisplay value={price * 5 - (price * 5 * weekDiscount / 100)} /></b></Box>}
                        </Box>
                        <FormHelperText>{errors.weekDiscount}</FormHelperText>
                    </FormControl>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Podés ademas dar un descuento a los usuarios por dia (reservas de hasta 4 dias)</div>
                    <FormControl error={!!errors.discount}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel control={<>
                                <Checkbox value={discountCheck} onChange={e => {
                                    setDiscountCheck(e.target.checked);
                                    setDiscount(0);
                                }} checked={discountCheck} />
                                <div className="discountInputContainer" style={{ border: '2px solid #979797', borderRadius: '3px', color: '#6D7278', opacity: 0.7, padding: '8px', width: '75px' }}>
                                    <NumberFormat value={discount}
                                        onValueChange={e => {
                                            let value = e.value;
                                            if (value >= 101) value = 100;
                                            if (value <= 0) {
                                                value = ''
                                                if (value < 0) setDiscountCheck(false);
                                            } else {
                                                setDiscountCheck(true);
                                            }
                                            setDiscount(value);
                                        }}
                                        id="discount" label="" required
                                        isNumericString={true}
                                        placeholder="0% OFF"
                                        customInput={InputBase}
                                        suffix={'% OFF'} />
                                </div>
                            </>} />
                            {discountCheck && price && <Box><PriceDisplay value={price} />{` - ${discount}% = `} <b><PriceDisplay value={price - (price * discount / 100)} /></b></Box>}
                        </Box>
                        <FormHelperText>{errors.discount}</FormHelperText>
                    </FormControl>
                </Box>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ? <OnboardingButtons stepUtils={stepUtils}
            nextFn={() => validateFormData() && stepUtils.saveSpaceAndNext(getSpaceData(true))}
            prevFn={() => stepUtils.previousStep()}
            closeFn={() => validateFormData() && stepUtils.saveSpaceAndClose(getSpaceData(true))} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={() => validateFormData() && stepUtils.updateSpace(getSpaceData())} />
        }
    </Box>;
};

export default Pricing;