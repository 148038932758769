import React, { useState, useContext } from "react";
import { Box } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import { useAuth0 } from "@auth0/auth0-react";
import is from "is_js";
import PartnerForm from '../../booking/PartnerForm';

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Partners = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { isOnboarding, context } = props;
    const { styles: contextStyles, stepUtils } = useContext(context);
    const { user } = useAuth0();

    var store = require('store');
    const booking = store.get(`${user.sub}_new_booking`);

    const emptyPartner = {
        name: '',
        lastname: '',
        birth: '',
        identification_number: ''
    }
    const [partners, setPartners] = useState(Array.apply(null, Array(+booking.quantity - 1)).map(function () { return { ...emptyPartner } }) || []);

    const [errors, setErrors] = useState({});

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty('name')) errorsAcum.name = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }


    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        ...contextStyles
    }

    if (+booking.quantity < 2) {
        stepUtils.nextStep();
        return null;
    }


    return <Box style={styles.stepsView}>
        <Box className="onboardingForm personalInfoForm">
            <div style={styles.onboardingTitle}>
                <span>Datos de mis compañeros/as de trabajo</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            {partners.map((pt, index) =>
                <PartnerForm key={'partner_' + index}
                    partner={partners[index]}
                    index={index}
                    errors={errors?.partners ? errors.partners[index] : {}}
                    setPartner={p =>
                        setPartners(ps => {
                            let aux = [...ps];
                            aux[index] = p;
                            return aux;
                        })
                    } />
            )}
        </Box>
        {isOnboarding ?
            <OnboardingButtons stepUtils={stepUtils}
                nextFn={() => validateFormData() && stepUtils.saveBookingAndNext({ partners })}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => validateFormData() && stepUtils.saveBookingAndClose({ partners })} />
            : null}
    </Box>
};

export default Partners;