import React from "react";
import { useMediaQuery } from "react-responsive";
import { Line } from 'react-chartjs-2';

const data = {
    labels: ['Junio 2020', 'Julio 2020', 'Agosto 2020', 'Septiembre 2020', 'Octubre 2020', 'Noviembre 2020'],
    datasets: [
        {
            label: 'Gasto mensual',
            data: [12000000, 19000000, 3000000, 18000000, 24000000, 300000],
            fill: false,
            backgroundColor: 'rgb(126, 45, 135)',
            borderColor: 'rgba(126, 45, 135, 0.5)',
        },
        {
            label: 'Limite',
            data: [25245000, 25245000, 25245000, 25245000, 25245000, 25245000],
            fill: false,
            backgroundColor: 'red',
            borderColor: 'red',
            borderWidth: 1
        },
    ],
}

const CompanyHomeChart = ({ bookingsList }) => {
    const isLessThan900pxWidth = useMediaQuery({ query: '(max-width: 900px)' });

    return <Line data={data} options={{
        onResize: function (chart, size) {
            chart.height = isLessThan900pxWidth ? 200 : size.height > 200 ? 200 : size.height;
            chart.update();
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: function (value, index, values) {
                        return 'COP ' + value.toLocaleString('es-AR', { minimumFractionDigits: 0 });
                    }
                }
            }]
        }
    }} height={isLessThan900pxWidth ? 200 : 60} />
};

export default CompanyHomeChart;


