import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Button, DialogContent, ListItem, Dialog, List, DialogActions, FormControl } from "@material-ui/core";
import HostService from '../../../services/HostService';
import { useAuth0 } from "@auth0/auth0-react";
import PublicationPreview from "./PublicationPreview";
import { useHistory } from "react-router-dom";
import HostSpaceService from "../../../services/HostSpaceService";
import Calendar from 'react-calendar-multiday'
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { useMediaQuery } from "react-responsive";


const Publications = () => {
    const [publications, setPublications] = useState([]);
    const [loadingPubs, setLoadingPubs] = useState(false);
    const { user, isAuthenticated } = useAuth0();
    const history = useHistory();

    const [errorsOpen, setErrorsOpen] = useState(false);
    const [pubErrors, setPubErrors] = useState([]);

    const [publicationsToEdit, setPublicationsToEdit] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);

    // eslint-disable-next-line
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setPublicationsToEdit([]);
        setModalOpen(false);
    };

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getPublicationsInit = async () => {
            setLoadingPubs(true);
            let resp = await HostService.getSpaceByHostId(HostService.getHostId(user));
            (isAuthenticated && mounted) && setPublications(resp.data);
            setLoadingPubs(false);
        }
        getPublicationsInit();
        return () => mounted = false;
    }, [user, isAuthenticated]);

    const toggleSpaceActive = (publication, active) => {
        const { activate, deactivate } = HostSpaceService;
        const toggle = active ? activate : deactivate;

        setPubErrors([]);

        let hostId = HostService.getHostId(user);
        toggle(hostId, publication.id).then(resp => {
            if (resp.data.errors) {
                setErrorsOpen(true);
                setPubErrors(resp.data.errors);
            } else {
                setPublications(pubs => {
                    let i = pubs.indexOf(publication);
                    let updatedPubs = [...pubs];
                    updatedPubs[i].active = active;
                    return updatedPubs;
                });
            }
        });
    };

    const getPublications = async () => {
        setLoadingPubs(true);
        let resp = await HostService.getSpaceByHostId(HostService.getHostId(user));
        isAuthenticated && setPublications(resp.data);
        setLoadingPubs(false);
    }

    if (loadingPubs) return <div style={styles.loading}><CircularProgress /></div>;

    return <Box style={{ padding: '15px', border: '1px solid #D8D8D8', backgroundColor: '#ffffff', borderRadius: '5px' }}>
        {/*
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '15px' }}>
            <Button variant="contained"
                onClick={handleModalOpen}
                disabled={is.empty(publicationsToEdit)}
                style={{
                    backgroundColor: is.empty(publicationsToEdit) ? '' : '#00D1BF',
                    color: '#ffffff',
                    fontFamily: 'Poppins',
                    borderRadius: '10px',
                    boxShadow: 'none',
                    height: '50px',
                    fontWeight: 'bold',
                }}>Editar Disponibilidad</Button>
        </Box>
            */}
        {publications.map(p => <PublicationPreview pub={p} key={p.id + '_pub'} toggleSpaceActive={e => toggleSpaceActive(p, e.target.checked)} refreshPubs={getPublications} checkOn={true} setPublicationsToEdit={setPublicationsToEdit} checked={publicationsToEdit.includes(p.id)} />)}
        <Button onClick={() => {
            sessionStorage.removeItem(user?.sub + '_space');
            history.push('/host/spaceCreation');
        }} variant="contained" style={{
            backgroundColor: '#00D1BF',
            color: '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: '100%',
            height: '50px',
            fontWeight: 'bold',
        }}>Crear un nuevo anuncio</Button>

        {modalOpen && <MassiveAvailabilityEditionModal open={modalOpen} onClose={handleModalClose} spaceIds={publicationsToEdit} reloadPubs={getPublications} />}

        <Dialog open={errorsOpen}>
            <DialogContent>
                <b>No podemos publicar tu espacio ya que falta que completes la siguiente información:</b>
                <List>
                    {pubErrors.map(pe => <ListItem>{pubErrorsLabels[pe.message]}</ListItem>)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: '#00D1BF' }} onClick={() => setErrorsOpen(false)} color="primary"> Cerrar</Button>
            </DialogActions>
        </Dialog>
    </Box>;
};

export default Publications;

const pubErrorsLabels = {
    'space-type-required': 'Tipo de espacio',
    'images-required': 'Fotos de tu espacio',
    'capacity-required': 'Capacidad',
    'check-in-out-required': 'Rango horario',
    'location-required': 'Ubicación',
    'building-type-required': 'Tipo de edificio',
    'services-required': 'Servicios adicionales',
    'price-required': 'Valor de tu espacio',
    'availabilities-required': 'Disponibilidad',
    'description-required': 'Descripción de tu espacio',
    'payment-method-required': 'Medios de pago'
}

const styles = {
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
        height: '100%',
        width: '100%',
        backgroundColor: '#ffffff',
    }
}

const MassiveAvailabilityEditionModal = ({ open, onClose, spaceIds, reloadPubs }) => {
    const { user } = useAuth0();
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 800px)' });
    const [availableDates, setAvailableDates] = useState([]);
    const [isUnlock, setIsUnlock] = useState(true);
    const [currentMonthName, setCurrentMonthName] = useState('');
    const [loading, setLoading] = useState(false);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        calendarBoxes: {
            width: isNarrowWidth ? '100%' : '45%',
        }
    }

    const dateSelection = (e) => {
        setAvailableDates(e.selected.map(e => momentTimezone.tz(e, momentTimezone.tz.guess())));
    }

    const toggleMonthAvailability = () => {
        isUnlock ? unlockCurrentMonth() : lockCurrentMonth();
        setIsUnlock(isUnlock => !isUnlock);
    }

    const monthChanged = () => {
        setTimeout(() => {
            setCurrentMonthName(document.getElementsByClassName('i_day-picker-title')?.[0]?.innerText);
            setAvailableDates([]);
            setLockOrUnlock();
        }, 100);
    }

    useEffect(() => {
        let monthNavigationButtons;
        setTimeout(() => {
            setCurrentMonthName(document.getElementsByClassName('i_day-picker-title')?.[0]?.innerText);
            setLockOrUnlock();
            monthNavigationButtons = document.getElementsByClassName("e_day-picker-arrow-container");
            for (var i = 0; i < monthNavigationButtons.length; i++)
                monthNavigationButtons[i].addEventListener('click', monthChanged);
        }, 100);

        return () => {
            for (var i = 0; i < monthNavigationButtons.length; i++)
                monthNavigationButtons[i].removeEventListener('click', monthChanged);
        };
        // eslint-disable-next-line 
    }, [availableDates]);

    const getCurrentMonthDays = () => {
        let arrDays = [];
        let selectedMonthNumber = document.querySelector('.i_day-picker-body .i_day-picker-row:nth-child(2) div')?.getAttribute('data-date')?.split('-')[0];
        let selectedyear = document.querySelector('.i_day-picker-title')?.textContent.replace(/\D/g, '');
        if (selectedyear && selectedMonthNumber) {
            let daysInMonth = moment(`${selectedyear}-${selectedMonthNumber}-01`).daysInMonth();
            let currentTz = momentTimezone.tz.guess();
            while (daysInMonth) {
                var current = momentTimezone.tz(`${selectedyear}-${selectedMonthNumber}-${pad(daysInMonth)} 00:00`, currentTz);
                const isTodayOrAfter = (current.isAfter(moment()) || current.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'));
                const isWeekDay = (current.isoWeekday() !== 6 && current.isoWeekday() !== 7);
                if (isTodayOrAfter && isWeekDay) arrDays.push(current);
                daysInMonth--;
            }
        }
        return arrDays;
    }

    const setLockOrUnlock = () => {
        setTimeout(() => {
            let currentMonthDays = getCurrentMonthDays();
            if (currentMonthDays.length > 0) {
                let currentMonthNumber = currentMonthDays[0].month();
                let currentMonthAvailableDays = availableDates.filter(d => d.month() === currentMonthNumber);
                let currentMonthLockedDays = currentMonthDays.length - currentMonthAvailableDays.length;
                (currentMonthLockedDays > currentMonthAvailableDays.length) ? setIsUnlock(true) : setIsUnlock(false);
            }
        }, 100);
    }

    const unlockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();
        setAvailableDates(ad => [...ad, ...currentMonthDays]);
    }

    const lockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();

        // substract the current month days to the availableDates selected
        let newAvailableDates = (() => {
            let nad = [];
            availableDates.forEach(ad => {
                if (currentMonthDays.filter(cmd => cmd.format('DD-MM-YYYY') === ad.format('DD-MM-YYYY')).length === 0) nad.push(ad)
            })
            return nad;
        })();

        setAvailableDates(newAvailableDates);
    }


    const handleSaveChanges = async () => {
        setLoading(true);
        try {
            const hostId = HostService.getHostId(user);
            let added = availableDates.map(ad => ad.format('YYYY-MM-DD'));
            let currentMonthDays = getCurrentMonthDays().map(cmd => cmd.format('YYYY-MM-DD'));
            let removed = currentMonthDays.filter(cmd => !added.includes(cmd));
            await HostService.massiveAvailabilityEdit(hostId, added, removed, spaceIds);
            reloadPubs();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            onClose();
        }
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth='lg' fullScreen={isMobile}>
            <DialogContent>
                <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', padding: '25px' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                        <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Estás modificando la disponibilidad de <b>{spaceIds.length}</b> espacio{spaceIds.length > 1 && 's'} a la vez.</div>
                        <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>La modificacion pisará la disponibilidad de todo el mes de <b>{currentMonthName}</b>.</div>
                    </Box>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        <FormControl style={{ ...styles.calendarBoxes, marginBottom: isMobile ? '20px' : 0 }}>
                            <Calendar selected={availableDates} className="AvailabilityCalendarForEdition" isMultiple={true} DayComponent={<PositionDay />} onChange={dateSelection} />
                            <Button onClick={toggleMonthAvailability} style={{ color: '#00D1BF', fontFamily: 'Poppins', textTransform: 'none' }}>{isUnlock ? 'Desbloquear' : 'Bloquear'} todo el mes (dias hábiles)</Button>
                        </FormControl>
                        <div style={{ ...styles.calendarBoxes, marginTop: isMobile ? 0 : '45px', marginBottom: isMobile ? 0 : '0px', background: 'rgba(216, 216, 216, 0.128141)', border: '1px solid #D8D8D8', borderRadius: '3px', padding: isMobile ? '5px' : '25px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minHeight: '300px', textAlign: 'left' }}>
                            <div style={{ color: '#818181', fontSize: isMobile ? '14px' : '18px' }}>Tu calendario está configurado para estar completamente bloqueado de manera predeterminada</div>
                            <div style={{ color: '#818181', fontSize: isMobile ? '14px' : '18px' }}>Cero días disponibles para reservar</div>
                            <div style={{ color: '#00D1BF', fontSize: isMobile ? '14px' : '18px' }}>Cambiar la configuración de disponibilidad</div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', backgroundColor: 'white', border: '0.7px solid #D8D8D8', borderRadius: '2.1px' }}></div>
                                    <div style={{ color: '#818181', paddingLeft: '10px' }}>Disponible</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    <div className="diagonal-container" style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: '0.7px solid #D8D8D8', borderRadius: '2.1px' }}></div>
                                    <div style={{ color: '#818181', paddingLeft: '10px' }}>Bloqueada</div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined"
                    onClick={onClose}
                    style={{
                        color: '#00D1BF',
                        fontFamily: 'Poppins',
                        borderColor: '#00D1BF',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: '100%',
                        height: '50px',
                        fontWeight: 'bold',
                    }}>Cancelar</Button>
                <Button variant="contained"
                    onClick={() => handleSaveChanges()}
                    disabled={loading}
                    style={{
                        backgroundColor: '#00D1BF',
                        color: '#ffffff',
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: '100%',
                        height: '50px',
                        fontWeight: 'bold',
                    }}>{loading ? <CircularProgress /> : 'Confirmar'}</Button>
            </DialogActions>
        </Dialog>
    );
}


const PositionDay = props => {

    const onClick = (e) => {
        if (props.isInThePast) e.stopPropagation();
    }
    return (
        <div className={(props.isSelected || !isFromSelectedMonth(props.date)) ? '' : 'diagonal-container'}>
            <div onClick={onClick}
                className={getStyle(props)}
                style={{ ...getInline(props), display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ fontSize: '14px', color: !isFromSelectedMonth(props.date) && '#383839', opacity: !isFromSelectedMonth(props.date) && '0.5', }}>{props.label}</div>
            </div>
        </div>)
}

const getStyle = function ({ date, isSelected }) {
    return `${isSelected ? 'o_selected-day' : ''} ${date.type}-day`
}

const getInline = ({ isToday, isInThePast }) => ({
    cursor: isInThePast ? 'not-allowed' : 'inherit',
    background: isInThePast ? '#e4e4e4' : 'inherit',
    color: isInThePast ? '#555555' : 'inherit',
})

const isFromSelectedMonth = date => (date.type === 'month');

const pad = d => (d < 10) ? '0' + d.toString() : d.toString();