import { Box, Button, CircularProgress, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, List, ListItem, MuiThemeProvider, TextField } from '@material-ui/core';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import HostService from '../../services/HostService';
import UserService from '../../services/UserService';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import * as CONSTS from '../const/constants';
import BookingService from '../../services/BookingService';
import PublicatioHomeResult from '../publication/PublicatioHomeResult';
import SpaceTitle from '../publication/SpaceTitle';
import { PriceDisplay } from '../styled/PriceDisplay';
import is from 'is_js';
import SpaceCalification from '../publication/SpaceCalification';
import ReviewDisplay from '../booking/ReviewDisplay';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { LocalizationProvider, PickersDay, StaticDatePicker } from "@material-ui/pickers";
import MomentUtils from '@material-ui/pickers/adapter/moment';

const BookingDisplay = ({ booking, reloadBookings, isHost }) => {
    const isLessThan1100pxWidth = useMediaQuery({ query: '(max-width: 1100px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { user } = useAuth0();
    const [reviewsModalOpen, setReviewsModalOpen] = useState(false);
    const [reviews, setReviews] = useState();

    const [multidateCalendarModalOpen, setMultidateCalendarModalOpen] = useState(false);

    const showHostData = ![1, 3].includes(booking.host_status);

    const goToPublication = spaceId => {
        const newWindow = window.open(`/publication/${spaceId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    const handleReviewModalOpen = async () => {
        try {
            const resp = await HostService.getUserReviews(HostService.getHostId(user), booking.id);
            setReviews(resp.data);
            setReviewsModalOpen(true);
        } catch (e) {
            console.log("Error:", e);
        }
    }

    return <Box style={{ justifyContent: isMobile ? 'center' : 'space-between', flexDirection: isLessThan1100pxWidth ? 'column' : 'row', padding: isMobile ? '10px' : 20, border: '1px solid lightgrey', borderRadius: '15px', marginBottom: 10, textAlign: 'left', display: 'flex', backgroundColor: '#ffffff', width: '100%' }}>
        {isHost ?
            <Box style={{ position: 'relative' }}>{/* SEEN BY HOST */}
                <Box style={{ fontWeight: 'bold', marginBottom: '20px' }}>Reserva #{booking.id}</Box>
                {booking.multiple_date && <>
                    <Link onClick={() => setMultidateCalendarModalOpen(true)} style={{ color: '#7E2D87', cursor: 'pointer' }}>{booking.dates.length} jornadas</Link>
                    <BookingDatesCalendarModal
                        open={multidateCalendarModalOpen}
                        setOpen={setMultidateCalendarModalOpen}
                        booking={booking}
                    />
                </>}
                <Box style={{ fontWeight: 'bold', marginBottom: '20px' }}>Detalles del Huésped</Box>
                {booking.user.reviews_count > 0 && <Button variant="outlined" style={{ borderColor: '#0E0E0E', fontFamily: 'Poppins', position: isMobile ? '' : 'absolute', right: 0 }} onClick={handleReviewModalOpen}><Box><SpaceCalification space={booking.user} showText={true} byHost={true} /></Box></Button>}
                <Box style={{ lineHeight: 2 }}>{`${booking.user.name} ${booking.user.lastname}, ${moment().diff(booking.user.birth, 'years', false)} años`}</Box>
                <ReviewsModal
                    open={reviewsModalOpen}
                    setOpen={setReviewsModalOpen}
                    reviews={reviews}
                />
                {booking.user.identification_number && <Box style={{ lineHeight: 2 }}><NumberFormat prefix={'DNI: '}
                    value={booking.user.identification_number}
                    decimalSeparator={','}
                    thousandSeparator={'.'}
                    displayType={'text'}
                    renderText={value => <div>{value}</div>} /></Box>}
                {booking.user.birth && <Box style={{ lineHeight: 2 }}>{`Fecha de Nacimiento: ${moment(booking.user.birth).format('DD/MM/YYYY')}`}</Box>}
                {booking.user.phone && <Box>{`Teléfono: ${booking.user.phone}`}</Box>}
                {booking.user.address && is.not.empty(booking.user.address) && <Box style={{ lineHeight: 2 }}>{`Dirección: ${booking.user.address?.street || ''} ${booking.user.address?.street_number || ''} ${booking.user.address?.apt_number ? `, ${booking.user.address?.apt_number}` : ''},  ${booking.user.address?.city || ''}, ${booking.user.address?.zip_code || ''}, ${booking.user.address?.country || ''} `}</Box>}
                {booking.user.email && <Box style={{ lineHeight: 2 }}>{`Correo: ${booking.user.email}`}</Box>}
                {booking.user.languages && <Box style={{ lineHeight: 2 }}>{`Idiomas: ${booking.user.languages?.map(l => ` ${l.name}`)}`}</Box>}

                {booking.user.networking && is.not.empty(booking.user.networking) && <>
                    {booking.user.networking.company && <Box style={{ lineHeight: 2 }}>{`Empresa: ${booking.user.networking.company || ''}`}</Box>}
                    {booking.user.networking.position && <Box style={{ lineHeight: 2 }}>{`Posición: ${booking.user.networking.position || ''}`}</Box>}
                    {booking.user.networking.interests && <Box style={{ lineHeight: 2 }}>{`Intereses: ${booking.user.networking.interests?.map(i => ` ${i}`) || ''}`}</Box>}
                    {booking.user.networking.linkedin_url && <Box style={{ lineHeight: 2 }}>{`Linkedin: ${booking.user.networking.linkedin_url || ''}`}</Box>}
                    {booking.user.networking.facebook_url && <Box style={{ lineHeight: 2 }}>{`Facebook: ${booking.user.networking.facebook_url || ''}`}</Box>}
                    {booking.user.networking.instagram_url && <Box style={{ lineHeight: 2 }}>{`Instagram: ${booking.user.networking.instagram_url || ''}`}</Box>}
                </>}
                
                <Box style={{ display: 'flex', maxWidth: '94vw' }}>
                    <img src={booking.user?.identification_attachment_front?.original_url} alt="" style={{ maxHeight: '250px', maxWidth: '45%', objectFit: 'contain', margin: 5, borderRadius: 5 }} />
                    <img src={booking.user?.identification_attachment_back?.original_url} alt="" style={{ maxHeight: '250px', maxWidth: '45%', objectFit: 'contain', margin: 5, borderRadius: 5 }} />
                </Box>
                <Box>
                    {booking.partners.map((p, index) => <Box key={p.name} style={{ marginBottom: '10px' }}>
                        <Box style={{ fontWeight: 600 }}>Datos del compañero/a #{index + 1}</Box>
                        <Box style={{ marginLeft: 10 }}>{`${p.name} ${p.lastname}, ${moment().diff(p.birth, 'years', false)} años`}</Box>
                        <Box style={{ marginLeft: 10 }}><NumberFormat prefix={'DNI: '}
                            value={p.identification_number}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            displayType={'text'}
                            renderText={value => <div>{value}</div>} /></Box>
                        <Box style={{ marginLeft: 10 }}>{`Fecha de Nacimiento: ${moment(p.birth).format('DD/MM/YYYY')}`}</Box>
                    </Box>)}
                </Box>
            </Box>
            :
            <Box>{/* SEEN BY USER */}
                <Box style={{ fontWeight: 'bold', marginBottom: '10px' }}>Detalles de la Reserva #{booking.id}</Box>
                {booking.multiple_date && <>
                    <Link onClick={() => setMultidateCalendarModalOpen(true)} style={{ color: '#7E2D87', cursor: 'pointer' }}>{booking.dates.length} jornadas</Link>
                    <BookingDatesCalendarModal
                        open={multidateCalendarModalOpen}
                        setOpen={setMultidateCalendarModalOpen}
                        booking={booking}
                    />
                </>}
                <PublicatioHomeResult space={booking.space} clickAction={() => goToPublication(booking.space.id)} isSelected={true} />
                {showHostData && <>
                    <Box style={{ lineHeight: 2 }}>{`Dirección: ${booking.space.location?.street} ${booking.space.location?.street_number} ${booking.space.location?.apt_number ? `, ${booking.space.location?.apt_number}` : ''},  ${booking.space.location?.city}, ${booking.space.location?.zip_code}, ${booking.space.location?.country} `}</Box>
                    <Box style={{ lineHeight: 2 }}>
                        <span>Check in: </span>
                        <span>{booking.space?.check_in_time}</span>
                    </Box>
                    <Box style={{ lineHeight: 2 }}>
                        <span>Check out: </span>
                        <span>{booking.space?.check_out_time}</span>
                    </Box>
                    <Box style={{ fontWeight: 'bold', marginBottom: '5px', marginTop: 15 }}>Anfitrión</Box>
                    <Box style={{ lineHeight: 2 }}>{`${booking.space.host.name} ${booking.space.host.lastname}, ${moment().diff(booking.space.host.birth, 'years', false)} años`}</Box>
                    <Box style={{ lineHeight: 2 }}><NumberFormat prefix={'DNI: '}
                        value={booking.space.host.identification_number}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        displayType={'text'}
                        renderText={value => <div>{value}</div>} /></Box>
                    <Box>{`Teléfono: ${booking.space.host.phone}`}</Box>
                    <Box style={{ lineHeight: 2 }}>{`Correo: ${booking.space.host.email}`}</Box>
                </>}
                <Box style={{ marginTop: 20 }}>
                    {booking.partners.map((p, index) => <Box key={p.name} style={{ marginBottom: '10px' }}>
                        <Box style={{ fontWeight: 600 }}>Datos del compañero/a #{index + 1}</Box>
                        <Box style={{ marginLeft: 10 }}>{`${p.name} ${p.lastname}, ${moment().diff(p.birth, 'years', false)} años`}</Box>
                        <Box style={{ marginLeft: 10 }}><NumberFormat prefix={'DNI: '}
                            value={p.identification_number}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            displayType={'text'}
                            renderText={value => <div>{value}</div>} /></Box>
                        <Box style={{ marginLeft: 10 }}>{`Fecha de Nacimiento: ${moment(p.birth).format('DD/MM/YYYY')}`}</Box>
                    </Box>)}
                </Box>
            </Box>}
        <Box style={{ flexBasis: '45%', border: '1px solid lightgrey', borderRadius: 15, padding: isMobile ? '10px' : 20, marginLeft: isMobile ? 0 : 10, marginTop: 15 }}>
            <div style={{ marginBottom: 15 }}>
                <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839', fontWeight: 500 }}><SpaceTitle space={booking.space} /></div>
                <div style={{ fontFamily: 'Poppins', fontSize: '14px', color: '#383839' }}>{booking.space?.buildingType?.name}</div>
            </div>
            <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                <Box>{`${booking.dates.length ? booking.dates.length : 1} jornadas, por persona (${booking.space_quantity})`}</Box>
                <PriceDisplay value={booking.space_total_price} />
            </Box>
            {booking.total_discount > 0 && <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                <Box>{`Descuento`}</Box>
                <PriceDisplay value={-booking.total_discount} />
            </Box>}
            <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                <Box>{`Costo de Servicio`}</Box>
                <PriceDisplay value={booking.fee_amount} />
            </Box>
            {is.not.empty(booking.space_services) && <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                <Box>{`Servicios adicionales`}</Box>
                <PriceDisplay value={booking.services_total_price} />
            </Box>}
            {booking.space_services?.map(ss =>
                <Box key={ss.service_name + '_ss'} style={{ display: 'flex', justifyContent: 'space-between', color: 'grey', marginLeft: '10px', lineHeight: 2 }}>
                    <Box>{`${ss.service_name} (${ss.quantity})`}</Box>
                    <PriceDisplay value={ss.total_price} />
                </Box>
            )}
            {booking.promocode_amount > 0 && <Box style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 2 }}>
                <Box>{`Código promocional`}</Box>
                <PriceDisplay value={-booking.promocode_amount} />
            </Box>}
            <Box style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 600, lineHeight: 2 }}>
                <Box>{`Total`}</Box>
                <PriceDisplay value={booking.final_price} />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>Medio de Pago: {booking.payment_method?.id === 1 ? 'Efectivo' : <img src={require('../../assets/images/mp-logo.png')} alt="" style={{ width: '91px', height: '24px', margin: '5px' }} />}</Box>

            {isHost ? <HostBookingButtons booking={booking} reloadBookings={reloadBookings} /> : <UserBookingButtons booking={booking} reloadBookings={reloadBookings} />}
        </Box>
    </Box>;
};

export const ReviewsModal = ({ open, setOpen, onClose, reviews, isUserReviews = true }) => {

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const handleCloseReviewModal = () => {
        setOpen(false);
    };

    return (
        <Dialog fullScreen={isMobile} onClose={handleCloseReviewModal} aria-labelledby="simple-dialog-title" open={open}>
            <div style={{ textAlign: 'right' }}>
                <IconButton onClick={handleCloseReviewModal}>
                    <HighlightOffRoundedIcon />
                </IconButton>
            </div>
            <div style={{
                fontFamily: 'PoppinsBold',
                fontWeight: 'bold',
                color: '#383839',
                fontSize: '20px',
                textAlign: 'center',
            }} >
                <span>Listado de Evaluaciones</span>
            </div>
            <List>
                {reviews?.sort((a, b) => new Date(b.date) - new Date(a.date)).map((r, i) => (
                    <ListItem key={`reviw_${i}`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <ReviewDisplay review={r} byHost={isUserReviews} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default BookingDisplay;

const HostBookingButtons = ({ booking, reloadBookings }) => {
    const history = useHistory();

    const [showApproveBookingDialog, setShowApproveBookingDialog] = useState(false);
    const [showRejectBookingDialog, setShowRejectBookingDialog] = useState(false);

    const { user } = useAuth0();

    const hostStatus = BookingService.getHostStatus(booking);

    const cancelBooking = async () => {
        let resp = await HostService.cancelBooking(HostService.getHostId(user), booking.id);
        console.log(resp);
        reloadBookings();
    }

    if (hostStatus === CONSTS.HOST_STATUS.PENDING_APPROVAL) return <>
        <Button onClick={() => setShowApproveBookingDialog(true)} variant="contained" style={{ color: '#ffffff', backgroundColor: '#00D1BF', width: '100%', height: '40px', borderRadius: '10px', marginTop: '15px', fontFamily: 'Poppins' }}>Aprobar</Button>
        <Button onClick={() => setShowRejectBookingDialog(true)} variant="outlined" style={{ borderColor: '#00D1BF', color: '#00D1BF', backgroundColor: '#ffffff', width: '100%', height: '40px', borderRadius: '10px', marginTop: '15px', fontFamily: 'Poppins' }}>Rechazar</Button>
        <AcceptBookingDialog open={showApproveBookingDialog} setOpen={setShowApproveBookingDialog} booking={booking} reloadBookings={reloadBookings} />
        <RejectBookingDialog open={showRejectBookingDialog} setOpen={setShowRejectBookingDialog} booking={booking} cancelBooking={cancelBooking} />
    </>;


    if (hostStatus === CONSTS.HOST_STATUS.PAYMENT_PENDING)
        return <Box>
            <Box style={{ color: '#7E2D87', border: '1px solid #7E2D87', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>
                <Box>Pago pendiente</Box>
            </Box>
            <CancelBookingInfo booking={booking} reloadBookings={reloadBookings} isHost={true} />
        </Box>;

    if (hostStatus === CONSTS.HOST_STATUS.PAYMENT_ERROR)
        return <Box>
            <Box style={{ color: '#FF4941', border: '1px solid #FF4941', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>
                <Box>ERROR en el pago</Box>
            </Box>
            <CancelBookingInfo booking={booking} reloadBookings={reloadBookings} isHost={true} />
        </Box>;

    if (hostStatus === CONSTS.HOST_STATUS.REJECTED) return <Box style={{ color: '#FF4941', border: '1px solid #FF4941', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>Reserva cancelada por el anfitrión</Box>;

    if (hostStatus === CONSTS.HOST_STATUS.APPROVED) return <Box>
        <Box>Reserva aprobada</Box>
        <CancelBookingInfo booking={booking} reloadBookings={reloadBookings} isHost={true} />
    </Box>;

    if (hostStatus === CONSTS.HOST_STATUS.PENDING_REVIEW) return <Box>
        <Button variant="contained" style={{ color: '#ffffff', backgroundColor: '#00D1BF', width: '100%', height: '40px', borderRadius: '10px', marginTop: '15px', fontFamily: 'Poppins' }} onClick={() => history.push(`/booking/H/${booking.id}/review`)}>Calificar</Button>
    </Box>;

    if (hostStatus === CONSTS.HOST_STATUS.REVIEWED) return <Box style={{ color: '#00D1BF', border: '1px solid #00D1BF', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>Reserva calificada</Box>

    if (hostStatus === CONSTS.HOST_STATUS.CANCELLED_BY_USER) return <Box style={{ color: '#FF4941', border: '1px solid #FF4941', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>Reserva cancelada por el usuario</Box>;

    return <Box>???????????</Box>;
}

const UserBookingButtons = ({ booking, reloadBookings }) => {
    const history = useHistory();

    const userStatus = BookingService.getUserStatus(booking);

    const payWithMercadoPago = payment_url => window.location.href = payment_url;

    if (userStatus === CONSTS.USER_STATUS.PAYMENT_PENDING)
        return <Box>
            {booking.payment_url && <Button onClick={() => payWithMercadoPago(booking.payment_url)} variant="contained" style={{ color: '#ffffff', backgroundColor: '#00D1BF', width: '100%', height: '40px', borderRadius: '10px', marginTop: '15px', fontFamily: 'Poppins' }}>Pagar</Button>}
            <CancelBookingInfo booking={booking} reloadBookings={reloadBookings} isHost={false} />
        </Box>;

    if (userStatus === CONSTS.USER_STATUS.PAYMENT_ERROR)
        return <Box>
            {booking.payment_url && <Button onClick={() => payWithMercadoPago(booking.payment_url)} variant="contained" style={{ color: '#ffffff', backgroundColor: '#00D1BF', width: '100%', height: '40px', borderRadius: '10px', marginTop: '15px', fontFamily: 'Poppins' }}>Pagar</Button>}
            <CancelBookingInfo booking={booking} reloadBookings={reloadBookings} isHost={false} />
        </Box>;

    if (userStatus === CONSTS.USER_STATUS.PENDING_APPROVAL)
        return <Box>
            <Box style={{ color: '#7E2D87', border: '1px solid #7E2D87', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>
                Pendiente de aprobación por Anfitrión
            </Box>
            <CancelBookingInfo booking={booking} reloadBookings={reloadBookings} isHost={false} />
        </Box>;

    if (userStatus === CONSTS.USER_STATUS.APPROVED)
        return <Box>
            <CancelBookingInfo booking={booking} reloadBookings={reloadBookings} isHost={false} />
        </Box>;

    if (userStatus === CONSTS.USER_STATUS.REJECTED)
        return <Box style={{ color: '#FF4941', border: '1px solid #FF4941', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>Reserva cancelada por el anfitrión</Box>;

    if (userStatus === CONSTS.USER_STATUS.CANCELLED_BY_USER)
        return <Box style={{ color: '#FF4941', border: '1px solid #FF4941', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>Reserva cancelada por el usuario</Box>;

    if (userStatus === CONSTS.USER_STATUS.PENDING_REVIEW)
        return <Box>
            <Button variant="contained" style={{ color: '#ffffff', backgroundColor: '#00D1BF', width: '100%', height: '40px', borderRadius: '10px', marginTop: '15px', fontFamily: 'Poppins' }} onClick={() => history.push(`/booking/U/${booking.id}/review`)}>Calificar</Button>
        </Box>;

    if (userStatus === CONSTS.USER_STATUS.REVIEWED)
        return <Box style={{ color: '#00D1BF', border: '1px solid #00D1BF', padding: 10, borderRadius: 15, textAlign: 'center', marginTop: 20 }}>Calificada</Box>;

    return 'EMPTY';
}

const CancelBookingInfo = ({ booking, reloadBookings, isHost }) => {
    const [showCancelBookingDialog, setShowCancelBookingDialog] = useState(false);
    const cancellationAllowed = moment(booking.date).diff(moment(), 'hours') > 24;
    const { user } = useAuth0();

    const cancelBooking = async () => {
        let resp = await UserService.cancelBooking(UserService.getUserId(user), booking.id);
        console.log(resp);
        reloadBookings();
    }

    return <>
        <Button disabled={!cancellationAllowed} onClick={() => setShowCancelBookingDialog(true)} variant="contained" style={{ color: '#ffffff', backgroundColor: cancellationAllowed ? '#FF4941' : '', width: '100%', height: '40px', borderRadius: '10px', marginTop: '15px', fontFamily: 'Poppins' }}>Cancelar Reserva</Button>
        {!cancellationAllowed && <Box style={{ color: '#383839', fontSize: 11, padding: 5 }}>
            <span>Si necesitás ayuda, contactanos en </span>
            <a className="footerLink" style={{ color: '#818181', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href="mailto:contacto@worknmates.com">contacto@worknmates.com</a>
            <span> o por Whatsapp al </span>
            <a className="footerLink" style={{ marginLeft: 5, color: '#818181', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href="https://wa.me/5491122556618">54 9 11 2255-6618</a>
        </Box>}
        {isHost ?
            <Box style={{ fontFamily: 'Poppins', color: '#818181', fontSize: 14, padding: 15 }}>
                <Box>Podrás cancelar las reservas de tu espacio hasta 24 horas antes de cada Check-In, ofreciendo un reembolso del 100% a cada usuario.</Box>
                <Box>No se podrán realizar cancelaciones dentro de las 24 horas de cada Check-In</Box>
                <Box>- Reserva Confirmada</Box>
                <Box>- 24 horas antes: reembolso total</Box>
                <Box>- Dentro de las 24 horas del check-in: Opción de cancelación desactivada</Box>
            </Box>
            :
            <Box style={{ fontFamily: 'Poppins', color: '#818181', fontSize: 14, padding: 15 }}>
                <Box>Podrás cancelar las reservas hasta 24 horas antes de cada Check-In.</Box>
                <Box>No se podrán realizar cancelaciones dentro de las 24 horas de cada Check-In</Box>
                <Box>- Reserva Confirmada</Box>
                <Box>- 24 horas antes: reembolso total</Box>
                <Box>- Dentro de las 24 horas del check-in: Opción de cancelación desactivada</Box>
            </Box>
        }
        <CancelBookingDialog open={showCancelBookingDialog} setOpen={setShowCancelBookingDialog} booking={booking} cancelBooking={cancelBooking} isHost={isHost} />
    </>;
};

const CancelBookingDialog = ({ open, setOpen, booking, cancelBooking, isHost }) => {
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const handleClose = async (isCancel) => {
        if (isCancel) {
            setLoading(true);
            try {
                await cancelBooking();
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false);
            }
        }
        setOpen(false);
    }

    return <Dialog open={open} onClose={handleClose} className={isMobile ? 'mobile cancelBookingDialog' : 'cancelBookingDialog'}>
        <DialogTitle style={{ padding: 15 }}><span style={{ fontFamily: 'Poppins' }}>{'Cancelación de reserva'}</span></DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            {isHost ? <>
                <div style={{ fontFamily: 'Poppins' }}>Estás a un paso de cancelar la reserva de {booking?.user?.name}.</div>
                <div style={{ fontFamily: 'Poppins' }}>¿Estás seguro que querés cancelar la reserva de {booking?.user?.name}?</div>
            </>
                :
                <>
                    <div style={{ fontFamily: 'Poppins' }}>Estás a un paso de cancelar tu reserva en el espacio de {booking?.space?.host?.name}, la misma no tendrá penalidad por estar dentro de los plazos establecidos en la politica de cancelación.</div>
                    <div style={{ fontFamily: 'Poppins' }}>¿Estás seguro que querés cancelar la reserva en el espacio de {booking?.space?.host?.name}?</div>
                </>
            }
        </DialogContent>
        <DialogActions style={{ padding: 15, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Button
                disabled={loading}
                style={{ borderColor: '#FF4941', color: '#FF4941', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(true)}
                color="primary"
                variant="outlined"
                autoFocus>
                {loading ? <CircularProgress /> : 'Cancelar Reserva'}
            </Button>
            <Button
                disabled={loading}
                style={{ backgroundColor: '#FF4941', color: '#FFFFFF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(false)}
                variant="contained"
                color="primary">
                Salir sin cancelar
            </Button>
        </DialogActions>
    </Dialog>;
}

const AcceptBookingDialog = ({ open, setOpen, booking, reloadBookings }) => {
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { user } = useAuth0();

    const acceptBooking = async () => {
        let resp = await HostService.approveBooking(HostService.getHostId(user), booking.id);
        console.log(resp);
        reloadBookings();
    }

    const handleClose = async (isApprove) => {
        if (isApprove) {
            setLoading(true);
            await acceptBooking();
            reloadBookings();
            setLoading(false);
        }
        setOpen(false);
    }
    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ padding: 15 }}><span style={{ fontFamily: 'Poppins' }}>{'Aprobación de reserva'}</span></DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            <div style={{ fontFamily: 'Poppins' }}>¿Quieres aprobar la reserva?</div>
        </DialogContent>
        <DialogActions style={{ padding: 15, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Button
                disabled={loading}
                style={{ borderColor: '#00D1BF', color: '#00D1BF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(false)}
                color="primary"
                variant="outlined"
                autoFocus>
                Cancelar
            </Button>
            <Button
                disabled={loading}
                style={{ backgroundColor: '#00D1BF', color: '#FFFFFF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(true)}
                variant="contained"
                color="primary">
                {loading ? <CircularProgress /> : 'Aprobar'}
            </Button>
        </DialogActions>
    </Dialog>;
}

const RejectBookingDialog = ({ open, setOpen, booking, cancelBooking }) => {
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const handleClose = async (isReject) => {
        if (isReject) {
            setLoading(true);
            try {
                await cancelBooking();
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false);
            }
        }
        setOpen(false);
    }

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ padding: 15 }}><span style={{ fontFamily: 'Poppins' }}>{'Rechazo de reserva'}</span></DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            <div style={{ fontFamily: 'Poppins' }}>¿Quieres rechazar la reserva?</div>
        </DialogContent>
        <DialogActions style={{ padding: 15, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Button
                disabled={loading}
                style={{ borderColor: '#00D1BF', color: '#00D1BF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(true)}
                color="primary"
                variant="outlined"
                autoFocus>
                {loading ? <CircularProgress /> : 'Rechazar'}
            </Button>
            <Button
                disabled={loading}
                style={{ backgroundColor: '#00D1BF', color: '#FFFFFF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => handleClose(false)}
                variant="contained"
                color="primary">
                No rechazar
            </Button>
        </DialogActions>
    </Dialog>;
}


const BookingDatesCalendarModal = ({ open, setOpen, booking }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const bookingDates = booking.dates.map(d => moment(d.date));

    return <Dialog open={open} fullScreen={isMobile}>
        <DialogTitle style={{ padding: 15 }}><span style={{ fontFamily: 'Poppins' }}>{'Fechas reservadas (' + bookingDates.length + ')'}</span></DialogTitle>
        <DialogContent style={{ padding: 15, textAlign: 'center' }}>
            <MuiThemeProvider theme={muiTheme}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <StaticDatePicker
                        readOnly={true}
                        displayStaticWrapperAs="desktop"
                        onChange={() => null}
                        value={null}
                        renderInput={(props) => <TextField {...props} />}
                        renderDay={(date, _selectedDates, DayComponentProps) => {
                            const isBookedDay = bookingDates.filter(d => d.format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))[0];
                            return <PickersDay {...DayComponentProps} key={date.toString()} style={{ backgroundColor: !!isBookedDay ? '#7E2D87' : '#ffffff', color: !!isBookedDay ? '#ffffff' : '#383839' }} />
                        }}
                        disableHighlightToday={true}
                    />
                </LocalizationProvider>
            </MuiThemeProvider>
        </DialogContent>
        <DialogActions style={{ padding: 15, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Button
                style={{ borderColor: '#00D1BF', color: '#00D1BF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '200px' }}
                onClick={() => setOpen(false)}
                color="primary"
                variant="outlined"
                autoFocus>
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>;
}

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#7E2D87'
        }
    }
});