import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import useHover from "../../hooks/useHover";
import Skeleton from '@material-ui/lab/Skeleton';
import { useMediaQuery } from "react-responsive";
import UserService from "../../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from "../../helpers/UserContext";
import SpaceTitle from "./SpaceTitle";
import SpaceCalification from "./SpaceCalification";

const PublicatioHomeResult = ({ space, clickAction, hoverAction, isSelected, makeResponsive }) => {
    const [favoriteIconRef, favoriteIconHovered] = useHover();
    const [pubRef, pubHovered] = useHover();
    const { user } = useAuth0();
    const { userFavourites, setUserFavourites } = useContext(UserContext);
    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        setIsFavourite(userFavourites.includes(space?.id));
    }, [space, userFavourites]);


    const isLessThan500pxWitdth = useMediaQuery({ query: '(max-width: 500px)' });
    const isLessThan1000pxWitdth = useMediaQuery({ query: '(max-width: 1000px)' });

    const toggleFavourites = () => {
        const userId = UserService.getUserId(user);
        if (isFavourite)
            UserService.removeFavourite(userId, space.id)
                .then(resp => {
                    setUserFavourites(uf => uf.filter(f => f !== space.id));
                }).catch(e => console.log(e));
        else
            UserService.addFavourite(userId, space.id)
                .then(resp => {
                    setUserFavourites(uf => [...uf, space.id]);
                }).catch(e => console.log(e));
    }

    if (!space) return <Box style={{ width: '300px', maxWidth: '80vw', margin: '10px' }}>
        <Skeleton variant="rect" width={'100%'} height={200} style={{ borderRadius: '10px' }} />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
    </Box>;

    return <Box ref={pubRef} style={{
        cursor: 'pointer',
        minWidth: (makeResponsive && isLessThan500pxWitdth) ? '95vw' : '230px',
        width: (makeResponsive && isLessThan500pxWitdth) ? '95vw' : isLessThan1000pxWitdth ? '320px' : '280px',
        maxWidth: (makeResponsive && isLessThan500pxWitdth) ? '95vw' : '80vw',
        margin: '10px',
        textAlign: 'left',
        position: 'relative',
        border: isSelected ? '2px solid #7E2D87' : '1px solid #D8D8D8',
        borderRadius: '15px'
    }}>
        <div ref={favoriteIconRef}
            onClick={e => { e.preventDefault(); toggleFavourites(); }}
            style={{ position: 'absolute', right: 0, cursor: 'pointer', zIndex: 99 }} >
            {isFavourite ?
                <BookmarkIcon style={{ fontSize: '42px', color: favoriteIconHovered ? '#6B2D87' : 'white', transition: '0.3s' }} /> :
                <BookmarkBorderIcon style={{ fontSize: '42px', color: favoriteIconHovered ? '#6B2D87' : 'white', transition: '0.3s' }} />
            }
        </div>
        {space.services.filter(s => s.service.important).map(s => <div key={s.id_ + '_srv'} style={{ top: 8, left: -8, fontFamily: 'PoppinsBold', color: '#ffffff', backgroundColor: '#00D1BF', padding: '5px 10px', fontSize: '12px', position: 'absolute', zIndex: 99, borderRadius: '15px' }}>{s.service.name}</div>)}
        <div onClick={clickAction} onMouseEnter={hoverAction}>
            <div style={{ height: '200px', width: '100%', borderRadius: '13px', overflow: 'hidden', WebkitBackfaceVisibility: 'hidden', MozBackfaceVisibility: 'hidden', WebkitTransform: 'translate3d(0, 0, 0)', MozTransform: 'translate3d(0, 0, 0)' }}>
                <div style={{
                    height: '200px',
                    width: '100%',
                    backgroundImage: `url(${space.images?.[0]?.original_url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    transform: pubHovered ? 'scale(1.1)' : 'scale(1)',
                    transition: ' all .5s'
                }}>
                    <div style={{ borderRadius: '10px', backgroundImage: 'linear-gradient(45deg, transparent, rgb(0,0,0,0.3) 250%)', width: '100%', height: '100%' }}></div>
                </div>
            </div>
            <div style={{ border: '1px solid #D8D8D8', borderTop: 'none', borderRadius: '12px', padding: '10px', paddingTop: '50px', marginTop: '-40px', minHeight: '140px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839', fontWeight: 500 }}><SpaceTitle space={space} /></div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '14px', color: '#383839', marginBottom: 5 }}>{space?.building_type}</div>
                    <SpaceCalification space={space} showText={true} mini={true} />
                </div>
                <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839' }}>
                    <span style={{ fontFamily: 'PoppinsBold', fontStyle: 'normal', fontWeight: 'bold', fontSize: '22px', color: '#383839' }}>{`$ ${space?.price > 0 ? (Math.round(space?.price - space?.price * space?.discount_percentage / 100)) : 0} `}</span>
                    <span>por jornada</span>
                </div>
            </div>
        </div>
    </Box>
};

export default PublicatioHomeResult;