import React, { useContext, useState } from "react";
import { Box, Button, CircularProgress, FormHelperText } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import TextField from '../../styled/StyledTextField';
import is from "is_js";
import UserService from "../../../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Codes = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context } = props;
    const { styles: contextStyles, fullUser, refreshUser } = useContext(context);
    const [code, setCode] = useState(fullUser.referred_code || '');

    const [errors, setErrors] = useState({});
    const [updateUserLoading, setUpdateUserLoading] = useState(false);

    const { user } = useAuth0();

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: '100%'
        },
        ...contextStyles
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(code)) errorsAcum.code = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getUserData = () => ({
        referred_code: code
    });

    const updateUser = async (userData) => {
        try {
            setUpdateUserLoading(true);
            await UserService.updateUser(UserService.getUserId(user), userData);
            refreshUser();
        } catch (e) {
            console.log(e);
        } finally {
            setUpdateUserLoading(false)
        }
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm codes">
            <div style={styles.onboardingTitle}>
                <span>Código de referidos</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <span style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Si fuiste referido por un miembro de Worknmates, ingresá el código aquí:</span>
                    <TextField
                        id="lastname"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        variant="outlined"
                        disabled={!!fullUser.referred_code}
                        type="text"
                        error={!!errors.code}
                        style={{ maxWidth: '120px', marginLeft: 5, minWidth: 120 }}
                        InputLabelProps={{ shrink: true, }} />

                </Box>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {!fullUser.referred_code &&
            <div style={{ width: '100%', textAlign: 'end' }}>
                <Button onClick={() => validateFormData() && updateUser(getUserData())}
                    variant="contained"
                    disabled={updateUserLoading}
                    style={{
                        backgroundColor: '#7E2D87',
                        color: '#ffffff',
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: isMobile ? '100%' : '168px',
                        height: '50px',
                        fontWeight: 'bold',
                        marginBottom: isMobile ? '10px' : '0'
                    }}>{updateUserLoading ? <CircularProgress color="secondary" /> : 'Guardar'}</Button>
            </div>}
    </Box>;
};

export default Codes;