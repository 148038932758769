import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Footer from "./Footer";
import HomeImage from '../assets/images/users-home01.png';
import PublicatioHomeResult from "./publication/PublicatioHomeResult";
import SearchSpacesBox from "./results/SearchSpacesBox";
import { useMediaQuery } from 'react-responsive';
import SpaceService from "../services/SpaceService";
import WhatsappButton from "./WhatsappButton";

const Home = () => {
    const isLessThan500pxWitdth = useMediaQuery({ query: '(max-width: 500px)' });
    const isLessThan1000pxWitdth = useMediaQuery({ query: '(max-width: 1000px)' });
    const isLessThan1600pxWitdth = useMediaQuery({ query: '(max-width: 1600px)' });

    const [spaces, setSpaces] = useState([]);

    useEffect(() => {
        getHomeSpaces();
    }, []);

    const getHomeSpaces = () => {
        SpaceService.homeSearch().then(resp => {
            setSpaces(resp.data);
        });
    };

    const goToPublication = (spaceId) => {
        const newWindow = window.open(`/publication/${spaceId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    const styles = {
        homeText: {
            color: '#ffffff',
            fontSize: isLessThan500pxWitdth ? '28px' : (isLessThan1600pxWitdth ? '4vw' : '64px'),
            fontFamily: 'PoppinsBold'
        },
    };

    return <>
        <Container>
            <WhatsappButton /> 
            <Box style={{
                overflow: 'hidden',
                zIndex: -1,
                backgroundImage: `url(${HomeImage})`,
                backgroundSize: 'cover',
                height: '30vw',
                maxHeight: '400px',
                minHeight: '200px',
                borderRadius: '15px',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '15px'
            }}>
                {isLessThan500pxWitdth ? <>
                    <div style={styles.homeText}>VOS ELEGIS</div>
                    <div style={styles.homeText}>DONDE TRABAJAR HOY</div>
                </>
                    :
                    <div style={styles.homeText}>VOS ELEGIS DONDE TRABAJAR HOY</div>}
            </Box>

            <SearchSpacesBox />
            
            <Box style={{
                display: 'flex',
                alignItems: isLessThan500pxWitdth ? 'center' : 'baseline',
                justifyContent: 'space-around',
                flexDirection: isLessThan500pxWitdth ? 'column' : 'row',
                flexWrap: isLessThan1000pxWitdth ? 'wrap' : 'nowrap'
            }}>
                {[0, 1, 2, 3].map(i => <PublicatioHomeResult space={spaces[i]} key={i + '_space_pubs'} clickAction={() => goToPublication(spaces[i].id)} />)}
            </Box>

        </Container>

        <Footer />
    </>;
};

export default Home;

