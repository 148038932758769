import React from "react";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from './StyledTextField';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText } from "@material-ui/core";

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        border: '1px solid',
        borderColor: '#818181',
        height: '24px',
        width: '24px',
        '&:hover': {
            backgroundColor: '#00D1BF',
            borderColor: '#00D1BF',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#00D1BF',
            borderColor: '#00D1BF',
        },
    },
})(IconButton);

const QuantitySelector = (props) => {
    const { value = 0, min = 'unset', max = 'unset', minusAction, plusAction, disabled, error = '' } = props;

    const styles = {
        input: {
            padding: 5,
            textAlign: 'center',
            margin: 0
        }
    }

    return <FormControl error={!!error}>
        <div className="quantytySelector" style={{ display: 'flex', alignItems: 'center', width: '150px', justifyContent: 'space-around' }}>
            <BootstrapButton aria-label="remove" onClick={minusAction} disabled={min === value || disabled}>
                <RemoveIcon />
            </BootstrapButton>
            <TextField
                variant="outlined"
                style={{ maxWidth: '80px' }}
                disabled={disabled} value={value}
                inputProps={{ className: 'quantytySelectorInput', style: styles.input, min, max }}
                type="number" />
            <BootstrapButton aria-label="add" onClick={plusAction} disabled={max === value || disabled}>
                <AddIcon />
            </BootstrapButton>
        </div>
        <FormHelperText>{error}</FormHelperText>
    </FormControl>;
};

export default QuantitySelector;