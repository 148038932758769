import React from 'react';

const SpaceTitle = ({ space }) => {
    const type = space.space_type ? space.space_type : space.type;
    if (type.id === 1)
        return <>
            <b>{space?.space_type?.name || space?.type?.name}</b>
        </>;
    if (type.id === 2)
        return <>
            <b style={{ marginRight: 4 }}>{space?.space_type?.name || space?.type?.name}</b> para <b style={{ marginRight: 4, marginLeft: 4 }}>{space?.space_capacity || space?.capacity}</b> personas
        </>;
    if (type.id === 3)
        return <>
            <b>{space?.space_type?.name || space?.type?.name}</b>
        </>;
};

export default SpaceTitle;