import React, { useState, useContext } from "react";
import { Box, FormHelperText, Tooltip, withStyles } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '../../styled/StyledTextField';
import OnboardingButtons from "./OnboardingButtons";
import HostSpaceService from "../../../services/HostSpaceService";
import { useAuth0 } from "@auth0/auth0-react";
import HostService from "../../../services/HostService";
import EditionButtons from "../dashboard/EditionButtons";
import is from "is_js";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import LaptopChromebookOutlinedIcon from '@material-ui/icons/LaptopChromebookOutlined';
import LocalCafeOutlinedIcon from '@material-ui/icons/LocalCafeOutlined';
import RUG, { DropArea } from 'react-upload-gallery';
import 'react-upload-gallery/dist/style.css';
import Axios from "axios";

const NO_IMAGES_ERROR = 'Debes agregar al menos una foto de tu espacio.';
const BIG_IMAGE_ERROR = 'El tamañno máximo de imagen es 5mb.';
const SHORT_DESCRIPTION_ERROR = 'Este campo debe tener al menos 100 caracteres.';

const Photos = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { context, isOnboarding } = props;
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const { user } = useAuth0();

    const [description, setDescription] = useState(space?.description || '');
    const [spaceImagesIds, setSpaceImagesIds] = useState(space?.images.map(i => i.uuid) || []);

    const [loadingCounter, setLoadingCounter] = useState(0);
    const [errors, setErrors] = useState({});
    const [imageWarning, setImageWarning] = useState(false);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '30px',
        },
        ...contextStyles
    }


    const removeImage = (image) => {
        let spaceId = HostService.getCurrentSpaceId(user);
        let hostId = HostService.getHostId(user);
        HostSpaceService.deletePhoto(hostId, spaceId, image.code).then(resp => {
            if (image.uploading) setLoadingCounter(c => c - 1);
            setSpaceImagesIds(sii => sii.filter(i => i !== image.code));
        }).catch(e => console.log(e));
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (description.length < 100) errorsAcum.description = SHORT_DESCRIPTION_ERROR;
        if (is.empty(spaceImagesIds)) errorsAcum.images = NO_IMAGES_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getSpaceData = () => ({
        description,
        images: spaceImagesIds.flat()
    });

    const customRequest = ({ uid, file, send, action, headers, onProgress, onSuccess, onError }) => {

        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();

        const uploadProgressFn = ({ total, loaded }) => {
            onProgress(uid, Math.round(loaded / total * 100));
        }

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function () {
            setLoadingCounter(c => c + 1);
            let spaceId = HostService.getCurrentSpaceId(user);
            let resp = await HostSpaceService.postImage(user, spaceId, reader.result, uploadProgressFn, source.token);
            setSpaceImagesIds(imagesIds => ([...imagesIds, resp.data.uuid]));
            onSuccess(uid, resp.data, { code: resp.data.uuid });
            setLoadingCounter(c => c - 1);
        };
        reader.onerror = function (error) {
            onError(uid, {
                action,
                status: error.request,
                response: error.response
            })
        };

        return {
            abort() {
                source.cancel();
            }
        }
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm spacePhotosForm">
            <div style={styles.onboardingTitle}>
                <span>Fotos y descripción del espacio</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <span>Dale vida al anuncio con fotos</span>
                        <StyledTooltip enterTouchDelay={0} arrow={true} title={<PhotosTips />}>
                            <InfoOutlinedIcon style={{ color: '#00D1BF', fontSize: '32px' }} />
                        </StyledTooltip>
                    </div>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Sacá fotos de tu espacio con una cámara o celular y subí al menos una para publicar el anuncio. Podes editar y seguir subiendo imágenes a la galería más tarde.</div>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Cuando hayas subido las imágenes, arrastralas y movelas para que se muestren en el orden que quieras. <b>La primera imagen será la de portada.</b></div>
                </Box>

                <FormControl style={{ ...styles.formControl }} error={!!errors.images || imageWarning}>
                    {imageWarning && <FormHelperText>{imageWarning}</FormHelperText>}
                    <FormHelperText>{errors.images}</FormHelperText>
                    <RUG rules={{ limit: 30, size: 5000 }}
                        onSuccess={hackFnToUpdateOrderAfterLoading}
                        onSortEnd={images => setSpaceImagesIds(images.map(i => i.code))}
                        source={response => response.url}
                        sorting={{ axis: 'xy', pressDelay: 200, distance: 0 }}
                        customRequest={customRequest}
                        initialState={space?.images.map(i => ({ source: i.url, code: i.uuid }))}
                        onDeleted={removeImage}
                        header={({ openDialogue }) => (
                            <DropArea>
                                {(isDrag) => <div style={{ border: 'dashed', borderColor: 'rgba(0, 0, 0, 0.12)', borderRadius: '4px', background: isDrag ? 'lightgrey' : '#fff' }}>
                                    <UploadButton openDialogue={openDialogue} />
                                </div>}
                            </DropArea>
                        )}
                        onChange={() => null}
                        onWarning={(type, rules) => {
                            switch (type) {
                                case 'limit':
                                    setImageWarning('Has cargado el máximo de imagenes: ' + rules.limit);
                                    break;
                                case 'size':
                                    setImageWarning(BIG_IMAGE_ERROR);
                                    break;
                                default:
                            }
                        }}
                    />

                </FormControl>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                    <FormControl style={{ ...styles.formControl, flexGrow: 3 }} >
                        <div style={{ color: '#383839', fontSize: '18px', textAlign: 'left' }}>Contales a los trabajadores sobre tu espacio</div>
                        <TextField
                            style={{ marginTop: '20px' }}
                            id="summary"
                            multiline
                            error={!!errors.description}
                            helperText={errors.description}
                            rows={description.split(/\r\n|\r|\n/).length + 3}
                            variant="outlined"
                            value={description}
                            onChange={event => setDescription(event.target.value)}
                        />
                        <div style={{ textAlign: 'right', color: '#818181', fontFamily: 'Poppins', fontSize: '10px', marginTop: '5px' }}>{description ? description.length : 0} caracteres</div>
                    </FormControl>
                    <div style={{ width: isMobile ? '' : '400px', maxWidth: isMobile ? '100%' : '40vw' }}><DescriptionExample /></div>
                </div>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ? <OnboardingButtons stepUtils={stepUtils} nextDisabled={loadingCounter > 0}
            nextFn={() => validateFormData() && stepUtils.saveSpaceAndNext(getSpaceData())}
            prevFn={() => stepUtils.previousStep()}
            closeFn={() => validateFormData() && stepUtils.saveSpaceAndClose(getSpaceData())} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={() => validateFormData() && stepUtils.updateSpace(getSpaceData())} saveDisabled={loadingCounter > 0} />
        }
    </Box>;
};

export default Photos;

const DescriptionExample = () => {
    return <Box style={{ color: '#383839', border: '1px solid grey', margin: '8px', padding: '8px', borderRadius: '5px', textAlign: 'left', fontSize: '14px' }}>
        <b>Ejemplo de Contanos acerca de tu espacio</b>
        <div>Bienvenidos a mi hogar!</div>
        <div>Hermoso departamento ubicado en Palermo Hollywood. Disponemos de 2 mesas para 6 personas cada una. Equipado para una jornada laboral super confortable! Contamos con tv y espacio para comer separado del espacio de trabajo. El departmento tiene un amplio balcon y el edificio tiene terraza, donde podrán ir a descansar y a relajarse durante el día! </div>
        <br />
        <div>El espacio</div>
        <div>El espacio esta diseñado totalmente para el trabajador. Contamos con un espacio separado, donde podrán utilizarlo para tener call siempre que este disponible.</div>
        <br />
        <div>Otros aspectos para tener en cuenta</div>
        <div>El departamento cuenta con un espacio para dejar la bici o scooter, sin cargo adicional.</div>
        <div>El edificio cuenta con seguridad las 24hs.</div>
        <div>Se requiere pasaporte o dni porque lo piden en la recepción del edificio.</div>
    </Box>;
};

const PhotosTips = () => {
    return <Box style={{ padding: '10px', fontSize: '14px', fontFamily: 'Poppins' }}>
        <Box style={{ textAlign: 'center' }}>
            <img src={require('../../../assets/images/photos-tip.png')} alt="" />
        </Box>
        <br />
        <Box style={{ display: 'flex' }}>
            <CameraAltOutlinedIcon style={{ color: 'white', fontSize: '30px', marginRight: '5px' }} />
            <div style={{ color: 'white', padding: '3px', lineHeight: '18px' }}>Sacá fotos con la cámara horizontal para capturar toda la esencia de tu espacio. Sumale perspectiva a tus ambientes sacando las fotos desde las esquinas.</div>
        </Box>
        <br />
        <Box style={{ display: 'flex' }}>
            <WbSunnyOutlinedIcon style={{ color: 'white', fontSize: '30px', marginRight: '5px' }} />
            <div style={{ color: 'white', padding: '3px', lineHeight: '18px' }}>Los espacios se ven mejor con luz natural. Si es de noche, prendé las luces. Evitá usar el flash.</div>
        </Box>
        <br />
        <Box style={{ display: 'flex' }}>
            <LaptopChromebookOutlinedIcon style={{ color: 'white', fontSize: '30px', marginRight: '5px' }} />
            <div style={{ color: 'white', padding: '3px', lineHeight: '18px' }}>Incluí todos los espacios a los que van a poder acceder los compañeros de trabajo. </div>
        </Box>
        <br />
        <Box style={{ display: 'flex' }}>
            <LocalCafeOutlinedIcon style={{ color: 'white', fontSize: '30px', marginRight: '5px' }} />
            <div style={{ color: 'white', padding: '3px', lineHeight: '18px' }}>Si ofreces servicios adicionales, puedes incluir las fotos de los mismos ayudando a atraer a los usuarios a tu espacio.</div>
        </Box>
    </Box>;
};

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#00D1BF',
        color: 'rgba(0, 0, 0)',
        border: '1px solid #dadde9',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.180261)'
    },
    arrow: {
        color: '#00D1BF',
    },
}))(Tooltip);

const UploadButton = (openDialogue) => <Box style={{ marginTop: '5%', zIndex: 99 }}>
    <Button onClick={() => openDialogue.openDialogue()} variant="contained" style={{
        borderRadius: '10px',
        borderColor: '#6B2D87',
        backgroundColor: '#6B2D87',
        height: '50px',
        color: '#ffffff',
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    }}>Subí las fotos</Button>
    <div style={{ color: '#383839', fontFamily: 'Poppins', marginTop: '5px', marginBottom: '20px', fontSize: '14px' }}>o arrastralas</div>
</Box>;


// not best solution, but works ;)
const hackFnToUpdateOrderAfterLoading = image => {
    const element = document.getElementsByClassName("rug-items __card __sorting")[0].getElementsByTagName('div')[0];
    element.dispatchEvent(new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
        screenX: 0,
        screenY: 0
    }));
    setTimeout(() => element.dispatchEvent(new MouseEvent('mouseup', {
        bubbles: true,
        cancelable: true,
        screenX: 0,
        screenY: 0
    })), 201);
}