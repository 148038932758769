import React from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
import HostService from '../services/HostService';

const ProtectedRoute = ({ appReady, component, ...args }) => {
    const { isLoading } = useAuth0();

    if (isLoading || !appReady)
        return <Backdrop open={true} style={{ backgroundColor: '#ffffff' }} className={HostService.isHostSection() ? 'hostSection' : ''}><CircularProgress /></Backdrop>;

    return <Route component={withAuthenticationRequired(component, { loginOptions: { screen_hint: 'signup' } })} {...args} />
};

export default ProtectedRoute;