import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Select, MenuItem, FormHelperText } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import Calendar from 'react-calendar-multiday'
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import OnboardingButtons from "./OnboardingButtons";
import StyledInputBase from "../../styled/StyledInputBase";
import HostService from "../../../services/HostService";
import HostSpaceService from "../../../services/HostSpaceService";
import { useAuth0 } from "@auth0/auth0-react";
import momentTimezone from 'moment-timezone';
import EditionButtons from "../dashboard/EditionButtons";
import is from "is_js";

const NO_AVAILABILE_DATES_ERROR = 'Debes tener al menos un dia disponible.'
const TIMEFRAME_ERROR = 'Debes tener al menos 5hs entre el horario de apertura y de cierre.'

const EARLIER_CHECKIN_ALLOWED = '6:00'; // h:mm
const LATER_CHECKOUT_ALLOWED = '22:00'; // h:mm

const completeTimesOptions = Array(24).fill(null).map((_, i) => i).map(h => [`${h}:00`, `${h}:30`]).flat();
const allowedTimeOptions = completeTimesOptions.slice(
    completeTimesOptions.indexOf(EARLIER_CHECKIN_ALLOWED),
    completeTimesOptions.indexOf(LATER_CHECKOUT_ALLOWED) + 2
);

const Availability = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 800px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context, isOnboarding } = props;
    const [availableDates, setAvailableDates] = useState([]);
    const [originalAvailableDates, setOriginalAvailableDates] = useState([]);
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const [checkinOptions, setCheckinOptions] = useState(allowedTimeOptions);
    const [checkoutOptions, setCheckoutOptions] = useState(allowedTimeOptions);
    const [checkin, setCheckin] = useState(space.check_in_time || EARLIER_CHECKIN_ALLOWED);
    const [checkout, setCheckout] = useState(space.check_out_time || LATER_CHECKOUT_ALLOWED);
    const { user } = useAuth0();
    const [isUnlock, setIsUnlock] = useState(true);
    const [errors, setErrors] = useState({});

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        calendarBoxes: {
            width: isNarrowWidth ? '100%' : '45%',
        },
        ...contextStyles
    }

    const getAvailabilities = () => {
        let hostId = HostService.getHostId(user);
        let spaceId = HostService.getCurrentSpaceId(user);
        HostSpaceService.getAvailabilities(hostId, spaceId).then(resp => {
            let spaceAvailabilities = resp.data.map(a => moment(a.date)).filter(a => {
                const isTodayOrAfter = (a.isAfter(moment()) || a.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'));
                return isTodayOrAfter;
            });
            setAvailableDates(spaceAvailabilities);
            setOriginalAvailableDates(spaceAvailabilities);
        });
    };

    useEffect(() => {
        getAvailabilities();
        // eslint-disable-next-line 
    }, [user]);

    useEffect(() => {
        setCheckoutOptions(allowedTimeOptions.slice(
            allowedTimeOptions.indexOf(checkin) + 1,
            allowedTimeOptions.indexOf(LATER_CHECKOUT_ALLOWED) + 1
        ));
    }, [checkin]);

    useEffect(() => {
        setCheckinOptions(allowedTimeOptions.slice(
            allowedTimeOptions.indexOf(EARLIER_CHECKIN_ALLOWED),
            allowedTimeOptions.indexOf(checkout)
        ));
    }, [checkout]);

    useEffect(() => {
        setLockOrUnlock();
        let monthNavigationButtons = document.getElementsByClassName("e_day-picker-arrow-container");
        for (var i = 0; i < monthNavigationButtons.length; i++)
            monthNavigationButtons[i].addEventListener('click', setLockOrUnlock);

        return () => {
            for (var i = 0; i < monthNavigationButtons.length; i++)
                monthNavigationButtons[i].removeEventListener('click', setLockOrUnlock);
        };
        // eslint-disable-next-line 
    }, [availableDates]);

    // substract the original month days to the availableDates selected
    const calcAddedDates = () => {
        let nad = [];
        availableDates.forEach(ad => {
            if (originalAvailableDates.filter(oad => oad.format('DD-MM-YYYY') === ad.format('DD-MM-YYYY')).length === 0) nad.push(ad)
        });

        //remove duplicates
        nad = nad.filter((v, i) => {
            return nad.findIndex(candidate => v.isSame(candidate)) === i
        });
        return nad;
    };

    const calcRemovedDates = () => {
        let rad = [];
        originalAvailableDates.forEach(oad => {
            if (availableDates.filter(ad => ad.format('DD-MM-YYYY') === oad.format('DD-MM-YYYY')).length === 0) rad.push(oad)
        });

        //remove duplicates
        rad = rad.filter((v, i) => {
            return rad.findIndex(candidate => v.isSame(candidate)) === i
        });
        return rad;
    };

    const getAvailabilityData = () => {
        let hostId = HostService.getHostId(user);
        let added = calcAddedDates();
        let removed = calcRemovedDates();
        return {
            added,
            removed,
            host_id: hostId,
        }
    };

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(availableDates)) errorsAcum.availableDates = NO_AVAILABILE_DATES_ERROR;
        if (calcHourDiff(checkin, checkout) < 5) errorsAcum.time = TIMEFRAME_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getCheckinAndCheckoutData = () => ({
        checkInTime: checkin,
        checkOutTime: checkout
    });

    const dateSelection = (e) => {
        setAvailableDates(e.selected.map(e => momentTimezone.tz(e, momentTimezone.tz.guess())));
    }

    // adding logic to nextFn to allow persist checkin and checkout time in separate request
    const nextFn = async () => {
        if (validateFormData()) {
            await stepUtils.saveOrUpdateSpace(getCheckinAndCheckoutData());
            await stepUtils.saveAvailabilityAndNext(getAvailabilityData());
        }
    }

    const closeFn = async () => {
        if (validateFormData()) {
            await stepUtils.saveOrUpdateSpace(getCheckinAndCheckoutData());
            await stepUtils.saveAvailabilityAndClose(getAvailabilityData());
        }
    }

    const saveFn = async () => {
        if (validateFormData()) {
            await stepUtils.updateSpace(getCheckinAndCheckoutData());
            await stepUtils.setAvailability(space.id, getAvailabilityData());
            getAvailabilities();
        }
    }

    const getCurrentMonthDays = () => {
        let selectedMonthNumber = document.querySelector('.i_day-picker-body .i_day-picker-row:nth-child(2) div')?.getAttribute('data-date')?.split('-')[0];
        let selectedyear = document.querySelector('.i_day-picker-title')?.textContent.replace(/\D/g, '');
        let daysInMonth = moment(`${selectedyear}-${selectedMonthNumber}-01`).daysInMonth();
        let currentTz = momentTimezone.tz.guess();

        let arrDays = [];
        while (daysInMonth) {
            var current = momentTimezone.tz(`${selectedyear}-${selectedMonthNumber}-${pad(daysInMonth)} 00:00`, currentTz);
            const isTodayOrAfter = (current.isAfter(moment()) || current.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'));
            const isWeekDay = (current.isoWeekday() !== 6 && current.isoWeekday() !== 7);
            if (isTodayOrAfter && isWeekDay) arrDays.push(current);
            daysInMonth--;
        }
        return arrDays;
    }

    const setLockOrUnlock = () => {
        setTimeout(() => {
            let currentMonthDays = getCurrentMonthDays();
            if (currentMonthDays.length > 0) {
                let currentMonthNumber = currentMonthDays[0].month();
                let currentMonthAvailableDays = availableDates.filter(d => d.month() === currentMonthNumber);
                let currentMonthLockedDays = currentMonthDays.length - currentMonthAvailableDays.length;
                (currentMonthLockedDays > currentMonthAvailableDays.length) ? setIsUnlock(true) : setIsUnlock(false);
            }
        }, 100);
    }

    const unlockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();
        setAvailableDates(ad => [...ad, ...currentMonthDays]);
    }

    const lockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();

        // substract the current month days to the availableDates selected
        let newAvailableDates = (() => {
            let nad = [];
            availableDates.forEach(ad => {
                if (currentMonthDays.filter(cmd => cmd.format('DD-MM-YYYY') === ad.format('DD-MM-YYYY')).length === 0) nad.push(ad)
            })
            return nad;
        })();

        setAvailableDates(newAvailableDates);
    }

    const toggleMonthAvailability = () => {
        isUnlock ? unlockCurrentMonth() : lockCurrentMonth();
        setIsUnlock(isUnlock => !isUnlock);
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Disponibilidad</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Marcá en el calendario las fechas que disponés para ofrecer tu espacio</div>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Podés editarlas en todo momento</div>
                </Box>
                <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    <FormControl style={{ ...styles.calendarBoxes, marginBottom: isMobile ? '20px' : 0 }} error={!!errors.availableDates}>
                        <Calendar selected={availableDates} className="coco" isMultiple={true} DayComponent={<PositionDay />} onChange={dateSelection} />
                        <Button onClick={toggleMonthAvailability} style={{ color: '#00D1BF', fontFamily: 'Poppins', textTransform: 'none' }}>{isUnlock ? 'Desbloquear' : 'Bloquear'} todo el mes (dias hábiles)</Button>
                        <FormHelperText>{errors.availableDates}</FormHelperText>
                    </FormControl>
                    <div style={{ ...styles.calendarBoxes, marginTop: isMobile ? 0 : '45px', marginBottom: isMobile ? 0 : '36px', background: 'rgba(216, 216, 216, 0.128141)', border: '1px solid #D8D8D8', borderRadius: '3px', padding: isMobile ? '5px' : '25px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minHeight: '300px', textAlign: 'left' }}>
                        <div style={{ color: '#818181', fontSize: isMobile ? '14px' : '18px' }}>Tu calendario está configurado para estar completamente bloqueado de manera predeterminada</div>
                        <div style={{ color: '#818181', fontSize: isMobile ? '14px' : '18px' }}>Cero días disponibles para reservar</div>
                        <div style={{ color: '#00D1BF', fontSize: isMobile ? '14px' : '18px' }}>Cambiar la configuración de disponibilidad</div>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', backgroundColor: 'white', border: '0.7px solid #D8D8D8', borderRadius: '2.1px' }}></div>
                                <div style={{ color: '#818181', paddingLeft: '10px' }}>Disponible</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <div className="diagonal-container" style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: '0.7px solid #D8D8D8', borderRadius: '2.1px' }}></div>
                                <div style={{ color: '#818181', paddingLeft: '10px' }}>Bloqueada</div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Definí un horario de entrada y salida de tu espacio</div>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Podés editarlas en todo momento</div>
                </Box>
                <Box>
                    <FormControl style={{ ...styles.formControl, order: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', flexWrap: 'wrap' }} error={!!errors.time}>
                        <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                            <Select id="checkInTime" value={checkin} onChange={e => setCheckin(e.target.value)} input={<StyledInputBase />}>
                                {checkinOptions.map(ht => <MenuItem key={`ht_${ht}`} value={ht}>{ht}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 0 0 10px', height: '48px' }}>
                            <Select id="checkOutTime" value={checkout} onChange={e => setCheckout(e.target.value)} input={<StyledInputBase />}>
                                {checkoutOptions.map(ht => <MenuItem key={`ht_${ht}`} value={ht}>{ht}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormHelperText>{errors.time}</FormHelperText>
                    </FormControl>
                </Box>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ? <OnboardingButtons stepUtils={stepUtils}
            nextFn={nextFn}
            prevFn={() => stepUtils.previousStep()}
            closeFn={closeFn} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={saveFn} />
        }
    </Box>;
};

export default Availability;

const PositionDay = props => {

    const onClick = (e) => {
        if (props.isInThePast) e.stopPropagation();
    }
    return (
        <div className={(props.isSelected || !isFromSelectedMonth(props.date)) ? '' : 'diagonal-container'}>
            <div onClick={onClick}
                className={getStyle(props)}
                style={{ ...getInline(props), display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ fontSize: '14px', color: !isFromSelectedMonth(props.date) && '#383839', opacity: !isFromSelectedMonth(props.date) && '0.5', }}>{props.label}</div>
            </div>
        </div>)
}

const getStyle = function ({ date, isSelected }) {
    return `${isSelected ? 'o_selected-day' : ''} ${date.type}-day`
}

const getInline = ({ isToday, isInThePast }) => ({
    cursor: isInThePast ? 'not-allowed' : 'inherit',
    background: isInThePast ? '#e4e4e4' : 'inherit',
    color: isInThePast ? '#555555' : 'inherit',
})

const isFromSelectedMonth = date => (date.type === 'month');

const pad = d => (d < 10) ? '0' + d.toString() : d.toString();

const getMoment = (time) => {
    let [h, m] = time.split(':');
    return moment({ h, m });
};

const calcHourDiff = (checkin, checkout) => getMoment(checkout).diff(getMoment(checkin), 'hours');