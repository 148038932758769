import authAxios from '../helpers/authAxios';

const getCompanyIdsFromClaims = user => {
    let companyIds = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}company_ids`];
    if (!companyIds) companyIds = [];
    return companyIds;
}

const CompanyService = {

    isCompanySection: () => window.location.pathname.startsWith("/company"),

    getCompanyIds: getCompanyIdsFromClaims,

    getById: (id) => {
        console.log(`CompanyService.getById(${id})`);
        const url = `${process.env.REACT_APP_API_URL}/company/${id}`;
        return authAxios.get(url);
    },

}



export default CompanyService;