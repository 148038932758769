import React, { useState, useContext, useEffect } from "react";
import { Box, CircularProgress, Button, FormControl, FormHelperText } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import DropzoneArea from "../../uploader/DropzoneArea";
import HostService from "../../../services/HostService";
import is from "is_js";
import { useAuth0 } from "@auth0/auth0-react";

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Security = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isLessthan380pxWidth = useMediaQuery({ query: '(max-width: 380px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { isOnboarding, context } = props;
    const { styles: contextStyles, stepUtils, host } = useContext(context);

    const { user } = useAuth0();
    const [updateHostLoading, setUpdateHostLoading] = useState(false);

    const [idImageFront, setIdImageFront] = useState(host?.identification_attachment_front || '');
    const [idFrontLoading, setIdFrontLoading] = useState(false);
    const [frontHover, setFrontHover] = useState(false);

    const [idImageBack, setIdImageBack] = useState(host?.identification_attachment_back || '');
    const [idBackLoading, setIdBackLoading] = useState(false);
    const [backHover, setBackHover] = useState(false);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setIdImageFront(host?.identification_attachment_front || '');
        setIdFrontLoading(false);
        setIdImageBack(host?.identification_attachment_back || '');
        setIdBackLoading(false);
    }, [host]);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        idHover: {
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
        },
        ...contextStyles
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(idImageBack)) errorsAcum.idImageBack = REQUIERED_FIELD_ERROR;
        if (is.empty(idImageFront)) errorsAcum.idImageFront = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getHostData = () => {
        let hostData = {};
        if (is.string(idImageFront) && is.not.empty(idImageFront))
            hostData.identification_attachment_front = idImageFront;

        if (is.string(idImageBack) && is.not.empty(idImageBack))
            hostData.identification_attachment_back = idImageBack;

        return hostData;
    };

    const updateHost = async (host) => {
        try {
            setUpdateHostLoading(true);
            await HostService.updateHost(HostService.getHostId(user), host);
        } catch (e) {
            console.log(e);
        } finally {
            setUpdateHostLoading(false)
        }
    }

    const idFrontSelected = async (images) => {
        if (images[0]) await persistImage(images[0], setIdImageFront, setIdFrontLoading);
    }

    const idBackSelected = async (images) => {
        if (images[0]) await persistImage(images[0], setIdImageBack, setIdBackLoading);
    }

    const persistImage = async (file, setFn, setLoading) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function () {
            setLoading(true);
            let resp = await HostService.postImage(user, reader.result)
            setFn(resp.data);
            setLoading(false);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const IdSample = ({ idImage, sampleImg, isLoading, hover, setHover }) => {
        let resp = <div className="idSample"
            style={{ position: 'relative', minHeight: '200px' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <img src={sampleImg} alt="" style={isLessthan380pxWidth ? { maxWidth: '80vw' } : { maxWidth: '300px' }} />
            {hover &&
                <div style={styles.idHover} className="hover">
                    <div style={{ fontSize: '14px', color: '#818181', padding: '5px', backgroundColor: '#FFFFFF', borderRadius: '10px', border: '1px solid #818181' }}>Subir foto</div>
                </div>
            }

            {/* hack for css */}
            <div style={{ opacity: 0 }} className="staticPosition">
                <img src={sampleImg} alt="" style={isLessthan380pxWidth ? { maxWidth: '80vw' } : { maxWidth: '300px' }} />
            </div>

        </div>;
        if (is.string(idImage) && is.not.empty(idImage)) resp = null;
        if (isLoading) resp = <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 99, height: '100%', width: '100%', backgroundColor: '#ffffff', opacity: 0.5 }}><CircularProgress /></div>
        return resp;
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm securityOnboarding">
            <div style={styles.onboardingTitle}>
                <span>Seguridad</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: isNarrowWidth ? 'center' : 'space-around', justifyContent: 'space-around', flexDirection: isNarrowWidth ? 'column' : 'row', }}>
                <FormControl error={!!errors.idImageFront} style={{ display: 'flex', flexDirection: 'column', alignItems: isNarrowWidth ? 'center' : 'left' }}>
                    <div style={{ fontSize: '12px', color: '#383839', padding: '20px 0', textAlign: 'left' }}>Añadí una foto del frente de tu documento JPG o PNG</div>
                    <DropzoneArea onChange={idFrontSelected}
                        showAlerts={false}
                        previewGridProps={{ item: { style: { padding: '10px' } } }}
                        filesLimit={1}
                        maxFileSize={5000000}
                        dropzoneText={
                            <IdSample hover={frontHover} setHover={setFrontHover}
                                sampleImg={is.empty(idImageFront) ? require('../../../assets/images/id_sample_front.png') : idImageFront.original_url}
                                idImage={idImageFront}
                                isLoading={idFrontLoading} />
                        } />
                    <FormHelperText>{errors.idImageFront}</FormHelperText>
                </FormControl>
                <FormControl error={!!errors.idImageBack} style={{ display: 'flex', flexDirection: 'column', alignItems: isNarrowWidth ? 'center' : 'left' }}>
                    <div style={{ fontSize: '12px', color: '#383839', padding: '20px 0', textAlign: 'left' }}>Añadí una foto del dorso de tu documento JPG o PNG</div>
                    <DropzoneArea onChange={idBackSelected}
                        showAlerts={false}
                        previewGridProps={{ item: { style: { padding: '10px' } } }}
                        filesLimit={1}
                        maxFileSize={5000000}
                        dropzoneText={
                            <IdSample hover={backHover} setHover={setBackHover}
                                sampleImg={is.empty(idImageBack) ? require('../../../assets/images/id_sample_back.png') : idImageBack.original_url}
                                idImage={idImageBack}
                                isLoading={idBackLoading} />
                        } />
                    <FormHelperText>{errors.idImageBack}</FormHelperText>
                </FormControl>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ?
            <OnboardingButtons stepUtils={stepUtils}
                nextFn={() => validateFormData() && stepUtils.saveHostAndNext(getHostData())}
                nextDisabled={idBackLoading || idFrontLoading}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => validateFormData() && stepUtils.saveHostAndClose(getHostData())} />
            : <div style={{ width: '100%', textAlign: 'end' }}>
                <Button onClick={() => validateFormData() && updateHost(getHostData())}
                    variant="contained"
                    disabled={updateHostLoading}
                    style={{
                        backgroundColor: '#00D1BF',
                        color: '#ffffff',
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: isMobile ? '100%' : '168px',
                        height: '50px',
                        fontWeight: 'bold',
                        marginBottom: isMobile ? '10px' : '0'
                    }}>{updateHostLoading ? <CircularProgress color="secondary" /> : 'Guardar'}</Button>
            </div>}
    </Box>;
};

export default Security;