import authAxios from '../helpers/authAxios';
import HostService from './HostService';
import UserService from './UserService';

const HostSpaceService = {

    search: () => {
        console.log(`HostSpaceService.search()`);
        const url = `${process.env.REACT_APP_API_URL}/space/search`;
        return authAxios.post(url, {});
    },

    getById: (hostId, spaceId) => {
        console.log(`HostSpaceService.getById(${hostId}, ${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}`;
        return authAxios.get(url);
    },

    getByHostId: (hostId) => {
        console.log(`HostSpaceService.getByHostId(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space`;
        return authAxios.get(url);
    },

    createSpace: (hostId, space) => {
        console.log(`HostSpaceService.createSpace()`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space`;
        return authAxios.post(url, space);
    },

    deleteSpace: (hostId, spaceId) => {
        console.log(`HostSpaceService.deleteSpace(${hostId}, ${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}`;
        return authAxios.delete(url);
    },

    updateSpace: (hostId, space) => {
        console.log(`HostSpaceService.updateSpace(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${space.id}`;
        return authAxios.patch(url, space);
    },

    postImage: (user, spaceId, base64, onUploadProgress, cancelToken) => {
        let hostId = HostService.getHostId(user);
        console.log(`HostSpaceService.persistImage(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}/image`;
        return authAxios.post(url, {
            base64,
            user_id: UserService.getUserId(user)
        },
            { onUploadProgress, cancelToken });
    },

    setAvailability: (hostId, spaceId, availabilities) => {
        console.log(`HostSpaceService.setAvailabilities(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}/availability`;
        return authAxios.patch(url, availabilities);
    },

    getAvailabilities: (hostId, spaceId) => {
        console.log(`HostSpaceService.getAvailabilities(${hostId}, ${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}/availability`;
        return authAxios.get(url);
    },

    deletePhoto: (hostId, spaceId, uuid) => {
        console.log(`HostSpaceService.deletePhoto(${hostId}, ${spaceId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}/image/${uuid}`;
        return authAxios.delete(url);
    },

    activate: (hostId, spaceId) => {
        console.log(`HostSpaceService.activate(${hostId}, ${spaceId})`);
        return setActive(true, hostId, spaceId);
    },

    deactivate: (hostId, spaceId) => {
        console.log(`HostSpaceService.deactivate(${hostId}, ${spaceId})`);
        return setActive(false, hostId, spaceId);
    },

}

const setActive = (active, hostId, spaceId) => {
    const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}`;
    return authAxios.patch(url, { active });
}

export default HostSpaceService;