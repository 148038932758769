import React, { useContext, useState, useEffect } from "react";
import { Box, TextField, FormControl, Button, CircularProgress, FormHelperText, FormLabel } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import { useAuth0 } from "@auth0/auth0-react";
import UserService from "../../../services/UserService";
import Autocomplete from '@material-ui/lab/Autocomplete';
import StyledTextField from "../../styled/StyledTextField";
import Chip from "@material-ui/core/Chip";

const Networking = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isLessThan400pxWidth = useMediaQuery({ query: '(max-width: 400px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { isOnboarding, context } = props;
    const { styles: contextStyles, stepUtils, fullUser } = useContext(context);
    const { user } = useAuth0();

    const [company, setCompany] = useState(fullUser?.networking?.company || '');
    const [position, setPosition] = useState(fullUser?.networking?.position || '');
    const [linkedinUrl, setLinkedinUrl] = useState(fullUser?.networking?.linkedin_url || '');
    const [facebookUrl, setFacebookUrl] = useState(fullUser?.networking?.facebook_url || '');
    const [instagramUrl, setInstagramUrl] = useState(fullUser?.networking?.instagram_url || '');
    const [interests, setInterests] = useState(fullUser?.networking?.interests || [])

    const [updateUserLoading, setUpdateUserLoading] = useState(false);

    useEffect(() => {
        setCompany(fullUser?.networking?.company || '');
        setPosition(fullUser?.networking?.position || '');
        setLinkedinUrl(fullUser?.networking?.linkedin_url || '');
        setFacebookUrl(fullUser?.networking?.facebook_url || '');
        setInstagramUrl(fullUser?.networking?.instagram_url || '');
        setInterests(fullUser?.networking?.interests || [])
    }, [fullUser, user]);

    const getUserData = () => {
        let userData = {
            networking: {
                company,
                position,
                linkedin_url: linkedinUrl,
                facebook_url: facebookUrl,
                instagram_url: instagramUrl,
                interests
            }
        };
        return userData;
    };

    const updateUser = async (updatedUser) => {
        try {
            setUpdateUserLoading(true);
            await UserService.updateUser(UserService.getUserId(user), updatedUser);
        } catch (e) {
            console.log(e);
        } finally {
            setUpdateUserLoading(false)
        }
    }

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        confirmText: {
            color: '#383839',
            marginLeft: '12px',
            fontSize: isLessThan400pxWidth ? '16px' : '18px'
        },
        confirmBox: {
            display: 'flex',
            alignItems: 'end',
            marginBottom: '8px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        ...contextStyles
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Networking</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box>
                <Box style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px', textAlign: 'left' }}>Esta sección nos permitirá conocerte más y poder acercarte a personas que compartan intereses similares de acuerdo a tu perfil</Box>
                <Box style={{ color: '#383839', marginBottom: '10px', fontSize: '16px', textAlign: 'left' }}>Si estás trabajando actualmente nos gustaría saber:</Box>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between' }}>
                <FormControl style={styles.formControl}>
                    <TextField
                        id="company"
                        label="Nombre de tu empresa"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={styles.formControl}>
                    <TextField
                        id="position"
                        label="Posición actual"
                        value={position}
                        onChange={e => setPosition(e.target.value)}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>


            </Box>

            <div style={{ ...styles.onboardingTitle, marginTop: 40 }}>
                <span>Intereses</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between' }}>
                <FormControl>
                    <Autocomplete
                        multiple
                        id="interests"
                        options={[]}
                        freeSolo
                        value={interests}
                        onChange={(e, v) => setInterests(v)}
                        style={{ marginTop: '15px' }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option} style={{ backgroundColor: 'rgb(126, 45, 135, 0.2)' }} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={params => <StyledTextField {...params} />}
                    />
                    <FormHelperText>Ej: deportes, lectura, desarrollo, emprendedurismo, etc.</FormHelperText>
                </FormControl>
            </Box>

            <div style={{ ...styles.onboardingTitle, marginTop: 40 }}>
                <span>Redes Sociales</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between', marginBottom: 20 }}>
                <FormControl style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'left' : 'center', marginTop: 20 }}>
                    <FormLabel style={{ width: 200, textAlign: 'left' }}>URL de Linkedin: </FormLabel>
                    <TextField fullWidth
                        id="linkedinUrl"
                        value={linkedinUrl}
                        onChange={e => setLinkedinUrl(e.target.value)}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'left' : 'center', marginTop: 20 }}>
                    <FormLabel style={{ width: 200, textAlign: 'left' }}>URL de Facebook: </FormLabel>
                    <TextField fullWidth
                        id="facebookUrl"
                        value={facebookUrl}
                        onChange={e => setFacebookUrl(e.target.value)}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'left' : 'center', marginTop: 20 }}>
                    <FormLabel style={{ width: 200, textAlign: 'left' }}>URL de Instagram: </FormLabel>
                    <TextField fullWidth
                        id="instagramUrl"
                        value={instagramUrl}
                        onChange={e => setInstagramUrl(e.target.value)}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>


            </Box>
        </Box>
        {isOnboarding ?
            <OnboardingButtons stepUtils={stepUtils}
                nextFn={() => stepUtils.saveUserAndNext(getUserData())}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => stepUtils.saveUserAndClose(getUserData())} />
            : <div style={{ width: '100%', textAlign: 'end' }}>
                <Button onClick={() => updateUser(getUserData())}
                    variant="contained"
                    disabled={updateUserLoading}
                    style={{
                        backgroundColor: '#7E2D87',
                        color: '#ffffff',
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: isMobile ? '100%' : '168px',
                        height: '50px',
                        fontWeight: 'bold',
                        marginBottom: isMobile ? '10px' : '0'
                    }}>{updateUserLoading ? <CircularProgress color="secondary" /> : 'Guardar'}</Button>
            </div>}
    </Box>;
};

export default Networking;