import React, { useContext, useState, useEffect } from "react";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const getShareText = (code) => `Trabajá en la casa de quien y cuando quieras! Alquila un espacio usando mi código *${code}* al registrarte para que yo pueda ganar $200 en créditos con tu primer reserva. *Vos podrás compartir el tuyo y obtener el mismo beneficio* =) www.worknmates.com`;

const ReferralCode = (props) => {
    const { context } = props;
    const [copied, setCopied] = useState(false);
    const { styles: contextStyles, fullUser } = useContext(context);

    useEffect(() => {
        console.log(JSON.stringify(fullUser))
    }, [fullUser]);


    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px', marginBottom: '20px' }}>
            <Box className="onboardingForm personalInfoForm">
                <div style={{ fontFamily: 'PoppinsBold', fontWeight: 'bold', color: '#383839', fontSize: '20px', paddingTop: '20px' }}>
                    <span>Referidos</span>
                    <Box style={{ minHeight: '20px' }}></Box>
                </div>
            </Box>
            <Box style={contextStyles.stepsView}>
                <div style={{ color: '#383839', textAlign: 'left', fontSize: '16px' }}>
                    Con el sistema de puntos de Worknmates podrás compartir tu código con tus contactos que te permitirá ganar <b>ARS 200</b> por cada uno que lo utilice para utlizar en tus reservas de Worknmates.
                </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: '#383839', textAlign: 'left', fontSize: '16px' }}>
                        Tu código es <b style={{ fontFamily: 'PoppinsBold', color: '#61257D' }}>{fullUser?.affiliation_code}</b>
                    </div>
                    <CopyToClipboard text={getShareText(fullUser?.affiliation_code)} onCopy={() => { setCopied(true); setTimeout(() => setCopied(false), 1000); }}>
                        <Tooltip title="Copiar código">
                            <IconButton>
                                <img src={require('../../../assets/images/share.png')} alt="" />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                    <span style={{ fontSize: '12px', color: 'grey' }}>{copied && 'Copiado'}</span>
                </div>
                <br />
                <div style={{ color: '#383839', textAlign: 'left', fontSize: '16px' }}>
                    ¡Empezá ya a compartirlo y a sumar créditos!
                </div>
                <br />
                <div style={{ color: '#383839', textAlign: 'left', fontSize: '16px' }}>
                    Total: ARS {fullUser?.points_balance}
                </div>
            </Box>
            <br />
        </div>
    </div>;
};

export default ReferralCode;


