import React, { useEffect, useState } from 'react'
import chroma from 'chroma-js';
import is from 'is_js';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import TextField from "@material-ui/core/TextField";
import { StaticDateRangePicker, DateRangeDelimiter, LocalizationProvider, PickersDay } from "@material-ui/pickers";
import { useMediaQuery } from 'react-responsive';
import Tooltip from '@material-ui/core/Tooltip';
import { Box } from '@material-ui/core';

const COLOR_SCALE = 5;

const Calendar = ({ data, filterBookingList, availability }) => {

  const isLessThan1300px = useMediaQuery({ query: '(max-width: 1300px)' });
  const isLessThan1000px = useMediaQuery({ query: '(max-width: 1000px)' });
  const [value, setValue] = useState([null, null]);
  const [colorLimitList, setColorLimitList] = useState([1, 2, 3, 4, 5]);
  const schemes = {
    cybertron: chroma.scale(['#38FFEE', '#003B36']).correctLightness().colors(COLOR_SCALE),
    ...chroma.brewer
  };

  useEffect(() => {
    if (is.not.empty(data)) {
      let maxBookingAmount = COLOR_SCALE;
      const maxNumber = () => data.reduce((acc, el) => acc > el.data ? acc : el.data)

      if (maxNumber > COLOR_SCALE) {
        maxBookingAmount = maxNumber;
        let a = maxBookingAmount / COLOR_SCALE;
        let b = [];
        for (let index = 1; index <= COLOR_SCALE; index++) {
          b.push(a * index);
        }
        setColorLimitList(b)
      }
    }
  }, [data]);

  const getHeatMapColor = (number) => {
    if (number <= colorLimitList[0]) return schemes.cybertron[0];
    if (number <= colorLimitList[1]) return schemes.cybertron[1];
    if (number <= colorLimitList[2]) return schemes.cybertron[2];
    if (number <= colorLimitList[3]) return schemes.cybertron[3];
    if (number <= colorLimitList[4]) return schemes.cybertron[4];
  };

  return (
    <>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <StaticDateRangePicker
          disableHighlightToday={true}
          readOnly={true}
          minDate={moment()}
          maxDate={moment().add(2, 'month').endOf('month')}
          renderDay={(date, DateRangeDayProps) => {
            const currentDateOcupation = data.filter((d) => moment(d.key).format('YYYY-MM-DD') === moment(DateRangeDayProps.day).format('YYYY-MM-DD'))[0];
            let dateColor = 'transparent';
            let tooltipMsg = '';
            if (currentDateOcupation) {
              dateColor = getHeatMapColor(currentDateOcupation.data);
              tooltipMsg = `Reservas: ${currentDateOcupation.data}`;
            }
            if (is.not.empty(availability)){
             if(is.empty(availability.filter((a) => a.date === moment(DateRangeDayProps.day).format('YYYY-MM-DD')))){
               dateColor = '#BABABA';
               tooltipMsg = `Día deshabilitado`;
             }
            }
            const { isPreviewing, isStartOfPreviewing, isEndOfPreviewing, isHighlighting, isStartOfHighlighting, isEndOfHighlighting, ...dateProps } = DateRangeDayProps;
            return (
              <Tooltip title={tooltipMsg} arrow placement="bottom" key={DateRangeDayProps.day.toString()}>
                <Box onClick={(e) => filterBookingList(DateRangeDayProps.day)}>
                  <PickersDay style={{ backgroundColor: dateColor, color: 'black' }} {...dateProps} />
                </Box>
              </Tooltip>
            )
          }}
          calendars={isLessThan1000px ? 1 : isLessThan1300px ? 2 : 3}
          displayStaticWrapperAs="desktop"
          value={value}
          onChange={(newValue) => setValue(newValue)}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <DateRangeDelimiter> to </DateRangeDelimiter>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default Calendar
