import { Avatar, Box } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import SpaceCalification from '../publication/SpaceCalification';

const ReviewDisplay = ({ review, byHost }) => {
    return <Box style={{ backgroundColor: '#ffffff', padding: 10, borderRadius: 15, border: '1px solid lightgray', margin: 15, textAlign: 'left', maxWidth: '90vw' }}>
        <Box style={{ margin: 15, paddingBottom: 10}}>
            <SpaceCalification space={review} showText={true} byHost={byHost} />
            <Box style={{ color: 'grey' }}>{moment(review.date).format('LL')}</Box>
        </Box>
        <Box style={{ color: '#383839', margin: 10, paddingBottom: 10 }}>{review.review_comment}</Box>
        <Box style={{ color: '#383839', display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
            <Avatar style={{ width: '50px', height: '50px', margin: 10 }} src={review.avatar.url} />
            <Box style={{ color: '#383839', marginLeft: 10 }}>
                <Box style={{ color: '#383839', fontWeight: 800 }}>{review.user_name + ' ' + review.user_lastname}</Box>
                <Box style={{ color: '#383839' }}>Se registró en {moment(review.user_creation_date).format('MMM YYYY')}</Box>
            </Box>
        </Box>
    </Box>
}

export default ReviewDisplay;