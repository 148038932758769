import React, { useContext } from "react";
import { Box, Button } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

const Success = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context } = props;
    const { styles: contextStyles, fullUser } = useContext(context);
    const history = useHistory();

    const styles = {
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        goToDashboard: {
            backgroundColor: '#7E2D87',
            color: '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '168px',
            height: '50px',
            fontWeight: 'bold',
            marginBottom: isMobile ? '10px' : '0'
        },
        share: {
            fontFamily: 'PoppinsBold',
            color: '#383839',
            fontSize: '16px',
            textTransform: 'none'
        },
        ...contextStyles
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <Box style={{ backgroundColor: '#7E2D87', height: '400px', marginLeft: isMobile ? '-10px' : '-50px', marginRight: isMobile ? '-10px' : '-50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={require('../../../assets/images/success-booking.png')} alt="" style={{ maxWidth: '160px', maxHeight: '160px' }} />
                <div style={{ color: '#ffffff', fontSize: '30px' }}>{`¡Felicitaciones ${fullUser?.name}!`}</div>
            </Box>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column', height: '300px' }}>
                <div style={{ color: '#383839', fontSize: isMobile ? '16px' : '22px' }}>¡Tu solicitud de reserva se realizó con éxito!</div>
                <div style={{ color: '#383839', fontSize: isMobile ? '16px' : '22px' }}>En unos minutos te llegará un correo de confirmación</div>
                <br />
                <Button onClick={() => history.push("/dashboard/reservation")} variant="contained" style={styles.goToDashboard}>Ir a mi Tablero</Button>
                <br />
            </Box>
        </Box>
    </Box>;
};

export default Success;