import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Box, Button, CircularProgress } from "@material-ui/core";


const EditionButtons = ({ stepUtils, saveFn, saveDisabled }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [saveLoading, setSaveLoading] = useState(false);


    const save = async () => {
        try {
            setSaveLoading(true);
            await saveFn();
        } catch (e) {
            setSaveLoading(false);
            console.log(e);
        } finally {
            setSaveLoading(false)
        }
    }

    const styles = {
        cancelButton: {
            marginLeft: isMobile ? '0' : '30px',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '140px',
            height: '50px',
            fontWeight: 'bold',
            color: '#6D7278',
            marginBottom: isMobile ? '10px' : '0'
        },
        saveButton: {
            marginLeft: isMobile ? '0' : '30px',
            backgroundColor: '#7E2D87',
            color: saveDisabled? 'grey' : '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '168px',
            height: '50px',
            fontWeight: 'bold',
            marginBottom: isMobile ? '10px' : '0',
        },
    }

    return <Box style={{ justifyContent: 'flex-end', display: 'flex', paddingBottom: '15px', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        <Button onClick={stepUtils.backToPublications} style={styles.cancelButton}>Volver</Button>
        <Button onClick={save} variant="contained" style={styles.saveButton} disabled={saveDisabled}>
            {saveLoading ? <CircularProgress color="secondary" /> : 'Guardar'}
        </Button>
    </Box>;
}

export default EditionButtons;