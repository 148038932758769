import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";

const OnboardingButtons = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { stepUtils, nextFn, prevFn, closeFn, nextDisabled } = props;
    const [nextLoading, setNextLoading] = useState(false);
    const [closeLoading, setCloseLoading] = useState(false);
    const [backLoading, setBackLoading] = useState(false);

    const next = async () => {
        try {
            setNextLoading(true);
            await nextFn();
        } catch (e) {
            setNextLoading(false);
            console.log(e);
        } finally {
            setNextLoading(false)
        }
    }

    const close = async () => {
        try {
            setCloseLoading(true);
            await closeFn();
        } catch (e) {
            setCloseLoading(false);
            console.log(e);
        } finally {
            setCloseLoading(false)
        }
    }

    const prev = async () => {
        try {
            setBackLoading(true);
            await prevFn();
        } catch (e) {
            setBackLoading(false);
            console.log(e);
        } finally {
            setBackLoading(false)
        }
    }

    const styles = {
        saveButton: {
            marginLeft: isMobile ? '0' : '30px',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '140px',
            height: '50px',
            fontWeight: 'bold',
            color: '#6D7278',
            marginBottom: isMobile ? '10px' : '0'
        },
        backButton: {
            marginLeft: isMobile ? '0' : '30px',
            borderRadius: '10px',
            borderColor: '#00D1BF',
            color: '#00D1BF',
            width: isMobile ? '100%' : '168px',
            height: '50px',
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            marginBottom: isMobile ? '10px' : '0'
        },
        nextButton: {
            marginLeft: isMobile ? '0' : '30px',
            backgroundColor: '#00D1BF',
            color: (nextLoading || nextDisabled) ? 'grey' : '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '168px',
            height: '50px',
            fontWeight: 'bold',
            marginBottom: isMobile ? '10px' : '0'
        }
    }

    return !stepUtils.isLastStep && <Box style={{ justifyContent: 'flex-end', display: 'flex', paddingBottom: '15px', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        {!stepUtils.isFirstStep && <Button onClick={close} disabled={closeLoading} style={styles.saveButton}>
            {closeLoading ? <CircularProgress /> : 'Guardar'}
        </Button>}

        {!stepUtils.isFirstStep && <Button onClick={prev} disabled={backLoading} variant="outlined" style={styles.backButton}>
            {backLoading ? <CircularProgress /> : 'Paso Anterior'}
        </Button>}

        <Button onClick={next} disabled={nextLoading || nextDisabled} variant="contained" style={styles.nextButton}>
            {nextLoading ? <CircularProgress color="secondary" /> :
                <>{!stepUtils.isPenultimateStep ? 'Próximo Paso' : 'Finalizar'}</>
            }
        </Button>
    </Box >;
}

export default OnboardingButtons;