import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Container } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import HostService from '../../../services/HostService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReviewDisplay from '../../booking/ReviewDisplay';
import SpaceTitle from '../../publication/SpaceTitle';
import SpaceCalification from '../../publication/SpaceCalification';
import is from 'is_js';

const HostReviews = () => {
    const { user } = useAuth0();

    const [reviewsBySpace, setReviewsBySpace] = useState([]);
    const [reviewsLoading, setReviewsLoading] = useState(false);

    useEffect(() => {
        setReviewsLoading(true);
        HostService.getReviews(HostService.getHostId(user)).then(resp => {
            setReviewsBySpace(resp.data);
            setReviewsLoading(false);
        })
    }, [user]);

    return <Box>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <span>Evaluaciones</span>
            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <Box>
            {reviewsLoading ? <CircularProgress /> :
                <>
                    {reviewsBySpace.filter(s => is.not.empty(s.reviews)).map(s => <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ marginBottom: 15, textAlign: 'left' }}>
                                    <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839', fontWeight: 500 }}><SpaceTitle space={s} /></div>
                                    <div style={{ fontFamily: 'Poppins', fontSize: '14px', color: '#383839' }}>{s.buildingType?.name}</div>
                                </div>
                                <SpaceCalification space={s} showText={true} />
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container className="spaceReviews" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                {s.reviews.sort((a, b) => new Date(b.date) - new Date(a.date)).map(r => <ReviewDisplay review={r} byHost={false} />)}
                            </Container>
                        </AccordionDetails>
                    </Accordion>)}
                </>
            }
        </Box>
    </Box>
}

export default HostReviews;