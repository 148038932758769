import React from "react";
import { Box, Button, Divider, FormControl } from "@material-ui/core";
import { useMediaQuery } from 'react-responsive';
import Footer from "../Footer";
import HomeImage from '../../assets/images/company-home.png';
import HomeImage02 from '../../assets/images/company-home-02.png';
import HomeImage03 from '../../assets/images/company-home-03.png';
import WhatsappButton from "../WhatsappButton";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useHistory } from "react-router-dom";

const CompanyHome = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLessThan1500pxWidth = useMediaQuery({ query: '(max-width: 1500px)' });
    const isLessThan1200pxWidth = useMediaQuery({ query: '(max-width: 1200px)' });
    const isLessThan750pxWidth = useMediaQuery({ query: '(max-width: 750px)' });

    const history = useHistory();

    const Step1 = () => <Box style={{ display: 'flex', margin: '0 20px', alignItems: 'center', flexDirection: isLessThan1200pxWidth ? 'column-reverse' : 'row' }}>
        <img alt="" src={require('../../assets/images/company-home-08.png')} style={{ height: 180, objectFit: 'contain', boxShadow: '10px 10px 14px 0px rgba(0,0,0,0.5)', marginBottom: 10 }} />
        <Box style={{ padding: 25 }}>
            <Box>Servicio Cloud: No requiere de Instalación previa.</Box>
            <br />
            <Box>La Empresa configura su presupuesto mensual y los Topes por empleado, solo paga si lo usa.</Box>
        </Box>
    </Box>;

    const Step2 = () => <Box style={{ margin: '0 20px', alignItems: 'center', maxWidth: isLessThan750pxWidth ? '' : 300, display: isLessThan750pxWidth ? 'flex' : 'block', flexDirection: isLessThan1200pxWidth ? 'column-reverse' : 'row' }}>
        <img alt="" src={require('../../assets/images/company-home-09.png')} style={{ width: 300, objectFit: 'contain', boxShadow: '10px 10px 14px 0px rgba(0,0,0,0.5)' }} />
        <Box style={{ padding: 25 }}>El empleado busca y selecciona el espacio que desea reservar.</Box>
    </Box>;

    const Step3 = () => <Box style={{ display: 'flex', margin: '0 20px', alignItems: 'center', flexDirection: isLessThan1200pxWidth ? 'column-reverse' : 'row' }}>
        <img alt="" src={require('../../assets/images/company-home-10.png')} style={{ height: 150, objectFit: 'contain', boxShadow: '10px 10px 14px 0px rgba(0,0,0,0.5)', marginBottom: 10 }} />
        <Box style={{ padding: 25 }}>
            <Box>El empleado podrá utilizar los créditos de la empresa como medio de pago.</Box>
            <br />
            <Box>En caso de terminarse los créditos La Empresa podrá aumentarlos o bien el usuario seguir reservando a su propio coste.</Box>
        </Box>
    </Box>;

    const Step4 = () => <Box style={{ margin: '0 20px', alignItems: 'center', maxWidth: isLessThan750pxWidth ? '' : 300, display: isLessThan750pxWidth ? 'flex' : 'block', flexDirection: isLessThan1200pxWidth ? 'column-reverse' : 'row', }}>
        <img alt="" src={require('../../assets/images/company-home-11.png')} style={{ width: 300, objectFit: 'contain', boxShadow: '10px 10px 14px 0px rgba(0,0,0,0.5)' }} />
        <Box style={{ padding: 25 }}>
            <Box>La empresa podrá monitorear y  tener acceso a todas las reservas y gastos. </Box>
            <br />
            <Box>A fin de cada mes se realiza el cobro y se reanudan todos los créditos</Box>
        </Box>
    </Box>;

    return <Box>
        <Box>
            <WhatsappButton />
            <Box style={{
                overflow: 'hidden',
                zIndex: -1,
                backgroundImage: `url(${HomeImage})`,
                backgroundSize: 'cover',
                height: '32vw',
                maxHeight: '700px',
                minHeight: isMobile ? 400 : '600px',
                borderRadius: '15px',
                backgroundPosition: isMobile ? 'center' : 'center 30%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', 
                padding: 20
            }}>
                <Box style={{ marginTop: -10, color: '#383839', fontWeight: 900, fontSize: isMobile ? 30 : 50, fontFamily: 'PoppinsBold', textAlign: isMobile ? 'center' : 'left', marginLeft: isMobile ? 0 : '1%' }}>Work From Anywhere</Box>
                {isMobile ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: isMobile ? 10 : 30, textAlign: 'left' }}>
                    <div style={{ ...styles.homeText, fontSize: isMobile ? 18 : isLessThan1500pxWidth ? '2vw' : '35px' }}>Otorgá créditos a tus empleados para que puedan reservar espacios de trabajo cerca de su casa</div>
                </div> :
                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: isMobile ? 10 : 30, textAlign: 'left' }}>
                        <div style={{ ...styles.homeText, fontSize: isMobile ? 18 : isLessThan1500pxWidth ? '2.5vw' : '35px' }}>Otorgá créditos a tus empleados</div>
                        <div style={{ ...styles.homeText, fontSize: isMobile ? 18 : isLessThan1500pxWidth ? '2.5vw' : '35px' }}>en worknmates para que puedan</div>
                        <div style={{ ...styles.homeText, fontSize: isMobile ? 18 : isLessThan1500pxWidth ? '2.5vw' : '35px' }}>reservar espacios de trabajo</div>
                        <div style={{ ...styles.homeText, fontSize: isMobile ? 18 : isLessThan1500pxWidth ? '2.5vw' : '35px' }}>cerca de su casa</div>
                    </div>}
                <br />

                <FormControl style={{ ...styles.formControl, alignSelf: isMobile ? 'center' : 'normal', marginLeft: isMobile ? 0 : 30 }}>
                    <Button onClick={() => history.push("/company")} variant="contained" style={{ ...styles.startButton, fontSize: isMobile ? '16px' : '20px' }}>Comenzar</Button>
                </FormControl>
            </Box>

            <Box style={{ display: 'flex', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', marginTop: -30, marginBottom: 50, padding: 20 }}>
                <Box style={{ ...styles.homeSpaces, fontSize: isMobile ? '4.5vw' : '18px', marginBottom: 5 }}>coworkings</Box>
                <Box style={{ backgroundColor: 'rgb(107, 45, 135, 0.6)', width: 30, height: 8, borderRadius: 20, marginBottom: 5, margin: '0 5px' }}></Box>
                <Box style={{ ...styles.homeSpaces, fontSize: isMobile ? '4.5vw' : '18px', marginBottom: 5 }}>oficinas empresariales</Box>
                <Box style={{ backgroundColor: 'rgb(107, 45, 135, 0.6)', width: 30, height: 8, borderRadius: 20, marginBottom: 5, margin: '0 5px' }}></Box>
                <Box style={{ ...styles.homeSpaces, fontSize: isMobile ? '4.5vw' : '18px', marginBottom: 5 }}>casas / departamentos particulares</Box>
                <Box style={{ backgroundColor: 'rgb(107, 45, 135, 0.6)', width: 30, height: 8, borderRadius: 20, marginBottom: 5, margin: '0 5px' }}></Box>
                <Box style={{ ...styles.homeSpaces, fontSize: isMobile ? '4.5vw' : '18px', marginBottom: 5 }}>hoteles</Box>
            </Box>

            <br />
            <br />

            <Box style={{ marginTop: 50, borderRadius: 15, boxShadow: '0px 0px 100px -16px rgba(0,0,0,0.5)', padding: 20, height: isLessThan1200pxWidth ? '' : '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 20, marginRight: 20 }}>

                <Box style={{ fontSize: isMobile ? 18 : 30, textAlign: isMobile ? 'center' : 'left', marginBottom: 30, fontFamily: 'PoppinsBold' }}>La pandemia aceleró la transformación de las empresas, estableciendo un modo de trabajo más flexible y eficiente.</Box>
                <Box>
                    <div style={{ fontSize: isMobile ? 18 : 30, display: 'flex', justifyContent: 'center', color: '#383839', fontFamily: 'PoppinsBold', marginTop: isMobile ? 20 : 0 }}>2 Pilares del Futuro del Trabajo</div>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
                    <Box style={{
                        overflow: 'hidden',
                        zIndex: -1,
                        backgroundImage: `url(${HomeImage02})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: isMobile ? '95%' : '30%',
                        height: isMobile ? 230 : 350,
                        backgroundPosition: 'center',
                        fontSize: 20,
                        textAlign: isMobile ? 'center' : 'right',
                        display: 'flex',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        lineHeight: '36px',
                    }}>
                        El lugar de trabajo como un concepto moderno y flexible que permita satisfacer las necesidades de la empresa y del empleado.
                </Box>
                    <img alt="" src={require('../../assets/images/company-home-01.png')} style={{ width: isMobile ? '95%' : '30%' }} />
                    <Box style={{
                        overflow: 'hidden',
                        zIndex: -1,
                        backgroundImage: `url(${HomeImage03})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: isMobile ? '95%' : '30%',
                        height: isMobile ? 230 : 350,
                        backgroundPosition: 'center',
                        fontSize: 20,
                        textAlign: isMobile ? 'center' : 'left',
                        display: 'flex',
                        alignItems: isMobile ? 'flex-end' : 'center',
                        lineHeight: '36px',
                    }}>
                        La Tecnología como facilitador, permitiendo a los empleados hacer mejor su trabajo y ser cada dia mas eficientes.
                </Box>
                </Box>
            </Box>
            <br />
            <br />




            <Box style={{ marginTop: 50, borderRadius: 15, padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 20, marginRight: 20 }}>

                <Box>
                    <div style={{ marginTop: '50px', fontSize: '30px', display: 'flex', justifyContent: 'center', color: '#383839', fontFamily: 'PoppinsBold', marginBottom: 30 }}>Cómo funciona?</div>
                </Box>
                {isLessThan750pxWidth ?
                    <Box style={{ marginBottom: 40, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                        <Step1 />
                        <Box style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                            <FiberManualRecordIcon fontSize={'large'} style={{ color: '#7E2D87' }} />
                            <ArrowDownwardIcon fontSize={'large'} style={{ marginTop: -12, color: '#7E2D87' }} />
                        </Box>
                        <Step2 />
                        <Box style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                            <FiberManualRecordIcon fontSize={'large'} style={{ color: '#00D1BF' }} />
                            <ArrowDownwardIcon fontSize={'large'} style={{ marginTop: -12, color: '#00D1BF' }} />
                        </Box>
                        <Step3 />
                        <Box style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                            <FiberManualRecordIcon fontSize={'large'} style={{ color: '#7E2D87' }} />
                            <ArrowDownwardIcon fontSize={'large'} style={{ marginTop: -12, color: '#7E2D87' }} />
                        </Box>
                        <Step4 />
                    </Box>
                    :
                    <>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'left', fontSize: 15 }}>
                            <Step1 />
                            <Step3 />
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: -10, marginBottom: 10 }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
                                <ArrowUpwardIcon fontSize={'large'} style={{ marginBottom: -12, color: '#7E2D87' }} />
                                <FiberManualRecordIcon fontSize={'large'} style={{ color: '#7E2D87' }} />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                                <FiberManualRecordIcon fontSize={'large'} style={{ color: '#00D1BF' }} />
                                <ArrowDownwardIcon fontSize={'large'} style={{ marginTop: -12, color: '#00D1BF' }} />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
                                <ArrowUpwardIcon fontSize={'large'} style={{ marginBottom: -12, color: '#7E2D87' }} />
                                <FiberManualRecordIcon fontSize={'large'} style={{ color: '#7E2D87' }} />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                                <FiberManualRecordIcon fontSize={'large'} style={{ color: '#00D1BF' }} />
                                <ArrowDownwardIcon fontSize={'large'} style={{ marginTop: -12, color: '#00D1BF' }} />

                            </Box>
                        </Box>
                        <Box className="companyTimeLine">
                            <Divider style={{ marginTop: -60, marginBottom: 50 }} variant="middle" />
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'left', fontSize: 15 }}>
                            <Box style={{ margin: 20, maxWidth: 300 }}>
                            </Box>
                            <Step2 />
                            <Step4 />
                        </Box>
                    </>
                }
            </Box>



            <br />
            <br />

            <Box style={{ marginTop: 50, borderRadius: 15, boxShadow: '0px 0px 100px -16px rgba(0,0,0,0.5)', padding: 20, height: isLessThan1200pxWidth ? 'auto' : '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 20, marginRight: 20 }}>

                <Box>
                    <div style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', color: '#383839', fontFamily: 'PoppinsBold' }}>Beneficios</div>
                </Box>
                <Box>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', marginTop: 20 }}>
                        <Box style={{ marginBottom: 30 }}>
                            <img alt="" src={require('../../assets/images/company-home-04.png')} style={{ maxHeight: 200, maxWidth: 250, objectFit: 'contain' }} />
                            <Box style={{ margin: 15 }}>Mejora tu Marca Empleadora</Box>
                        </Box>
                        <Box style={{ marginBottom: 30 }}>
                            <img alt="" src={require('../../assets/images/company-home-07.png')} style={{ maxHeight: 200, maxWidth: 250, objectFit: 'contain' }} />
                            <Box style={{ margin: 15 }}>Globalizate: Oficinas en todos lados</Box>
                        </Box>
                        <Box style={{ marginBottom: 30 }}>
                            <img alt="" src={require('../../assets/images/company-home-05.png')} style={{ maxHeight: 200, maxWidth: 250, objectFit: 'contain' }} />
                            <Box style={{ margin: 15 }}>Incrementa la felicidad de tus empleados</Box>
                        </Box>
                        <Box style={{ marginBottom: 30 }}>
                            <img alt="" src={require('../../assets/images/company-home-06.png')} style={{ maxHeight: 200, maxWidth: 250, objectFit: 'contain' }} />
                            <Box style={{ margin: 15 }}>Reducí tus costos</Box>
                        </Box>
                    </div>
                </Box>
            </Box>
        </Box>
        <Footer />
    </Box>;
};

export default CompanyHome;



const styles = {
    title: {
        width: '300%',
        height: '5vw',
        fontFamily: 'PoppinsBold',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '3.3vw',
        color: '#383839',
        zIndex: 99,
        marginLeft: '-40%'
    },
    titleMobile: {
        fontFamily: 'PoppinsBold',
        fontSize: '28px',
        marginTop: '10%',
        color: '#383839',
    },
    formControl: {
        padding: '10px',
        marginBottom: '20px',
        flexShrink: 1,
        minWidth: '135px',
    },
    homeText: {
        fontFamily: 'PoppinsBold',
        color: '#383839',
        fontWeight: 600,
        width: 'fit-content',
        margin: '5px'
    },
    startButton: {
        background: 'linear-gradient(45deg, rgba(126,45,135,1) 0%, rgba(0,209,191,1) 100%)',
        color: '#ffffff',
        fontFamily: 'Poppins',
        borderRadius: '10px',
        boxShadow: 'none',
        width: '20vw',
        minWidth: '200px',
        height: '50px',
        fontWeight: 'bold'
    },
    homeSpaces: {
        color: '#818181',
    },
    tutorial: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
    },
    tutorialMobile: {
        display: 'flex',
        flexDirection: 'column'
    },
    paymentAdvice: {
        flexGrow: 1,
        maxWidth: '20%',
        minWidth: '275px',
        textAlign: 'center',
        color: '#383839',
        margin: '30px 5px 5px 5px',
    },
    paymentAdviceMobile: {
        flexGrow: 1,
        maxWidth: '95%',
        minWidth: '275px',
        textAlign: 'left',
        color: '#383839',
        marginTop: '40px',
    }
};