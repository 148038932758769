import React, { useState, useContext, useEffect } from "react";
import { Box, Button, CircularProgress, FormHelperText } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '../../styled/StyledTextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useMediaQuery } from "react-responsive";
import StyledInputBase from '../../styled/StyledInputBase';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import OnboardingButtons from "./OnboardingButtons";
import Geocode from "react-geocode";
import { usePosition } from 'use-position';
import EditionButtons from "../dashboard/EditionButtons";
import is from "is_js";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import DataService from "../../../services/DataService";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
Geocode.setLanguage("es");
Geocode.setRegion("ar");

const googleMapsLibraries = ['places'];

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Location = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context, isOnboarding } = props;
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const [address, setAddress] = useState({})
    const { latitude: currentLatitude, longitude: currentLongitude } = usePosition();
    const [currentLocationLoading, setCurrentLocationLoading] = useState(false);

    const { isLoaded: isScriptLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: googleMapsLibraries
    });

    const [country, setCountry] = useState('');
    const [city, setCity] = useState(space?.location?.city || '');
    const [streetName, setStreetName] = useState(space?.location?.street || '');
    const [streetNumber, setStreetNumber] = useState(space?.location?.street_number || '');
    const [floor, setFloor] = useState(space?.location?.apt_number || '');
    const [zipCode, setZipCode] = useState(space?.location?.zip_code || '');
    const [lat, setLat] = useState(space?.location?.latitude || 0);
    const [long, setLong] = useState(space?.location?.longitude || 0);

    const [errors, setErrors] = useState({});
    const [countryOptions, setCountryOptions] = useState([]);
    const [citiesAutocomplete, setCitiesAutocomplete] = useState(null);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        locationButton: {
            borderRadius: '10px',
            borderColor: '#00D1BF',
            color: '#00D1BF',
            maxWidth: '300px',
            height: '50px',
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            width: '100%',
            marginBottom: '20px'
        },
        ...contextStyles
    }

    useEffect(() => {
        let mounted = true;

        const getCountriesOptions = async () => {
            let resp = await DataService.getNationalities();
            mounted && setCountryOptions(resp.data);
        }
        getCountriesOptions().then(() => {
            mounted && setCountry(space?.location?.country || '');
        });

        return () => mounted = false;
    }, [space]);

    useEffect(() => {
        setCountry(c => address.country || c);
        setCity(ci => address.administrative_area_level_1 || ci);
        setStreetName(sn => address.route || sn);
        setStreetNumber(snum => address.street_number || snum);
        setZipCode(zc => address.postal_code || zc);
        setLat(lt => currentLatitude || lt);
        setLong(ln => currentLongitude || ln);
    }, [address, currentLatitude, currentLongitude]);

    useEffect(() => {
        setCity(space?.location?.city || '');
        setStreetName(space?.location?.street || '');
        setStreetNumber(space?.location?.street_number || '');
        setFloor(space?.location?.apt_number || '');
        setZipCode(space?.location?.zip_code || '');
        setLat(space?.location?.latitude || 0);
        setLong(space?.location?.longitude || 0);
    }, [space]);

    // get current location and update adress
    const getCurrentLocation = () => {
        setCurrentLocationLoading(true);
        Geocode.fromLatLng(currentLatitude, currentLongitude).then(
            response => {
                response.results[0] && setAddress(getAdressData(response.results[0]));
                setCurrentLocationLoading(false);
            },
            error => {
                console.error(error);
                setCurrentLocationLoading(false);
            }
        );
    }

    // returns address data from google-maps-api result
    const getAdressData = (googleMapsAPIResult) => {
        const addressAccumulator = {};
        if (googleMapsAPIResult?.address_components)
            googleMapsAPIResult.address_components.forEach(i => {
                let propName = i.types[0];
                return addressAccumulator[propName] = i.long_name;
            });
        return addressAccumulator;
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(country)) errorsAcum.country = REQUIERED_FIELD_ERROR;
        if (is.empty(city)) errorsAcum.city = REQUIERED_FIELD_ERROR;
        if (is.empty(streetName)) errorsAcum.streetName = REQUIERED_FIELD_ERROR;
        if (is.empty(streetNumber)) errorsAcum.streetNumber = REQUIERED_FIELD_ERROR;
        if (is.empty(zipCode)) errorsAcum.zipCode = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getSpaceData = (googleMapsAPIResult) => {
        let adressData = getAdressData(googleMapsAPIResult);
        setAddress(adressData);
        return {
            location: {
                country: adressData?.country || country,
                city: adressData?.administrative_area_level_1 || city,
                street: adressData?.street || streetName,
                street_number: adressData?.street_number || streetNumber,
                apt_number: floor,
                zip_code: adressData?.postal_code || zipCode,
                latitude: googleMapsAPIResult?.geometry?.location?.lat || lat,
                longitude: googleMapsAPIResult?.geometry?.location?.lng || long
            },
        }
    };

    // calc adress lat and long from adress string
    const getLocationFromAddressString = async () => {
        let adressString = `${streetName} ${streetNumber}, ${city}, ${country}`;
        let resp = await Geocode.fromAddress(adressString);
        return resp.results[0] ? resp.results[0] : {};
    }

    const nextFn = async () => {
        if (validateFormData()) {
            const googleMapsAPIResult = await getLocationFromAddressString();
            await stepUtils.saveSpaceAndNext(getSpaceData(googleMapsAPIResult));
        }
    }

    const closeFn = async () => {
        if (validateFormData()) {
            const googleMapsAPIResult = await getLocationFromAddressString();
            await stepUtils.saveSpaceAndClose(getSpaceData(googleMapsAPIResult));
        }
    }

    const citiesAutocompleteLoad = ac => setCitiesAutocomplete(ac);
    const citiesAutocompleteChange = () => citiesAutocomplete && setCity(citiesAutocomplete.getPlace().name);

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Ubicación</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>

            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                <div style={{ color: '#383839', marginBottom: '10px' }}>¿Dónde se encuentra tu espacio?</div>
                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px' }}>Los invitados verán tu dirección exacta después de reservar</div>
                <Button startIcon={currentLocationLoading ? null : <LocationOnIcon />} disabled={currentLocationLoading || (!currentLongitude && !currentLatitude)} onClick={getCurrentLocation} className="getLocationButton" variant="outlined" style={styles.locationButton}>
                    {currentLocationLoading ? <CircularProgress /> : 'Usar mi ubicación actual'}
                </Button>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between' }}>
                <FormControl style={styles.formControl} error={!!errors.country}>
                    <InputLabel shrink id="country-label" style={{ marginLeft: '8px', marginTop: '5px' }}>Pais / Región *</InputLabel>
                    <Select id="country" value={country} required onChange={e => setCountry(e.target.value)} displayEmpty input={<StyledInputBase />}>
                        {countryOptions.sort((a, b) => a.order - b.order).map(co => <MenuItem key={co.id + '_ctry'} value={co.name}>{co.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>{errors.country}</FormHelperText>
                </FormControl>

                <FormControl style={styles.formControl} error={!!errors.city}>
                    {isScriptLoaded &&
                        <Autocomplete
                            options={{ types: ['(cities)'], componentRestrictions: { country: getCountryCode(country) } }}
                            onLoad={citiesAutocompleteLoad}
                            onPlaceChanged={citiesAutocompleteChange} >
                            <TextField
                                id="city"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                placeholder=""
                                required
                                label="Ciudad"
                                type="text"
                                style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true, }}
                                autoComplete='off' />
                        </Autocomplete>
                    }
                    <FormHelperText>{errors.city}</FormHelperText>
                </FormControl>

                <FormControl style={styles.formControl}>
                    <TextField
                        id="adress"
                        label="Dirección"
                        value={streetName}
                        onChange={e => setStreetName(e.target.value)}
                        required
                        error={!!errors.streetName}
                        helperText={errors.streetName}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                    <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                        <TextField
                            id="address_number"
                            label="Número"
                            value={streetNumber}
                            onChange={e => setStreetNumber(e.target.value)}
                            required
                            error={!!errors.streetNumber}
                            helperText={errors.streetNumber}
                            type="text"
                            InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }} />
                    </FormControl>
                    <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: '0', }}>
                        <TextField
                            id="address_floor"
                            label="Piso"
                            value={floor}
                            onChange={e => setFloor(e.target.value)}
                            type="text"
                            InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }} />
                    </FormControl>
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 3 }}>
                    <TextField
                        id="postal_code"
                        label="Código Postal"
                        onChange={e => setZipCode(e.target.value)}
                        value={zipCode}
                        required
                        error={!!errors.zipCode}
                        helperText={errors.zipCode}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ? <OnboardingButtons stepUtils={stepUtils}
            nextFn={nextFn}
            prevFn={() => stepUtils.previousStep()}
            closeFn={closeFn} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={() => validateFormData() && stepUtils.updateSpace(getSpaceData())} />
        }
    </Box>;
};

export default Location;

// TODO move this to backend
const countriesAndCodes = [
    {
        "code": "AD",
        "name": "Andorra"
    },
    {
        "code": "AE",
        "name": "Emiratos Árabes Unidos"
    },
    {
        "code": "AF",
        "name": "Afganistán"
    },
    {
        "code": "AG",
        "name": "Antigua y Barbuda"
    },
    {
        "code": "AI",
        "name": "Anguila"
    },
    {
        "code": "AL",
        "name": "Albania"
    },
    {
        "code": "AM",
        "name": "Armenia"
    },
    {
        "code": "AN",
        "name": "Antillas Neerlandesas"
    },
    {
        "code": "AO",
        "name": "Angola"
    },
    {
        "code": "AQ",
        "name": "Antártida"
    },
    {
        "code": "AR",
        "name": "Argentina"
    },
    {
        "code": "AS",
        "name": "Samoa Americana"
    },
    {
        "code": "AT",
        "name": "Austria"
    },
    {
        "code": "AU",
        "name": "Australia"
    },
    {
        "code": "AW",
        "name": "Aruba"
    },
    {
        "code": "AX",
        "name": "Islas Áland"
    },
    {
        "code": "AZ",
        "name": "Azerbaiyán"
    },
    {
        "code": "BA",
        "name": "Bosnia y Herzegovina"
    },
    {
        "code": "BB",
        "name": "Barbados"
    },
    {
        "code": "BD",
        "name": "Bangladesh"
    },
    {
        "code": "BE",
        "name": "Bélgica"
    },
    {
        "code": "BF",
        "name": "Burkina Faso"
    },
    {
        "code": "BG",
        "name": "Bulgaria"
    },
    {
        "code": "BH",
        "name": "Bahréin"
    },
    {
        "code": "BI",
        "name": "Burundi"
    },
    {
        "code": "BJ",
        "name": "Benin"
    },
    {
        "code": "BL",
        "name": "San Bartolomé"
    },
    {
        "code": "BM",
        "name": "Bermudas"
    },
    {
        "code": "BN",
        "name": "Brunéi"
    },
    {
        "code": "BO",
        "name": "Bolivia"
    },
    {
        "code": "BR",
        "name": "Brasil"
    },
    {
        "code": "BS",
        "name": "Bahamas"
    },
    {
        "code": "BT",
        "name": "Bhután"
    },
    {
        "code": "BV",
        "name": "Isla Bouvet"
    },
    {
        "code": "BW",
        "name": "Botsuana"
    },
    {
        "code": "BY",
        "name": "Belarús"
    },
    {
        "code": "BZ",
        "name": "Belice"
    },
    {
        "code": "CA",
        "name": "Canadá"
    },
    {
        "code": "CC",
        "name": "Islas Cocos"
    },
    {
        "code": "CF",
        "name": "República Centro-Africana"
    },
    {
        "code": "CG",
        "name": "Congo"
    },
    {
        "code": "CH",
        "name": "Suiza"
    },
    {
        "code": "CI",
        "name": "Costa de Marfil"
    },
    {
        "code": "CK",
        "name": "Islas Cook"
    },
    {
        "code": "CL",
        "name": "Chile"
    },
    {
        "code": "CM",
        "name": "Camerún"
    },
    {
        "code": "CN",
        "name": "China"
    },
    {
        "code": "CO",
        "name": "Colombia"
    },
    {
        "code": "CR",
        "name": "Costa Rica"
    },
    {
        "code": "CU",
        "name": "Cuba"
    },
    {
        "code": "CV",
        "name": "Cabo Verde"
    },
    {
        "code": "CX",
        "name": "Islas Christmas"
    },
    {
        "code": "CY",
        "name": "Chipre"
    },
    {
        "code": "CZ",
        "name": "República Checa"
    },
    {
        "code": "DE",
        "name": "Alemania"
    },
    {
        "code": "DJ",
        "name": "Yibuti"
    },
    {
        "code": "DK",
        "name": "Dinamarca"
    },
    {
        "code": "DM",
        "name": "Domínica"
    },
    {
        "code": "DO",
        "name": "República Dominicana"
    },
    {
        "code": "DZ",
        "name": "Argel"
    },
    {
        "code": "EC",
        "name": "Ecuador"
    },
    {
        "code": "EE",
        "name": "Estonia"
    },
    {
        "code": "EG",
        "name": "Egipto"
    },
    {
        "code": "EH",
        "name": "Sahara Occidental"
    },
    {
        "code": "ER",
        "name": "Eritrea"
    },
    {
        "code": "ES",
        "name": "España"
    },
    {
        "code": "ET",
        "name": "Etiopía"
    },
    {
        "code": "FI",
        "name": "Finlandia"
    },
    {
        "code": "FJ",
        "name": "Fiji"
    },
    {
        "code": "FK",
        "name": "Islas Malvinas"
    },
    {
        "code": "FM",
        "name": "Micronesia"
    },
    {
        "code": "FO",
        "name": "Islas Faroe"
    },
    {
        "code": "FR",
        "name": "Francia"
    },
    {
        "code": "GA",
        "name": "Gabón"
    },
    {
        "code": "GB",
        "name": "Reino Unido"
    },
    {
        "code": "GD",
        "name": "Granada"
    },
    {
        "code": "GE",
        "name": "Georgia"
    },
    {
        "code": "GF",
        "name": "Guayana Francesa"
    },
    {
        "code": "GG",
        "name": "Guernsey"
    },
    {
        "code": "GH",
        "name": "Ghana"
    },
    {
        "code": "GI",
        "name": "Gibraltar"
    },
    {
        "code": "GL",
        "name": "Groenlandia"
    },
    {
        "code": "GM",
        "name": "Gambia"
    },
    {
        "code": "GN",
        "name": "Guinea"
    },
    {
        "code": "GP",
        "name": "Guadalupe"
    },
    {
        "code": "GQ",
        "name": "Guinea Ecuatorial"
    },
    {
        "code": "GR",
        "name": "Grecia"
    },
    {
        "code": "GS",
        "name": "Georgia del Sur e Islas Sandwich del Sur"
    },
    {
        "code": "GT",
        "name": "Guatemala"
    },
    {
        "code": "GU",
        "name": "Guam"
    },
    {
        "code": "GW",
        "name": "Guinea-Bissau"
    },
    {
        "code": "GY",
        "name": "Guayana"
    },
    {
        "code": "HK",
        "name": "Hong Kong"
    },
    {
        "code": "HM",
        "name": "Islas Heard y McDonald"
    },
    {
        "code": "HN",
        "name": "Honduras"
    },
    {
        "code": "HR",
        "name": "Croacia"
    },
    {
        "code": "HT",
        "name": "Haití"
    },
    {
        "code": "HU",
        "name": "Hungría"
    },
    {
        "code": "ID",
        "name": "Indonesia"
    },
    {
        "code": "IE",
        "name": "Irlanda"
    },
    {
        "code": "IL",
        "name": "Israel"
    },
    {
        "code": "IM",
        "name": "Isla de Man"
    },
    {
        "code": "IN",
        "name": "India"
    },
    {
        "code": "IO",
        "name": "Territorio Británico del Océano Índico"
    },
    {
        "code": "IQ",
        "name": "Irak"
    },
    {
        "code": "IR",
        "name": "Irán"
    },
    {
        "code": "IS",
        "name": "Islandia"
    },
    {
        "code": "IT",
        "name": "Italia"
    },
    {
        "code": "JE",
        "name": "Jersey"
    },
    {
        "code": "JM",
        "name": "Jamaica"
    },
    {
        "code": "JO",
        "name": "Jordania"
    },
    {
        "code": "JP",
        "name": "Japón"
    },
    {
        "code": "KE",
        "name": "Kenia"
    },
    {
        "code": "KG",
        "name": "Kirguistán"
    },
    {
        "code": "KH",
        "name": "Camboya"
    },
    {
        "code": "KI",
        "name": "Kiribati"
    },
    {
        "code": "KM",
        "name": "Comoros"
    },
    {
        "code": "KN",
        "name": "San Cristóbal y Nieves"
    },
    {
        "code": "KP",
        "name": "Corea del Norte"
    },
    {
        "code": "KR",
        "name": "Corea del Sur"
    },
    {
        "code": "KW",
        "name": "Kuwait"
    },
    {
        "code": "KY",
        "name": "Islas Caimán"
    },
    {
        "code": "KZ",
        "name": "Kazajstán"
    },
    {
        "code": "LA",
        "name": "Laos"
    },
    {
        "code": "LB",
        "name": "Líbano"
    },
    {
        "code": "LC",
        "name": "Santa Lucía"
    },
    {
        "code": "LI",
        "name": "Liechtenstein"
    },
    {
        "code": "LK",
        "name": "Sri Lanka"
    },
    {
        "code": "LR",
        "name": "Liberia"
    },
    {
        "code": "LS",
        "name": "Lesotho"
    },
    {
        "code": "LT",
        "name": "Lituania"
    },
    {
        "code": "LU",
        "name": "Luxemburgo"
    },
    {
        "code": "LV",
        "name": "Letonia"
    },
    {
        "code": "LY",
        "name": "Libia"
    },
    {
        "code": "MA",
        "name": "Marruecos"
    },
    {
        "code": "MC",
        "name": "Mónaco"
    },
    {
        "code": "MD",
        "name": "Moldova"
    },
    {
        "code": "ME",
        "name": "Montenegro"
    },
    {
        "code": "MG",
        "name": "Madagascar"
    },
    {
        "code": "MH",
        "name": "Islas Marshall"
    },
    {
        "code": "MK",
        "name": "Macedonia"
    },
    {
        "code": "ML",
        "name": "Mali"
    },
    {
        "code": "MM",
        "name": "Myanmar"
    },
    {
        "code": "MN",
        "name": "Mongolia"
    },
    {
        "code": "MO",
        "name": "Macao"
    },
    {
        "code": "MQ",
        "name": "Martinica"
    },
    {
        "code": "MR",
        "name": "Mauritania"
    },
    {
        "code": "MS",
        "name": "Montserrat"
    },
    {
        "code": "MT",
        "name": "Malta"
    },
    {
        "code": "MU",
        "name": "Mauricio"
    },
    {
        "code": "MV",
        "name": "Maldivas"
    },
    {
        "code": "MW",
        "name": "Malawi"
    },
    {
        "code": "MX",
        "name": "México"
    },
    {
        "code": "MY",
        "name": "Malasia"
    },
    {
        "code": "MZ",
        "name": "Mozambique"
    },
    {
        "code": "NA",
        "name": "Namibia"
    },
    {
        "code": "NC",
        "name": "Nueva Caledonia"
    },
    {
        "code": "NE",
        "name": "Níger"
    },
    {
        "code": "NF",
        "name": "Islas Norkfolk"
    },
    {
        "code": "NG",
        "name": "Nigeria"
    },
    {
        "code": "NI",
        "name": "Nicaragua"
    },
    {
        "code": "NL",
        "name": "Países Bajos"
    },
    {
        "code": "NO",
        "name": "Noruega"
    },
    {
        "code": "NP",
        "name": "Nepal"
    },
    {
        "code": "NR",
        "name": "Nauru"
    },
    {
        "code": "NU",
        "name": "Niue"
    },
    {
        "code": "NZ",
        "name": "Nueva Zelanda"
    },
    {
        "code": "OM",
        "name": "Omán"
    },
    {
        "code": "PA",
        "name": "Panamá"
    },
    {
        "code": "PE",
        "name": "Perú"
    },
    {
        "code": "PF",
        "name": "Polinesia Francesa"
    },
    {
        "code": "PG",
        "name": "Papúa Nueva Guinea"
    },
    {
        "code": "PH",
        "name": "Filipinas"
    },
    {
        "code": "PK",
        "name": "Pakistán"
    },
    {
        "code": "PL",
        "name": "Polonia"
    },
    {
        "code": "PM",
        "name": "San Pedro y Miquelón"
    },
    {
        "code": "PN",
        "name": "Islas Pitcairn"
    },
    {
        "code": "PR",
        "name": "Puerto Rico"
    },
    {
        "code": "PS",
        "name": "Palestina"
    },
    {
        "code": "PT",
        "name": "Portugal"
    },
    {
        "code": "PW",
        "name": "Islas Palaos"
    },
    {
        "code": "PY",
        "name": "Paraguay"
    },
    {
        "code": "QA",
        "name": "Qatar"
    },
    {
        "code": "RE",
        "name": "Reunión"
    },
    {
        "code": "RO",
        "name": "Rumanía"
    },
    {
        "code": "RS",
        "name": "Serbia y Montenegro"
    },
    {
        "code": "RU",
        "name": "Rusia"
    },
    {
        "code": "RW",
        "name": "Ruanda"
    },
    {
        "code": "SA",
        "name": "Arabia Saudita"
    },
    {
        "code": "SB",
        "name": "Islas Solomón"
    },
    {
        "code": "SC",
        "name": "Seychelles"
    },
    {
        "code": "SD",
        "name": "Sudán"
    },
    {
        "code": "SE",
        "name": "Suecia"
    },
    {
        "code": "SG",
        "name": "Singapur"
    },
    {
        "code": "SH",
        "name": "Santa Elena"
    },
    {
        "code": "SI",
        "name": "Eslovenia"
    },
    {
        "code": "SJ",
        "name": "Islas Svalbard y Jan Mayen"
    },
    {
        "code": "SK",
        "name": "Eslovaquia"
    },
    {
        "code": "SL",
        "name": "Sierra Leona"
    },
    {
        "code": "SM",
        "name": "San Marino"
    },
    {
        "code": "SN",
        "name": "Senegal"
    },
    {
        "code": "SO",
        "name": "Somalia"
    },
    {
        "code": "SR",
        "name": "Surinam"
    },
    {
        "code": "ST",
        "name": "Santo Tomé y Príncipe"
    },
    {
        "code": "SV",
        "name": "El Salvador"
    },
    {
        "code": "SY",
        "name": "Siria"
    },
    {
        "code": "SZ",
        "name": "Suazilandia"
    },
    {
        "code": "TC",
        "name": "Islas Turcas y Caicos"
    },
    {
        "code": "TD",
        "name": "Chad"
    },
    {
        "code": "TF",
        "name": "Territorios Australes Franceses"
    },
    {
        "code": "TG",
        "name": "Togo"
    },
    {
        "code": "TH",
        "name": "Tailandia"
    },
    {
        "code": "TH",
        "name": "Tanzania"
    },
    {
        "code": "TJ",
        "name": "Tayikistán"
    },
    {
        "code": "TK",
        "name": "Tokelau"
    },
    {
        "code": "TL",
        "name": "Timor-Leste"
    },
    {
        "code": "TM",
        "name": "Turkmenistán"
    },
    {
        "code": "TN",
        "name": "Túnez"
    },
    {
        "code": "TO",
        "name": "Tonga"
    },
    {
        "code": "TR",
        "name": "Turquía"
    },
    {
        "code": "TT",
        "name": "Trinidad y Tobago"
    },
    {
        "code": "TV",
        "name": "Tuvalu"
    },
    {
        "code": "TW",
        "name": "Taiwán"
    },
    {
        "code": "UA",
        "name": "Ucrania"
    },
    {
        "code": "UG",
        "name": "Uganda"
    },
    {
        "code": "US",
        "name": "Estados Unidos"
    },
    {
        "code": "UY",
        "name": "Uruguay"
    },
    {
        "code": "UZ",
        "name": "Uzbekistán"
    },
    {
        "code": "VA",
        "name": "Ciudad del Vaticano"
    },
    {
        "code": "VC",
        "name": "San Vicente y las Granadinas"
    },
    {
        "code": "VE",
        "name": "Venezuela"
    },
    {
        "code": "VG",
        "name": "Islas Vírgenes Británicas"
    },
    {
        "code": "VI",
        "name": "Islas Vírgenes de los Estados Unidos de América"
    },
    {
        "code": "VN",
        "name": "Vietnam"
    },
    {
        "code": "VU",
        "name": "Vanuatu"
    },
    {
        "code": "WF",
        "name": "Wallis y Futuna"
    },
    {
        "code": "WS",
        "name": "Samoa"
    },
    {
        "code": "YE",
        "name": "Yemen"
    },
    {
        "code": "YT",
        "name": "Mayotte"
    },
    {
        "code": "ZA",
        "name": "Sudáfrica"
    }
];

const getCountryCode = countryName => countriesAndCodes.find(c => c.name === countryName)?.code || '';