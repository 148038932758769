import React from "react";
import { Avatar, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { PriceDisplay } from "../../styled/PriceDisplay";
import moment from 'moment';
import CompanyHomeChart from "./CompanyHomeChart";

const DashboardCompanyHome = () => {
    return <Box style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px', marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <span>Inicio</span>
            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <CompanyHomeChart bookingsList={EMPLOYEES_BOOKINGS.payments} />
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Empleado</b></TableCell>
                            <TableCell><b>Pais</b></TableCell>
                            <TableCell><b>Fecha de Reserva</b></TableCell>
                            <TableCell><b>Estado</b></TableCell>
                            <TableCell><b>Total</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {EMPLOYEES_BOOKINGS.payments.filter(p => p.host_status !== 3).map(p => (
                            <TableRow key={p.id + '_pay'}>
                                <TableCell>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar alt={p.name} style={{ width: 40, borderRadius: '50%', marginRight: 5 }}>{`${p.name.split(' ')[0][0]}${p.name.split(' ')[1][0]}`}</Avatar>
                                        <Box>{p.name}</Box>
                                    </Box>
                                </TableCell>
                                <TableCell>{p.country}</TableCell>
                                <TableCell>{moment(p.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{paymentStatusLabels[p.host_status]}</TableCell>
                                <TableCell>
                                    <PriceDisplay value={p.payment_amount} currency={p.currency} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    </Box >;
};

export default DashboardCompanyHome;

const paymentStatusLabels = {
    0: 'Pendiente',
    1: 'Aprobado',
    2: 'Finalizado',
    3: 'Cancelado'
}

const EMPLOYEES_BOOKINGS = {
    "total_payment_new": 20304,
    "total_payment_pending": 500,
    "total_paid": 61444.75,
    "total_debt": 2949.75,
    "total": 79299,
    "payments": [
        {
            "id": 544,
            "date": "2020-10-23",
            "name": "Juan Perez",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Argentina",
            "currency": "ARS",
            "subtotal": "1200.00",
            "service_fee": "60.00",
            "host_fee": "0.00",
            "payment_amount": "1260.00",
            "concept": 0,
            "host_status": 2,
            "admin_status": 1,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 554,
            "date": "2020-10-26",
            "name": "Hector Larrazabal",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Colombia",
            "currency": "COP",
            "subtotal": "180.00",
            "service_fee": "9.00",
            "host_fee": "0.00",
            "payment_amount": "12000.00",
            "concept": 0,
            "host_status": 2,
            "admin_status": 1,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 527,
            "date": "2020-10-22",
            "name": "Laura Mendoza",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Colombia",
            "currency": "COP",
            "subtotal": "700.00",
            "service_fee": "60.00",
            "host_fee": "0.00",
            "payment_amount": "10000.00",
            "concept": 0,
            "host_status": 2,
            "admin_status": 1,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },

        {
            "id": 627,
            "date": "2020-12-10",
            "name": "Máximo Cozzeti",
            "country": "Estados Unidos",
            "currency": "USD",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "subtotal": "5940.00",
            "service_fee": "252.00",
            "host_fee": "0.00",
            "payment_amount": "10.00",
            "concept": 0,
            "host_status": 0,
            "admin_status": 0,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 546,
            "date": "2020-10-23",
            "name": "Juan Perez",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Argentina",
            "currency": "ARS",
            "subtotal": "3915.00",
            "service_fee": "195.75",
            "host_fee": "0.00",
            "payment_amount": "4110.75",
            "concept": 0,
            "host_status": 2,
            "admin_status": 1,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 545,
            "date": "2020-10-23",
            "name": "Juan Perez",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Argentina",
            "currency": "ARS",
            "subtotal": "300.00",
            "service_fee": "15.00",
            "host_fee": "0.00",
            "payment_amount": "315.00",
            "concept": 0,
            "host_status": 2,
            "admin_status": 1,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 633,
            "date": "2020-11-10",
            "name": "Hector Larrazabal",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Colombia",
            "currency": "COP",
            "subtotal": "22800.00",
            "service_fee": "1140.00",
            "host_fee": "0.00",
            "payment_amount": "23940.00",
            "concept": 0,
            "host_status": 2,
            "admin_status": 1,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 632,
            "date": "2020-11-10",
            "name": "Máximo Cozzeti",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Estados Unidos",
            "currency": "USD",
            "subtotal": "29400.00",
            "service_fee": "1470.00",
            "host_fee": "0.00",
            "payment_amount": "14.00",
            "concept": 0,
            "host_status": 2,
            "admin_status": 1,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 635,
            "date": "2020-11-16",
            "name": "Juan Perez",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Argentina",
            "currency": "ARS",
            "subtotal": "1920.00",
            "service_fee": "96.00",
            "host_fee": "0.00",
            "payment_amount": "2016.00",
            "concept": 0,
            "host_status": 0,
            "admin_status": 0,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        },
        {
            "id": 636,
            "date": "2020-12-08",
            "name": "Laura Mendoza",
            "payment_method_id": 1,
            "payment_method_name": "Efectivo",
            "country": "Colombia",
            "currency": "COP",
            "subtotal": "11520.00",
            "service_fee": "576.00",
            "host_fee": "0.00",
            "payment_amount": "12096.00",
            "concept": 0,
            "host_status": 0,
            "admin_status": 0,
            "host_id": 19,
            "host_name": "Gonzalo",
            "host_lastname": "Aguero",
            "payment_date": null
        }
    ]
};


