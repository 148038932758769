import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { IconButton } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

const WhatsappButton = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return <IconButton style={{ position: 'fixed', bottom: isMobile ? 30 : 50, right: isMobile ? 30 : 50, backgroundColor: '#25d366', zIndex: 9999, boxShadow: '5px 5px 5px #888888' }} onClick={() => window.open("https://wa.me/5491122556618", "_blank")}>
        <WhatsAppIcon style={{ color: '#ffffff', fontSize: isMobile ? 36 : 45 }} />
    </IconButton>;
}

export default WhatsappButton;