import React, { useState, useEffect } from "react";
import DataService from "../services/DataService";
import { CircularProgress } from "@material-ui/core";

const TermsAndConditions = () => {
    const [loading, setLoading] = useState(false);
    const [html, setHtml] = useState('');

    useEffect(() => {
        setLoading(true);
        DataService.getTermsAndConditions().then(resp => {
            setHtml(resp.data);
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }, []);

    return loading ? <CircularProgress /> : <div className="termsAndConditions" dangerouslySetInnerHTML={{ __html: html }} />;
}

export default TermsAndConditions;