import React, { useState, useContext, useEffect } from "react";
import { Checkbox, Box, Button, CircularProgress, IconButton, Dialog, DialogContent, DialogActions, FormHelperText, FormControl } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import DataService from "../../../services/DataService";
import TextField from "../../styled/StyledTextField";
import HostService from "../../../services/HostService";
import { useAuth0 } from "@auth0/auth0-react";
import is from "is_js";
import InfoIcon from '@material-ui/icons/Info';

const PAYMENT_METHOD_REQIRED_ERROR = 'Es necesario seleccionar al menos un método de pago.'
const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Payment = ({ context, isOnboarding }) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { user } = useAuth0();
    const { styles: contextStyles, stepUtils, host } = useContext(context);
    const [paymentOptions, setPaymentOptions] = useState([])
    const [paymentTypesSelection, setPaymentTypesSelection] = useState(host?.paymentTypes?.map(p => p.id) || []);
    const [mpEmail, setMpEmail] = useState(host?.mercado_pago_id || '');

    const [updateHostLoading, setUpdateHostLoading] = useState(false);
    const [errors, setErrors] = useState({});

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getPaymentOptions = async () => {
            let resp = await DataService.getPaymentTypes();
            mounted && setPaymentOptions(resp.data);
        }
        getPaymentOptions();

        return () => mounted = false;
    }, []);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        paymentMethodOption: {
            border: '1px solid #D8D8D8',
            borderRadius: '3px',
            width: isMobile ? 'unset' : '450px',
            marginTop: '20px',
            display: 'flex',
            minHeight: '67px',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        ...contextStyles
    }



    const paymentSelected = (checked, id) => {
        if (checked) {
            setPaymentTypesSelection(currentSelection => [...new Set([...currentSelection, id])]);
        } else {
            if (id === 1) setMpEmail('');
            setPaymentTypesSelection(currentSelection => [...new Set(currentSelection.filter(i => i !== id))]);
        }
    }

    const updateHost = async (host) => {
        try {
            setUpdateHostLoading(true);
            await HostService.updateHost(HostService.getHostId(user), host);
        } catch (e) {
            console.log(e);
        } finally {
            setUpdateHostLoading(false)
        }
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(paymentTypesSelection)) errorsAcum.payment = PAYMENT_METHOD_REQIRED_ERROR;
        if (paymentTypesSelection.includes(1) && is.empty(mpEmail)) errorsAcum.mp = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getHostData = () => ({
        paymentTypes: paymentTypesSelection,
        mercado_pago_id: paymentTypesSelection.includes(1) ? mpEmail : ''
    });

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Cobros</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <div style={{ fontSize: '14px', color: '#383839', textAlign: 'left' }}>Realizá tus transacciones a través de una plataforma de pagos y cobros. Añadí las que más te convengan</div>
                <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                    {paymentOptions.map(po =>
                        <div key={'pm_' + po.id} style={styles.paymentMethodOption}>
                            <Checkbox onChange={e => paymentSelected(e.target.checked, po.id)} checked={paymentTypesSelection.includes(po.id)} />
                            <div style={{ display: 'flex', flexDirection: 'row', width: '350px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <PaymentMethodDisplay
                                    errors={errors}
                                    pmId={po.id}
                                    mpEmail={mpEmail}
                                    setMpEmail={setMpEmail}
                                    paymentTypesSelection={paymentTypesSelection}
                                    paymentSelected={paymentSelected} />
                            </div>
                        </div>
                    )}
                    <div style={{ fontSize: '14px', color: '#383839', padding: '20px 0', textAlign: 'left' }}>Se le enviará un correo electrónico al huésped para que coordinen el pago en efectivo</div>
                </Box>
            </Box>
        </Box>
        {!!errors.payment && <Box style={{ color: 'red' }}>{errors.payment}</Box>}
        {isOnboarding ?
            <OnboardingButtons stepUtils={stepUtils}
                nextFn={() => validateFormData() && stepUtils.saveHostAndNext(getHostData())}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => validateFormData() && stepUtils.saveHostAndClose(getHostData())} />
            : <div style={{ width: '100%', textAlign: 'end' }}>
                <Button onClick={() => validateFormData() && updateHost(getHostData())}
                    variant="contained"
                    disabled={updateHostLoading}
                    style={{
                        backgroundColor: '#00D1BF',
                        color: '#ffffff',
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: isMobile ? '100%' : '168px',
                        height: '50px',
                        fontWeight: 'bold',
                        marginBottom: isMobile ? '10px' : '0'
                    }}>{updateHostLoading ? <CircularProgress color="secondary" /> : 'Guardar'}</Button>
            </div>}
    </Box>;
};

export default Payment;

const PaymentMethodDisplay = ({ pmId, mpEmail, setMpEmail, paymentTypesSelection, paymentSelected, errors }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [mpDialogOpen, setMpDialogOpen] = useState(false);

    let pmView = {
        2: <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-around' }}>
            <MercadoPagoHelpDialog open={mpDialogOpen} setOpen={setMpDialogOpen} />
            <img src={require('../../../assets/images/mp-logo.png')} alt="" style={{ width: '91px', height: '24px', margin: '5px' }} />
            <FormControl error={!!errors.mp}>
                <div style={{ display: 'flex', marginBottom: '-12px' }}>
                    <TextField id="mpEmail"
                        value={mpEmail}
                        InputProps={{
                            readOnly: !paymentTypesSelection.includes(1),
                        }}
                        onChange={e => setMpEmail(e.target.value)}
                        onClick={() => paymentSelected(true, 1)}
                        placeholder="email o Client ID"
                        type="email"
                        style={{ width: '220px', marginLeft: '5px' }}
                        InputLabelProps={{ shrink: true, }} />
                    <IconButton onClick={() => setMpDialogOpen(true)}>
                        <InfoIcon />
                    </IconButton>
                </div>
                <FormHelperText>{errors.mp}</FormHelperText>
            </FormControl>
        </div>,
        1: <>
            <div>PAGO EN EFECTIVO</div>
            <img src={require('../../../assets/images/cash-logo.png')} alt="" style={{}} />
        </>
    }
    return pmView[pmId] || null;
}

const MercadoPagoHelpDialog = ({ open, setOpen }) => {

    return <Dialog open={open} onClose={() => setOpen(false)} >
        <DialogContent>
            <div style={{ display: 'flex' }}>
                <Box style={{ border: '1px red solid', padding: '10px' }}>
                    <div style={{ color: 'red' }}>IMPORTANTE</div>
                    <div>Asegúrate de poner correctamente tu Client ID o mail, de lo contrario no podremos procesar los pagos y vos no podrás recibirlos.</div>
                </Box>
                <img src={require('../../../assets/images/mp-logo.png')} alt="" style={{ width: '91px', height: '24px', margin: '5px' }} />
            </div>
            <br />
            <Box>
                <b>Dónde encuentro mi Client ID?</b>
                <div>Ingresá a tu cuenta de MercadoPago y selecciona <a href="https://www.mercadopago.com/mla/account/credentials?type=basic" target="_blank" rel="noopener noreferrer" style={{ color: 'cadetblue' }}>Tu Negocio &gt; Configuraciones &gt; Credenciales</a></div>
                <br />
                <b>No tienes cuenta de MercadoPago?</b>
                <div>Créala gratis desde <a href="http://mercadopago.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'cadetblue' }}>www.mercadopago.com</a></div>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary" className="okMpButton">Cerrar</Button>
        </DialogActions>
    </Dialog>;
};
