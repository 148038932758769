import React, { useContext, useState, useEffect } from "react";
import { Box, IconButton, Tooltip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@material-ui/core";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import HostService from '../../../services/HostService';
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';

const getShareText = (code) => `Conoces *Worknmates*? Te invito a que seas parte de esta comunidad alquilando tu hogar por jornada laboral y *ganando dinero extra*! Publica tu espacio disponible (departamento, casa, quincho, garage, oficina) y comenza a recibir trabajadores por día! Usá mi código *${code}* al registrarte para que yo pueda ganar $2000 en efectivo con tu primer transacción. *Vos podrás compartir el tuyo y obtener el mismo beneficio* =) https://www.worknmates.com/hostHome`;

const ReferralCode = (props) => {
    const { context } = props;
    const [copied, setCopied] = useState(false);
    const { styles: contextStyles, host } = useContext(context);
    const { user } = useAuth0();
    const [referreds, setReferreds] = useState([]);

    useEffect(() => {
        HostService.getReferreds(HostService.getHostId(user))
            .then(resp => {
                setReferreds(resp.data);
            });
    }, [user]);


    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px', marginBottom: '20px' }}>
            <Box className="onboardingForm personalInfoForm">
                <div style={{ fontFamily: 'PoppinsBold', fontWeight: 'bold', color: '#383839', fontSize: '20px', paddingTop: '20px' }}>
                    <span>Referidos</span>
                    <Box style={{ minHeight: '20px' }}></Box>
                </div>
            </Box>
            <Box style={contextStyles.stepsView}>
                <div style={{ fontFamily: 'PoppinsBold', fontWeight: 'bold', color: '#383839', fontSize: '25px', paddingTop: '20px', textAlign: 'left' }}>
                    <span>Ganá ARS 2.000 por cada amigo que refieras y se convierta en Anfitrión!</span>
                </div>
                <br />
                <div style={{ color: '#383839', textAlign: 'left', fontSize: '16px' }}>
                    En Worknmates te damos un código para que invites a tus contactos y por cada uno que se convierta en anfitrión y realice su primer transacción, inmediatamente ganás ARS 2.000 en efectivo.
            </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: '#383839', textAlign: 'left', fontSize: '16px' }}>
                        Tu código es <b style={{ fontFamily: 'PoppinsBold', color: '#61257D' }}>{host?.affiliation_code}</b>
                    </div>
                    <CopyToClipboard text={getShareText(host?.affiliation_code)} onCopy={() => { setCopied(true); setTimeout(() => setCopied(false), 1000); }}>
                        <Tooltip title="Copiar código">
                            <IconButton>
                                <img src={require('../../../assets/images/share.png')} alt="" />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                    <span style={{ fontSize: '12px', color: 'grey' }}>{copied && 'Copiado'}</span>
                </div>
            </Box>
            <br />
        </div>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><b>Nombre</b></TableCell>
                        <TableCell><b>Fecha de Alta</b></TableCell>
                        <TableCell><b>Estado</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {referreds.map((r, index) => (
                        <TableRow key={r.lastname + index + '_referred'}>
                            <TableCell component="th" scope="row"> {`${r.name || ''} ${r.lastname || ''}`}</TableCell>
                            <TableCell>{moment(r.creation_date).format('LL')}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '5px'}}>{referredStatusLables[r.status].title}</span>
                                    {referredStatusLables[r.status].description &&
                                        <Tooltip placement="top" title={<div style={{ fontSize: '16px', opacity: 1, lineHeight: '16px' }}>{referredStatusLables[r.status].description}</div>}>
                                            <InfoIcon />
                                        </Tooltip>
                                    }
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>;
};

export default ReferralCode;

const referredStatusLables = {
    0: {
        title: 'Primer alquiler pendiente',
        description: 'Recuerda que para recibir tu dinero, la persona a la cual referiste debe generar su primer transaccion.'
    },
    1: {
        title: 'Pago pendiente',
        description: 'Te realizaremos el pago en las proximas horas.'
    },
    2: {
        title: 'Pago realizado',
        description: ''
    },
};

