import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import CompanyDashboardMenu from "./CompanyDashboardMenu";
import { Box, CircularProgress, Tooltip, Link } from "@material-ui/core";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import DashboardContext, { DashboardProvider } from './dashboard/DashboardContext';
import CompanyService from "../../services/CompanyService";
import { useAuth0 } from "@auth0/auth0-react";
import HelpIcon from '@material-ui/icons/Help';
import UserService from "../../services/UserService";
import is from "is_js";
import Employees from "./dashboard/Employees";
import CompanyInfo from "./onboarding/CompanyInfo";
import Budgets from "./dashboard/Bugdets";
import Payments from "./dashboard/Payments";
import DashboardCompanyHome from "./dashboard/DashboardCompanyHome";

const CompanyDashboard = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    let { path } = useRouteMatch();
    const { isAuthenticated, user } = useAuth0();
    const [contextData, setContextData] = useState({
        styles: {
            stepsView: {
                backgroundColor: '#ffffff',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'space-between',
                paddingLeft: isMobile ? '10px' : '50px',
                paddingRight: isMobile ? '10px' : '50px'
            }
        }
    });
    const [companyLoading, setCompanyLoading] = useState(false);
    console.log(companyLoading);

    useEffect(() => {
        let mounted = true;
        const getCompany = async () => {
            setCompanyLoading(true);
            let companyIds = CompanyService.getCompanyIds(user);
            if (is.not.empty(companyIds)) {
                let resp = await CompanyService.getById(companyIds[0]);
                (isAuthenticated && mounted) && setContextData(cd => ({ ...cd, companies: resp.data }));
            }
            mounted && setCompanyLoading(false);
        }
        getCompany();
        return () => mounted = false;
    }, [isAuthenticated, user]);

    return <DashboardProvider value={contextData}>
        <Box style={{ display: 'flex', backgroundColor: '#F6F6F6' }} id="hostDashboard">
            {!isMobile && <CompanyDashboardMenu />}
            <Box style={{ padding: isMobile ? 'unset' : '15px 40px', width: isMobile ? '100vw' : '100%', minHeight: 'calc(100vh - 64px)' }}>
                <CompanyStatusAlert status={contextData?.host?.status} />
                <Switch>
                    <Route exact path={`${path}/`}>
                        <Redirect to={`${path}/home`} />
                    </Route>
                    <Route path={`${path}/home`}>
                        <DashboardCompanyHome />
                    </Route>
                    <Route path={`${path}/data`}>
                        <CompanyInfo context={DashboardContext} />
                    </Route>
                    <Route path={`${path}/employees`}>
                        <Employees />
                    </Route>
                    <Route path={`${path}/budget`}>
                        <Budgets />
                    </Route>
                    <Route path={`${path}/payments`}>
                        <Payments /> 
                    </Route>
                </Switch>
            </Box>
        </Box>
    </DashboardProvider>;
};

export default CompanyDashboard;



const CompanyStatusAlert = ({ status }) => {
    const { user } = useAuth0();
    const [verificationSent, setVerificationSent] = useState(false);
    const [sending, setSending] = useState(false);
    const { getUserId } = UserService;

    const resendEmailVerification = () => {
        setSending(true);
        UserService.resendEmailVerification(getUserId(user))
            .then(resp => {
                console.log(JSON.stringify(resp));
                setVerificationSent(true);
            })
            .catch(e => console.log(e))
            .finally(() => setSending(false));
    };

    let warnings = [];
    if ([0, 2, 3].includes(status)) warnings.push(<CompanyWarning key={'status_' + status} title={<b>{hostStatusLabels[status].title}</b>} tooltipMsg={hostStatusLabels[status].tooltip} />);
    if (!user.email_verified)
        warnings.push(
            <CompanyWarning key={'status_email_verified'}
                title={<>
                    <span>Validación Correo Pendiente </span>
                    {sending ? <CircularProgress color="secondary" size={20} /> :
                        <span>
                            {verificationSent ? <b>Enviado (revise su correo)</b> : <Link component="button" onClick={resendEmailVerification} style={{ color: '#383839', fontSize: '16px', height: '23px', paddingBottom: '1px' }} >Reenviar</Link>}
                        </span>
                    }
                </>}
                tooltipMsg={'Todavía no validaste tu correo. Por favor revisa si has recibido un email de Worknmates en tu bandeja de entrada y correo no deseado para poder verificar tu cuenta. En caso de no haberlo recibido escribinos a contacto@worknmates.com. Hasta tanto no lo valides, tus anuncios no seran publicados.'} />
        )
    return warnings;
};

const CompanyWarning = ({ title, tooltipMsg }) => <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ff6666', border: '2px solid red', borderRadius: '5px', padding: '5px', color: 'white', margin: '10px' }}>
    <span style={{ marginRight: '10px' }}>{title}</span>
    <Tooltip title={<div style={{ fontSize: '14px' }}>{tooltipMsg}</div>}>
        <HelpIcon />
    </Tooltip>
</div>;

const hostStatusLabels = {
    0: {
        title: 'Pendiente Aprobación',
        tooltip: 'Su usuario se encuentra pendiente de aprobación por Worknmates. Esto puede demorar hasta 12hs. Tu cuenta debe ser verificada para que tus avisos sean publicados. Puedes contactarte directamente con nosotros enviando un correo a: contacto@worknmates.com.',
    },
    1: {
        title: 'Activo',
        tooltip: ''
    },
    2: {
        title: 'Usuario Suspendido',
        tooltip: 'Worknmates ha suspendido tu cuenta temporalmente debido a algunas irregularidades detectadas. Por el momento tus anuncios no podrán ser publicados. Nos pondremos en contacto a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com. '
    },
    3: {
        title: 'Usuario Bloqueado',
        tooltip: 'Worknamtes ha bloqueado tu cuenta temporalmente debido a algunas irregularidades detectadas. Por el momento tus anuncios no podrán ser publicados. Nos pondremos en contacto a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com. '
    },
    4: {
        title: 'Eliminado',
        tooltip: ''
    },
}
