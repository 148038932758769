import axios from 'axios';
import HostService from './HostService';

const DataService = {

    getBuildingTypes: () => {
        console.log(`DataService.getBuildingTypes()`);
        const url = `${process.env.REACT_APP_API_URL}/data/building_type`;
        return axios.get(url);
    },

    getSpaceTypes: () => {
        console.log(`DataService.getSpaceTypes()`);
        const url = `${process.env.REACT_APP_API_URL}/data/space_type`;
        return axios.get(url);
    },

    getSpaceServices: () => {
        console.log(`DataService.getSpaceServices()`);
        const url = `${process.env.REACT_APP_API_URL}/data/service`;
        return axios.get(url);
    },

    getLanguages: () => {
        console.log(`DataService.getLanguages()`);
        const url = `${process.env.REACT_APP_API_URL}/data/language`;
        return axios.get(url);
    },

    getGenders: () => {
        console.log(`DataService.getGenders()`);
        const url = `${process.env.REACT_APP_API_URL}/data/gender`;
        return axios.get(url);
    },

    getNationalities: () => {
        console.log(`DataService.getNationalities()`);
        const url = `${process.env.REACT_APP_API_URL}/data/nationality`;
        return axios.get(url);
    },

    getCurrencies: () => {
        console.log(`DataService.getCurrencies()`);
        const url = `${process.env.REACT_APP_API_URL}/data/currency`;
        return axios.get(url);
    },

    getPaymentTypes: () => {
        console.log(`DataService.getPaymentTypes()`);
        const url = `${process.env.REACT_APP_API_URL}/data/payment_type`;
        return axios.get(url);
    },

    getSpaceRules: () => {
        console.log(`DataService.getSpaceRules()`);
        const url = `${process.env.REACT_APP_API_URL}/data/space_rules`;
        return axios.get(url);
    },

    getRedirectUri: () => {
        const { protocol, hostname, port } = window.location;
        let url = `${protocol}//${hostname}${port ? `:${port}` : ''}/`;
        HostService.isHostSection() && (url = url + 'hostHome');
        return url
    },

    getTermsAndConditions: () => {
        return axios.get('https://worknmates-data.s3.amazonaws.com/config/html/terms-and-conditions.html');
    },

    getPrivacyPolicy: () => {
        return axios.get('https://worknmates-data.s3.amazonaws.com/config/html/privacy.html');
    }
}

export default DataService;