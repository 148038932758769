import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@material-ui/core';
import BookingService from '../../../services/BookingService';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import * as CONSTS from '../../const/constants';
import BookingDisplay from "../../booking/BookingDisplay";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HostService from '../../../services/HostService';
import SpaceTitle from "../../publication/SpaceTitle";
import Avatar from '@material-ui/core/Avatar';
import is from 'is_js';
import useDynamicRefs from "use-dynamic-refs";
import Calendar from './Calendar';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const bookingStatusHostLabels = {
    [CONSTS.USER_STATUS.PAYMENT_PENDING]: 'Pago pendiente',
    [CONSTS.USER_STATUS.PAYMENT_ERROR]: 'Error de Pago',
    [CONSTS.HOST_STATUS.PENDING_APPROVAL]: 'Aprobación pendiente',
    [CONSTS.HOST_STATUS.APPROVED]: 'Confirmada',
    [CONSTS.HOST_STATUS.REJECTED]: 'Rechazada',
    [CONSTS.HOST_STATUS.PENDING_REVIEW]: 'Pendiente de Calificacón',
    [CONSTS.HOST_STATUS.REVIEWED]: 'Calificada',
    [CONSTS.HOST_STATUS.CANCELLED_BY_USER]: 'Cancelada por usuario',
}

const bookingStatusHostColor = {
    [CONSTS.USER_STATUS.PAYMENT_PENDING]: 'rgb(255, 102, 102)',
    [CONSTS.USER_STATUS.PAYMENT_ERROR]: 'rgb(255, 102, 102)',
    [CONSTS.HOST_STATUS.PENDING_APPROVAL]: '#7E2D87',
    [CONSTS.HOST_STATUS.APPROVED]: '#00D1BF',
    [CONSTS.HOST_STATUS.REJECTED]: 'rgb(255, 102, 102)',
    [CONSTS.HOST_STATUS.PENDING_REVIEW]: '#7E2D87',
    [CONSTS.HOST_STATUS.REVIEWED]: '#00D1BF',
    [CONSTS.HOST_STATUS.CANCELLED_BY_USER]: 'rgb(255, 102, 102',
}

const getStatusOrder = {
    'pending-approve': 1,
    'approved': 2,
    'pending-review': 3,
    'payment-pending': 4,
    'payment-error': 5,
    'reviewed': 6,
    'rejected': 7,
    'cancelled-user': 8,
}

const HostCalendar = () => {
    const isLessThan1430pxWidth = useMediaQuery({ query: '(max-width: 1430px)' });

    const [bookingsLoading, setBookingsLoading] = useState(false);
    const [spaces, setSpaces] = useState([{
        price: null,
        id: -1,
        type: {
            id: -1,
            name: "Todos"
        }
    }]);
    const [users, setUsers] = useState([]);
    const [services, setServices] = useState([]);
    // const [spaceSelected, setSpaceSelected] = useState('');
    const [usersSelected, setUsersSelected] = useState([]);
    const [servicesSelected, setServicesSelected] = useState([]);
    const [allBookings, setAllBookings] = useState([]);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [calendarData, setCalendarData] = useState([]);
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const { user } = useAuth0();
    // eslint-disable-next-line
    const [getBookingRef, setBookingRef] = useDynamicRefs();
    const [dataToSearch, setDataToSearch] = useState({ space_id: [] });
    const [availability, setAvailability] = useState([]);

    useEffect(() => {
        filterSearch();
        // eslint-disable-next-line
    }, [dataToSearch]);

    const getSelectData = async () => {
        setBookingsLoading(true);
        try {
            filterSearch();
            const userId = await HostService.getHostId(user);
            const spaceByHostId = await HostService.getSpaceByHostId(userId);
            setSpaces((currentValues) => [...currentValues, ...spaceByHostId.data]);
            const filterUsers = await HostService.getFilterUsers(userId);
            setUsers(filterUsers.data);
            const filterServices = await HostService.getFilterServices(userId);
            setServices(filterServices.data);
        } catch (e) {
            console.log(e);
        } finally {
            setBookingsLoading(false);
        }
    };

    const setCalendarInfo = () => {
        const newData = [];
        for (const b in allBookings) {
            const date = `${allBookings[b].date}T08:00:00.000Z`;
            newData.push({
                key: new Date(date),
                data: allBookings[b].bookings.length,
            });
        }
        setCalendarData(newData);
    };

    useEffect(() => {
        setCalendarInfo();
        // eslint-disable-next-line
    }, [allBookings]);

    useEffect(() => {
        getSelectData();
        // eslint-disable-next-line
    }, []);

    const filterSearch = async () => {
        setSelectedBookings([]);
        setAvailability([]);
        setAllBookings([]);
        setBookingsLoading(true);
        try {
            const data = {
                dateFrom: moment(),
                dateTo: moment().add(3, 'months'),
                ...dataToSearch
            }
            const resp = await HostService.getFilteredBookings(HostService.getHostId(user), data);
            if (is.not.empty(dataToSearch.space_id)) {
                const resp = await HostService.getSpaceAvailability(HostService.getHostId(user), dataToSearch.space_id);
                setAvailability(resp.data);
            }
            setAllBookings(resp.data);
        } catch (e) {
            console.log(e);
        } finally {
            setBookingsLoading(false);
        }
    }

    const sortByStatusAndDate = (b1, b2) => {
        const b1StatusOrder = getStatusOrder[BookingService.getHostStatus(b1)];
        const b2StatusOrder = getStatusOrder[BookingService.getHostStatus(b2)];

        if (b1StatusOrder > b2StatusOrder) return 1;
        if (b1StatusOrder < b2StatusOrder) return -1;

        if (moment(b1.date).valueOf() > moment(b2.date).valueOf()) return 1;
        if (moment(b1.date).valueOf() < moment(b2.date).valueOf()) return -1;
    }

    const handleExpandChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const filterBookingList = (e) => {
        setSelectedBookings(allBookings.filter(d => d.date === moment(e).format('YYYY-MM-DD')))
    };

    return <Box>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span>Calendario</span>
                {bookingsLoading && <CircularProgress size={30} style={{ marginLeft: 5 }} />}
            </Box>

            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <Box style={{ display: 'flex', flexDirection: isLessThan1430pxWidth ? 'column' : 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <Autocomplete
                id="autocomplete-spaces"
                options={spaces}
                defaultValue={spaces[0]}
                // value={spaceSelected}
                onChange={(event, newValue) => {
                    setDataToSearch((currentValues) => ({ ...currentValues, space_id: newValue === null ? [] : newValue?.id === -1 ? [] : [newValue.id] }));
                }}
                getOptionLabel={(option) => {
                    if (option?.price > 0) {
                        return `${option.type?.name} (${option.buildingType?.name} $${Math.round(option?.price - option?.price * option?.discount_percentage / 100)})`
                    } if (option?.price === 0) {
                        return `${option.type?.name} (${option.buildingType?.name})`
                    } else {
                        return `${option.type?.name}`
                    }
                }}
                ChipProps={{
                    variant: "outlined"
                }}
                renderOption={(option) => (
                    <React.Fragment>
                        <SpacesPreview pub={option} />
                    </React.Fragment>
                )}
                style={{ width: isMobile ? 300 : 420 }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Espacios" placeholder="Espacios" />
                )}
            />
            <Autocomplete
                multiple
                id="checkboxes-users"
                options={users}
                disableCloseOnSelect
                value={usersSelected}
                onChange={(event, newValue) => {
                    setUsersSelected(newValue);
                    const usersId = [];
                    for (const u in newValue) {
                        usersId.push(newValue[u].id);
                    }
                    setDataToSearch((currentValues) => ({ ...currentValues, user_id: usersId }));

                }}
                getOptionLabel={(option) => (option.name + ' ' + option.lastname)}
                ChipProps={{
                    variant: "outlined",
                }}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        <Avatar alt={option.name} src={option?.avatar?.url} style={{ marginRight: '5px' }} />
                        {option.name} {option.lastname}
                    </React.Fragment>
                )}
                style={{ width: isMobile ? 300 : isLessThan1430pxWidth ? 420 : 350, marginTop: isLessThan1430pxWidth ? '10px' : '0px' }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Usuarios" placeholder="Usuarios" />
                )}
            />
            <Autocomplete
                multiple
                id="checkboxes-services"
                options={services}
                disableCloseOnSelect
                value={servicesSelected}
                onChange={(event, newValue) => {
                    setServicesSelected(newValue);
                    const servicesId = [];
                    for (const s in newValue) {
                        servicesId.push(newValue[s].id);
                    }
                    setDataToSearch((currentValues) => ({ ...currentValues, service_id: servicesId }));


                }}
                getOptionLabel={(option) => option.name}
                ChipProps={{
                    variant: "outlined"
                }
                }
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        <img src={option.image?.url} alt={option.name} style={{ marginRight: '5px', maxWidth: '15px' }} />
                        {option.name}
                    </React.Fragment>
                )}
                style={{ width: isMobile ? 300 : isLessThan1430pxWidth ? 420 : 350, marginTop: isLessThan1430pxWidth ? '10px' : '0px' }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Servicios" placeholder="Servicios" />
                )}
            />
        </Box>
        <Box className="hostCalendar" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '25px' }}>
            <Calendar data={calendarData} availability={availability} filterBookingList={filterBookingList} />
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '25px' }}>
            {bookingsLoading || <div style={{
                fontFamily: 'PoppinsBold',
                fontWeight: 'bold',
                color: '#383839',
                fontSize: '20px',
                paddingTop: '20px'
            }} >
                <span>Reservas del día</span> {is.not.empty(selectedBookings) && <span>{moment(selectedBookings[0]?.date).format('DD/MM/YYYY')}</span>}
                <Box style={{ minHeight: '20px' }}></Box>
                {is.empty(selectedBookings) && <span style={{ fontSize: '15px', color: '#ABABAB' }}>Hace click en un día pra ver las reservas del mismo.</span>}
            </div>}
            {is.not.empty(selectedBookings) && selectedBookings[0].bookings.sort(sortByStatusAndDate).map(b => <Accordion expanded={expanded === b.id} onChange={handleExpandChange(b.id)} key={b.id + `_booking`} style={{ width: '100%' }} ref={setBookingRef(b.id)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
                        <b style={{ width: isMobile ? 'unset' : '100px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{b.multiple_date ?
                            `${moment(b.dates[0]?.date).format('DD/MM/YYYY')} al ${moment(b.dates[b.dates.length - 1]?.date).format('DD/MM/YYYY')}`
                            :
                            moment(b.date).format('DD/MM/YYYY')
                        }</b>
                        <b style={{ width: isMobile ? 'unset' : '15%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>{b.user?.name + ' ' + b.user?.lastname}</b>
                        <Box style={{ width: isMobile ? 'unset' : '40%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}><SpaceTitle space={b.space} /></Box>
                        <Box style={{ width: isMobile ? 'unset' : '200px', alignItems: 'center', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                            <Box style={{
                                height: '18px',
                                color: '#ffffff',
                                padding: '5px 10px',
                                fontWeight: 500,
                                borderRadius: 5,
                                fontSize: 12,
                                width: 'fit-content',
                                textTransform: 'uppercase',
                                backgroundColor: bookingStatusHostColor[BookingService.getHostStatus(b)]
                            }}>
                                {bookingStatusHostLabels[BookingService.getHostStatus(b)]}
                            </Box>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <BookingDisplay booking={b} reloadBookings={getSelectData} isHost={true} />
                </AccordionDetails>
            </Accordion>)}
        </Box>
    </Box>;
}

export default HostCalendar;


const SpacesPreview = ({ pub }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    if (pub.type.id === -1) return <div className="spacesPreviewSelect" style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'row', border: '1px solid #D8D8D8', borderRadius: '12px', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
            {isMobile ? '' : <div style={{ width: '100px', borderRadius: '12px' }}></div>}
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 2, alignItems: 'center', justifyContent: 'inherit', width: '100%' }}>
                <div style={{ padding: '10px', width: '250px', alignContent: 'center' }}>
                    <div style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#383839', textAlign: isMobile ? 'center' : 'left' }}><b>Todos</b></div>
                </div>
            </div>
        </div>
    </div >


    return <div className="spacesPreviewSelect" style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', flexShrink: 0, flexGrow: 3, border: '1px solid #D8D8D8', borderRadius: '12px', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
            <img src={pub.images[0]?.url || require('../../../assets/images/photo_placeholder.png')} alt="" style={{ width: '100px', height: '75px', borderRadius: '12px', marginTop: isMobile ? '10px' : '0' }} />
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 2, alignItems: 'center', justifyContent: 'inherit', width: '100%' }}>
                <div style={{ padding: '10px', textAlign: isMobile ? 'center' : 'left', width: '250px' }}>
                    <div style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#383839' }}><SpaceTitle space={pub} /></div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#383839' }}>{pub?.buildingType?.name}</div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#383839' }}>
                        <span style={{ fontFamily: 'PoppinsBold', fontStyle: 'normal', fontWeight: 'bold', fontSize: '24px', color: '#383839' }}>{`$ ${pub?.price > 0 ? (Math.round(pub?.price - pub?.price * pub?.discount_percentage / 100)) : 0} `}</span>
                        <span>por jornada</span>
                    </div>
                </div>
            </div>
        </div>
    </div >
};
