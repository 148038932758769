import React, { useContext, useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import EditionButtons from "../dashboard/EditionButtons";

const containerStyle = {
    width: '100%',
    height: '400px'
};

const ConfirmLocation = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { context, isOnboarding } = props;
    const { styles: contextStyles, stepUtils, fullUser } = useContext(context);
    const [latitude, setLatitude] = useState(fullUser?.address?.latitude || 0);
    const [longitude, setLongitude] = useState(fullUser?.address?.longitude || 0);
    const { isLoaded: isMapLoaded, loadError: mapLoadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    })

    useEffect(() => {
        setLatitude(fullUser?.address?.latitude || 0);
        setLongitude(fullUser?.address?.longitude || 0);
    }, [fullUser]);

    const updatePosition = (e) => {
        setLatitude(e.latLng.lat);
        setLongitude(e.latLng.lng);
    }

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        ...contextStyles
    }

    const getUserData = () => ({ address: { latitude, longitude } });

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Confirmación de ubicación</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Verificá que el marcador esté en la ubicación correcta</div>
                <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Si es necesario, podés ajustar el mapa para que el marcador esté en la ubicación correcta.</div>
            </Box>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'center' }}>
                {mapLoadError ? <div>Error loading map: {JSON.stringify(mapLoadError)}</div>
                    : <>{isMapLoaded ?
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{ lat: latitude, lng: longitude }}
                            zoom={15}>
                            <Marker draggable={true} onDragEnd={updatePosition} position={{ lat: latitude, lng: longitude }} />
                        </GoogleMap>
                        : <CircularProgress />
                    }</>
                }
            </Box>
        </Box>
        {isOnboarding ? <OnboardingButtons stepUtils={stepUtils}
            nextFn={() => stepUtils.saveUserAndNext(getUserData())}
            prevFn={() => stepUtils.previousStep()}
            closeFn={() => stepUtils.saveUserAndClose(getUserData())} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={() => stepUtils.updateUser(getUserData())}/>
        }
    </Box >;
};

export default ConfirmLocation;