import React, { useState } from "react";
import { Box, IconButton, LinearProgress, MenuItem, Select, TextField, withStyles } from "@material-ui/core";
import { PriceDisplay } from '../../styled/PriceDisplay';
import NumberFormat from "react-number-format";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useMediaQuery } from "react-responsive";

const CountryBudget = ({ country, readOnly = false }) => {
    const [monthBudget, setMonthBudget] = useState(country.monthBudget);
    const [editingMonthBudget, setEditingMonthBudget] = useState(false);
    const [newMonthBudget, setNewMonthBudget] = useState(country.monthBudget);

    const [employeeBudget, setEmployeeBudget] = useState(country.employeeBudget);
    const [editingEmployeeBudget, setEditingEmployeeBudget] = useState(false);
    const [newEmployeeBudget, setNewEmployeeBudget] = useState(country.employeeBudget);

    const [currency, setCurrency] = useState('COP');

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const normalise = value => +(value * 100 / monthBudget).toFixed(0);

    return <Box style={{ fontSize: isMobile ? 16 : 20, textAlign: 'left', padding: isMobile ? 10 : 15, margin: isMobile ? 5 : 20, border: '1px solid grey', borderRadius: 15, width: '100%' }}>
        <Box style={{ display: 'flex', margin: '30px 0', justifyContent: 'center', alignItems: 'center' }}>
            <Box>Moneda: </Box>
            {readOnly ?
                <Select style={{ marginLeft: 10, fontWeight: 900 }}
                    id="currency"
                    value={currency}
                    variant="outlined"
                    onChange={e => setCurrency(e.target.value)}>
                    {['COP', 'ARS', 'USD'].map(c => <MenuItem key={c + '_curr'} value={c}>{c}</MenuItem>)}
                </Select>
                :
                <Box style={{ fontWeight: 900, marginLeft: 3 }}>{country.currency}</Box>}
        </Box>
        <Box style={{ display: 'flex', margin: '30px 0', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
            <Box>Presupuesto total mensual: </Box>
            <Box style={{ fontWeight: 900, marginLeft: 3, display: 'flex', alignItems: 'center' }}>
                {editingMonthBudget && !readOnly ?
                    <NumberFormat id="monthBudget"
                        value={newMonthBudget}
                        prefix={`${country.currency} `}
                        onValueChange={e => setNewMonthBudget(e.value)}
                        customInput={TextField}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        inputProps={{ min: 0, style: { fontSize: 20, fontWeight: 900 } }}
                        InputLabelProps={{ shrink: true, }} />
                    :
                    <PriceDisplay value={monthBudget} currency={country.currency} />
                }
                {!readOnly && <IconButton onClick={() => setEditingMonthBudget(e => { setNewMonthBudget(monthBudget); return !e; })}>{editingMonthBudget ? <CloseIcon style={{ color: 'red' }} /> : <EditIcon style={{ color: '#7E2D87' }} />}</IconButton>}
                {editingMonthBudget && !readOnly && <IconButton onClick={() => { setMonthBudget(newMonthBudget); setEditingMonthBudget(false); }}><CheckIcon style={{ color: 'green' }} /></IconButton>}
            </Box>
        </Box>
        <Box style={{ margin: '30px 0' }}>
            <Box style={{ display: 'flex', margin: '10px 0', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box>Presupuesto utilizado: </Box>
                <Box style={{ fontWeight: 900, marginLeft: 3, color: normalise(country.usedBudget) > 80 ? 'red' : '' }}><PriceDisplay value={country.usedBudget} currency={country.currency} /></Box>
            </Box>
            <BorderLinearProgress variant="determinate" value={normalise(country.usedBudget)} />
            <Box style={{ marginLeft: `calc(${normalise(country.usedBudget)}% - 20px)`, color: normalise(country.usedBudget) > 80 ? 'red' : '#7E2D87' }}>{normalise(country.usedBudget)}%</Box>
        </Box>
        <Box style={{ display: 'flex', margin: '30px 0', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', textAlign: 'center' }}>
            <Box>Presupuesto por empleado mensual: </Box>
            <Box style={{ fontWeight: 900, marginLeft: 3, display: 'flex', alignItems: 'center' }}>
                {!readOnly && editingEmployeeBudget ?
                    <NumberFormat id="employeeBudget"
                        value={newEmployeeBudget}
                        onValueChange={e => setNewEmployeeBudget(e.value)}
                        customInput={TextField}
                        prefix={`${country.currency} `}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        inputProps={{ min: 0, style: { fontSize: 20, fontWeight: 900 } }}
                        InputLabelProps={{ shrink: true, }} />
                    :
                    <PriceDisplay value={employeeBudget} currency={country.currency} />
                }
                {!readOnly && <IconButton onClick={() => setEditingEmployeeBudget(e => { setNewEmployeeBudget(employeeBudget); return !e; })}>{editingEmployeeBudget ? <CloseIcon style={{ color: 'red' }} /> : <EditIcon style={{ color: '#7E2D87' }} />}</IconButton>}
                {!readOnly && editingEmployeeBudget && <IconButton onClick={() => { setEmployeeBudget(newEmployeeBudget); setEditingEmployeeBudget(false); }}><CheckIcon style={{ color: 'green' }} /></IconButton>}
            </Box>
        </Box>
        <Box style={{ display: 'flex', margin: '30px 0', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', textAlign: 'center' }}>
            <Box>Cantidad de empleados: </Box>
            <Box style={{ fontWeight: 900, marginLeft: 3, display: 'flex', alignItems: 'center' }}>{country.employeesCount}</Box>
        </Box>
    </Box>;
};

export default CountryBudget;


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);