import React, { useContext, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const getShareMessage = pubId => `*Worknmates* te permite reservar un espacio de trabajo, en la casa de quien y cuando quieras. Vos elegis donde trabajar hoy!
Yo ya soy parte! Te comparto mi espacio para que lo veas y vengas a trabajar conmigo. Te espero!
${window.location.origin}/publication/${pubId}`

const Success = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context } = props;
    const { styles: contextStyles, host, space } = useContext(context);
    const history = useHistory();

    const [copied, setCopied] = useState(false);
    
    const styles = {
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        goToDashboard: {
            backgroundColor: '#00D1BF',
            color: '#ffffff',
            fontFamily: 'Poppins',
            borderRadius: '10px',
            boxShadow: 'none',
            width: isMobile ? '100%' : '168px',
            height: '50px',
            fontWeight: 'bold',
            marginBottom: isMobile ? '10px' : '0'
        },
        share: {
            fontFamily: 'PoppinsBold',
            color: '#383839',
            fontSize: '16px',
            textTransform: 'none'
        },
        ...contextStyles
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <Box style={{ backgroundColor: '#00D1BF', height: '400px', marginLeft: isMobile ? '-10px' : '-50px', marginRight: isMobile ? '-10px' : '-50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ height: '135px', width: '135px', borderRadius: '135px', backgroundColor: 'lightgrey', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', color: '#383839', overflow: 'hidden' }}>
                    <img src={host?.avatar?.url} alt="" style={{ maxWidth: '160px', maxHeight: '160px' }} />
                </div>
                <div style={{ color: '#ffffff', fontSize: '30px' }}>{`¡Felicitaciones ${host?.name}!`}</div>
            </Box>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column', height: '300px' }}>
                <div style={{ color: '#383839', fontSize: '26px' }}>Tu espacio está listo para recibir compañeros de trabajo</div>
                <br />
                <Button onClick={() => history.push("/host/dashboard")} variant="contained" style={styles.goToDashboard}>Ir a mi Tablero</Button>
                <br />
                <CopyToClipboard text={getShareMessage(space.id)} onCopy={() => { setCopied(true); setTimeout(() => setCopied(false), 1000); }}>
                    <Box style={{ display: 'flex', position: 'relative' }}>
                        <Button variant="link" style={styles.share}><img style={{ marginRight: '20px' }} alt="" src={require('../../../assets/images/share.png')} />Compartí tu anuncio</Button>
                        <span style={{ fontSize: '12px', color: 'grey', marginBottom: '50px', position: 'absolute', top: '-20px' }}>{copied && 'Copiado!'}</span>
                    </Box>
                </CopyToClipboard>
            </Box>
        </Box>
    </Box>;
};

export default Success;