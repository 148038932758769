import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Container, Box, CircularProgress } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Space from "../onboarding/Space";
import Location from "../onboarding/Location";
import ConfirmLocation from "../onboarding/ConfirmLocation";
import Service from "../onboarding/Service";
import Photos from "../onboarding/Photos";
import Rules from "../onboarding/Rules";
import Availability from "../onboarding/Availability";
import Pricing from "../onboarding/Pricing";
import Policy from "../onboarding/Policy";
import Success from "../onboarding/Success";
import HostService from "../../../services/HostService";
import { useAuth0 } from "@auth0/auth0-react";
import SpaceCreationContext, { SpaceCreationProvider } from '../space/SpaceCreationContext';
import HostSpaceService from "../../../services/HostSpaceService";
import StepUtils from '../onboarding/StepUtils';

const SpaceCreation = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    let { path } = useRouteMatch();
    const { user, isAuthenticated } = useAuth0();
    
    const [host, setHost] = useState({});
    const [hostLoading, setHostLoading] = useState(true);

    const [space, setSpace] = useState({ id: 0 });
    const [spaceLoading, setSpaceLoading] = useState(false);

    useEffect(() => { 
        let hostId = HostService.getHostId(user); 
        updateProgress(); 
        refreshHost(hostId); 
        refreshSpace(hostId); 
        // eslint-disable-next-line
    }, [user]);
    
    // sort for onboarding steps
    const stepsOrder = [
        `${path}/new`,
        `${path}/location`,
        `${path}/confirm_location`,
        `${path}/service`,
        `${path}/photos`,
        `${path}/rules`,
        `${path}/availability`,
        `${path}/pricing`,
        `${path}/policy`,
        `${path}/success`,
    ];


    // calculate top bar onboarding progress
    const calcProgress = () => {
        let p = undefined;
        if (isAuthenticated) {
            let stepNumber = stepsOrder.indexOf(window.location.pathname) + 1;
            stepNumber = stepNumber > 0 ? stepNumber : 1;
            let progress = (stepNumber * 100) / (stepsOrder.length - 1);
            p = progress > 100 ? 100 : progress;
        }
        return p;
    };
    const [progress, setProgress] = useState(calcProgress());
    const updateProgress = () => setProgress(calcProgress());
    
    // api calls
    const refreshHost = async (hostId) => {
        setHostLoading(true);
        if (hostId <= 0) {
            setHostLoading(false);
            return;
        }
        try {
            let resp = await HostService.getById(hostId);
            setHost(resp.data);
            console.log('HOST', resp.data);
        } catch (e) {
            console.log(e);
        } finally {
            setHostLoading(false);
        }
    }
    const refreshSpace = async (hostId) => {
        setSpaceLoading(true);
        try {
            let spaceId = HostService.getCurrentSpaceId(user);
            if (spaceId === 0 || hostId === 0) return;
            let resp = await HostSpaceService.getById(hostId, spaceId);
            let s = resp.data;
            setSpace(s);
            console.log('SPACE', s);
        } catch (e) {
            console.log(e);
        } finally {
            setSpaceLoading(false);
        }
    }

    const stepUtils = StepUtils(stepsOrder, updateProgress, refreshHost, refreshSpace);

    const styles = {
        stepsView: {
            backgroundColor: '#ffffff',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 135px)',
            paddingBottom: isNarrowWidth ? '20px' : 0,
            paddingLeft: isMobile ? '10px' : '50px',
            paddingRight: isMobile ? '10px' : '50px'
        }
    }

    const stepsProps = {
        context: SpaceCreationContext,
        isOnboarding: true
    }

    // context data
    const contextData = {
        stepUtils,
        styles,
        space,
        host
    }
    return <SpaceCreationProvider value={contextData}>
        <div style={{ backgroundColor: isMobile ? '#ffffff' : '#F2F2F2', paddingTop: isMobile ? (stepUtils.isLastStep ? 0 : '16px') : '40px' }}>
            <Container style={{ minHeight: 'calc(100vh - 104px)', padding: isMobile && 0 }}>
                {!stepUtils.isLastStepAndMobile &&
                    <div style={{ padding: isMobile ? '0 20px' : 'unset', height: isMobile ? 'unset' : '30px', overflow: 'hidden' }}>
                        <BorderLinearProgress variant={progress ? 'determinate' : undefined} value={progress} style={{ height: isMobile ? '13px' : '30px', borderRadius: '10px', colorPrimary: '#00D1BF', marginTop: isMobile ? '0' : '15px' }} />
                    </div>
                }

                {(hostLoading || spaceLoading || !progress) ?
                    <Box style={{ ...styles.stepsView, justifyContent: 'center', alignItems: 'center', flexFlow: 'row' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <Switch>
                            <Route exact path={path}>
                                <Redirect to={`${path}/new`} />
                            </Route>
                            <Route path={`${path}/new`}>
                                <Space {...stepsProps} />
                            </Route>
                            <Route path={`${path}/location`}>
                                <Location {...stepsProps} />
                            </Route>
                            <Route path={`${path}/confirm_location`}>
                                <ConfirmLocation refreshSpace={() => refreshSpace(HostService.getHostId(user))} {...stepsProps} />
                            </Route>
                            <Route path={`${path}/service`}>
                                <Service {...stepsProps} />
                            </Route>
                            <Route path={`${path}/photos`}>
                                <Photos {...stepsProps} />
                            </Route>
                            <Route path={`${path}/rules`}>
                                <Rules {...stepsProps} />
                            </Route>
                            <Route path={`${path}/availability`}>
                                <Availability {...stepsProps} />
                            </Route>
                            <Route path={`${path}/pricing`}>
                                <Pricing {...stepsProps} />
                            </Route>
                            <Route path={`${path}/policy`}>
                                <Policy {...stepsProps} />
                            </Route>
                            <Route path={`${path}/success`}>
                                <Success {...stepsProps} />
                            </Route>
                        </Switch>
                    </>
                }
            </Container>
        </div>
    </SpaceCreationProvider>;
};

export default SpaceCreation;


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: '#D8D8D8',
    },
    bar: {
        backgroundColor: '#00D1BF',
    },
}))(LinearProgress);