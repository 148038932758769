import React, { useState } from "react";
import { Box, Button, Radio, RadioGroup } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";


const Payments = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [paymentSelected, setPaymentSelected] = useState(1);

    const styles = {
        paymentMethodOption: {
            border: '1px solid #D8D8D8',
            borderRadius: '3px',
            width: isMobile ? 'unset' : '450px',
            marginTop: '20px',
            display: 'flex',
            minHeight: '67px',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            maxWidth: '93vw'
        },
    }
    
    return <Box style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px', marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <span>Pagos</span>
            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <RadioGroup value={paymentSelected}>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: isMobile ? 'column' : 'row' }}>
                <div style={styles.paymentMethodOption}>
                    <Radio onChange={e => setPaymentSelected(1)} value={1} />
                    <div style={{ display: 'flex', flexDirection: 'row', width: '350px', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div>Transferencia Bancaria</div>
                        <img src={require('../../../assets/images/cash-logo.png')} alt="" style={{}} />
                    </div>
                </div>

                <div style={styles.paymentMethodOption}>
                    <Radio onChange={e => setPaymentSelected(2)} value={2} />
                    <div style={{ display: 'flex', flexDirection: 'row', width: '350px', justifyContent: 'space-around', alignItems: 'center' }}>
                        <img src={require('../../../assets/images/mp-logo.png')} alt="" style={{ width: '91px', height: '24px', margin: '5px' }} />
                    </div>
                </div>
            </Box>
        </RadioGroup>
        <Button onClick={() => null}
            variant="contained"
            style={{
                backgroundColor: '#7E2D87',
                color: '#ffffff',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                boxShadow: 'none',
                width: isMobile ? '100%' : '168px',
                height: '50px',
                fontWeight: 'bold',
                marginBottom: isMobile ? '10px' : '0',
                alignSelf: 'flex-end'
            }}>Guardar</Button>
    </Box >;
};

export default Payments;


