import React from "react";
import { useMediaQuery } from 'react-responsive';
import { Box, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

const UserDashboardMenu = ({ closeDrawer }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const history = useHistory();
    let { pathname } = useLocation();

    const isActive = (path) => path === pathname;

    const menuItems = [
        {
            label: 'Datos personales',
            icon: require('../../assets/images/menu/user-icon.png'),
            action: () => history.push("/dashboard/personal"),
            disabled: false,
            active: isActive("/dashboard/personal")
        },
        {
            label: 'Mis Reservas',
            icon: require('../../assets/images/menu/income-icon.png'),
            action: () => history.push("/dashboard/reservation"),
            disabled: false,
            active: isActive("/dashboard/reservation")
        },
        {
            label: 'Espacios guardados',
            icon: require('../../assets/images/menu/favourite.png'),
            action: () => history.push("/dashboard/favourites"),
            disabled: false,
            active: isActive("/dashboard/favourites")
        },
        {
            label: 'Evaluaciones',
            icon: require('../../assets/images/menu/star-icon.png'),
            action: () => history.push("/dashboard/reviews"),
            disabled: false,
            active: isActive("/dashboard/reviews")
        },
        {
            label: 'Referidos',
            icon: require('../../assets/images/menu/check-icon.png'),
            action: () => history.push("/dashboard/points"),
            disabled: false,
            active: isActive("/dashboard/points")
        }
    ];

    const DashboardMenuItem = ({ label, icon, action, disabled, active }) => {
        return <Box style={{ display: 'flex', width: '100%', overflow: 'hidden', borderBottom: '1px solid #D8D8D8', alignItems: 'center' }}>
            <Button onClick={() => { action(); closeDrawer && closeDrawer(); }} className={'dashboardMenuButton'} color={active ? 'primary' : 'default'} disabled={disabled} style={{ padding: isMobile ? '20px' : '25px 15px', width: '100%' }}>
                <img src={icon} alt="" style={{ width: '24px', height: '24px', marginRight: '10px' }}></img>
                <div style={{ textTransform: 'none', fontFamily: 'Poppins', }}>{label}</div>
            </Button>
        </Box>;
    }

    return <Box style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', alignItems: 'baseline', border: '1px solid #D8D8D8' }}>
        {menuItems.map(i => <DashboardMenuItem key={'item_' + i.label} {...i} />)}
    </Box>;
};

export default UserDashboardMenu;

