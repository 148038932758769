import React from "react";
import Switch from "../../styled/StyledSwitch";
import { IconButton, Tooltip, Menu, MenuItem, DialogActions, Dialog, DialogTitle, Button, DialogContent, CircularProgress, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMediaQuery } from "react-responsive";
import HelpIcon from '@material-ui/icons/Help';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import HostSpaceService from "../../../services/HostSpaceService";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import DashboardContext from '../dashboard/DashboardContext';
import SpaceTitle from "../../publication/SpaceTitle";
import WarningIcon from '@material-ui/icons/Warning';
import DateRangeIcon from '@material-ui/icons/DateRange';

const messages = {
    hostEmailNotVerified: 'Todavía no validaste tu correo. Por favor revisa si has recibido un email de Worknmates en tu bandeja de entrada y correo no deseado para poder verificar tu cuenta. En caso de no haberlo recibido escribinos a contacto@worknmates.com. Hasta tanto no lo valides, tus anuncios no seran publicados.',
    hostIsNotActive: 'Su usuario se encuentra pendiente de aprobación por Worknmates. Esto puede demorar hasta 12hs. Tu cuenta debe ser verificada para que tus avisos sean publicados. Puedes contactarte directamente con nosotros enviando un correo a: contacto@worknmates.com.',
    publicationBlockedByAdmin: 'El anuncio ha sido temporalmente inhabilitado por Worknmates debido a algunas irregularidades. Nos pondremos en contacto contigo a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com '
};

const hostStatusLabels = {
    0: {
        title: 'Pendiente Aprobación',
        tooltip: 'Su usuario se encuentra pendiente de aprobación por Worknmates. Esto puede demorar hasta 12hs. Tu cuenta debe ser verificada para que tus avisos sean publicados. Puedes contactarte directamente con nosotros enviando un correo a: contacto@worknmates.com.',
    },
    1: {
        title: 'Activo',
        tooltip: ''
    },
    2: {
        title: 'Usuario Suspendido',
        tooltip: 'Worknmates ha suspendido tu cuenta temporalmente debido a algunas irregularidades detectadas. Por el momento tus anuncios no podrán ser publicados. Nos pondremos en contacto a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com. '
    },
    3: {
        title: 'Usuario Bloqueado',
        tooltip: 'Worknamtes ha bloqueado tu cuenta temporalmente debido a algunas irregularidades detectadas. Por el momento tus anuncios no podrán ser publicados. Nos pondremos en contacto a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com. '
    },
    4: {
        title: 'Eliminado',
        tooltip: ''
    },
}

const getShareMessage = pubId => `*Worknmates* te permite reservar un espacio de trabajo, en la casa de quien y cuando quieras. Vos elegis donde trabajar hoy!
Yo ya soy parte! Te comparto mi espacio para que lo veas y vengas a trabajar conmigo. Te espero!
${window.location.origin}/publication/${pubId}`

const PublicationPreview = ({ pub, toggleSpaceActive, refreshPubs, checkOn = false, setPublicationsToEdit, checked }) => {
    const history = useHistory();
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 1000px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 850px)' });
    const { user } = useAuth0();
    const { host } = useContext(DashboardContext);

    const publicationReadyToPublish = user.email_verified && (host?.status === 1) && pub.admin_approval;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [deleteLoading, setDeleteLoading] = useState(false);

    const [deletDialogOpen, setDeletDialogOpen] = useState(false);
    const [copied, setCopied] = useState(false);

    // eslint-disable-next-line
    const handleChange = (event) => {
        if (event.target.checked) {
            setPublicationsToEdit((currentValues) => [...currentValues, pub.id]);
        } else {
            setPublicationsToEdit((currentValues) => {
                const filteredValues = currentValues.filter((v) => v !== pub.id);
                return filteredValues;
            });
        }
    };

    const getShareText = () => (publicationReadyToPublish ? getShareMessage(pub.id) : null);

    const handleDialogDeleteClose = (removePub, pub) => {
        if (removePub) {
            setDeleteLoading(true);
            HostSpaceService.deleteSpace(pub.host.id, pub.id)
                .then(() => refreshPubs())
                .catch(e => console.log(e))
                .finally(() => {
                    setDeleteLoading(false);
                    setDeletDialogOpen(false);
                });
        } else {
            setDeletDialogOpen(false);
        }
    }

    const deletePublication = () => {
        setDeletDialogOpen(true);
        setAnchorEl(null);
    }
    return <div className="publicationHostPreview" style={{ display: 'flex', marginBottom: '20px', position: 'relative' }}>
        <div style={{ display: 'flex', flexDirection: isNarrowWidth ? 'column' : 'row', flexShrink: 0, flexGrow: 3, border: pub.availability_full ? '2px solid red' : pub.availability_end_close ? '2px solid #FF8C00' : '1px solid #D8D8D8', borderRadius: '12px', alignItems: 'center', justifyContent: 'space-between', minWidth: isMobile ? '100%' : '520px' }}>
            <img src={pub.images[0]?.url || require('../../../assets/images/photo_placeholder.png')} alt="" style={{ width: '200px', height: '150px', borderRadius: '12px' }} />
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', flexGrow: 2, alignItems: 'center', justifyContent: 'inherit', width: isMobile ? '100%' : 'unset' }}>
                <div style={{ padding: '10px', textAlign: 'left', width: isNarrowWidth ? 'unset' : '250px' }}>
                    <div style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#383839' }}><SpaceTitle space={pub} /></div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#383839' }}>{pub?.buildingType?.name}</div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '18px', color: '#383839' }}>
                        <span style={{ fontFamily: 'PoppinsBold', fontStyle: 'normal', fontWeight: 'bold', fontSize: '24px', color: '#383839' }}>{`$ ${pub?.price > 0 ? (Math.round(pub?.price - pub?.price * pub?.discount_percentage / 100)) : 0} `}</span>
                        <span>por jornada</span>
                    </div>
                    <div>{pub.active}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: isMobile ? '100%' : 'unset', justifyContent: 'space-between' }}>
                    {isNarrowWidth && <>
                        {publicationReadyToPublish ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexShrink: 0, padding: '10px', maxWidth: '33% !important' }}>
                                    <div>Estado: {pub.active ? 'Activo' : 'Pausado'}</div>
                                    <div>
                                        <Switch
                                            checked={pub.active}
                                            onChange={toggleSpaceActive}
                                            color="primary"
                                            name="active"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                            </>
                            : <PublicationDisabled pubAdminApproval={pub.admin_approval} />
                        }
                    </>}
                    {(pub.availability_end_close || pub.availability_full) &&
                        <Box style={{ top: 10, right: 10, position: isNarrowWidth ? 'absolute' : '', marginRight: isNarrowWidth ? 0 : 15, display: 'flex', alignItems: 'center' }}>
                            <Tooltip title={pub.availability_end_close ? 'Su espacio está proximo a quedarse sin disponibilidad.' : 'Espacio sin disponibilidad.'} placement={'top'} enterTouchDelay={0}>
                                <WarningIcon style={{ color: pub.availability_full ? 'red' : '#FF8C00' }} />
                            </Tooltip>
                    </Box>
                    }
                    {/*<Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} style={{ top: 0, right: 0, position: isNarrowWidth ? 'absolute' : '' }} /> */}
                    <Tooltip title="Editar espacio">
                        <IconButton className="editButton" onClick={() => history.push(`/host/spaceEdit/${pub.id}`)} style={{ height: '40px', width: '40px', marginRight: isNarrowWidth ? 0 : 15 }}>
                            <img src={require('../../../assets/images/edit_pub.png')} alt="" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar disponibilidad">
                        <IconButton onClick={() => history.push(`/host/spaceEdit/${pub.id}/6`)} style={{ height: '40px', width: '40px', marginRight: isNarrowWidth ? 0 : 15 }}>
                            <DateRangeIcon />
                        </IconButton>
                    </Tooltip>
                    <CopyToClipboard text={getShareText()} onCopy={() => { setCopied(true); setTimeout(() => setCopied(false), 1000); }}>
                        <Tooltip title="Compartir">
                            <Box style={{ display: 'flex', position: 'relative' }}>
                                <IconButton disabled={!publicationReadyToPublish} className="editButton" onClick={() => null} style={{ height: '40px', width: '40px' }}>
                                    <img src={require('../../../assets/images/share.png')} alt="" />
                                </IconButton>
                                <span style={{ fontSize: '12px', color: 'grey', marginBottom: '50px', position: 'absolute', top: '-20px' }}>{copied && publicationReadyToPublish && 'Copiado!'}</span>
                            </Box>
                        </Tooltip>
                    </CopyToClipboard>

                    <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={e => setAnchorEl(null)}>
                        <MenuItem onClick={deletePublication}>
                            <DeleteIcon /> Eliminar
                        </MenuItem>
                    </Menu>
                    <DeletePublicationDialog pub={pub} open={deletDialogOpen} handleClose={handleDialogDeleteClose} loading={deleteLoading} />
                </div>
            </div>
        </div>
        {
            !isNarrowWidth && <>
                {publicationReadyToPublish ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexShrink: 0, padding: '10px', minWidth: '170px' }}>
                        <div>Estado: {pub.active ? 'Activo' : 'Pausado'}</div>
                        <div>
                            <Switch
                                checked={pub.active}
                                onChange={toggleSpaceActive}
                                disabled={!publicationReadyToPublish}
                                color="primary"
                                name="active"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />

                        </div>
                    </div>
                    : <PublicationDisabled pubAdminApproval={pub.admin_approval} />}
            </>
        }
    </div >
};

export default PublicationPreview;

const PublicationDisabled = ({ pubAdminApproval }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 350px)' });
    const { user } = useAuth0();
    const { host } = useContext(DashboardContext);
    let alerts = [];

    if (!user.email_verified) alerts.push(messages.hostEmailNotVerified);
    if (host?.status !== 1) alerts.push(hostStatusLabels[host?.status]?.tooltip);
    if (!pubAdminApproval) alerts.push(messages.publicationBlockedByAdmin);

    return <div style={{ display: 'flex', justifyContent: 'center', flexShrink: 0, fontSize: isMobile ? '14px' : '16px', padding: isMobile ? '0' : '10px', minWidth: isMobile ? 'unset' : '170px', color: 'red', alignItems: 'center' }}>
        <Tooltip title={<div style={{ fontSize: '14px' }}>{alerts.map(m => <div key={m + '_e'}><b>{m}</b><br /><br /></div>)}</div>} style={{ marginRight: '10px' }}>
            <HelpIcon />
        </Tooltip>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
            <div>Anuncio</div>
            <div>Inhabilitado</div>
        </div>
    </div >;
}


const DeletePublicationDialog = ({ pub, open, handleClose, loading }) => {
    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle><span style={{ fontFamily: 'Poppins' }}>Esta seguro que desea eliminar la publicación?</span></DialogTitle>
        <DialogContent>
            <div style={{ border: '1px solid grey', borderRadius: '8px', padding: '10px', width: 'fit-content' }}>
                <div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839' }}><SpaceTitle space={pub} /></div>
                    <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839' }}>{pub?.buildingType?.name}</div>
                </div>
                <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839' }}>
                    <span style={{ fontFamily: 'PoppinsBold', fontStyle: 'normal', fontWeight: 'bold', fontSize: '18px', color: '#383839' }}>{`$ ${pub.price > 0 ? pub.price : 0} `}</span>
                    <span>por jornada</span>
                </div>
            </div>
            <br />
            <div style={{ fontFamily: 'Poppins', color: 'red' }}>Esta acción es irreversible.</div>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={loading}
                style={{ borderColor: '#00D1BF', color: '#00D1BF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '148px', }}
                onClick={() => handleClose(false, null)}
                color="primary"
                variant="outlined"
                autoFocus>No</Button>
            <Button
                disabled={loading}
                style={{ backgroundColor: '#00D1BF', color: '#FFFFFF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '148px', }}
                onClick={() => handleClose(true, pub)}
                variant="contained"
                color="primary">
                {loading ? <CircularProgress color="secondary" /> : 'Eliminar'}
            </Button>
        </DialogActions>
    </Dialog>;
}