import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, { history } from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es');

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};


ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

