import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import HostDashboardMenu from "./HostDashboardMenu";
import { Box, CircularProgress, Tooltip, Button, Dialog, DialogTitle, DialogContent, DialogActions, Link } from "@material-ui/core";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import PersonalInfo from './onboarding/PersonalInfo';
import Payment from './onboarding/Payment';
import Security from "./onboarding/Security";
import DashboardContext, { DashboardProvider } from './dashboard/DashboardContext';
import HostService from "../../services/HostService";
import { useAuth0 } from "@auth0/auth0-react";
import Publications from "./dashboard/Publications";
import DashboardHome from "./dashboard/DashboardHome";
import ReferralCode from "./dashboard/ReferralCode";
import HelpIcon from '@material-ui/icons/Help';
import DeleteIcon from '@material-ui/icons/Delete';
import UserService from "../../services/UserService";
import HostBookings from "./dashboard/HostBookings";
import HostReviews from "./dashboard/HostReviews";
import HostCalendar from "./dashboard/HostCalendar";

const HostDashboard = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    let { path } = useRouteMatch();
    const { isAuthenticated, user } = useAuth0();
    const [contextData, setContextData] = useState({
        styles: {
            stepsView: {
                backgroundColor: '#ffffff',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'space-between',
                paddingLeft: isMobile ? '10px' : '50px',
                paddingRight: isMobile ? '10px' : '50px'
            }
        }
    });
    const [hostLoading, setHostLoading] = useState(false);

    useEffect(() => {
        let mounted = true;
        const getHost = async () => {
            setHostLoading(true);
            let hostId = HostService.getHostId(user);
            let resp = await HostService.getById(hostId);
            (isAuthenticated && mounted) && setContextData(cd => ({ ...cd, host: resp.data }));
            mounted && setHostLoading(false);
        }
        getHost();
        return () => mounted = false;
    }, [isAuthenticated, user]);

    return <DashboardProvider value={contextData}>
        <Box style={{ display: 'flex', backgroundColor: '#F6F6F6' }} id="hostDashboard">
            {!isMobile && <HostDashboardMenu />}
            <Box style={{ padding: isMobile ? 'unset' : '15px 40px', width: isMobile ? '100vw' : '100%', minHeight: 'calc(100vh - 64px)' }}>
                <HostStatusAlert status={contextData?.host?.status} />
                <Switch>
                    <Route exact path={`${path}/`}>
                        <Redirect to={`${path}/home`} />
                    </Route>
                    <Route path={`${path}/home`}>
                        <DashboardHome context={DashboardContext} />
                    </Route>
                    <Route path={`${path}/personal`}>
                        {hostLoading ?
                            <div style={styles.loading}><CircularProgress /></div>
                            : <>
                                <div style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px' }}>
                                    <PersonalInfo context={DashboardContext} />
                                </div>
                                <br />
                                <div style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px' }}>
                                    <Security context={DashboardContext} />
                                </div>
                                <br />
                                <div style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px' }}>
                                    <Payment context={DashboardContext} />
                                </div>
                                <RemoveHostButton user={user} />
                            </>
                        }
                    </Route>
                    <Route path={`${path}/reservation/:bookingId?`}>
                        <HostBookings />
                    </Route>
                    <Route path={`${path}/calendar`}>
                        <HostCalendar />
                    </Route>
                    <Route path={`${path}/publications`}>
                        <Publications />
                    </Route>
                    <Route path={`${path}/review`}>
                        <HostReviews />
                    </Route>
                    <Route path={`${path}/points`}>
                        <ReferralCode context={DashboardContext} />
                    </Route>
                </Switch>
            </Box>
        </Box>
    </DashboardProvider>;
};

export default HostDashboard;

const RemoveHostButton = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { logout } = useAuth0();

    const handleClose = (removeUser, user) => {
        if (removeUser) {
            setLoading(true);
            HostService.deleteHost(HostService.getHostId(user))
                .then(() => logout())
                .catch(e => console.log(e))
                .finally(() => {
                    setLoading(false);
                    setOpen(false);
                });
        } else {
            setOpen(false);
        }
    }
    return <>
        <Button variant="outlined" style={{ marginTop: '15px', borderColor: 'red', color: 'red', width: '200px', height: '50px', borderRadius: '8px' }} onClick={() => setOpen(true)}>
            <DeleteIcon /> Eliminar Usuario
        </Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle><span style={{ fontFamily: 'Poppins' }}>{<>Esta seguro que desea eliminar el usuario <b>{user.email}</b> de Worknmates?</>}</span></DialogTitle>
            <DialogContent>
                <div style={{ fontFamily: 'Poppins', color: 'red' }}>Esta acción es irreversible.</div>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    style={{ borderColor: '#00D1BF', color: '#00D1BF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '148px', }}
                    onClick={() => handleClose(false, null)}
                    color="primary"
                    variant="outlined"
                    autoFocus>No</Button>
                <Button
                    disabled={loading}
                    style={{ backgroundColor: '#00D1BF', color: '#FFFFFF', fontFamily: 'Poppins', borderRadius: '10px', height: '40px', width: '148px', }}
                    onClick={() => handleClose(true, user)}
                    variant="contained"
                    color="primary">
                    {loading ? <CircularProgress color="secondary" /> : 'Eliminar'}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

const HostStatusAlert = ({ status }) => {
    const { user } = useAuth0();
    const [verificationSent, setVerificationSent] = useState(false);
    const [sending, setSending] = useState(false);
    const { getUserId } = UserService;

    const resendEmailVerification = () => {
        setSending(true);
        UserService.resendEmailVerification(getUserId(user))
            .then(resp => {
                console.log(JSON.stringify(resp));
                setVerificationSent(true);
            })
            .catch(e => console.log(e))
            .finally(() => setSending(false));
    };

    let warnings = [];
    if ([0, 2, 3].includes(status)) warnings.push(<HostWarning key={'status_' + status} title={<b>{hostStatusLabels[status].title}</b>} tooltipMsg={hostStatusLabels[status].tooltip} />);
    if (!user.email_verified)
        warnings.push(
            <HostWarning key={'status_email_verified'}
                title={<>
                    <span>Validación Correo Pendiente </span>
                    {sending ? <CircularProgress color="secondary" size={20} /> :
                        <span>
                            {verificationSent ? <b>Enviado (revise su correo)</b> : <Link component="button" onClick={resendEmailVerification} style={{ color: '#383839', fontSize: '16px', height: '23px', paddingBottom: '1px' }} >Reenviar</Link>}
                        </span>
                    }
                </>}
                tooltipMsg={'Todavía no validaste tu correo. Por favor revisa si has recibido un email de Worknmates en tu bandeja de entrada y correo no deseado para poder verificar tu cuenta. En caso de no haberlo recibido escribinos a contacto@worknmates.com. Hasta tanto no lo valides, tus anuncios no seran publicados.'} />
        )
    return warnings;
};

const HostWarning = ({ title, tooltipMsg }) => <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ff6666', border: '2px solid red', borderRadius: '5px', padding: '5px', color: 'white', margin: '10px' }}>
    <span style={{ marginRight: '10px' }}>{title}</span>
    <Tooltip title={<div style={{ fontSize: '14px' }}>{tooltipMsg}</div>}>
        <HelpIcon />
    </Tooltip>
</div>;

const hostStatusLabels = {
    0: {
        title: 'Pendiente Aprobación',
        tooltip: 'Su usuario se encuentra pendiente de aprobación por Worknmates. Esto puede demorar hasta 12hs. Tu cuenta debe ser verificada para que tus avisos sean publicados. Puedes contactarte directamente con nosotros enviando un correo a: contacto@worknmates.com.',
    },
    1: {
        title: 'Activo',
        tooltip: ''
    },
    2: {
        title: 'Usuario Suspendido',
        tooltip: 'Worknmates ha suspendido tu cuenta temporalmente debido a algunas irregularidades detectadas. Por el momento tus anuncios no podrán ser publicados. Nos pondremos en contacto a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com. '
    },
    3: {
        title: 'Usuario Bloqueado',
        tooltip: 'Worknamtes ha bloqueado tu cuenta temporalmente debido a algunas irregularidades detectadas. Por el momento tus anuncios no podrán ser publicados. Nos pondremos en contacto a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com. '
    },
    4: {
        title: 'Eliminado',
        tooltip: ''
    },
}



const styles = {
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
        height: '100%',
        width: '100%',
        backgroundColor: '#ffffff',
    }
}

