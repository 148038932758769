import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import UserService from "../../../services/UserService";

const StepUtils = (stepsOrder, updateProgress, refreshUser, refreshBooking) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const history = useHistory();
    const { user } = useAuth0();
    const userId = UserService.getUserId(user);


    const updateUser = async (updatedUserData) => {
        await UserService.updateUser(userId, updatedUserData).catch(e => { throw new Error(e) });
        refreshUser();
    }

    // true if current step number is equal to the value passed as parameter
    const isStep = (stepNum) => (stepsOrder.indexOf(window.location.pathname) === stepNum);

    // true if is the last step
    const isLastStep = isStep(stepsOrder.length - 1);

    // true if is the first step
    const isFirstStep = isStep(0);

    // true if is the previous of the last step (second counting from behind)
    const isPenultimateStep = isStep(stepsOrder.length - 2);

    // true if is the last step and is on mobile
    const isLastStepAndMobile = isLastStep && isMobile;

    // navigate to next step
    const nextStep = () => {
        goToStep(stepsOrder.indexOf(window.location.pathname) + 1);
        updateProgress();
    };

    // ====================== USER ======================
    // save user data and go to next step
    const saveUserAndNext = async (newUserData) => {
        await updateUser(newUserData)
        nextStep();
    };
    // save user data and close onboarding
    const saveUserAndClose = async (newUserData) => {
        await updateUser(newUserData);
        history.push('/');
    };

    // ====================== BOOKINGS ======================
    // save user booking and go to next step
    const saveBookingAndNext = async (newBookingData) => {
        refreshBooking(newBookingData);
        nextStep();
    };
    // save booking data and close onboarding
    const saveBookingAndClose = async (newBookingData) => {
        refreshBooking(newBookingData);
        history.push('/');
    };

    // back to previous step
    const previousStep = async () => {
        await refreshUser();
        goToStep(stepsOrder.indexOf(window.location.pathname) - 1);
        updateProgress();
    };

    // got to step passed as parameter
    const goToStep = (stepIndex) => {
        history.push(stepsOrder[stepIndex]);
    };

    return {
        isLastStep,
        isFirstStep,
        isPenultimateStep,
        isLastStepAndMobile,
        nextStep,
        saveUserAndNext,
        saveUserAndClose,
        saveBookingAndNext,
        saveBookingAndClose,
        previousStep,
        goToStep
    };
};

export default StepUtils;