import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Checkbox, Box, CircularProgress, Avatar, Container, Button } from "@material-ui/core";
import SpaceService from "../../services/SpaceService";
import { useLoadScript, GoogleMap, OverlayView } from "@react-google-maps/api";
import Footer from "../Footer";
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from "react-responsive";
import SearchBar from "../results/SearchBar";
import is from "is_js";
import BookingBox from "../booking/BookingBox";
import ReviewDisplay from "../booking/ReviewDisplay";
import SpaceTitle from "./SpaceTitle";
import SpaceCalification from "./SpaceCalification";
import { ReviewsModal } from '../booking/BookingDisplay';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const MAX_SERVICES = 6;

const Publication = () => {
    const isLessThan500pxWitdth = useMediaQuery({ query: '(max-width: 500px)' });
    const isLessThan800pxWitdth = useMediaQuery({ query: '(max-width: 800px)' });
    const isLessThan1200pxWitdth = useMediaQuery({ query: '(max-width: 1200px)' });
    const isLessThan1350pxWitdth = useMediaQuery({ query: '(max-width: 1350px)' });
    const isLessThan1500pxWitdth = useMediaQuery({ query: '(max-width: 1500px)' });
    const isLessThan1700pxWitdth = useMediaQuery({ query: '(max-width: 1700px)' });
    var store = require('store');
    const newBooking = store.get('new_booking');

    const { state: { searchCriteria } = {} } = useLocation();

    let { spaceId } = useParams();
    const [space, setSpace] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [displayedServices, setDisplayedServices] = useState([]);
    const [totalServiceCount, setTotalServiceCount] = useState(0);

    const [openReviewsModal, setOpenReviewsModal] = useState(false);
    const [reviews, setReviews] = useState([]);

    const { isLoaded: isMapLoaded, loadError: mapLoadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });

    const serviceSelected = (selected, s) => {
        if (selected) {
            setSelectedServices(ss => [...ss, s])
        } else {
            setSelectedServices(ss => ss.filter(servs => servs.space_service_id !== s.space_service_id))
        }
    }

    useEffect(() => {
        setDisplayedServices(space?.services?.filter(s => s.free).sort(sortServices).slice(0, MAX_SERVICES));
        setTotalServiceCount(space?.services?.filter(s => s.free).length || 0);
    }, [space, selectedServices]);

    useEffect(() => {
        let mounted = true;
        SpaceService.getById(spaceId)
            .then(resp => mounted && setSpace(resp.data))
            .catch(e => console.log('error getting space with id=' + spaceId, e));

        SpaceService.getReviews(spaceId)
            .then(resp => mounted && setReviews(resp.data))
            .catch(e => console.log('error getting reviews for space with id=' + spaceId, e))
        return () => mounted = false;
    }, [spaceId]);


    const handleDisplayedServices = () => {
        if (displayedServices.length === MAX_SERVICES)
            setDisplayedServices(space?.services?.filter(s => s.free).sort(sortServices));
        else
            setDisplayedServices(space?.services?.filter(s => s.free).sort(sortServices).slice(0, MAX_SERVICES));
    }

    const calcReserveRightPosition = () => {
        if (isLessThan1350pxWitdth) return '20px';
        if (isLessThan1500pxWitdth) return '80px';
        if (isLessThan1700pxWitdth) return '150px';
        return '14vw';
    }

    return <>{space ? <>
        {searchCriteria && <SearchBar searchCriteria={searchCriteria} />}
        <ReviewsModal
            open={openReviewsModal}
            setOpen={setOpenReviewsModal}
            reviews={reviews}
            isUserReviews={false}
        />
        <Container className="publicationContainer" style={{ display: isLessThan800pxWitdth ? 'block' : 'flex' }}>
            <Box className="publicationMain" style={{ maxWidth: isLessThan800pxWitdth ? '100%' : 'calc(100% - 312px)' }}>
                <Box style={{ display: 'flex', flexDirection: isLessThan800pxWitdth ? 'column' : 'row', margin: '10px', padding: '15px', alignItems: isLessThan800pxWitdth ? 'baseline' : 'center', justifyContent: 'space-between' }}>
                    <Box style={{ textAlign: 'left' }}>
                        <div style={{ fontSize: '26px' }}><SpaceTitle space={space} /></div>
                        <div>{space.building_type}</div>
                    </Box>
                    {(space.rating && parseInt(space.rating) > 0) && <Box style={{ marginTop: isLessThan800pxWitdth ? 10 : 0, border: '1px solid #383839', borderRadius: 10, padding: 10, alignSelf: isLessThan500pxWitdth ? 'center' : '' }}>
                        <SpaceCalification space={space} showText={true} />
                    </Box>}
                </Box>
                <Box style={{ display: 'flex', margin: '10px' }}>
                    <Carousel statusFormatter={(current, total) => `${current} de ${total}`} className={isLessThan500pxWitdth ? 'mobile' : 'desktop'}>
                        {space.images?.map(i => <div style={{ maxHeight: '500px' }} key={i.uuid}>
                            <img style={{ maxHeight: '500px', maxWidth: '90vw', objectFit: 'contain' }} src={i.original_url} alt="" />
                        </div>)}
                    </Carousel>
                </Box>
                <Box style={{ border: '1px solid #D8D8D8', margin: '10px', borderRadius: '10px', display: 'flex', padding: '15px' }}>
                    <Avatar src={space.host_avatar?.url} alt={space.host_name} style={{ width: '68px', height: '68px' }} />
                    <Box style={{ marginLeft: '15px' }}>
                        <div style={{ color: '#383839', fontSize: '26px', textAlign: 'left' }}>Anfitrión: {space.host_name} {space.host_lastname}</div>
                        <div style={{ textAlign: 'left' }}>Se registró en {moment(space.host_registration_date).format('MMMM YYYY')}</div>
                    </Box>
                </Box>
                <Box style={{ fontSize: '18px', lineHeight: '30px', margin: '10px', textAlign: 'left', overflowWrap: 'break-word' }}>
                    {space.description}
                </Box>
                <br />
                <Box style={{ display: 'flex', textAlign: 'left', padding: '15px', margin: '10px', flexDirection: isLessThan800pxWitdth ? 'column' : 'row' }}>
                    <Box style={{ flexBasis: isLessThan1200pxWitdth ? '66%' : '95%' }}>
                        <b style={{ fontSize: '20px' }}>Servicios Incluidos</b>
                        <div style={{ columns: isLessThan800pxWitdth ? isLessThan500pxWitdth ? 1 : 2 : 1, margin: '5px' }}>
                            {displayedServices?.map(s => <div key={s.space_service_id + '_service'} style={{ fontSize: '16px', lineHeight: '45px', display: 'flex', alignItems: 'center' }}>
                                <img src={s.service.image.url} alt="" style={{ marginRight: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} />
                                <span>{s.service.name}</span>
                            </div>)}
                            {(totalServiceCount > MAX_SERVICES) && <Button variant="outlined" onClick={handleDisplayedServices}>{displayedServices?.length === MAX_SERVICES ? 'Mostrar mas' : 'Mostrar menos'}</Button>}
                        </div>
                    </Box>
                    <br />
                    {is.not.empty(space.services?.filter(s => !s.free)) &&
                        <Box style={{ flexGrow: 3, width: '100%' }}>
                            <b style={{ fontSize: '20px ' }}>Servicios Adicionales</b>
                            {space.services?.filter(s => !s.free).sort(sortServices).map(s => <div key={s.space_service_id + '_service_free'} style={{ fontSize: '16px', lineHeight: '45px', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox onChange={e => serviceSelected(e.target.checked, s)} checked={!!selectedServices.filter(serv => serv.space_service_id === s.space_service_id)[0] || false} />
                                    <img src={s.service.image.url} style={{ marginRight: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} alt="" />
                                    <span> {s.service.name}</span>
                                </div>
                                <div>{isLessThan500pxWitdth ? '' : 'Costo por '}día / <b>${s.price}</b></div>
                            </div>)}
                        </Box>}
                </Box>
                {is.not.empty(reviews) && <Box style={{ fontSize: '18px', lineHeight: '30px', margin: '10px', textAlign: 'left', borderTop: '1px solid #D8D8D8', padding: 10 }}>
                    <b style={{ fontSize: '20px' }}>Evaluaciones</b>
                    {reviews.slice(0, 3).map(r => <ReviewDisplay review={r} key={r.date + '_review'} />)}
                    {reviews.length > 3 && <Button variant="outlined" onClick={() => setOpenReviewsModal(true)}>Mostrar mas</Button>}
                </Box>}
                <Box style={{ textAlign: 'left', marginBottom: '20px', padding: isLessThan500pxWitdth ? '15px 0' : '15px', margin: isLessThan500pxWitdth ? '5px 0' : '10px', borderTop: '1px solid #D8D8D8' }}>
                    <b style={{ fontSize: '20px' }}>Ubicación</b>
                    <div style={{ fontSize: '20px', color: '#818181' }}>{space.street} al {Math.floor(+space.street_number / 100) * 100}</div>
                    <div style={{ fontSize: '20px', color: '#818181' }}>{space.city}, {space.country}</div>
                    {mapLoadError ? <div>Error loading map: {JSON.stringify(mapLoadError)}</div>
                        : <div style={{ borderRadius: '15px', overflow: 'hidden' }}>{isMapLoaded && space ?
                            <GoogleMap
                                clickableIcons={false}
                                mapContainerStyle={containerStyle}
                                center={{ lat: space.latitude || 0, lng: space.longitude || 0 }}
                                zoom={14}>
                                <OverlayView position={{ lat: +space.latitude || 0, lng: +space.longitude || 0 }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                                    <img src={require('../../assets/images/logo-small.png')} style={{ marginTop: -35, marginLeft: -35, height: '70px', width: '70px', objectFit: 'scale-down' }} alt="" />
                                </OverlayView>
                            </GoogleMap>
                            : <CircularProgress />
                        }</div>
                    }
                </Box>
                <Box style={{ marginBottom: '20px', justifyContent: 'center', textAlign: 'left', borderTop: '1px solid #D8D8D8', padding: '15px' }}>
                    <b style={{ fontSize: '20px', lineHeight: '38px' }}>Qué tenés que saber</b>
                    <br />
                    <div style={{ display: 'flex', flexDirection: isLessThan800pxWitdth ? 'column' : 'row' }}>
                        <Box style={{ flexBasis: '40%', padding: '10px', margin: '10px' }}>
                            <b style={{ lineHeight: '38px' }}>Reglas de la casa</b>
                            <div style={{ lineHeight: '38px' }}>- Check in: {space.check_in_time}</div>
                            <div style={{ lineHeight: '38px' }}>- Check out: {space.check_out_time}</div>
                            {space.rules?.map(r => <div style={{ lineHeight: '38px' }} key={r.name}>- {r.name}</div>)}
                        </Box>
                        <Box style={{ flexBasis: '55%', padding: '10px', margin: '10px' }}>
                            <b style={{ lineHeight: '38px' }}>Política de cancelación</b>
                            <div style={{ lineHeight: '38px' }}>Podrás cancelar hasta 24 horas antes del check-in y obtené un reembolso total. Si cancelas dentro de las 24 horas del Check-In, tendrás una penalidad del 100% de la tarifa.</div>
                            <div style={{ lineHeight: '38px' }}>- Reserva Confimada</div>
                            <div style={{ lineHeight: '38px' }}>- 24 horas antes: reembolso total</div>
                            <div style={{ lineHeight: '38px' }}>- Dentro de las 24 horas del ckeck-in: penalidad 100% de la tarifa</div>
                        </Box>
                    </div>
                </Box>
            </Box>

            {isLessThan800pxWitdth ? null : <Box><div style={{ width: '312px' }}></div></Box>}
            <BookingBox
                space={space}
                selectedServices={selectedServices}
                multiDate={newBooking?.multiple_dates}
                date={newBooking?.date}
                dates={newBooking?.dates}
                quantity={newBooking?.quantity}
                rightPosition={calcReserveRightPosition()}
            />
        </Container>
    </>
        : <div style={styles.loading}><CircularProgress /></div>}
        <Footer />
    </>;
};

export default Publication;

const styles = {
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
        height: '90vh',
        width: '100%',
        backgroundColor: '#ffffff',
    }
}

const sortServices = (a, b) => a.service.order - b.service.order;
