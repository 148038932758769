import React, { useState, useContext, useEffect } from "react";
import { Box, FormHelperText, Radio } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import StyledInputBase from '../../styled/StyledInputBase';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QuantitySelector from "../../styled/QuantitySelector";
import OnboardingButtons from "./OnboardingButtons";
import DataService from "../../../services/DataService";
import EditionButtons from "../dashboard/EditionButtons";
import is from "is_js";

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Space = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const { isOnboarding, context } = props;
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const [spaceTypesOptions, setSpaceTypesOptions] = useState([]);
    const [buildingTypesOptions, setBuildingTypesOptions] = useState([]);

    const [buildingType, setBuildingType] = useState('');
    const [spaceType, setSpaceType] = useState(space?.type?.id || '');
    const [spaceQuantities, setSpaceQuantities] = useState({});

    const [errors, setErrors] = useState({});

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getBuildingTypeOptions = async () => {
            let resp = await DataService.getBuildingTypes();
            mounted && setBuildingTypesOptions(resp.data);
        }
        getBuildingTypeOptions().then(() => {
            mounted && setBuildingType(space?.buildingType?.id || '');
        });

        const getSpaceTypesOptions = async () => {
            let resp = await DataService.getSpaceTypes();
            mounted && setSpaceTypesOptions(resp.data);
        }
        getSpaceTypesOptions();

        return () => mounted = false;
    }, [space]);

    useEffect(() => {
        let sq = {};
        if (space?.type) {
            spaceTypesOptions.forEach(st => {
                space.type.id === st.id ? sq[st.id] = space.capacity : sq[st.id] = 0;
            });
            setSpaceQuantities(sq);
        } else {
            spaceTypesOptions.forEach(st => sq[st.id] = 0);
            setSpaceQuantities(sq);
        }
    }, [spaceTypesOptions, space]);

    const resetSpaceQuantities = () => {
        let sq = {};
        spaceTypesOptions.forEach(st => sq[st.id] = 0);
        setSpaceQuantities(sq);
    }

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: 250,
            padding: '10px',
            marginBottom: '20px',
        },
        questions: {
            color: '#383839',
            marginBottom: isMobile ? '16px' : '16px',
            fontSize: isMobile ? '16px' : '18px'
        },
        ...contextStyles
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(buildingType)) errorsAcum.buildingType = REQUIERED_FIELD_ERROR;
        if (is.empty(spaceType)) errorsAcum.spaceType = REQUIERED_FIELD_ERROR;
        if (spaceQuantities[spaceType] <= 0) errorsAcum.spaceQuantities = { [spaceType]: REQUIERED_FIELD_ERROR };

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getSpaceData = () => ({
        type_id: spaceType,
        building_type_id: buildingType,
        capacity: spaceQuantities[spaceType]
    });

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Espacio</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', textAlign: 'left', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ marginBottom: '50px' }}>
                    <div style={styles.questions}>¿Qué tipo de espacio vas a ofrecer?</div>
                    <FormControl style={styles.formControl} error={!!errors.buildingType}>
                        <InputLabel shrink id="building-type" style={{ marginLeft: '8px', marginTop: '5px' }}>Tipo de espacio</InputLabel>
                        <Select
                            id="building"
                            value={buildingType}
                            onChange={e => setBuildingType(e.target.value)}
                            displayEmpty
                            input={<StyledInputBase />}>
                            {buildingTypesOptions.map(bt => <MenuItem key={'bt_' + bt.id} value={bt.id}>{bt.name}</MenuItem>)}
                        </Select>
                        <FormHelperText>{errors.buildingType}</FormHelperText>
                    </FormControl>
                </Box>
                <Box>
                    <div style={styles.questions}>¿Qué le vas a disponibilizar a tus invitados?</div>
                    <FormControl style={styles.formControl} error={!!errors.spaceType}>
                        <FormHelperText>{errors.spaceType}</FormHelperText>
                        <RadioGroup name="space-type" value={spaceType} onChange={event => { resetSpaceQuantities(); setSpaceType(+event.target.value); }}>
                            {spaceTypesOptions.map(so =>
                                <div key={'so_' + so.id}>
                                    <FormControlLabel value={so.id} control={<Radio />} label={so.name} style={{ marginTop: '20px' }} />
                                    <div style={{ padding: '0px 20px 40px' }}>
                                        <div style={{ color: '#818181', fontSize: '16px', marginBottom: '15px' }}>{so.description}</div>
                                        <QuantitySelector
                                            error={errors.spaceQuantities?.[so.id]}
                                            value={spaceQuantities?.[so.id]}
                                            min={0}
                                            disabled={spaceType !== so.id}
                                            minusAction={() => setSpaceQuantities(spaceQuantities => ({ ...spaceQuantities, [so.id]: spaceQuantities[so.id] - 1 }))}
                                            plusAction={() => setSpaceQuantities(spaceQuantities => ({ ...spaceQuantities, [so.id]: spaceQuantities[so.id] + 1 }))} />
                                    </div>
                                </div>
                            )}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ?
            <OnboardingButtons stepUtils={stepUtils}
                nextFn={() => validateFormData() && stepUtils.saveSpaceAndNext(getSpaceData())}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => validateFormData() && stepUtils.saveSpaceAndClose(getSpaceData())} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={() => validateFormData() && stepUtils.updateSpace(getSpaceData())} />
        }
    </Box>;
};

export default Space;