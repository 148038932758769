import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { Checkbox } from "@material-ui/core";
import OnboardingButtons from "./OnboardingButtons";
import DataService from "../../../services/DataService";
import EditionButtons from "../dashboard/EditionButtons";

const Rules = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { context, isOnboarding } = props;
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const [rulesOptions, setRulesOptions] = useState([]);
    const [rulesSelection, setRulesSelection] = useState(space?.rules?.map(r => r.id) || []);

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getRulesOptions = async () => {
            let resp = await DataService.getSpaceRules();
            mounted && setRulesOptions(resp.data);
        }
        getRulesOptions();

        return () => mounted = false;
    }, []);

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        ...contextStyles
    }

    const ruleSelected = (checked, id) => {
        if (checked)
            setRulesSelection(currentSelection => [...currentSelection, id])
        else
            setRulesSelection(currentSelection => currentSelection.filter(i => i !== id));
    }

    const getSpaceData = () => ({
        rules: rulesSelection
    });

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Reglas de la casa</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Establecé las reglas que consideres indispensables para los trabajadores</div>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Ellos deben aceptar las reglas del espacio antes de reservar</div>
                </Box>
                <Box style={{ textAlign: 'left', color: '#383839', maxWidth: '400px' }}>
                    {rulesOptions.map((r, i) => <div style={{ marginBottom: '25px' }} key={i+'_rules_opt'}>
                        <div key={'rule_' + r.id}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{`${r.name} ${r.extra_fee? '*' : ''}`}</div><Checkbox onChange={e => ruleSelected(e.target.checked, r.id)} checked={rulesSelection.indexOf(r.id) !== -1} />
                        </div>
                        {r.extra_fee && <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '12px', textAlign: 'left' }}>* Esta regla tendrá un costo adicional del 50% del valor</div>}
                    </div>)}
                </Box>
                <Box>
                </Box>
            </Box>
        </Box>
        {isOnboarding ? <OnboardingButtons stepUtils={stepUtils}
            nextFn={() => stepUtils.saveSpaceAndNext(getSpaceData())}
            prevFn={() => stepUtils.previousStep()}
            closeFn={() => stepUtils.saveSpaceAndClose(getSpaceData())} />
            :
            <EditionButtons stepUtils={stepUtils} saveFn={() => stepUtils.updateSpace(getSpaceData())} />
        }
    </Box>;
};

export default Rules;