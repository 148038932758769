import { useAuth0 } from '@auth0/auth0-react';
import MomentUtils from '@date-io/moment';
import { Box, Button, CircularProgress, Container, createMuiTheme, FormControl, FormHelperText, MuiThemeProvider, Radio, RadioGroup, TextField } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import is from 'is_js';
import React, { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import UserContext from '../../helpers/UserContext';
import BookingService from '../../services/BookingService';
import SpaceService from '../../services/SpaceService';
import PartnerForm from '../booking/PartnerForm';
import { PaymentMethodDisplay } from '../user/onboarding/Payment';
import BookingBox from './BookingBox';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const NewBooking = () => {
    var store = require('store');

    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLessThan1100pxWidth = useMediaQuery({ query: '(max-width: 1100px)' });
    const isLessThan800pxWidth = useMediaQuery({ query: '(max-width: 800px)' });
    const { user } = useAuth0();

    const booking = store.get(`${user.sub}_new_booking`);
    const [bookingError, setBookingError] = useState('');

    const [space, setSpace] = useState({});
    const [savingBooking, setSavingBooking] = useState(false);

    const history = useHistory();

    const { fullUser } = useContext(UserContext);
    const { name, lastname, birth, identification_number } = fullUser;
    const [updatedUser, setUpdatedUser] = useState({ name, lastname, birth, identification_number });
    const emptyPartner = {
        name: '',
        lastname: '',
        birth: '',
        identification_number: ''
    }
    const [partners, setPartners] = useState(booking?.quantity > 1 ? Array.apply(null, Array(booking?.quantity - 1)).map(function () { return { ...emptyPartner } }) : []);
    const [paymentTypeSelected, setPaymentTypeSelected] = useState('');

    const [promoCode, setPromoCode] = useState('');
    const [promoCodeData, setPromoCodeData] = useState({});
    const [codeError, setCodeError] = useState('');
    const [loadingPromoCode, setLoadingPromoCode] = useState(false);


    const addPromoCode = async () => {
        setLoadingPromoCode(true);
        setPromoCodeData({});
        setCodeError('');
        try {
            const resp = await BookingService.addPromoCode(promoCode);
            setPromoCodeData(resp.data);
        } catch (e) {
            const errorCode = e.response.data.error;
            if (errorCode === 'PC02')
                setCodeError('El código ya fue utilizado.');
            else
                setCodeError('Código inválido o expirado.');
        } finally {
            setLoadingPromoCode(false);
        }
    }

    useEffect(() => {
        if (booking) {
            SpaceService.getById(booking.space_id).then(resp => {
                setSpace(resp.data);
            }).catch(e => console.log(e));
        }
        // eslint-disable-next-line
    }, []);

    const paymentSelected = (checked, id) => {
        setPaymentTypeSelected(id);
    }
    const [errors, setErrors] = useState({});

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px',
            textAlign: 'left'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isLessThan1100pxWidth ? '100%' : '45%'
        },
        paymentMethodOption: {
            border: '1px solid #D8D8D8',
            borderRadius: '3px',
            width: isMobile ? 'unset' : '450px',
            marginTop: '20px',
            display: 'flex',
            maxWidth: '94vw',
            minHeight: '67px',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
    }

    const validateFormData = () => {
        let errorsAcum = {};

        if (is.empty(paymentTypeSelected)) errorsAcum.paymentType = REQUIERED_FIELD_ERROR;

        if (is.empty(updatedUser.name)) errorsAcum.name = REQUIERED_FIELD_ERROR;
        if (is.empty(updatedUser.lastname)) errorsAcum.lastname = REQUIERED_FIELD_ERROR;
        if (is.empty(updatedUser.birth)) errorsAcum.birth = REQUIERED_FIELD_ERROR;
        if (is.empty(updatedUser.identification_number)) errorsAcum.identification_number = REQUIERED_FIELD_ERROR;

        errorsAcum.partners = [];
        partners.forEach((p, index) => {
            let partnerErrors = {};
            if (is.empty(p.name)) partnerErrors.name = REQUIERED_FIELD_ERROR;
            if (is.empty(p.lastname)) partnerErrors.lastname = REQUIERED_FIELD_ERROR;
            if (is.empty(p.birth)) partnerErrors.birth = REQUIERED_FIELD_ERROR;
            if (is.empty(p.identification_number)) partnerErrors.identification_number = REQUIERED_FIELD_ERROR;

            if (is.not.empty(partnerErrors)) errorsAcum.partners[index] = partnerErrors;
        })

        if (is.empty(errorsAcum.partners)) delete errorsAcum.partners;

        console.log(errorsAcum);
        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const confirmBooking = async () => {
        setSavingBooking(true);
        setBookingError('');
        const newBooking = {
            ...store.get(`${user.sub}_new_booking`),
            payment_method_id: paymentTypeSelected,
            partners,
            promocode_id: is.not.empty(promoCodeData) ? promoCodeData.id : 0
        };

        if (!validateFormData()) {
            setSavingBooking(false);
            return;
        }
        try {
            const resp = await BookingService.create(newBooking);
            setSavingBooking(false);
            if (paymentTypeSelected === 2) {
                const { preference_url } = resp.data;
                if (preference_url)
                    window.location.href = preference_url;
                else
                    history.push('/booking/success');
            } else {
                history.push('/booking/success');
            }
        } catch (e) {
            setSavingBooking(false);
            if (e.response?.data?.statusCode === 400) setBookingError('No es posible reservar en tu propio espacio.')
        }
    }

    const ReserveButton = () => <Button
        className="reserveButton"
        onClick={confirmBooking}
        disabled={savingBooking}
        style={{ backgroundColor: '#61257D', padding: '8px', borderRadius: '10px', width: '100%', fontFamily: 'Poppins', fontSize: '16px', textTransform: 'none', margin: '5px', maxWidth: '90vw' }}
        variant="contained">
        {savingBooking ? <CircularProgress color="secondary" /> : 'Reservar'}
    </Button>;



    return <div style={{ display: isLessThan800pxWidth ? 'unset' : 'flex', marginBottom: '15px' }}>
        <Container style={{ marginBottom: 40 }}>
            {/* PERSONAL INFO */}
            <Box>
                <Box className="onboardingForm personalInfoForm">
                    <div style={styles.onboardingTitle}>
                        <span>Datos Personales</span>
                        <Box style={{ minHeight: '20px' }}></Box>
                    </div>
                    <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between' }}>
                        <FormControl style={{ ...styles.formControl, order: 1 }}>
                            <TextField
                                id="name"
                                label="Nombre"
                                value={updatedUser.name}
                                onChange={e => { e.persist(); setUpdatedUser(u => ({ ...u, name: e.target.value })) }}
                                required
                                error={!!errors.name}
                                helperText={errors.name}
                                type="text"
                                InputLabelProps={{ shrink: true, }} />
                        </FormControl>

                        <FormControl style={{ ...styles.formControl, order: 2 }}>
                            <TextField
                                id="lastname"
                                label="Apellido"
                                value={updatedUser.lastname}
                                onChange={e => { e.persist(); setUpdatedUser(u => ({ ...u, lastname: e.target.value })) }}
                                required
                                error={!!errors.lastname}
                                helperText={errors.lastname}
                                type="text"
                                InputLabelProps={{ shrink: true, }} />
                        </FormControl>

                        <FormControl style={{ ...styles.formControl, order: 3 }}>
                            <NumberFormat id="identification_number" label="DNI"
                                value={updatedUser.identification_number}
                                onValueChange={e => setUpdatedUser(u => ({ ...u, identification_number: e.value }))}
                                customInput={TextField}
                                required
                                error={!!errors.identification_number}
                                helperText={errors.identification_number}
                                decimalSeparator={','}
                                thousandSeparator={'.'}
                                inputProps={{ min: 0 }}
                                InputLabelProps={{ shrink: true, }} />
                        </FormControl>

                        <FormControl style={{ ...styles.formControl, order: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                            <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                                <MuiThemeProvider theme={muiTheme}>
                                    <LocalizationProvider dateAdapter={MomentUtils}>
                                        <div className="birthInput">
                                            <DatePicker
                                                id="birth"
                                                label="Fecha de Nacimiento *"
                                                format="DD/MM/yyyy"
                                                disableHighlightToday={true}
                                                value={updatedUser.birth}
                                                onChange={d => setUpdatedUser(u => ({ ...u, birth: d }))}
                                                autoOk
                                                variant="inline"
                                                disableFuture={true}
                                                renderInput={(props) => <TextField
                                                    InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }}
                                                    {...props} helperText={errors.birth} error={!!errors.birth} />}
                                            />
                                        </div>
                                    </LocalizationProvider>
                                </MuiThemeProvider>
                            </FormControl>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            {is.not.empty(partners) && <Box>
                <div style={styles.onboardingTitle}>
                    <span>Datos de mis compañeros</span>
                    <Box style={{ minHeight: '20px' }}></Box>
                </div>
                {partners.map((pt, index) =>
                    <PartnerForm key={'partner_' + index}
                        partner={partners[index]}
                        index={index}
                        errors={errors?.partners ? errors.partners[index] : {}}
                        setPartner={p =>
                            setPartners(ps => {
                                let aux = [...ps];
                                aux[index] = p;
                                return aux;
                            })
                        } />
                )}
            </Box>}
            <Box style={{ marginBottom: 20, display: 'flex', flexDirection: isLessThan1100pxWidth ? 'column' : 'row' }}>
                <Box style={{ flexGrow: 1 }}>
                    <div style={styles.onboardingTitle}>
                        <span>Medio de Pago</span>
                        <Box style={{ minHeight: '20px' }}></Box>
                    </div>
                    <FormControl error={!!errors.paymentType} style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column' }}>
                        <RadioGroup value={paymentTypeSelected} style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                            {space?.payment_methods?.map(po =>
                                <div key={'pm_' + po.id} style={styles.paymentMethodOption}>
                                    <Radio onChange={e => paymentSelected(e.target.checked, po.id)} value={po.id} />
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '350px', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <PaymentMethodDisplay
                                            errors={errors}
                                            pmId={po.id}
                                            paymentTypesSelection={paymentTypeSelected}
                                            paymentSelected={paymentSelected} />
                                    </div>
                                </div>
                            )}
                        </RadioGroup>
                        <FormHelperText>{errors.paymentType}</FormHelperText>
                    </FormControl>
                </Box>
                <Box style={{ flexGrow: 1 }}>
                    <div style={styles.onboardingTitle}>
                        <span>Código Promocional</span>
                        <Box style={{ minHeight: '20px' }}></Box>
                    </div>
                    <FormControl error={!!codeError}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            <TextField
                                id="code"
                                // label="Code"
                                value={promoCode}
                                onChange={e => setPromoCode(e.target.value)}
                                style={{ marginLeft: '5px' }}
                                type="text"
                                variant="outlined"
                                InputLabelProps={{ shrink: true, }} />
                            <Button style={{ backgroundColor: '#6b2d87', color: '#fff', marginLeft: 10, padding: 14 }} disabled={loadingPromoCode} onClick={(e) => { e.preventDefault(); addPromoCode() }}>Aplicar</Button>
                            {loadingPromoCode ?
                                <CircularProgress size={25} style={{ marginLeft: 10 }} /> :
                                <>
                                    {is.not.empty(codeError) && <CloseIcon style={{ color: 'red' }} fontSize="large" />}
                                    {is.empty(codeError) && is.not.empty(promoCodeData) && <DoneIcon style={{ color: 'green' }} fontSize="large" />}
                                </>
                            }
                        </Box>
                        <FormHelperText>{codeError}</FormHelperText>
                    </FormControl>
                </Box>
            </Box>
            {!isLessThan800pxWidth && !!bookingError && <FormHelperText style={{ padding: 10 }} error={true}>{bookingError}</FormHelperText>}
            {!isLessThan800pxWidth && <ReserveButton />}
        </Container>
        <Box><div style={{ width: '312px' }}></div></Box>
        <BookingBox
            space={space}
            selectedServices={booking?.bookingSpaceServices}
            multiDate={booking?.multiple_dates}
            date={booking?.date}
            dates={booking?.dates}
            quantity={booking?.quantity}
            readOnly={true}
            topPosition={120}
            promoCodeData={promoCodeData}
        />
        {isLessThan800pxWidth && !!bookingError && <FormHelperText style={{ padding: 10 }} error={true}>{bookingError}</FormHelperText>}
        {isLessThan800pxWidth && <ReserveButton />}
    </div>;
}

export default NewBooking;

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#7E2D87'
        }
    }
});