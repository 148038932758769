import React, { forwardRef, useState } from "react";
import { Avatar, Box, Button, MenuItem, Select, TextField } from "@material-ui/core";
import { PriceDisplay } from '../../styled/PriceDisplay';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import NumberFormat from "react-number-format";
import AddBoxIcon from '@material-ui/icons/AddBox';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Employees = () => {

    const normaliseUsed = employee => +(employee.used * 100 / employee.limit).toFixed(0);

    const [data, setData] = useState([
        {
            name: 'Juan',
            lastname: 'Perez',
            mail: 'juan.perez@contoso.com',
            role: 'Dev',
            limit: 3000,
            used: 300,
            country: 'Argentina'
        },
        {
            name: 'Laura',
            lastname: 'Mendoza',
            mail: 'laura.mendoza@contoso.com',
            role: 'Dev',
            limit: 50000,
            used: 17000,
            country: 'Colombia'
        },
        {
            name: 'Hector',
            lastname: 'Larrazabal',
            mail: 'hector.l@contoso.com',
            role: 'Manager',
            limit: 50000,
            used: 2000,
            country: 'Colombia'
        },
        {
            name: 'Máximo',
            lastname: 'Cozzeti',
            mail: 'max@contoso.com',
            role: 'QA',
            limit: 700,
            used: 650,
            country: 'Estados Unidos'
        },
    ].sort((a, b) => normaliseUsed(b) - normaliseUsed(a)));
    const currenciesCountries = {
        'Colombia': 'COP',
        'Argentina': 'ARS',
        'Estados Unidos': 'USD',
    };


    return <Box style={{ background: '#FFFFFF', border: '1px solid #D8D8D8', borderRadius: '5px', padding: '15px', marginBottom: '20px' }}>
        <div style={{
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        }} >
            <span>Nómina</span>
            <Box style={{ minHeight: '20px' }}></Box>
        </div>
        <Box style={{ fontSize: 15 }}>
            <Button style={{
                backgroundColor: '#00D1BF',
                color: '#ffffff',
                fontFamily: 'Poppins',
                borderRadius: '10px',
                boxShadow: 'none',
                width: '250px',
                height: '40px',
                fontWeight: 'bold',
                marginBottom: 20
            }} variant="contained"><AddBoxIcon style={{ marginRight: 10 }} />Agregar Empleados</Button>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={[
                    {
                        title: <b>Empleado</b>, field: 'name', render: rowData => <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt={`${rowData.name} ${rowData.lastname}`} style={{ width: 40, borderRadius: '50%', marginRight: 5 }}>{`${rowData.name[0]}${rowData.lastname[0]}`}</Avatar>
                            <Box>{`${rowData.name} ${rowData.lastname}`}</Box>
                        </Box>,
                        editable: 'never'
                    },
                    { title: <b>Posición</b>, field: 'role' },
                    { title: <b>Email</b>, field: 'mail', editable: 'never' },
                    {
                        title: <b>Pais</b>, field: 'country',
                        editComponent: props => (
                            <Select
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}>
                                {['Argentina', 'Colombia', 'Estados Unidos'].map(c => <MenuItem key={c + '_curr'} value={c}>{c}</MenuItem>)}
                            </Select>
                        )
                    },
                    {
                        title: <b>Límite</b>, field: 'limit', render: rowData => <PriceDisplay value={rowData.limit} currency={currenciesCountries[rowData.country]} />,
                        editComponent: props => <NumberFormat
                                onValueChange={e => props.onChange(e.value)}
                                value={props.value}
                                customInput={TextField}
                                decimalSeparator={','}
                                thousandSeparator={'.'}
                            style={{ width: 200 }}
                            prefix={`${currenciesCountries[props.rowData.country]} `} />
                    },
                    {
                        title: <b>Uso</b>, field: 'used', render: rowData => <Box style={{ color: (rowData.used * 100 / rowData.limit) > 80 ? 'red' : '', display: 'flex', alignItems: 'center', width: 150 }}>
                            <Box style={{ marginRight: 5 }}>{normaliseUsed(rowData)}%</Box>
                            (<b><PriceDisplay value={rowData.used} currency={currenciesCountries[rowData.country]} /></b>)
                        </Box>,
                        editable: 'never'
                    },
                ]}
                data={data}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setData([...dataUpdate].sort((a, b) => normaliseUsed(b) - normaliseUsed(a)));
                                resolve();
                            }, 1000)
                        })
                }}
            />
        </Box>

    </Box>;
};

export default Employees;