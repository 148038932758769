const titlesList = {
    '^/host/onboarding': 'Configuración de Perfil de anfitrión'
}

const NavBarTitle = () => {
    let title = '';
    for (let [key, value] of Object.entries(titlesList)) {
        if (new RegExp(key).test(window.location.pathname)) {
            title = value;
            break;
        }
    }
    return title;
};

export default NavBarTitle;

