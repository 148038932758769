import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import SignupButton from './styled/SignupButton';
import LogoutButton from './styled/LogoutButton';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import NavBarTitle from '../components/NavBarTitle';
import { useHistory, useLocation } from "react-router-dom";
import HostService from '../services/HostService';
import Avatar from '@material-ui/core/Avatar';
import { useMediaQuery } from "react-responsive";
import HostDashboardMenu from "./host/HostDashboardMenu";
import LoginButton from "./styled/LoginButton";
import UserDashboardMenu from "./user/UserDashboardMenu";
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import { CircularProgress } from "@material-ui/core";
import CompanyService from "../services/CompanyService";
import CompanyDashboardMenu from "./company/CompanyDashboardMenu";

const LogoImage = (props) => <img src={require('../assets/images/logo.png')} style={{ maxHeight: props.maxHeight }} alt="" />;

const NavBar = ({ avatarPicture }) => {
    const { isAuthenticated, user, isLoading } = useAuth0();
    const location = useLocation();
    const [isHostSection, setIsHostSection] = useState(HostService.isHostSection());
    const [isDashboard, setIsDashboard] = useState(location.pathname.includes("/dashboard"));
    const [isHomeOrDashboards, setIsHomeOrDashboards] = useState(location.pathname.includes("/dashboard") || location.pathname === '/' || location.pathname === '/hostHome');

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            color: HostService.isHostSection() ? '#00D1BF' : '#7E2D87'
        },
        title: {
            flexGrow: 1,
            marginLeft: '-60px',
            paddingTop: '5px'
        },
        becameHostButton: {
            textTransform: 'none',
            color: '#383839',
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            marginRight: '5px'
        }
    }));

    const history = useHistory();
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        setIsHostSection(HostService.isHostSection());
        setIsDashboard(location.pathname.includes("/dashboard"));
        setIsHomeOrDashboards(location.pathname.includes("/dashboard") || location.pathname === '/' || location.pathname === '/hostHome');
    }, [location]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };



    const avatarClick = () => {
        let redirectTo = '/dashboard';
        if (isHostSection && HostService.hasHostRole(user))
            redirectTo = '/host/dashboard';
        history.push(redirectTo);
    };

    return <AppBar style={{ backgroundColor: '#ffffff' }} position="sticky">
        <Toolbar>

            {/* Mobile */}
            <Hidden smUp>
                <IconButton edge="start" className={classes.menuButton} aria-label="menu" onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                <div className={classes.title} >
                    <div style={{ cursor: 'pointer' }} onClick={() => history.push("/")}><LogoImage maxHeight="35px" /></div>
                </div>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} className={HostService.isHostSection() ? 'hostSection' : ''}>
                    {!isLoading &&
                        <Box style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {isAuthenticated && <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                <Avatar onClick={avatarClick} alt={user.name} style={{ width: '70px', height: '70px', cursor: 'pointer' }} src={avatarPicture} />
                            </div>}

                            {(isAuthenticated && HostService.hasHostRole(user) && isHomeOrDashboards) &&
                                <ToggleUserModeButton classes={classes} isHostSection={isHostSection} isDashboard={isDashboard} />
                            }


                            {(!isHostSection && !HostService.hasHostRole(user)) && <Button onClick={() => { history.push("/hostHome"); setDrawerOpen(false); }} className={classes.becameHostButton}>Convertite en anfitrión</Button>}


                            {(location.pathname === "/hostHome" || location.pathname === "/") && <Button onClick={() => { history.push("/companyHome"); setDrawerOpen(false); }} className={classes.becameHostButton}>Modo empresas</Button>}

                            {(
                                (!isAuthenticated && (location.pathname === "/hostHome" || location.pathname === "/companyHome")) ||
                                (isAuthenticated && (location.pathname === "/companyHome"))
                            ) && <Button onClick={() => { history.push("/"); setDrawerOpen(false); }} className={classes.becameHostButton}>Modo trabajador</Button>}

                            {isAuthenticated && (location.pathname === "/companyHome") && <>
                                <Button onClick={() => { history.push("/hostHome"); setDrawerOpen(false); }} className={classes.becameHostButton}>Modo anfitrión</Button>
                            </>}

                            {(isAuthenticated && isMobile) && <>
                                {(HostService.hasHostRole(user) && isHostSection) ?
                                    <HostDashboardMenu closeDrawer={() => setDrawerOpen(false)} />
                                : CompanyService.isCompanySection() ?
                                    <CompanyDashboardMenu closeDrawer={() => setDrawerOpen(false)} />
                                    :
                                    <UserDashboardMenu closeDrawer={() => setDrawerOpen(false)} />}
                            </>}

                            <br />

                            {!isAuthenticated && <LoginButton />}

                            {isAuthenticated ? <LogoutButton /> : <SignupButton />}
                        </Box>
                    }
                </Drawer>
            </Hidden>

            {/* Desktop */}
            <Hidden xsDown>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', paddingTop: '5px' }} >
                    <div style={{ cursor: 'pointer' }} onClick={() => history.push("/")}><LogoImage maxHeight="45px" /></div>
                    <div style={{ color: '#383839' }}> <NavBarTitle /> </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        {(location.pathname === "/hostHome" || location.pathname === "/") && <>
                            <Button onClick={() => { history.push("/companyHome"); setDrawerOpen(false); }} className={classes.becameHostButton}>Modo empresas</Button>
                            <Box style={{ borderLeft: '1px solid #383839', height: '45px' }}></Box>
                        </>}

                        {(
                            (!isAuthenticated && (location.pathname === "/hostHome" || location.pathname === "/companyHome")) ||
                            (isAuthenticated && (location.pathname === "/companyHome"))
                        ) && <>
                                <Button onClick={() => { history.push("/"); setDrawerOpen(false); }} className={classes.becameHostButton}>Modo trabajador</Button>
                                <Box style={{ borderLeft: '1px solid #383839', height: '45px' }}></Box>
                            </>
                        }

                        {isAuthenticated && (location.pathname === "/companyHome") && <>
                            <Button onClick={() => { history.push("/hostHome"); setDrawerOpen(false); }} className={classes.becameHostButton}>Modo anfitrión</Button>
                        </>}

                        {(!isHostSection && !HostService.hasHostRole(user)) && <Button onClick={() => history.push("/hostHome")} className={classes.becameHostButton}>Convertite en anfitrión</Button>}
                        {isLoading ?
                            <Avatar style={{ marginLeft: '20px', cursor: 'pointer' }}><CircularProgress size={20} /></Avatar>
                            :
                            <div style={{
                                borderLeft: (!isAuthenticated && !isHostSection) ? '1px solid #383839' : '',
                                paddingLeft: (!isAuthenticated && !isHostSection) ? '5px' : 0,
                                display: 'flex', alignItems: 'center'
                            }}>
                                {(isAuthenticated && HostService.hasHostRole(user) && isHomeOrDashboards) &&
                                    <ToggleUserModeButton classes={classes} isHostSection={isHostSection} isDashboard={isDashboard} />
                                }
                                {!isAuthenticated && <LoginButton />}
                                {isAuthenticated ? <LogoutButton /> : <SignupButton />}
                                {isAuthenticated && <>
                                    <Avatar onClick={avatarClick} alt={user.name} src={avatarPicture} style={{ marginLeft: '20px', cursor: 'pointer' }} />
                                </>}
                            </div>
                        }
                    </div>
                </div>
            </Hidden>

        </Toolbar>
    </AppBar >;
};

export default NavBar;


const ToggleUserModeButton = ({ classes, isHostSection, isDashboard }) => {
    const history = useHistory();

    const toggleUserMode = () => {
        let redirectTo = isHostSection ? '/' : '/hostHome';
        if (isDashboard)
            redirectTo = isHostSection ? '/dashboard' : '/host/dashboard';

        history.push(redirectTo);
    }

    return <Button onClick={toggleUserMode} className={classes.becameHostButton} style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px'
    }}>
        <span style={{
            color: !isHostSection ? '#00D1BF' : '#7E2D87',
            marginRight: '8px',
            fontSize: '14px',
            textTransform: 'none'
        }}>
            Modo {!isHostSection ? 'anfitrión' : 'trabajador'}
        </span>
        <SwapCallsIcon style={{ color: 'grey' }} />
    </Button>;
}

