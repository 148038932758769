import { withStyles } from '@material-ui/core/styles';
import { Switch } from "@material-ui/core";

const StyledSwitch = withStyles({
    switchBase: {
        color: 'lightgray',
        '&$checked': {
            color: '#00D1BF',
        },
        '&$checked + $track': {
            backgroundColor: '#00D1BF',
        },
    },
    checked: {},
    track: {},
})(Switch);

export default StyledSwitch;