import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import HostService from "../../../services/HostService";
import HostSpaceService from "../../../services/HostSpaceService";
import { useAuth0 } from "@auth0/auth0-react";
import { authorize } from '../../../helpers/authAxios';

const StepUtils = (stepsOrder, updateProgress, refreshHost, refreshSpace) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const history = useHistory();
    const { user, getAccessTokenSilently } = useAuth0();
    const hostId = HostService.getHostId(user);

    // host api calls
    const createHost = async (newHostData) => {
        try {
            let newHostId = (await HostService.createHost(newHostData)).data;
            sessionStorage.setItem(user.sub, newHostId);
            authorize(await getAccessTokenSilently({ ignoreCache: true }));
            return newHostId;
        } catch (e) {
            throw new Error(e);
        }
    };
    const updateHost = async (updatedHostData) => await HostService.updateHost(hostId, updatedHostData).catch(e => {throw new Error(e)});

    // space api calls
    const createSpace = async (spaceData) => {
        try {
            let newSpaceId = (await HostSpaceService.createSpace(hostId, spaceData)).data;
            sessionStorage.setItem(user.sub+'_space', newSpaceId);
            authorize(await getAccessTokenSilently({ ignoreCache: true }));
            return newSpaceId;
        } catch (e) {
            throw new Error(e);
        }
    }
    const updateSpace = async (updatedSpaceData) => await HostSpaceService.updateSpace(hostId, updatedSpaceData).catch(e => {throw new Error(e)});
    const setAvailability = async (spaceId, availability) => await HostSpaceService.setAvailability(hostId, spaceId, availability).catch(e => {throw new Error(e)});

    // true if current step number is equal to the value passed as parameter
    const isStep = (stepNum) => (stepsOrder.indexOf(window.location.pathname) === stepNum);

    // true if is the last step
    const isLastStep = isStep(stepsOrder.length - 1);

    // true if is the first step
    const isFirstStep = isStep(0);

    // true if is the second step
    const isSecondStep = isStep(1)

    // true if is the previous of the last step (second counting from behind)
    const isPenultimateStep = isStep(stepsOrder.length - 2);

    // true if is the last step and is on mobile
    const isLastStepAndMobile = isLastStep && isMobile;

    // navigate to next step
    const nextStep = () => {
        goToStep(stepsOrder.indexOf(window.location.pathname) + 1);
        updateProgress();
    };

    // ====================== HOST ======================
    // decide to post or patch host data
    const saveOrUpdateHost = async (hostNewData) => {
        if (hostId <= 0) {
            let newHostId = await createHost(hostNewData);
            console.log('newHostId', newHostId);
            //await refreshHost(newHostId);
        } else {
            await updateHost(hostNewData);
            //await refreshHost(hostId);
        };
    };
    // save host data and go to next step
    const saveHostAndNext = async (hostNewData) => {
        await saveOrUpdateHost(hostNewData);
        nextStep();
    };
    // save host data and close onboarding
    const saveHostAndClose = async (hostNewData) => {
        await saveOrUpdateHost(hostNewData);
        history.push('/host/dashboard');
    };

    // ====================== SPACE ======================
    // decide to post or patch space data
    const saveOrUpdateSpace = async (spaceData) => {
        let spaceId = HostService.getCurrentSpaceId(user); 
        if (spaceId <= 0) {
            let newSpaceId = await createSpace(spaceData);
            console.log('newSpaceId', newSpaceId);
            //await refreshSpace(hostId, newSpaceId);
        } else {
            await updateSpace({id: spaceId, ...spaceData});
            //await refreshSpace(hostId, space.id);
        };
    };
    // save space data and go to next step
    const saveSpaceAndNext = async (spaceData) => {
        await saveOrUpdateSpace(spaceData);
        nextStep();
    };
    // save space data and close onboarding
    const saveSpaceAndClose = async (spaceData) => {
        await saveOrUpdateSpace(spaceData);
        sessionStorage.removeItem(user?.sub + '_space');
        history.push('/host/dashboard/publications');
    };


    // ====================== SPACE AVAILABILITY ======================
    // save space data and go to next step
    const saveAvailabilityAndNext = async (availabilies) => {
        let spaceId = HostService.getCurrentSpaceId(user); 
        availabilies.space_id = spaceId;
        await setAvailability(spaceId, availabilies);
        nextStep();
    };
    // save space data and close onboarding
    const saveAvailabilityAndClose = async (availabilies) => {
        await setAvailability(availabilies);
        history.push('/host/dashboard/publications');
    };

    // back to previous step
    const previousStep = async () => {
        await refreshHost(hostId);
        await refreshSpace(hostId);
        goToStep(stepsOrder.indexOf(window.location.pathname) - 1);
        updateProgress();
    };

    // got to step passed as parameter
    const goToStep = (stepIndex) => {
        history.push(stepsOrder[stepIndex]);
    };

    return {
        isLastStep,
        isFirstStep,
        isSecondStep,
        isPenultimateStep,
        isLastStepAndMobile,
        nextStep,
        saveHostAndNext,
        saveHostAndClose,
        saveSpaceAndNext,
        saveSpaceAndClose,
        saveAvailabilityAndNext,
        saveAvailabilityAndClose,
        previousStep,
        goToStep,
        saveOrUpdateHost,
        saveOrUpdateSpace,
    };
};

export default StepUtils;