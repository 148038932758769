import authAxios from '../helpers/authAxios';
import UserService from './UserService';

const getHostIdFromClaims = user => {
    let hostId = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}host_id`];
    if (!hostId) hostId = sessionStorage.getItem(user?.sub);
    if (!hostId) hostId = 0;
    return hostId;
}

const HostService = {

    isHostSection: () => window.location.pathname.startsWith("/host"),

    hasHostRole: user => {
        let roles = user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}roles`];
        let isHost = roles?.includes('host');
        if (!isHost) isHost = sessionStorage.getItem(user?.sub) || false;
        return isHost;
    },

    getHostId: getHostIdFromClaims,

    getCurrentSpaceId: (user) => sessionStorage.getItem(user?.sub + '_space') || 0,

    getById: (id) => {
        console.log(`HostService.getById(${id})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${id}`;
        return authAxios.get(url);
    },

    getSpaceByHostId: (hostId) => {
        console.log(`HostService.getSpaceByHostId(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space`;
        return authAxios.get(url);
    },

    postImage: (user, base64) => {
        let hostId = getHostIdFromClaims(user);
        console.log(`HostService.postImage('${hostId}')`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/image`;
        return authAxios.post(url, {
            base64,
            user_id: UserService.getUserId(user)
        });
    },

    createHost: (host) => {
        console.log(`HostService.createHost(host)`);
        const url = `${process.env.REACT_APP_API_URL}/host`;
        return authAxios.post(url, host);
    },

    deleteHost: (hostId) => {
        console.log(`HostService.deleteHost(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}`;
        return authAxios.delete(url);
    },

    updateHost: (hostId, hostData) => {
        console.log(`HostService.updateHost(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}`;
        return authAxios.patch(url, hostData);
    },

    getBookingsByHostId: (hostId) => {
        console.log(`HostService.getBookingsByHostId(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/search`;
        return authAxios.post(url, {});
    },

    getBookingsDashboard: (hostId) => {
        console.log(`HostService.getBookingsDashboard(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/dashboard`;
        return authAxios.post(url, {});
    },

    getPayments: (hostId) => {
        console.log(`HostService.getPayments(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/payment`;
        return authAxios.get(url);
    },

    getReferreds: (hostId) => {
        console.log(`HostService.getReferreds(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/referred`;
        return authAxios.get(url);
    },

    getBookings: (hostId) => {
        console.log(`HostService.getBookings(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/search`;
        return authAxios.post(url, {});
    },

    cancelBooking: (hostId, bookingId) => {
        console.log(`HostService.cancelBooking(${hostId}, ${bookingId})`);
        return changeBookingStatus(hostId, bookingId, 3)
    },

    approveBooking: (hostId, bookingId) => {
        console.log(`HostService.approveBooking(${hostId}, ${bookingId})`);
        return changeBookingStatus(hostId, bookingId, 2)
    },

    getReviews: (hostId) => {
        console.log(`HostService.getReviews(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/review`;
        return authAxios.get(url);
    },

    getUserReviews: (hostId,bookingId) => {
        console.log(`HostService.getUserReviews(${hostId}, ${bookingId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/${bookingId}/review`;
        return authAxios.get(url);
    },

    getFilterUsers: (hostId) => {
        console.log(`HostService.getFilterUsers(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/filter/user`;
        return authAxios.get(url);
    },

    getFilterServices: (hostId) => {
        console.log(`HostService.getFilterServices(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/filter/service/additional`;
        return authAxios.get(url);
    },

    getFilteredBookings: (hostId,data) => {
        console.log(`HostService.getFilteredBookings(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/calendar/search`;
        return authAxios.post(url,data);
    },

    getSpaceAvailability: (hostId,spaceId) => {
        console.log(`HostService.getSpaceAvailability(${hostId})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/space/${spaceId}/availability`;
        return authAxios.get(url);
    },

    massiveAvailabilityEdit: (host_id, added, removed, space_ids) => {
        console.log(`HostService.massiveAvailabilityEdit(${host_id}, ${space_ids.toString()})`);
        const url = `${process.env.REACT_APP_API_URL}/host/${host_id}/spaces/availability`;
        return authAxios.patch(url, {
            added,
            removed,
            host_id,
            space_ids
        });
    },

}

const changeBookingStatus = (hostId, bookingId, newStatus) => {
    const url = `${process.env.REACT_APP_API_URL}/host/${hostId}/bookings/${bookingId}/status`;
    return authAxios.patch(url, {
        host_id: hostId,
        booking_id: bookingId,
        host_status: newStatus
    });
}

export default HostService;