import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import UserService from '../../../services/UserService';
import PublicatioHomeResult from '../../publication/PublicatioHomeResult';

const Favourites = () => {
    const [favs, setFavs] = useState([]);
    const { user } = useAuth0();
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    useEffect(() => {
        UserService.getFavourites(UserService.getUserId(user)).then(resp => {
            setFavs(resp.data)
        }).catch(e => console.log(e));
    }, [user]);

    const goToPublication = (spaceId) => {
        const newWindow = window.open(`/publication/${spaceId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    return <Container>
        <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: isMobile ? 'center' : 'left' }}>
            {favs.map(f => <PublicatioHomeResult space={f} key={f.id + '_space_pub'} clickAction={() => goToPublication(f.id)} />)}
        </Box>
    </Container>
}

export default Favourites;