import React from "react";
import { Box, Button } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import { useMediaQuery } from 'react-responsive';
import Container from '@material-ui/core/Container';
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import HomeImage from '../../assets/images/host-home.png';
import WhatsappButton from "../WhatsappButton";

const tutorialData = [
    {
        title: 'Publicá tu espacio gratis',
        img: require('../../assets/images/tutorial-01.png'),
        text: 'Compartí cualquier tipo de espacio sin gastar nada en registrarte: podés ofrecer desde el lugar donde trabajas hasta una segunda vivienda o el quincho de tu casa'
    },
    {
        title: 'Elegí como querés hospedar',
        img: require('../../assets/images/tutorial-02.png'),
        text: 'Elegí tus propios horarios, precios y requisitos para los huéspedes. Te vamos a acompañar en cada paso'
    },
    {
        title: '¡Dales la bienvenida!',
        img: require('../../assets/images/tutorial-03.png'),
        text: 'Cuando tu espacio esté publicado (¡no te olvides compartirlo en tu redes!), los compañeros de trabajo que cumplan los requisitos van a poder encontrarlo, leé nuestros consejos, prepará tu espacio y comenzá a recibirlos'
    },
];

const adviceData = [
    {
        title: '¡Las reglas las ponés vos!',
        items: [
            'Elegí el horario que vos quieras para recibir compañeros de trabajo',
            'Definí las normas de convivencia que vos desees',
            'Seleccioná la forma de recibir dinero que más te convenga',
        ]
    },
    {
        title: '¡Algunos consejos para ser buen anfitrión!',
        items: [
            'Atraé a compañeros de trabajo con fotos de calidad',
            'Recibí a los nuevos invitados con un obsequio de bienvenida (puede ser un rico cafe)',
            'Recordá que siempre es agradable estar en un ambiente limpio y cómodo',
            'Hacelos sentir como es su casa, así querrán volver',
        ]
    },
];

const paymentProcessData = [
    {
        title: 'Vos decidís cuánto cobrar',
        text: 'Vos ponés el precio siempre. Si necesitas ayuda avisanos y recordá que la naturaleza es que sea más accesible que una oficina o coworking'
    },
    {
        title: 'Pagás tarifas bajas',
        text: `Registrarte es totalmente gratuito. 
        En general, Worknmates cobra a los anfitriones una tarifa fija del 5 % por reserva`
    },
    {
        title: 'Recibí tu dinero de inmediato',
        text: 'Cuando el huésped haya hecho el check-in, podemos enviarte el dinero en cualquiera de las formas disponible de manera inmediata'
    },
    {
        title: 'Código único',
        text: 'Ganá dinero extra compartiendo e invitando nuevos anfitriones a la plataroma, no olvides decirles que pongan tu codigo asi recibiras dinero por cada anfitrión que refieras'
    },

];

const HostHome = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLessThan1500pxWidth = useMediaQuery({ query: '(max-width: 1500px)' });
    const history = useHistory();

    return <>
        <Container>
            <WhatsappButton />
            <Box style={{
                overflow: 'hidden',
                zIndex: -1,
                backgroundImage: `url(${HomeImage})`,
                backgroundSize: 'cover',
                height: '30vw',
                maxHeight: '400px',
                minHeight: '250px',
                borderRadius: '15px',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ ...styles.homeText, fontSize: isMobile ? '24px' : isLessThan1500pxWidth? '3.5vw' : '50px', lineHeight: isMobile ? '24px' : isLessThan1500pxWidth? '3.5vw' : '50px', }}>Ganá dinero extra</div>
                    <div style={{ ...styles.homeText, fontSize: isMobile ? '24px' : isLessThan1500pxWidth? '3.5vw' : '50px', lineHeight: isMobile ? '24px' : isLessThan1500pxWidth? '3.5vw' : '50px', }}>alquilando tu hogar</div>
                    <div style={{ ...styles.homeText, fontSize: isMobile ? '24px' : isLessThan1500pxWidth? '3.5vw' : '50px', lineHeight: isMobile ? '24px' : isLessThan1500pxWidth? '3.5vw' : '50px', }}>por jornada laboral</div>
                </div>
                <br />
                <FormControl style={styles.formControl}>
                    <Button onClick={() => history.push("/host")} variant="contained" style={{ ...styles.startButton, fontSize: isMobile ? '16px' : '20px' }}>Comenzar</Button>
                </FormControl>
            </Box>


            <Box>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    {adviceData.map((a, index) => <AdvicesList key={`advice_${index}`} {...a} isMobile={isMobile} />)}
                </div>
            </Box>
            <br />
            <br />
            <Box>
                <div style={{ marginTop: '60px', fontSize: '30px', display: 'flex', justifyContent: 'center', color: '#383839', fontFamily: 'PoppinsBold' }}>Cómo ser anfitrión en 3 simples pasos</div>
            </Box>
            <Box>
                <div style={isMobile ? styles.tutorialMobile : styles.tutorial}>
                    {tutorialData.map((t, index) => isMobile ?
                        <TutorialStepMobile key={`tutorial_${index}`} {...t} /> :
                        <TutorialStep key={`tutorial_${index}`} {...t} />
                    )}
                </div>
            </Box>
            <br />
            <br />
            <Box>
                <div style={{ marginTop: '60px', fontSize: '30px', display: 'flex', justifyContent: 'center', color: '#383839', fontFamily: 'PoppinsBold' }}>Proceso de pagos sencillos</div>
            </Box>
            <Box>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    {paymentProcessData.map((p, index) => <PaymentProcessTemplate key={`payment_${index}`} {...p} isMobile={isMobile} />)}
                </div>
            </Box>
        </Container>

        <Footer />
    </>;
};

export default HostHome;

const AdvicesList = ({ title, items, isMobile }) => <div style={{ flexGrow: 1, maxWidth: isMobile ? '95%' : '40%', minWidth: '275px', textAlign: 'left', color: '#383839', marginTop: '30px' }}>
    <div style={{ fontWeight: 'bold', fontSize: '22px' }}>{title}</div>
    <ul style={{ margin: '10px 0 0 -20px', fontSize: '16px' }}>
        {items.map((i, index) => <li key={`adviceList_${index}`} style={{ marginTop: '10px' }}>{i}</li>)}
    </ul>
</div>;

const TutorialStep = ({ title, img, text }) => <div style={{ flexGrow: 1, color: '#383839', marginTop: '30px', maxWidth: '30%', minWidth: '180px' }}>
    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{title}</div>
    <img src={img} alt="" style={{ width: '15vw', minWidth: '150px', maxWidth: '250px' }} />
    <div style={{ fontSize: '16px' }}>{text}</div>
</div>;

const TutorialStepMobile = ({ title, img, text }) => <div style={{ flexGrow: 1, color: '#383839', marginTop: '30px' }}>
    <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
        <img src={img} alt="" style={{ width: '15vw', minWidth: '150px', maxWidth: '250px', margin: '10px' }} />
        <div style={{ fontWeight: 'bold', fontSize: '16px', maxWidth: '200px', marginLeft: '10px' }}>{title}</div>
    </div>
    <div style={{ fontSize: '16px', textAlign: 'left', margin: '5px' }}>{text}</div>
</div>;

const PaymentProcessTemplate = ({ title, text, isMobile }) => <div style={isMobile ? styles.paymentAdviceMobile : styles.paymentAdvice}>
    <div style={{ fontWeight: 'bold', fontSize: '20px', whiteSpace: 'nowrap' }}>{title}</div>
    <div style={{ fontSize: '16px', marginTop: '15px' }}>{text}</div>
</div>;

const styles = {
    title: {
        width: '300%',
        height: '5vw',
        fontFamily: 'PoppinsBold',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '3.3vw',
        color: '#383839',
        zIndex: 99,
        marginLeft: '-40%'
    },
    titleMobile: {
        fontFamily: 'PoppinsBold',
        fontSize: '28px',
        marginTop: '10%',
        color: '#383839',
    },
    formControl: {
        padding: '10px',
        marginBottom: '20px',
        flexShrink: 1,
        minWidth: '135px',
    },
    homeText: {
        fontFamily: 'Poppins',
        color: '#ffffff',
        backgroundColor: 'rgb(0, 209, 191, 0.47)',
        width: 'fit-content',
        margin: '5px'
    },
    startButton: {
        backgroundColor: '#00D1BF',
        color: '#ffffff',
        fontFamily: 'Poppins',
        borderRadius: '10px',
        boxShadow: 'none',
        width: '20vw',
        minWidth: '200px',
        height: '50px',
        fontWeight: 'bold'
    },
    tutorial: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
    },
    tutorialMobile: {
        display: 'flex',
        flexDirection: 'column'
    },
    paymentAdvice: {
        flexGrow: 1,
        maxWidth: '20%',
        minWidth: '275px',
        textAlign: 'center',
        color: '#383839',
        margin: '30px 5px 5px 5px',
    },
    paymentAdviceMobile: {
        flexGrow: 1,
        maxWidth: '95%',
        minWidth: '275px',
        textAlign: 'left',
        color: '#383839',
        marginTop: '40px',
    }
};