import React, { useState, useContext, useEffect } from "react";
import { Checkbox, Box, Button, CircularProgress, Chip, ListItemText, MuiThemeProvider, createMuiTheme, FormHelperText, FormControlLabel } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '../../styled/StyledTextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useMediaQuery } from "react-responsive";
import StyledInputBase from '../../styled/StyledInputBase';
import OnboardingButtons from "./OnboardingButtons";
import DataService from "../../../services/DataService";
import { useAuth0 } from "@auth0/auth0-react";
import NumberFormat from 'react-number-format';
import is from "is_js";
import DropzoneArea from "../../uploader/DropzoneArea";
import MuiPhoneInput from 'material-ui-phone-number';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider, DatePicker } from '@material-ui/pickers';
import { Link } from "react-router-dom";
import UserService from "../../../services/UserService";

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';
const WRONG_DATE_FORMAT_ERROR = 'Fecha inválida.';

const PersonalInfo = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLessThan400pxWidth = useMediaQuery({ query: '(max-width: 400px)' });
    const isLessThan1100pxWidth = useMediaQuery({ query: '(max-width: 1100px)' });
    const { isOnboarding, context } = props;
    const { styles: contextStyles, stepUtils, fullUser } = useContext(context);
    const { user } = useAuth0();

    // form data
    const [langOptions, setLangOptions] = useState([]);
    const [nationalityOptions, setNationalityOptions] = useState([]);
    const [genderOptions, setGenderOptions] = useState([]);
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [invalidBirth, setInvalidBirth] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const [updateUserLoading, setUpdateUserLoading] = useState(false);
    const [isPhotoHover, setPhotoHover] = useState(false);

    // user data
    const [avatar, setAvatar] = useState(fullUser?.avatar || '');
    const [name, setName] = useState(fullUser?.name || user?.given_name || '');
    const [lastname, setLastname] = useState(fullUser?.lastname || user?.family_name || '');
    const [identification_number, setIdNumber] = useState(fullUser?.identification_number || '');
    const [birth, setBirth] = useState(fullUser?.birth || '');
    const [gender, setGender] = useState('');
    const [languages, setLanguages] = useState([]);
    const [nationality, setNationality] = useState('');
    const [phone, setPhone] = useState(fullUser?.phone || '');
    const [email, setEmail] = useState(user?.email || fullUser?.email || '');

    useEffect(() => {
        const userGivenName = fullUser?.name || user?.given_name || '';

        setAvatar(fullUser?.avatar || '');
        setName(is.email(userGivenName) ? '' : userGivenName);
        setLastname(fullUser?.lastname || user?.family_name || '');
        setIdNumber(fullUser?.identification_number || '');
        setBirth(fullUser?.birth || '');
        setGender('');
        setLanguages([]);
        setNationality('');
        setPhone(fullUser?.phone || '');
        setEmail(user?.email || fullUser?.email || '');
    }, [fullUser, user]);

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getLangsOptions = async () => {
            let resp = await DataService.getLanguages();
            mounted && setLangOptions(resp.data);
        }
        getLangsOptions().then(() => {
            mounted && setLanguages(fullUser?.languages?.map(l => l.id) || []);
        });

        const getNationalityOptions = async () => {
            let resp = await DataService.getNationalities();
            mounted && setNationalityOptions(resp.data);
        }
        getNationalityOptions().then(() => {
            mounted && setNationality(fullUser?.nationality?.id || '');
        });

        const getGenderOptions = async () => {
            let resp = await DataService.getGenders();
            mounted && setGenderOptions(resp.data);
        }
        getGenderOptions().then(() => {
            mounted && setGender(fullUser?.gender?.id || '');
        });

        return () => mounted = false;
    }, [fullUser])

    const avatarSelected = (images) => {
        if (images[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(images[0]);
            reader.onload = async function () {
                setAvatarLoading(true);
                let resp = await UserService.postImage(UserService.getUserId(user), reader.result)
                setAvatar(resp.data);
                setAvatarLoading(false);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    const AvatarPreview = () => {
        let resp = 'agregar foto';
        if (is.empty(avatar)) resp = avatarLoading ? '' : 'agregar foto';
        if (is.string(avatar) && is.not.empty(avatar)) resp = null;
        return resp;
    }

    const updateUser = async (userData) => {
        try {
            setUpdateUserLoading(true);
            await UserService.updateUser(UserService.getUserId(user), userData);
        } catch (e) {
            console.log(e);
        } finally {
            setUpdateUserLoading(false)
        }
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(name)) errorsAcum.name = REQUIERED_FIELD_ERROR;
        if (is.empty(lastname)) errorsAcum.lastname = REQUIERED_FIELD_ERROR;
        if (is.empty(identification_number)) errorsAcum.identification_number = REQUIERED_FIELD_ERROR;
        if (invalidBirth)
            errorsAcum.birth = WRONG_DATE_FORMAT_ERROR;
        else if (is.empty(birth)) errorsAcum.birth = REQUIERED_FIELD_ERROR;

        if (is.empty(nationality)) errorsAcum.nationality = REQUIERED_FIELD_ERROR;
        if (is.empty(phone.replace('+', ''))) errorsAcum.phone = REQUIERED_FIELD_ERROR;
        if (is.empty(email)) errorsAcum.email = REQUIERED_FIELD_ERROR;
        if (is.empty(avatar)) errorsAcum.avatar = REQUIERED_FIELD_ERROR;
        if (isOnboarding && is.falsy(termsAccepted)) errorsAcum.termsAccepted = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getUserData = () => {
        let userData = {
            name,
            lastname,
            identification_number,
            birth,
            gender,
            languages,
            nationality,
            phone,
            email,
            authentication_provider_id: user.sub
        };

        if (is.string(avatar) && is.not.empty(avatar))
            userData.avatar = avatar;

        return userData;
    };

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isLessThan1100pxWidth ? '100%' : '45%'
        },
        ...contextStyles
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm personalInfoForm">
            <div style={styles.onboardingTitle}>
                <span>Datos Personales</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', justifyContent: isMobile ? 'center' : 'space-between' }}>
                {(isOnboarding && !isLessThan400pxWidth) &&
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px', maxWidth: '50%' }}>
                        <div style={{ color: '#6D7278', opacity: 0.7 }}>Worknmates solo proporciona los datos de contacto de los anfitriones y los huespedes cuando se ha confirmado la reservación.</div>
                    </Box>
                }
                <FormControl error={!!errors.avatar} style={{ display: 'flex', alignItems: 'center' }}>
                    <Box onMouseEnter={() => setPhotoHover(true)}
                        onMouseLeave={() => setPhotoHover(false)}
                        style={{
                            height: '135px',
                            width: '135px',
                            borderRadius: '135px',
                            backgroundColor: 'lightgrey',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#383839',
                            textTransform: 'none',
                            overflow: 'hidden',
                            position: 'relative',
                            opacity: isPhotoHover ? 0.5 : 1
                        }}>
                        {avatarLoading && <div style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 99,
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#ffffff',
                            opacity: 0.5
                        }}><CircularProgress /></div>}
                        <DropzoneArea onChange={avatarSelected}
                            showAlerts={false}
                            previewGridProps={{ item: { style: { padding: '0' } } }}
                            filesLimit={1}
                            maxFileSize={5000000}
                            dropzoneText={(avatar?.url) ? <img src={avatar?.url} alt="" style={{ maxWidth: '160px', maxHeight: '160px' }} /> : <AvatarPreview />} />
                    </Box>
                    <FormHelperText>{errors.avatar}</FormHelperText>
                </FormControl>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between' }}>
                <FormControl style={{ ...styles.formControl, order: 1 }}>
                    <TextField
                        id="name"
                        label="Nombre"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        error={!!errors.name}
                        helperText={errors.name}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 2 }}>
                    <TextField
                        id="lastname"
                        label="Apellido"
                        value={lastname}
                        onChange={e => setLastname(e.target.value)}
                        required
                        error={!!errors.lastname}
                        helperText={errors.lastname}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 3 }}>
                    <NumberFormat id="identification_number" label="DNI"
                        value={identification_number}
                        onValueChange={e => setIdNumber(e.value)}
                        customInput={TextField}
                        required
                        error={!!errors.identification_number}
                        helperText={errors.identification_number}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        inputProps={{ min: 0 }}
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                    <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                        <MuiThemeProvider theme={muiTheme}>
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <div className="birthInput">
                                    <DatePicker
                                        id="birth"
                                        label="Fecha de Nacimiento *"
                                        format="DD/MM/yyyy"
                                        disableHighlightToday={true}
                                        value={birth}
                                        onChange={d => setBirth(d)}
                                        autoOk
                                        variant="inline"
                                        disableFuture={true}
                                        onError={reason => reason ? setInvalidBirth(true) : setInvalidBirth(false)}
                                        renderInput={(props) => <TextField
                                            InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }}
                                            {...props} helperText={errors.birth} error={!!errors.birth} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </MuiThemeProvider>
                    </FormControl>
                    <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 0 0 10px', height: '48px' }}>
                        <InputLabel shrink id="sex-label">Sexo</InputLabel>
                        <Select id="sex" value={gender} onChange={e => setGender(e.target.value)} displayEmpty input={<StyledInputBase />}>
                            {genderOptions.sort((a, b) => a.order - b.order).map(go => <MenuItem key={go.id + '_gender'} value={go.id}>{go.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 5 }} error={!!errors.nationality}>
                    <InputLabel shrink id="nationality-label" style={{ marginLeft: '8px', marginTop: '5px' }}>Nacionalidad *</InputLabel>
                    <Select
                        id="nationality"
                        value={nationality}
                        onChange={e => setNationality(e.target.value)}
                        displayEmpty
                        required
                        input={<StyledInputBase />}>
                        {nationalityOptions.sort((a, b) => a.order - b.order).map(no => <MenuItem key={no.id + '_nation'} value={no.id}>{no.name}</MenuItem>)}
                    </Select>
                    <FormHelperText>{errors.nationality}</FormHelperText>
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 6 }}>
                    <InputLabel shrink id="language-label" style={{ marginLeft: '8px', marginTop: '5px' }}>Idiomas</InputLabel>
                    <Select id="language"
                        value={languages}
                        onChange={e => setLanguages(e.target.value)}
                        displayEmpty
                        multiple
                        input={<StyledInputBase />}
                        renderValue={(selected) => (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {selected.map(value => (
                                    <Chip key={'lang_sel_' + value} label={(langOptions.find(e => e.id === value) || {}).name} style={{ margin: 2 }} />
                                ))}
                            </div>
                        )}>
                        {langOptions.map(l => <MenuItem key={'lang_' + l.code} value={l.id}>
                            <Checkbox checked={languages.includes(l.id)} />
                            <ListItemText primary={l.name} />
                        </MenuItem>
                        )}
                    </Select>
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 7 }}>
                    <MuiPhoneInput
                        id="phone"
                        required
                        error={!!errors.phone}
                        helperText={errors.phone}
                        autoFormat={false}
                        disableAreaCodes={true}
                        defaultCountry={'ar'}
                        label="Teléfono"
                        value={phone}
                        onChange={setPhone} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 8 }}>
                    <TextField
                        id="email"
                        value={email}
                        InputProps={{ readOnly: true }}
                        onChange={e => setEmail(e.target.value)}
                        label="Correo electrónico"
                        required
                        error={!!errors.email}
                        helperText={errors.email}
                        type="email"
                        InputLabelProps={{ shrink: true, }}
                    />
                </FormControl>

                {isOnboarding && <FormControl style={{ ...styles.formControl, order: 10 }} error={!!errors.termsAccepted}>
                    <FormControlLabel
                        control={<Checkbox
                            onChange={e => setTermsAccepted(e.target.checked)}
                            checked={termsAccepted} />}
                        label={
                            <div style={{ color: '#383839', textAlign: 'left' }}>
                                <span>Estoy de acuerdo con la </span>
                                <Link to="/privacy" target="_blank" style={{ color: '#7E2D87', textDecoration: 'none' }}>Política de Privacidad</Link>
                                <span> y con los </span>
                                <Link to="/terms" target="_blank" style={{ color: '#7E2D87', textDecoration: 'none' }}>Términos de Uso</Link>.
                            </div>
                        } />
                    <FormHelperText>{errors.termsAccepted}</FormHelperText>
                </FormControl>}
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        {isOnboarding ?
            <OnboardingButtons stepUtils={stepUtils}
                nextFn={() => validateFormData() && stepUtils.saveUserAndNext(getUserData())}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => validateFormData() && stepUtils.saveUserAndClose(getUserData())} />
            : <div style={{ width: '100%', textAlign: 'end' }}>
                <Button onClick={() => validateFormData() && updateUser(getUserData())}
                    variant="contained"
                    disabled={updateUserLoading}
                    style={{
                        backgroundColor: '#7E2D87',
                        color: '#ffffff',
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        width: isMobile ? '100%' : '168px',
                        height: '50px',
                        fontWeight: 'bold',
                        marginBottom: isMobile ? '10px' : '0'
                    }}>{updateUserLoading ? <CircularProgress color="secondary" /> : 'Guardar'}</Button>
            </div>}
    </Box>
};

export default PersonalInfo;

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#7E2D87'
        }
    }
});