import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';
import HostService from '../../services/HostService';
import DataService from "../../services/DataService";


const SignupButton = () => {
    const { loginWithRedirect } = useAuth0();
    const { isHostSection } = HostService;
    const buttonColor = isHostSection()? '#00D1BF' : '#61257D';

    return <Button onClick={() => loginWithRedirect({ redirectUri: DataService.getRedirectUri(), screen_hint: 'signup' })} variant="outlined" style={{
        borderRadius: '10px', 
        borderColor: buttonColor, 
        color: buttonColor, 
        width: '140px',
        height: '50px',
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    }}>Registrate</Button>;
};

export default SignupButton;