import React, { useContext, useEffect, useState } from "react";
import { Box, Tooltip } from "@material-ui/core";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import useHover from "../../hooks/useHover";
import Skeleton from '@material-ui/lab/Skeleton';
import { useMediaQuery } from "react-responsive";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import is from "is_js";
import { useInView } from "react-hook-inview";
import UserService from "../../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from "../../helpers/UserContext";
import SpaceTitle from "./SpaceTitle";
import SpaceCalification from "./SpaceCalification";
var momentBussinesDays = require('moment-business-days');

const MAX_SERVICES = 6;

const PublicatioResult = ({ space, clickAction, hoverAction, isSelected, mini = false, selectCurrent, dateFrom, dateTo }) => {
    const isLessThan900pxWitdth = useMediaQuery({ query: '(max-width: 900px)' });
    const isLessThan1050pxWitdth = useMediaQuery({ query: '(max-width: 1050px)' });
    const isLessThan1200pxWitdth = useMediaQuery({ query: '(max-width: 1200px)' });

    const [dailyPrice, setDailyPrice] = useState(0);

    const { user } = useAuth0();

    const { userFavourites, setUserFavourites } = useContext(UserContext);
    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        setIsFavourite(userFavourites.includes(space?.id));
    }, [space, userFavourites]);

    useEffect(() => {
        if (space) {
            const weekdaysSearchCount = momentBussinesDays(dateFrom, 'YYYY-MM-DD').businessDiff(momentBussinesDays(dateTo, 'YYYY-MM-DD')) + 1;

            let discountToApply = space.discount_percentage || 0;
            if (weekdaysSearchCount >= 5 && space.discount_week_percentage) discountToApply = space.discount_week_percentage;
            if (weekdaysSearchCount >= 22 && space.discount_month_percentage) discountToApply = space.discount_month_percentage;

            let dailyPriceToShow = 0;
            if (space.price > 0) dailyPriceToShow = (Math.round(space.price - space.price * discountToApply / 100));

            setDailyPrice(dailyPriceToShow);
        }
    }, [space, dateFrom, dateTo]);

    const toggleFavourites = () => {
        const userId = UserService.getUserId(user);
        if (isFavourite)
            UserService.removeFavourite(userId, space.id)
                .then(resp => {
                    setUserFavourites(uf => uf.filter(f => f !== space.id));
                }).catch(e => console.log(e));
        else
            UserService.addFavourite(userId, space.id)
                .then(resp => {
                    setUserFavourites(uf => [...uf, space.id]);
                }).catch(e => console.log(e));
    }

    const [favoriteIconRef, favoriteIconHovered] = useHover();
    const [pubRef, pubHovered] = useHover();
    const [pubRefInView, isPubInView] = useInView({
        threshold: 0.9,
    });

    const spaceServices = space?.services?.filter(s => !s.service.important) || [];
    const totalServicesCount = spaceServices.length;

    const [displayedServices, setDisplayedServices] = useState(spaceServices.sort(sortServices).slice(0, MAX_SERVICES));

    const handleDisplayedServices = (e) => {
        e.stopPropagation();
        if (displayedServices.length === MAX_SERVICES)
            setDisplayedServices(spaceServices.sort(sortServices));
        else
            setDisplayedServices(spaceServices.sort(sortServices).slice(0, MAX_SERVICES));
    };

    useEffect(() => {
        selectCurrent();
        // eslint-disable-next-line
    }, [isPubInView]);

    if (!space) return <Box style={{ width: '300px', maxWidth: '80vw', margin: '10px' }}>
        <Skeleton variant="rect" width={'100%'} height={200} style={{ borderRadius: '10px' }} />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
    </Box>;

    return <div ref={pubRefInView}>

        <Box ref={pubRef}
            style={{
                height: mini ? '130px' : 'auto',
                cursor: 'pointer',
                maxWidth: isLessThan900pxWitdth ? mini ? '80vw' : '' : '80vw',
                margin: '10px',
                textAlign: 'left',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                border: isSelected ? '2px solid #7E2D87' : '1px solid #D8D8D8',
                borderRadius: '15px',
                backgroundColor: '#ffffff',
                width: mini ? '500px' : ''
            }}>

            {!isLessThan1200pxWitdth && (space.rating && parseInt(space.rating) > 0) && <Box style={{ border: '1px solid #383839', borderRadius: 10, position: 'absolute', right: 0, margin: 10, padding: 10 }} >
                <SpaceCalification space={space} showText={true} mini={false} />
            </Box>}
            {space.services.filter(s => s.service.important).map(s =>
                <div key={s.id_ + '_srv'} style={{
                    top: mini ? -9 : 8,
                    left: -8,
                    fontFamily: 'PoppinsBold',
                    color: '#ffffff',
                    backgroundColor: '#00D1BF',
                    padding: mini ? '2px 5px' : '5px 10px',
                    fontSize: mini ? '9px' : '12px',
                    position: 'absolute',
                    zIndex: 99,
                    borderRadius: '15px',
                    maxWidth: mini ? '100px' : 'unset',
                    textAlign: 'center'
                }}>
                    {s.service.name}
                </div>
            )}
            <div style={{ display: 'flex' }} onClick={clickAction} onMouseEnter={hoverAction}>
                <div style={{ minHeight: mini ? '130px' : '200px', borderRadius: '13px', overflow: 'hidden', flexBasis: '50%', maxWidth: isLessThan1050pxWitdth ? mini ? '130px' : '200px' : '250px', minWidth: isLessThan1050pxWitdth ? mini ? '130px' : '200px' : '250px', WebkitBackfaceVisibility: 'hidden', MozBackfaceVisibility: 'hidden', WebkitTransform: 'translate3d(0, 0, 0)', MozTransform: 'translate3d(0, 0, 0)' }}>
                    <div style={{
                        height: '100%',
                        width: '100%',
                        backgroundImage: `url(${space.images?.[0]?.original_url})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        transform: pubHovered ? 'scale(1.1)' : 'scale(1)',
                        transition: ' all .5s'
                    }}>
                        <div style={{ borderRadius: '10px', backgroundImage: 'linear-gradient(45deg, transparent, rgb(0,0,0,0.3) 250%)', width: '100%', height: '100%' }}></div>
                    </div>
                </div>
                <div style={{ padding: mini ? 10 : '15px', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div>
                        <div style={{ fontFamily: 'Poppins', fontSize: mini ? '14px' : '16px', color: '#383839', fontWeight: 500 }}><SpaceTitle space={space} /></div>
                        <div style={{ fontFamily: 'Poppins', fontSize: mini ? '12px' : '14px', color: '#383839', marginBottom: 5 }}>{space?.building_type}</div>
                        {isLessThan1200pxWitdth && <SpaceCalification space={space} showText={true} mini={mini || isLessThan1200pxWitdth} />}
                    </div>
                    {!mini && <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {displayedServices.map((s, i) => <Tooltip key={i + '_ss'} placement="top" title={s.service.name}>
                            <img src={s.service?.image?.url} alt={s.service?.name || ''} style={{ margin: '5px', height: '20px', width: '20px', objectFit: 'scale-down' }} />
                        </Tooltip>)}
                        {(is.not.empty(space.services) && totalServicesCount > MAX_SERVICES) &&
                            <Tooltip title={displayedServices.length === MAX_SERVICES ? `Mostrar más` : 'Mostrar menos'} placement="top">
                                <MoreHorizIcon style={{ padding: '0px', width: '32px' }} onClick={(e) => handleDisplayedServices(e)}></MoreHorizIcon>
                            </Tooltip>
                        }
                    </div>}
                    <div style={{ fontFamily: 'Poppins', fontSize: mini ? '12px' : '16px', color: '#383839' }}>
                        <span style={{ fontFamily: 'PoppinsBold', fontStyle: 'normal', fontWeight: 'bold', fontSize: mini ? '16px' : '22px', color: '#383839' }}>{`$ ${dailyPrice} `}</span>
                        <span>por jornada</span>
                    </div>
                </div>
                {!mini && <div ref={favoriteIconRef}
                    onClick={toggleFavourites}
                    style={{ position: 'absolute', right: 0, cursor: 'pointer', bottom: 0 }} >
                    {isFavourite ?
                        <BookmarkIcon style={{ fontSize: '42px', color: favoriteIconHovered ? 'grey' : '#6B2D87', transition: '0.3s' }} /> :
                        <BookmarkBorderIcon style={{ fontSize: '42px', color: favoriteIconHovered ? 'grey' : '#6B2D87', transition: '0.3s' }} />
                    }
                </div>}
            </div>
        </Box>
    </div>
};

export default PublicatioResult;

const sortServices = (a, b) => a.service.order - b.service.order;