import MomentUtils from '@date-io/moment';
import { Box, createMuiTheme, FormControl, MuiThemeProvider, TextField } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useMediaQuery } from 'react-responsive';

const PartnerForm = ({ partner, setPartner, index, errors = {} }) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLessThan1100pxWidth = useMediaQuery({ query: '(max-width: 1100px)' });

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isLessThan1100pxWidth ? '100%' : '45%'
        },
    }

    return <Box style={{ marginBottom: '20px' }}>
        <Box style={{ textAlign: 'left', fontWeight: '600' }}>Compañero/a #{index + 1}</Box>
        <Box className="onboardingForm personalInfoForm">
            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isNarrowWidth ? 'center' : 'space-between' }}>
                <FormControl style={{ ...styles.formControl, order: 1 }}>
                    <TextField
                        className="name"
                        label="Nombre"
                        value={partner.name}
                        onChange={e => setPartner({ ...partner, name: e.target.value })}
                        required
                        error={!!errors.name}
                        helperText={errors.name}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 2 }}>
                    <TextField
                        className="lastname"
                        label="Apellido"
                        value={partner.lastname}
                        onChange={e => setPartner({ ...partner, lastname: e.target.value })}
                        required
                        error={!!errors.lastname}
                        helperText={errors.lastname}
                        type="text"
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 3 }}>
                    <NumberFormat className="identification_number" label="DNI"
                        value={partner.identification_number}
                        onValueChange={e => setPartner({ ...partner, identification_number: e.value })}
                        customInput={TextField}
                        required
                        error={!!errors.identification_number}
                        helperText={errors.identification_number}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        inputProps={{ min: 0 }}
                        InputLabelProps={{ shrink: true, }} />
                </FormControl>

                <FormControl style={{ ...styles.formControl, order: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                    <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                        <MuiThemeProvider theme={muiTheme}>
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <div className="birthInput">
                                    <DatePicker
                                        classNames="birth"
                                        label="Fecha de Nacimiento *"
                                        format="DD/MM/yyyy"
                                        disableHighlightToday={true}
                                        value={partner.birth}
                                        onChange={d => setPartner({ ...partner, birth: d })}
                                        autoOk
                                        variant="inline"
                                        disableFuture={true}
                                        renderInput={(props) => <TextField
                                            InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }}
                                            {...props} helperText={errors.birth} error={!!errors.birth} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </MuiThemeProvider>
                    </FormControl>
                </FormControl>
            </Box>
        </Box>
    </Box>;
}

export default PartnerForm;

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#7E2D87'
        }
    }
});