import React, { useContext, useEffect, useRef, useState } from 'react';
import TextField from '../styled/StyledTextField';
import { LocalizationProvider, DateRangePicker, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { PriceDisplay } from "../styled/PriceDisplay";
import { Box, Button, MuiThemeProvider, createMuiTheme, Select, MenuItem, Switch, withStyles } from "@material-ui/core";
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import is from 'is_js';
import SpaceService from '../../services/SpaceService';
import UserContext from '../../helpers/UserContext';
import moment from 'moment';
import { useInView } from 'react-hook-inview';
var momentBussinesDays = require('moment-business-days');

const BookingBox = ({ space, selectedServices, date, quantity, readOnly = false, rightPosition = 10, topPosition = 80, promoCodeData = {}, multiDate = false, dates = [] }) => {
    const isLessThan500pxWitdth = useMediaQuery({ query: '(max-width: 500px)' });
    const isLessThan800pxWitdth = useMediaQuery({ query: '(max-width: 800px)' });

    const [reservationDate, setReservationDate] = useState('');
    const [reservationDateRange, setReservationDateRange] = useState([null, null]);

    const [dailyPrice, setDailyPrice] = useState(Math.round(space?.price - space?.price * space.discount_percentage / 100) || 0);

    const [availabilitiesOnDateRange, setAvailabilitiesOnDateRange] = useState([]);
    const [reservationDateAvailability, setReservationDateAvailability] = useState(space?.capacity || 1);
    const [availabilities, setAvailabilities] = useState([]);
    const [isMultiDate, setIsMultiDate] = useState(multiDate);

    const [reservationQuantity, setReservationQuantity] = useState(quantity || 1);

    // ======== totals calc functions ========
    const calcUsersTotal = () => {
        let usersCount = space?.type?.partial_booking ? reservationQuantity : 1;
        let datesCount = is.not.empty(availabilitiesOnDateRange) ? availabilitiesOnDateRange.length : 1;
        return usersCount * (space?.price || 0) * datesCount;
    }
    const calcDiscountTotal = () => {
        let usersCount = space?.type?.partial_booking ? reservationQuantity : 1;
        let datesCount = is.not.empty(availabilitiesOnDateRange) ? availabilitiesOnDateRange.length : 1;
        let discountToApply = space?.discount_percentage / 100 || 0;
        if (availabilitiesOnDateRange.length >= 5 && space?.discount_week_percentage) discountToApply = space?.discount_week_percentage / 100 || 0;
        if (availabilitiesOnDateRange.length >= 22 && space?.discount_month_percentage) discountToApply = space?.discount_month_percentage / 100 || 0;
        return datesCount * usersCount * (space?.price || 0) * discountToApply;
    }
    const calcAdditionalServicesTotal = () => {
        let usersCount = space?.type?.partial_booking ? reservationQuantity : 1;
        let datesCount = is.not.empty(availabilitiesOnDateRange) ? availabilitiesOnDateRange.length : 1;
        return selectedServices?.reduce((acc, cur) => acc + cur.price * usersCount * datesCount, 0)
    }
    const calcFee = () => {
        let usersCount = space?.type?.partial_booking ? reservationQuantity : 1;
        let datesCount = is.not.empty(availabilitiesOnDateRange) ? availabilitiesOnDateRange.length : 1;
        return usersCount * space?.fee * datesCount;
    }
    // =================


    const [usersTotal, setUsersTotal] = useState(calcUsersTotal());
    const [discountTotal, setDiscountTotal] = useState(calcDiscountTotal());
    const [additionalServicesTotal, setAdditionalServicesTotal] = useState(calcAdditionalServicesTotal());
    const [fee, setFee] = useState(calcFee());

    const [dateError, setDateError] = useState(false);
    const [actualPromocodeDiscount, setActualPromocodeDiscount] = useState(0);
    const [promocodeWarning, setPromocodeWarning] = useState(false);

    const [finalPrice, setFinalPrice] = useState(0);

    const location = useLocation();
    const isPublication = location.pathname.startsWith("/publication");
    const [reservBoxRef, reserveInView] = useInView();
    const [showFixedReserve, setShowFixedReserve] = useState(isPublication && isLessThan500pxWitdth && !reserveInView);

    const { fullUser } = useContext(UserContext);

    useEffect(() => {

        const calcAllBookingDates = (availabs) => {

            if (readOnly) return dates;

            const datesSelection = [];
            const availableDates = availabs?.map(a => a.date);
            for (let m = reservationDateRange[0]; m.diff(reservationDateRange[1], 'days') < 1; m.add(1, 'days')) {
                let d = m.format('YYYY-MM-DD');
                if (availableDates?.includes(d) && !datesSelection.includes(d)) {
                    datesSelection.push(d)
                }
            }

            // hack to make it work
            if (datesSelection[datesSelection.length - 1] &&
                reservationDateRange[1] &&
                datesSelection[datesSelection.length - 1] !== reservationDateRange[1].format('YYYY-MM-DD') &&
                !datesSelection.includes(reservationDateRange[1].format('YYYY-MM-DD')))
                datesSelection.push(reservationDateRange[1].format('YYYY-MM-DD'));

            return datesSelection;
        }

        if (reservationDateRange[0] && reservationDateRange[1]) {
            if (is.empty(availabilities) && space && space.id) {
                SpaceService.getAvailabilities(space?.id, reservationQuantity).then(resp => {
                    const spaceAvailabilities = resp.data;
                    setAvailabilities(spaceAvailabilities);
                    setAvailabilitiesOnDateRange(calcAllBookingDates(spaceAvailabilities));
                }).catch(e => console.log(e));
            } else {
                setAvailabilitiesOnDateRange(calcAllBookingDates(availabilities));
            }
        }
        // eslint-disable-next-line
    }, [reservationDateRange, availabilities]);

    useEffect(() => {
        setPromocodeWarning(false);
        const promoDiscount = (is.not.empty(promoCodeData) ? promoCodeData?.amount : 0);
        const final = usersTotal + additionalServicesTotal + fee - discountTotal - promoDiscount;

        if (final < 0) {
            setFinalPrice(0);
            setActualPromocodeDiscount(promoDiscount + final);
            setPromocodeWarning(true);
        } else {
            setActualPromocodeDiscount(promoDiscount)
            setFinalPrice(final);
        }
    }, [usersTotal, additionalServicesTotal, space, reservationQuantity, discountTotal, promoCodeData, fee]);

    const [lastDateSelected, setLastDateSelected] = useState(date);
    // eslint-disable-next-line
    const [lastDateRangeSelected, setLastDateRangeSelected] = useState(() => {
        let start = dates[0] ? moment(dates[0], 'YYYY-MM-DD') : null;
        let end = dates[dates.length - 1] ? moment(dates[dates.length - 1], 'YYYY-MM-DD') : null;
        return [start, end];
    });
    const history = useHistory();

    useEffect(() => {
        setUsersTotal(calcUsersTotal());
        setDiscountTotal(calcDiscountTotal());
        setAdditionalServicesTotal(calcAdditionalServicesTotal());
        lastDateSelected && setReservationDate(lastDateSelected);

        // hack to make it work, maybe a component bug
        lastDateRangeSelected[0] && setReservationDateRange([moment(lastDateRangeSelected[0]?.format('YYYY-MM-DD'), 'YYYY-MM-DD'), moment(lastDateRangeSelected[1]?.format('YYYY-MM-DD'), 'YYYY-MM-DD')]);

        // eslint-disable-next-line
    }, [space, selectedServices, date, dates]);

    useEffect(() => {
        if (space && availabilitiesOnDateRange[0] && availabilitiesOnDateRange[availabilitiesOnDateRange.length - 1]) {
            const weekdaysSearchCount = momentBussinesDays(availabilitiesOnDateRange[0], 'YYYY-MM-DD').businessDiff(momentBussinesDays(availabilitiesOnDateRange[availabilitiesOnDateRange.length - 1], 'YYYY-MM-DD')) + 1;

            let discountToApply = 0;
            discountToApply = space.discount_percentage;
            if (weekdaysSearchCount >= 5) discountToApply = space.discount_week_percentage;
            if (weekdaysSearchCount >= 22) discountToApply = space.discount_month_percentage;

            let dailyPriceToShow = 0;
            if (space.price > 0) dailyPriceToShow = (Math.round(space.price - space.price * discountToApply / 100));

            setDailyPrice(dailyPriceToShow);
        }
    }, [space, availabilitiesOnDateRange]);


    useEffect(() => {
        setUsersTotal(calcUsersTotal());
        setDiscountTotal(calcDiscountTotal());
        setAdditionalServicesTotal(calcAdditionalServicesTotal());
        setFee(calcFee());
        // eslint-disable-next-line
    }, [availabilitiesOnDateRange]);

    useEffect(() => {
        setUsersTotal(calcUsersTotal());
        setDiscountTotal(calcDiscountTotal());
        setAdditionalServicesTotal(calcAdditionalServicesTotal());
        setFee(calcFee());
        space && space.id && SpaceService.getAvailabilities(space?.id, reservationQuantity).then(resp => {
            const spaceAvailabilities = resp.data;
            setAvailabilities(spaceAvailabilities);
            if (isMultiDate) {
                setReservationDateRange([null, null]);
                setAvailabilitiesOnDateRange([]);
            } else {
                const selectedDate = (!reservationDate || is.empty(reservationDate)) ? lastDateSelected : reservationDate;
                if (spaceAvailabilities.filter(a => (a.date === selectedDate?.format('YYYY-MM-DD') && reservationQuantity <= a.availableSpace)).length > 0) {
                    setReservationDate(selectedDate);
                } else {
                    setLastDateSelected(reservationDate);
                    setReservationDate(null);
                }
            }
        }).catch(e => console.log(e));

        // eslint-disable-next-line
    }, [reservationQuantity]);

    useEffect(() => {
        const selectedDateString = is.string(reservationDate) ? reservationDate : reservationDate?.format('YYYY-MM-DD');
        let filtered = availabilities.filter(a => a.date === selectedDateString);

        if (is.empty(filtered))
            setReservationDateAvailability(space?.capacity || 1);
        else
            setReservationDateAvailability(filtered[0].availableSpace);

        // eslint-disable-next-line
    }, [availabilities, reservationDate]);

    useEffect(() => {
        setReservationDateRange([null, null]);
        setAvailabilitiesOnDateRange([]);
        setReservationDate('');
    }, [isMultiDate]);

    const reserve = () => {
        var store = require('store');

        setDateError(false);
        if ((!reservationDate || is.empty(reservationDate)) && is.all.falsy(...reservationDateRange)) {
            setDateError(true);
            return;
        }

        const newBooking = {
            multiple_dates: isMultiDate,
            quantity: +reservationQuantity,
            space_id: space.id,
            bookingSpaceServices: selectedServices?.map(ss => ({
                ...ss,
                quantity: +reservationQuantity
            })),
            back_urls: {
                success: `${window.location.origin}/booking/success`,
                failure: `${window.location.origin}/dashboard`,
                pending: `${window.location.origin}/dashboard`
            }
        };

        if (isMultiDate)
            newBooking.dates = availabilitiesOnDateRange;
        else
            newBooking.date = is.string(reservationDate) ? reservationDate : reservationDate.format('YYYY-MM-DD');

        store.set(`new_booking`, newBooking);
        history.push("/booking/new");
    }

    const scrollToBookingBox = () => {
        window.scrollTo(0, bookingBoxRef.current?.offsetTop - 100);
    }
    useEffect(() => {
        setShowFixedReserve(isPublication && isLessThan500pxWitdth && !reserveInView)
    }, [reserveInView, isLessThan500pxWitdth, isPublication]);
    const bookingBoxRef = useRef(null);

    return <div style={{ display: 'flex', justifyContent: 'center' }} ref={reservBoxRef}>
        <div ref={bookingBoxRef}>


            {showFixedReserve && <Box style={{ height: '50px', position: 'fixed', left: 0, bottom: '0px', backgroundColor: '#ffffff', display: 'flex', zIndex: 9997, width: '92vw', padding: '15px', borderTop: '1px solid lightgray', justifyContent: 'space-around', alignItems: 'center' }}>
                <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839', padding: '8px' }}>
                    <span style={{ fontFamily: 'PoppinsBold', fontStyle: 'normal', fontWeight: 'bold', fontSize: '25px', color: '#383839' }}>{`$ ${dailyPrice} `}</span>
                    <span>/ por jornada</span>
                </div>
                <Button
                    className="reserveButton"
                    onClick={scrollToBookingBox}
                    style={{ backgroundColor: '#61257D', padding: '8px', height: '40px', width: '110px', borderRadius: '10px', fontFamily: 'Poppins', fontSize: '16px', textTransform: 'none' }}
                    variant="contained">Reservar</Button>
            </Box>}


            <Box className="publicationSide" style={{ position: isLessThan800pxWitdth ? 'unset' : 'fixed', right: rightPosition, top: topPosition, zIndex: 999, border: '1px solid #D8D8D8', borderRadius: '10px', textAlign: 'left', width: isLessThan500pxWitdth ? '92vw' : '312px', backgroundColor: '#ffffff' }}>
                <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#383839', padding: '12px' }}>
                    <span style={{ fontFamily: 'PoppinsBold', fontStyle: 'normal', fontWeight: 'bold', fontSize: '25px', color: '#383839' }}>{`$ ${dailyPrice} `}</span>
                    <span>/ por jornada</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ borderTop: '1px solid #D8D8D8', borderBottom: '1px solid #D8D8D8', borderRight: '1px solid #D8D8D8', padding: '12px', flexBasis: '100%' }}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ fontFamily: 'PoppinsBold', fontSize: '16px' }}>Fecha</div>
                            {!readOnly && <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                <div style={{ fontSize: 12, color: 'grey' }}>Rango</div>
                                <StyledSwitch
                                    checked={isMultiDate}
                                    onChange={e => { setIsMultiDate(e.target.checked); setReservationDate(''); setReservationDateRange([null, null]) }}
                                    color="primary"
                                    name="active"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </Box>}
                        </Box>
                        <div>
                            <MuiThemeProvider theme={muiTheme}>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <div className="birthInput">
                                        {isMultiDate ? <DateRangePicker
                                            id="reservationDate"
                                            format="DD/MM/yyyy"
                                            disableHighlightToday={true}
                                            value={reservationDateRange}
                                            onChange={d => setReservationDateRange(d)}
                                            autoOk
                                            shouldDisableDate={d => {
                                                let disabled = true;
                                                let availableDates = availabilities?.map(a => a.date);
                                                if (availableDates?.includes(d.format('YYYY-MM-DD'))) {
                                                    disabled = false;
                                                }
                                                return disabled;
                                            }}
                                            variant="inline"
                                            disablePast={true}
                                            disabled={readOnly}
                                            startText=""
                                            endText=""
                                            renderInput={(startProps, endProps) => <>
                                                <TextField InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }} {...startProps} helperText={''} error={dateError} variant="standard" style={isLessThan500pxWitdth ? { marginBottom: 10, width: '95%' } : { marginLeft: 10, width: '40%' }} />
                                                <Box style={{ fontWeight: 600 }}> al </Box>
                                                <TextField InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }} {...endProps} helperText={''} error={dateError} variant="standard" style={isLessThan500pxWitdth ? { marginTop: 10, width: '95%' } : { marginLeft: 10, width: '40%' }} />
                                            </>}
                                        />
                                            :
                                            <DatePicker
                                                id="reservationDate"
                                                format="DD/MM/yyyy"
                                                disableHighlightToday={true}
                                                value={reservationDate}
                                                onChange={d => setReservationDate(d)}
                                                autoOk
                                                shouldDisableDate={d => {
                                                    let disabled = true;
                                                    let availableDates = availabilities?.map(a => a.date);
                                                    if (availableDates?.includes(d.format('YYYY-MM-DD'))) {
                                                        disabled = false;
                                                    }
                                                    return disabled;
                                                }}
                                                variant="inline"
                                                disablePast={true}
                                                disabled={readOnly}
                                                renderInput={(props) => <TextField InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }} {...props} helperText={''} error={dateError} />}
                                            />}
                                    </div>
                                </LocalizationProvider>
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #D8D8D8', padding: '14px', flexBasis: '100%' }}>
                        <div style={{ fontFamily: 'PoppinsBold', fontSize: '16px' }}>Personas</div>
                        <div className="reservQty">
                            {readOnly ?
                                <TextField
                                    value={reservationQuantity}
                                    id="reservQty"
                                    type="number"
                                    style={{ width: '100%' }}
                                    disabled={true}
                                    InputLabelProps={{ shrink: true }} />
                                :
                                <Select
                                    value={reservationQuantity}
                                    onChange={e => setReservationQuantity(e.target.value)}
                                    id="reservQty"
                                    style={{ width: '100%' }}
                                    disabled={readOnly}>
                                    {Array.from({ length: reservationDateAvailability }, (_, i) => i + 1).map(i => <MenuItem value={+i} key={i + '_item_q'}>{i}</MenuItem>)}
                                </Select>
                            }
                        </div>
                    </div>
                </div>


                {isMultiDate ?
                    <div style={{ borderBottom: '1px solid #D8D8D8', padding: '14px 0px', flexBasis: '100%', textAlign: 'center', fontSize: 12, color: '#383839' }}>
                        {is.not.empty(availabilitiesOnDateRange) ? `${availabilitiesOnDateRange.length} jornadas seleccionadas` : 'Seleccione una rango de fechas'}
                    </div>
                    :
                    <>
                        {!readOnly &&
                            <div style={{ borderBottom: '1px solid #D8D8D8', padding: '14px 0px', flexBasis: '100%', textAlign: 'center', fontSize: 12, color: '#383839' }}>
                                {reservationDate ? `${reservationDateAvailability} lugares disponibles` : 'Seleccione una fecha para ver la disponibilidad'}
                            </div>
                        }
                    </>
                }

                {!readOnly && <div style={{ borderBottom: '1px solid #D8D8D8', padding: '15px', flexBasis: '100%' }}>
                    <Button
                        disabled={fullUser.status === 4}
                        className="reserveButton"
                        onClick={reserve}
                        style={{ backgroundColor: fullUser.status === 4 ? 'lightgrey' : '#61257D', padding: '8px', borderRadius: '10px', width: '100%', fontFamily: 'Poppins', fontSize: '16px', textTransform: 'none' }}
                        variant="contained">{fullUser.status === 4 ? 'Usuario bloqueado' : 'Reservar'}</Button>
                </div>}

                <div style={{ fontSize: '14px', padding: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '28px' }}>
                        <div>{is.not.empty(availabilitiesOnDateRange) ? `${availabilitiesOnDateRange.length}` : 1} jornada, por persona ({reservationQuantity})</div>
                        <div><PriceDisplay value={usersTotal} /></div>
                    </div>
                    {discountTotal > 0 &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '28px' }}>
                        <div>
                            <span>Descuento (</span>
                            <span>{
                                (is.not.empty(availabilitiesOnDateRange) && availabilitiesOnDateRange.length >= 22 && space?.discount_month_percentage) ?
                                    `mensual ${space?.discount_month_percentage}%`
                                    :
                                    (is.not.empty(availabilitiesOnDateRange) && availabilitiesOnDateRange.length >= 5 && space?.discount_week_percentage) ?
                                        `semanal ${space?.discount_week_percentage}%`
                                        :
                                        `${space?.discount_percentage}%`
                            }
                            </span>
                            <span>)</span>
                        </div>
                            <div><PriceDisplay value={-discountTotal} /></div>
                        </div>
                    }

                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '28px' }}>
                        <div>Costos de servicio</div>
                        <div><PriceDisplay value={fee} /></div>
                    </div>


                    {is.not.empty(selectedServices) &&
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '28px' }}>
                                <div>Servicios Adicionales</div>
                                <div><PriceDisplay value={additionalServicesTotal} /></div>
                            </div>
                            {selectedServices?.map(s => <div key={s.space_service_id} style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '28px' }}>
                                <div className="addedService">{s.service.name} ({reservationQuantity * (is.not.empty(availabilitiesOnDateRange) ? availabilitiesOnDateRange.length : 1)})</div>
                                <div className="addedService"><PriceDisplay value={s.price * reservationQuantity * (is.not.empty(availabilitiesOnDateRange) ? availabilitiesOnDateRange.length : 1)} /></div>
                            </div>)}
                        </div>
                    }

                    {is.not.empty(promoCodeData) &&
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '28px' }}>
                                <div>Código promocional</div>
                                <div><PriceDisplay value={-actualPromocodeDiscount} /></div>
                            </div>
                            {promocodeWarning && <div style={{ fontSize: 12, color: '#383839', margin: 10 }}>
                                <span>El código <b>{promoCodeData.coupon}</b> descuenta hasta un máximo de </span>
                                <b><PriceDisplay value={promoCodeData.amount} /></b>,
                            <span> si no lo utilizas en su totalidad, el saldo pendiente se pierde.</span>
                            </div>}
                        </div>
                    }

                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', fontWeight: '500', borderTop: '1px solid #D8D8D8', lineHeight: '40px' }}>
                        <div>Total</div>
                        <div><PriceDisplay value={finalPrice} /></div>
                    </div>
                </div>
            </Box>
        </div>
    </div>;
}

export default BookingBox;

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#7E2D87'
        }
    }
});

const StyledSwitch = withStyles({
    switchBase: {
        color: 'lightgray',
        '&$checked': {
            color: '#61257D',
        },
        '&$checked + $track': {
            backgroundColor: '#61257D',
        },
    },
    checked: {},
    track: {},
})(Switch);