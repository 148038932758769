import { Box, Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BookingService from '../../../services/BookingService';
import StyledTextField from '../../styled/StyledTextField';
import Rating from '@material-ui/lab/Rating';
import { useMediaQuery } from 'react-responsive';
import UserService from '../../../services/UserService';
import HostService from '../../../services/HostService';
import { useAuth0 } from '@auth0/auth0-react';

const ratingLabels = {
    1: 'Horrible',
    2: 'Pésima',
    3: 'Muy Mala',
    4: 'Mala',
    5: 'Aceptable',
    6: 'Buena',
    7: 'Buena',
    8: 'Muy buena',
    9: 'Muy buena',
    10: 'Excelente',
};

const BookingReview = () => {
    const { bookingId, userType } = useParams();

    const [booking, setBooking] = useState({});
    const [comment, setComment] = useState('');
    const [rating, setRating] = React.useState('');
    
    const [hover, setHover] = React.useState(-1);
    const [sendingReview, setSendingReview] = useState(false);
    
    const history = useHistory();
    const { user } = useAuth0();
    
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
    const [isHost, setIsHost] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    
    const getBookingDataForHost = () => {
        setLoadingData(true);
        BookingService.getByIdAndHostId(HostService.getHostId(user), bookingId).then(resp => {
            setBooking(resp.data);
            setLoadingData(false);
        });
    };
    
    const getBookingDataForUser = () => {
        setLoadingData(true);
        BookingService.getByIdAndUserId(UserService.getUserId(user), bookingId).then(resp => {
            setBooking(resp.data);
            setLoadingData(false);
        });
    };
    const getBookingFn = userType === "H" ? getBookingDataForHost : getBookingDataForUser;
    
    useEffect(() => {
        getBookingFn();
        // eslint-disable-next-line
    }, [bookingId, user]);

    useEffect(() => {
        setIsHost(userType === "H");
    }, [userType]);

    const sendReview = () => {
        setSendingReview(true);
        BookingService.review(UserService.getUserId(user), bookingId, comment, rating, userType).then(resp => {
            if (isHost)
                history.push('/host/dashboard/reservation')
            else
                history.push('/dashboard/reservation');
        }).catch(e => {
            setSendingReview(false);
            console.log(e)
        })
    }

    const styles = {
        hostHeader: {
            backgroundColor: '#00D1BF',
            color: '#ffffff',
            display: 'flex', 
            justifyContent: 'center', 
            flexBasis: '25%', 
            alignItems: 'center', 
            fontSize: isMobile ? '18px' : '22px', 
            width: '100vw'
        },
        userHeader: {
            backgroundColor: '#7E2D87',
            color: '#ffffff',
            display: 'flex', 
            justifyContent: 'center', 
            flexBasis: '25%', 
            alignItems: 'center', 
            fontSize: isMobile ? '18px' : '22px', 
            width: '100vw'
        },
        hostButton: {
            color: '#ffffff', 
            backgroundColor: '#00D1BF', 
            width: '250px', 
            height: '45px', 
            borderRadius: '10px', 
            marginTop: '15px', 
            fontFamily: 'Poppins'
        },
        userButton: {
            color: '#ffffff', 
            backgroundColor: '#7E2D87', 
            width: '250px', 
            height: '45px', 
            borderRadius: '10px', 
            marginTop: '15px', 
            fontFamily: 'Poppins'
        }
    }

    return <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100vh - 64px)', alignItems: 'center' }}>
        <Box style={isHost? styles.hostHeader : styles.userHeader}>
            {loadingData ? <CircularProgress /> :  isHost ? `Muchas gracias por haber recibido a ${booking.user?.name}` : `Muchas gracias por haber trabajado en la casa de ${booking.space?.host?.name}`}
        </Box>
        <Box style={{ flexBasis: '10%' }}>
            {loadingData ? <CircularProgress /> : isHost 
                ? <Box style={{ color: '##101010', fontSize: isMobile ? '18px' : 25, marginBottom: '25px' }}><b>Califica tu experiencia con {isHost ? `${booking.user?.name}` : `${booking.space?.host?.name}`}</b></Box> 
                : <><Box style={{ color: '##101010', fontSize: isMobile ? '18px' : 25, marginBottom: '25px' }}><b>Califica tu experiencia en la casa de {booking.space?.host?.name}</b></Box>
            <Box style={{ color: '##383839', fontSize: isMobile ? '14px' : 16 }}>Tu opinión es muy importante, tanto para Worknmates, como para que</Box>
            <Box style={{ color: '##383839', fontSize: isMobile ? '14px' : 16 }}>{booking.space?.host?.name} pueda mejorar día a día sus servicios.</Box></>}
        </Box>
        <Box>
            <Rating
                max={10}
                size={isMobile ? '' : "large"}
                onChange={(event, newValue) => {
                    setRating(newValue);
                }}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }} />
            <Box style={{ height: 20 }}>{ratingLabels[hover !== -1 ? hover : rating]}</Box>
        </Box>
        <Box style={{ textAlign: 'left' }}>
            <Box style={{ color: '##383839', fontSize: isMobile ? '16px' : 18 }}>Describí tu experiencia</Box>
            <StyledTextField
                style={{ marginTop: '20px', width: '500px', maxWidth: '96vw' }}
                id="summary"
                multiline
                rows={4}
                variant="outlined"
                value={comment}
                onChange={event => setComment(event.target.value)}
            />
        </Box>
        <Button variant="contained" disabled={sendingReview} style={isHost ? styles.hostButton : styles.userButton} onClick={sendReview}>
            {sendingReview ? <CircularProgress /> : 'Calificar'}
        </Button>
        <Box></Box>
    </Box>;
}

export default BookingReview;