import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import SpaceService from "../../services/SpaceService";
import { Box, CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import { GoogleMap, OverlayView, useLoadScript } from "@react-google-maps/api";
import PublicatioResult from "../publication/PublicatioResult";
import is from "is_js";
import useDynamicRefs from 'use-dynamic-refs';
import { PriceDisplay } from "../styled/PriceDisplay";
import { useMediaQuery } from "react-responsive";
import SearchBar from "./SearchBar";
import SearchEditForm from "./SearchEditForm";

const ResultsList = () => {
    const { dateFrom: paramDateFrom, dateTo: paramDateTo, quantity = 1, lat = 0, long = 0, locationName } = useParams();
    const dateFrom = (paramDateFrom === 'YYYY-MM-DD') ? null : paramDateFrom;
    const dateTo = (paramDateTo === 'YYYY-MM-DD') ? null : paramDateTo;
    const date = (paramDateFrom === 'YYYY-MM-DD') ? null : paramDateFrom;
    const isMultiDate = (dateFrom !== 'YYYY-MM-DD' && dateTo !== 'YYYY-MM-DD');

    const location = useLocation();

    const isLessThan500pxWitdth = useMediaQuery({ query: '(max-width: 500px)' });
    const isLessThan900pxWitdth = useMediaQuery({ query: '(max-width: 900px)' });

    const [results, setResults] = useState([]);
    const [resultsLoading, setResultsLoading] = useState(false);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [mapZoom, setMapZoom] = useState(10);
    const [mapRef, setMapRef] = useState(null);

    const [showEditSearch, setShowEditSearch] = useState(false);
    const [displayFullResultsOnMap, setDisplayFullResultsOnMap] = useState(true);

    const [searchCriteria, setSearchCriteria] = useState({});

    const [selectedResult, setSelectedResult] = useState(0);
    const [getRefResult, setRefResult] = useDynamicRefs();

    const scrollToRef = (ref) => {
        document.getElementById('resultList')
            .scrollTo(ref.current?.offsetLeft, ref.current?.offsetTop - (isLessThan900pxWitdth ? 510 : 130));
    }

    const updateMapCenter = space => {
        const { latitude, longitude } = space;
        setSelectedResult(space.id);
        setMapCenter({ lat: isLessThan500pxWitdth ? latitude - 0.005 : latitude, lng: longitude });
        setMapZoom(15);
    };

    const markerClicked = r => {
        setSelectedResult(r.id);
        scrollToRef(getRefResult(r.id));
    };

    const mapLoadHandler = map => {
        setMapRef(map);
    };

    const fitBounds = map => {
        const bounds = new window.google.maps.LatLngBounds();
        results.map(r => {
            bounds.extend({ lat: r.latitude, lng: r.longitude });
            return r.id;
        });
        map.fitBounds(bounds, isLessThan500pxWitdth ? 45 : 90);
    };

    const { isLoaded: isMapLoaded, loadError: mapLoadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });

    useEffect(() => {
        setResultsLoading(true);
        const searchRequest = {
            latitude: lat,
            longitude: long,
            required_spaces: +quantity
        }

        if (isMultiDate) {
            searchRequest.date_from = dateFrom;
            searchRequest.date_to = dateTo;
        } else searchRequest.date = date;

        SpaceService.feedSearch(searchRequest).then(resp => {
            setResults(resp.data);
            setResultsLoading(false);
        }).catch(e => console.log(e));
        setSearchCriteria({ date, dateFrom, dateTo, quantity, locationName, lat, long });
    }, [date, dateTo, dateFrom, quantity, locationName, lat, long, isMultiDate]);



    useEffect(() => {
        if (is.not.empty(results) && mapRef) {
            setMapCenter(averageGeolocation(results.map(r => ({ latitude: r.latitude, longitude: r.longitude }))));
            fitBounds(mapRef);
        }
        // eslint-disable-next-line
    }, [results, mapRef]);

    const containerStyle = {
        width: '100%',
        height: 'calc(100vh - 136px)'
    };

    const goToPublication = (spaceId) => {
        var store = require('store');
        let dates = null;
        if (isMultiDate) dates = [dateFrom, dateTo];
        store.set(`new_booking`, { date, quantity, dates, multiple_dates: isMultiDate });
        const newWindow = window.open(`/publication/${spaceId}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;

        //history.push({ pathname: `/publication/${spaceId}`, state: { searchCriteria } });
    }

    return <Box style={{ minHeight: isLessThan500pxWitdth ? 'calc(100vh - 163px)' : 'calc(100vh - 136px)' }} key={location.key}>
        {showEditSearch && <SearchEditForm searchCriteria={searchCriteria} setShow={setShowEditSearch} />}
        <SearchBar searchCriteria={searchCriteria} setShow={setShowEditSearch} />
        <Box style={{
            display: 'flex',
            maxHeight: isLessThan500pxWitdth ? 'calc(100vh - 163px)' : 'calc(100vh - 136px)',
            flexDirection: isLessThan900pxWitdth ? 'column' : 'row'
        }}>
            <Box id="resultList"
                onTouchMove={() => setDisplayFullResultsOnMap(false)}
                style={{
                    flexBasis: '100%',
                    maxHeight: '100vh',
                    overflow: 'scroll',
                    order: isLessThan900pxWitdth ? 2 : 0,
                    position: isLessThan900pxWitdth ? 'absolute' : 'auto',
                    zIndex: 99,
                    display: isLessThan900pxWitdth ? 'flex' : 'block',
                    bottom: isLessThan900pxWitdth ? 0 : 'auto',
                    maxWidth: isLessThan900pxWitdth ? '100vw' : 'auto',
                }}>
                {!isLessThan900pxWitdth && <Box style={{
                    fontWeight: "500",
                    fontSize: "30px",
                    lineHeight: "45px",
                    color: "#383839",
                    textAlign: 'left',
                    padding: '15px'
                }}>
                    {locationName?.split(',')[0] ? `Espacios en ${locationName.split(',')[0]}` : 'Espacios'}
                </Box>}
                {results.map(r => <div key={r.id} ref={setRefResult(r.id)}>
                    <PublicatioResult
                        mini={isLessThan900pxWitdth}
                        space={r}
                        selectCurrent={() => !displayFullResultsOnMap && updateMapCenter(r)}
                        isSelected={selectedResult === r.id}
                        clickAction={() => goToPublication(r.id)}
                        hoverAction={() => updateMapCenter(r)}
                        makeResponsive={true}
                        dateFrom={dateFrom}
                        dateTo={dateTo} />
                </div>
                )}
                {resultsLoading ?
                    <CircularProgress />
                    : is.empty(results) && <Box style={{ backgroundColor: '#ffffff', padding: '5px' }}>Sin resultados</Box>
                }
            </Box>
            <Box style={{ flexBasis: '100%' }}>
                {mapLoadError ? <div>Error loading map: {JSON.stringify(mapLoadError)}</div>
                    : <>{isMapLoaded ?
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={mapCenter}
                            zoom={mapZoom}
                            clickableIcons={false}
                            options={{
                                fullscreenControlOptions: false,
                                gestureHandling: 'greedy',
                                mapTypeControlOptions: false
                            }}
                            onLoad={mapLoadHandler}>
                            {results.map(r => <OverlayView key={r.id + '_marker'} position={{ lat: r.latitude, lng: r.longitude }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                                <StyledTooltip arrow={true} placement="top" title={<PriceDisplay value={r.price} />}>
                                    <img src={require('../../assets/images/logo-small.png')} style={{ marginTop: -16, marginLeft: -16, height: '32px', width: '32px', objectFit: 'scale-down' }} alt="" onClick={() => markerClicked(r)} />
                                </StyledTooltip>
                            </OverlayView>)}
                        </GoogleMap>
                        : <CircularProgress />
                    }</>
                }
            </Box>
        </Box>
    </Box>;
}

export default ResultsList;

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#00D1BF',
        color: '#ffffff',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.180261)',
        fontSize: '16px',
        fontWeight: 900
    },
    arrow: {
        color: '#00D1BF',
    },
}))(Tooltip);

function averageGeolocation(coords) {
    if (coords.length === 1) {
        return coords[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let coord of coords) {
        let latitude = coord.latitude * Math.PI / 180;
        let longitude = coord.longitude * Math.PI / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
    }

    let total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
        lat: centralLatitude * 180 / Math.PI - 0.15,
        lng: centralLongitude * 180 / Math.PI
    };
}