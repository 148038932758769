import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { Container, Box, CircularProgress, Tabs, Tab, Tooltip } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import Space from "../onboarding/Space";
import Location from "../onboarding/Location";
import ConfirmLocation from "../onboarding/ConfirmLocation";
import Service from "../onboarding/Service";
import Photos from "../onboarding/Photos";
import Rules from "../onboarding/Rules";
import Availability from "../onboarding/Availability";
import Pricing from "../onboarding/Pricing";
import HostService from "../../../services/HostService";
import { useAuth0 } from "@auth0/auth0-react";
import SpaceCreationContext, { SpaceCreationProvider } from '../space/SpaceCreationContext';
import HostSpaceService from "../../../services/HostSpaceService";
import HelpIcon from '@material-ui/icons/Help';

const messages = {
    publicationBlockedByAdmin: 'El anuncio ha sido temporalmente inhabilitado por Worknmates debido a algunas irregularidades. Nos pondremos en contacto contigo a la brevedad. Si tienes alguna duda puedes escribirnos a contacto@worknmates.com '
};

const PublicationEdit = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { user } = useAuth0();
    let { spaceId, initialTab } = useParams();
    const history = useHistory();

    const [space, setSpace] = useState({ id: 0 });
    const [spaceLoading, setSpaceLoading] = useState(false);

    const [tab, setTab] = useState(+initialTab || 0);

    useEffect(() => {
        let hostId = HostService.getHostId(user);
        sessionStorage.setItem(user.sub + '_space', spaceId);
        refreshSpace(hostId);
        // eslint-disable-next-line
    }, [spaceId, user])

    const refreshSpace = async (hostId) => {
        setSpaceLoading(true);
        try {
            let spaceId = HostService.getCurrentSpaceId(user);
            if (spaceId === 0 || hostId === 0) return;
            let resp = await HostSpaceService.getById(hostId, spaceId);
            let s = resp.data;
            setSpace(s);
            console.log('SPACE', s);
        } catch (e) {
            console.log(e);
        } finally {
            setSpaceLoading(false);
        }
    }

    const tabChange = async (e, newTabIndex) => {
        if (newTabIndex === 4) refreshSpace();
        setTab(newTabIndex);
    };

    const styles = {
        stepsView: {
            backgroundColor: '#ffffff',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 135px)',
            paddingBottom: isNarrowWidth ? '20px' : 0,
            paddingLeft: isMobile ? '10px' : '50px',
            paddingRight: isMobile ? '10px' : '50px'
        }
    }

    const stepsProps = {
        context: SpaceCreationContext,
        isOnboarding: false
    }

    const updateSpace = async (updatedSpaceData) => {
        let hostId = HostService.getHostId(user);
        await HostSpaceService.updateSpace(hostId, { ...updatedSpaceData, id: space.id })
            .catch(e => { throw new Error(e) });
        refreshSpace(hostId);
    };

    const setAvailability = async (spaceId, availability) => {
        let hostId = HostService.getHostId(user);
        availability.space_id = spaceId;
        await HostSpaceService.setAvailability(hostId, spaceId, availability)
            .catch(e => { throw new Error(e) });
    }


    const backToPublications = () => history.push('/host/dashboard/publications');

    const stepUtils = {
        updateSpace,
        backToPublications,
        setAvailability
    }

    // context data
    const contextData = {
        styles,
        space,
        stepUtils,
    }
    return <SpaceCreationProvider value={contextData}>
        <div style={{ backgroundColor: isMobile ? '#ffffff' : '#F2F2F2' }}>
            <Container style={{ minHeight: 'calc(100vh - 104px)', padding: isMobile && 0 }} className="spaceEditionContainer">
                <Tabs value={tab} onChange={tabChange} variant="scrollable" scrollButtons="on">
                    <Tab label="Espacio" style={{ textTransform: 'none' }} />
                    <Tab label="Ubicación" style={{ textTransform: 'none' }} />
                    <Tab label="Confirmar Ubicación" style={{ textTransform: 'none' }} />
                    <Tab label="Servicios" style={{ textTransform: 'none' }} />
                    <Tab label="Fotos" style={{ textTransform: 'none' }} />
                    <Tab label="Reglas" style={{ textTransform: 'none' }} />
                    <Tab label="Disponibilidad" style={{ textTransform: 'none' }} />
                    <Tab label="Precio" style={{ textTransform: 'none' }} />
                </Tabs>

                {spaceLoading ?
                    <Box style={{ ...styles.stepsView, justifyContent: 'center', alignItems: 'center', flexFlow: 'row' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        {!space.admin_approval && <SpaceWarning title={'Anuncio Inhabilitado'} tooltipMsg={messages.publicationBlockedByAdmin} />}
                        <TabPanel value={tab} index={0}>
                            <Space {...stepsProps} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <Location {...stepsProps} />
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <ConfirmLocation refreshSpace={() => refreshSpace(HostService.getHostId(user))} {...stepsProps} />
                        </TabPanel>
                        <TabPanel value={tab} index={3}>
                            <Service {...stepsProps} />
                        </TabPanel>
                        <TabPanel value={tab} index={4}>
                            <Photos {...stepsProps} />
                        </TabPanel>
                        <TabPanel value={tab} index={5}>
                            <Rules {...stepsProps} />
                        </TabPanel>
                        <TabPanel value={tab} index={6}>
                            <Availability {...stepsProps} />
                        </TabPanel>
                        <TabPanel value={tab} index={7}>
                            <Pricing {...stepsProps} />
                        </TabPanel>
                    </>
                }
            </Container>
        </div>
    </SpaceCreationProvider >;
};

export default PublicationEdit;

const SpaceWarning = ({ title, tooltipMsg }) => <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ff6666', border: '2px solid red', borderRadius: '5px', padding: '5px', color: 'white', margin: '10px' }}>
    <span style={{ marginRight: '10px' }}>{title}</span>
    <Tooltip title={<div style={{ fontSize: '14px' }}>{tooltipMsg}</div>}>
        <HelpIcon />
    </Tooltip>
</div>;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}