import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@material-ui/core/Button';
import DataService from "../../services/DataService";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <Button onClick={() => loginWithRedirect({ redirectUri: DataService.getRedirectUri() })} style={{
        borderRadius: '10px', 
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        textTransform: 'none',
        color: '#383839',
        marginRight: '5px'
    }}>Iniciar sesión</Button>;
};

export default LoginButton;