import React, { useEffect, useState } from 'react';
import './App.css';
import HostHome from './components/host/HostHome';
import HostOnboarding from './components/host/HostOnboarding';
import { Router, Switch, Route, Redirect } from "react-router-dom";
import NavBar from './components/NavBar';
import HostDashboard from './components/host/HostDashboard';
import Home from './components/Home';
import { createBrowserHistory } from 'history';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth0 } from '@auth0/auth0-react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import SpaceCreation from './components/host/space/SpaceCreation';
import PublicationEdit from './components/host/dashboard/PublicationEdit';
import HostService from './services/HostService';
import TermsAndConditions from './components/TermsAndConditions';
import Privacy from './components/Privacy';
import { authorize } from './helpers/authAxios';
import Publication from './components/publication/Publication';
import UserContext, { UserProvider } from './helpers/UserContext';
import UserService from './services/UserService';
import UserOnboarding from './components/user/UserOnboarding';
import is from 'is_js';
import UserDashboard from './components/user/UserDashboard';
import ResultsList from './components/results/ResultsList';
import EmailVerified from './components/EmailVerified';
import NewBooking from './components/booking/NewBooking';
import Success from './components/user/onboarding/Success';
import BookingReview from './components/user/dashboard/BookingReview';
import CompanyHome from './components/company/CompanyHome';
import CompanyDashboard from './components/company/CompanyDashboard';

export const history = createBrowserHistory();

function App() {
  const { isLoading, getAccessTokenSilently, isAuthenticated, user, loginWithRedirect } = useAuth0();
  const [appReady, setAppReady] = useState(false);
  const [fullUser, setFullUser] = useState({});
  const [userFavourites, setUserFavourites] = useState([]);

  const refreshUser = (getFavs) => {
    setAppReady(false);
    UserService.getById(UserService.getUserId(user)).then(resp => {
      setFullUser(resp.data);
      getFavs ?
        UserService.getFavourites(UserService.getUserId(user)).then(resp => {
          setUserFavourites(resp.data.map(f => f.id));
          setAppReady(true);
        })
        : setAppReady(true);
    });
  };

  useEffect(() => {
    async function getToken() {
      setAppReady(false);
      const at = await getAccessTokenSilently();
      authorize(at); // set access_token on headers
      refreshUser(true);
    }

    if (!isLoading && isAuthenticated) getToken();
    if (!isLoading && !isAuthenticated) setAppReady(true);

    // eslint-disable-next-line
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);



  return (<UserProvider value={{ fullUser, userFavourites, setUserFavourites }}>
    <div className="App">
      <Router history={history}>
        <NavBar avatarPicture={fullUser.avatar?.url || user?.picture} />
        <Switch>
          <Route path="/" exact component={Home} />

          {/* USER */}
          <ProtectedRoute appReady={appReady} path="/booking/new" component={
            () => {
              if (isAuthenticated && is.empty(fullUser))
                return <Backdrop open={true} className={HostService.isHostSection() ? 'hostSection' : ''}><CircularProgress /></Backdrop>;

              var store = require('store');
              const currentNewBooking = store.get(`new_booking`);
              store.remove('new_booking');
              store.set(`${user.sub}_new_booking`, { ...currentNewBooking, user_id: UserService.getUserId(user) });


              if (is.existy(fullUser.address))
                return <NewBooking />
              else
                return <Redirect to={"/booking/onboarding"} />
            }
          } />
          <ProtectedRoute appReady={appReady} path="/booking/onboarding" component={() => {
            return <UserOnboarding refreshUser={refreshUser} />;
          }} />
          <ProtectedRoute appReady={appReady} path="/dashboard" component={() => <UserDashboard refreshUser={refreshUser} />} />
          <ProtectedRoute appReady={appReady} path="/booking/success" component={() => <Success context={UserContext} />} />
          <ProtectedRoute appReady={appReady} path="/booking/:userType/:bookingId/review" component={BookingReview} />

          {/* SEARCH */}
          <Route path="/results/:dateFrom/:dateTo/:quantity/:lat/:long/:locationName?" component={ResultsList} />
          <Route path="/publication/:spaceId" component={Publication} />

          {/* COMMONS */}
          <Route path="/login">{() => loginWithRedirect()}</Route>
          <Route path="/terms" component={TermsAndConditions} />
          <Route path="/privacy" component={Privacy} />
          <ProtectedRoute appReady={appReady} path="/email_verified" component={EmailVerified} />


          <>
            {/* HOSTS */}
            <div className="hostSection">
              <Route path="/hostHome" component={HostHome} />
              <Route path="/host" exact>
                {HostService.hasHostRole(user) ? <Redirect to="/host/dashboard" /> : <Redirect to="/host/onboarding" />}
              </Route>
              <ProtectedRoute appReady={appReady} path="/host/onboarding" component={HostOnboarding} />
              <ProtectedRoute appReady={appReady} path="/host/dashboard" component={HostDashboard} />
              <ProtectedRoute appReady={appReady} path="/host/spaceCreation" component={SpaceCreation} />
              <ProtectedRoute appReady={appReady} path="/host/spaceEdit/:spaceId/:initialTab?" component={PublicationEdit} />
            </div>


            {/* COMPANY */}
            <div className="companySection">
              <Route path="/companyHome" component={CompanyHome} />
              <Route path="/company" exact>
                <Redirect to="/company/dashboard" />
              </Route>
              <ProtectedRoute appReady={appReady} path="/company/dashboard" component={CompanyDashboard} />
            </div>
          </>


        </Switch>
      </Router>
    </div>
  </UserProvider>
  );
}

export default App;
