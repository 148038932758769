import React, { useState, useContext, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Container, Box, CircularProgress } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import PersonalInfo from "./onboarding/PersonalInfo";
import Security from "./onboarding/Security";
import Location from "./onboarding/Location";
import ConfirmLocation from "./onboarding/ConfirmLocation";
import Codes from "./onboarding/Codes";
import Networking from "./onboarding/Networking";
import Success from "./onboarding/Success";
import { useAuth0 } from "@auth0/auth0-react";
import OnboardingContext, { OnboardingProvider } from './onboarding/OnboardingContext';
import StepUtils from './onboarding/StepUtils';
import UserContext from '../../helpers/UserContext';
import BookingBox from "../booking/BookingBox";
import Payment from "./onboarding/Payment";
import Partners from './onboarding/Partners';
import SpaceService from "../../services/SpaceService";
import is from "is_js";

const UserOnboarding = ({ refreshUser }) => {
    var store = require('store');

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLessThan800 = useMediaQuery({ query: '(max-width: 800px)' });
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const { path } = useRouteMatch();
    const { isAuthenticated } = useAuth0();
    const { fullUser } = useContext(UserContext);
    const { user } = useAuth0();
    const booking = store.get(`${user.sub}_new_booking`);

    const [space, setSpace] = useState({});
    const [promoCodeData, setPromoCodeData] = useState({});

    // sort for onboarding steps
    const stepsOrder = [
        `${path}/personal_info`,
        // `${path}/codes`,
        // `${path}/location`,
        // `${path}/confirm_location`,
        // `${path}/security`,
        `${path}/partners`,
        // `${path}/networking`,
        `${path}/payment`,
        `${path}/success`,
    ];

    useEffect(() => {
        if (booking) {
            SpaceService.getById(booking.space_id).then(resp => {
                setSpace(resp.data);
            }).catch(e => console.log(e));
        }
        // eslint-disable-next-line
    }, []);


    const refreshBooking = (updatedBookingData) => {
        const newBooking = store.get(`${user.sub}_new_booking`);
        store.set(`${user.sub}_new_booking`, { ...newBooking, ...updatedBookingData });
    }

    // calculate top bar onboarding progress
    const calcProgress = () => {
        let p = undefined;
        if (isAuthenticated) {
            let stepNumber = stepsOrder.indexOf(window.location.pathname) + 1;
            stepNumber = stepNumber > 0 ? stepNumber : 1;
            let progress = (stepNumber * 100) / (stepsOrder.length - 1);
            p = progress > 100 ? 100 : progress;
        }
        return p;
    };
    const [progress, setProgress] = useState(calcProgress());
    const updateProgress = () => setProgress(calcProgress());

    const stepUtils = StepUtils(stepsOrder, updateProgress, refreshUser, refreshBooking);

    const styles = {
        stepsView: {
            backgroundColor: '#ffffff',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 135px)',
            paddingBottom: isNarrowWidth ? '20px' : 0,
            paddingLeft: isMobile ? '10px' : '50px',
            paddingRight: isMobile ? '10px' : '50px'
        }
    }

    const stepsProps = {
        context: OnboardingContext,
        isOnboarding: true
    }

    // onboarding context data
    const contextData = {
        stepUtils,
        styles,
        fullUser,
        refreshBooking,
        promoCodeData,
        setPromoCodeData
    }
    return <OnboardingProvider value={contextData}>
        <div style={{ backgroundColor: isMobile ? '#ffffff' : '#F2F2F2', paddingTop: isMobile ? (stepUtils.isLastStep ? 0 : '16px') : '40px', display: 'flex', flexDirection: isLessThan800 ? 'column' : 'row' }} className="userOnboarding">
            <Container style={{ minHeight: 'calc(100vh - 104px)', padding: isMobile && 0 }}>
                {!stepUtils.isLastStepAndMobile &&
                    <div style={{ padding: isMobile ? '0 20px' : 'unset', height: isMobile ? 'unset' : '30px', overflow: 'hidden' }}>
                        <BorderLinearProgress variant={progress ? 'determinate' : undefined} value={progress} style={{ height: isMobile ? '13px' : '30px', borderRadius: '10px', colorPrimary: '#7E2D87', marginTop: isMobile ? '0' : '15px' }} />
                    </div>
                }

                {(!progress || is.empty(space) || is.empty(booking)) ?
                    <Box style={{ ...styles.stepsView, justifyContent: 'center', alignItems: 'center', flexFlow: 'row' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <Switch>
                            <Route exact path={path}>
                                <Redirect to={`${path}/personal_info`} />
                            </Route>
                            <Route path={`${path}/personal_info`}>
                                <PersonalInfo {...stepsProps} />
                            </Route>
                            <Route path={`${path}/codes`}>
                                <Codes {...stepsProps} />
                            </Route>
                            <Route path={`${path}/location`}>
                                <Location {...stepsProps} />
                            </Route>
                            <Route path={`${path}/confirm_location`}>
                                <ConfirmLocation {...stepsProps} />
                            </Route>
                            <Route path={`${path}/security`}>
                                <Security {...stepsProps} />
                            </Route>
                            <Route path={`${path}/partners`}>
                                <Partners {...stepsProps} />
                            </Route>
                            <Route path={`${path}/networking`}>
                                <Networking {...stepsProps} />
                            </Route>
                            <Route path={`${path}/payment`}>
                                <Payment {...stepsProps} />
                            </Route>
                            <Route path={`${path}/success`}>
                                <Success {...stepsProps} />
                            </Route>
                        </Switch>
                    </>
                }
            </Container>
            {!stepUtils.isLastStep && <Box><div style={{ width: '312px' }}></div></Box>}
            {!stepUtils.isLastStep && <BookingBox
                space={space}
                selectedServices={booking?.bookingSpaceServices}
                multiDate={booking.multiple_dates}
                date={booking?.date}
                dates={booking?.dates}
                quantity={booking?.quantity}
                readOnly={true}
                topPosition={120}
                promoCodeData={promoCodeData}
            />}
        </div>
    </OnboardingProvider>;
};

export default UserOnboarding;


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: '#D8D8D8',
    },
    bar: {
        backgroundColor: '#7E2D87',
    },
}))(LinearProgress);
