import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import UserService from '../../../services/UserService';
import ReviewDisplay from '../../booking/ReviewDisplay';
import SpaceCalification from '../../publication/SpaceCalification';

const UserReviews = () => {

    const { user } = useAuth0();
    const [reviewsByHost, setReviewsByHost] = useState([]);
    const [averageRating, setAverageRating] = useState([]);
    const [reviewsLoading, setReviewsLoading] = useState(false);

    useEffect(() => {
        setReviewsLoading(true);
        UserService.getReviews(UserService.getUserId(user)).then(resp => {
            const { reviews, ...averageRating } = resp.data;
            setReviewsByHost(reviews);
            setAverageRating(averageRating);
            setReviewsLoading(false);
        })
    }, [user]);

    return (
        <Box>
            <div style={{
                fontFamily: 'PoppinsBold',
                fontWeight: 'bold',
                color: '#383839',
                fontSize: '20px',
                paddingTop: '20px'
            }} >
                <span>Evaluaciones</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box Box style={{ marginTop: 10, border: '1px solid #383839', borderRadius: 10, padding: 10, alignSelf: 'center' }}>
                    <SpaceCalification space={averageRating} showText={true} byHost={true} />
                </Box>
                {reviewsLoading ? <CircularProgress /> :
                    <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        {reviewsByHost.sort((a,b)=> new Date(b.date) - new Date(a.date)).map((s, i) =>
                            <Box key={`userReviews_${i}`} className="userReviews" style={{ width: 400 }}>
                                <ReviewDisplay review={s} byHost={true} />
                            </Box>
                        )}
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default UserReviews
