import React, { useEffect, useState, useContext } from "react";
import { Box } from "@material-ui/core";
import HostService from '../../../services/HostService';
import { useAuth0 } from "@auth0/auth0-react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { PriceDisplay } from "../../styled/PriceDisplay";

const DashboardHome = (props) => {
    const { user } = useAuth0();
    const [payments, setPayments] = useState([]);
    const [totals, setTotals] = useState(0);

    const { context } = props;
    const { host } = useContext(context);

    useEffect(() => {
        let mounted = true;
        const getPayments = async () => {
            let hostId = HostService.getHostId(user);
            let resp = await HostService.getPayments(hostId);
            if (mounted) {
                const { payments: pys, ...tots } = resp.data;
                setPayments(pys);
                setTotals(tots)
            }
        }
        getPayments();

        return () => mounted = false;
    }, [host, user]);

    const styles = {
        tableTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '18px',
            textAlign: 'left',
            padding: '15px'
        },
    }
    return <Box>
        <TableContainer component={Paper}>
            <Box style={styles.tableTitle}>Cobros</Box>
            <Box style={{ textAlign: 'left', paddingLeft: '25px' }}>Total cobrado: <PriceDisplay value={totals.total_paid} /> </Box>
            <Box style={{ textAlign: 'left', paddingLeft: '25px' }}>Total por cobrar: <PriceDisplay value={totals.total_payment_pending} /> </Box>
            <Box style={{ textAlign: 'left', paddingLeft: '25px' }}>Total pendiente: <PriceDisplay value={totals.total_payment_new} /> </Box>
            <Box style={{ textAlign: 'left', paddingLeft: '25px' }}>Deuda a WnM: <PriceDisplay value={totals.total_debt} /> </Box>
            <Box style={{ textAlign: 'left', paddingLeft: '25px' }}><b>Total: <PriceDisplay value={totals.total} /></b></Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Nombre</b></TableCell>
                        <TableCell><b>Forma de Pago</b></TableCell>
                        <TableCell><b>Estado</b></TableCell>
                        <TableCell><b>Tipo</b></TableCell>
                        <TableCell><b>Fecha de Reserva</b></TableCell>
                        <TableCell><b>Sub Total</b></TableCell>
                        <TableCell><b>Gastos de Servicios</b></TableCell>
                        <TableCell><b>Gastos de MP</b></TableCell>
                        <TableCell><b>Total</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments.filter(p => p.host_status !== 3).map(p => (
                        <TableRow key={p.id + '_pay'}>
                            <TableCell>{p.name}</TableCell>
                            <TableCell>{p.payment_method_name}</TableCell>
                            <TableCell>{paymentStatusLabels[p.host_status]}</TableCell>
                            <TableCell>{paymentTypesLabels[p.concept]}</TableCell>
                            <TableCell>{moment(p.date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>
                                <PriceDisplay value={p.subtotal} />
                            </TableCell>
                            <TableCell>
                                <PriceDisplay value={p.service_fee} />
                            </TableCell>
                            <TableCell>
                                <PriceDisplay value={p.host_fee} />
                            </TableCell>
                            <TableCell>
                                <PriceDisplay value={p.payment_amount} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>;
};

export default DashboardHome;

const paymentTypesLabels = {
    0: 'Reserva',
    1: 'Referido',
    2: 'Código Promocional'
}

const paymentStatusLabels = {
    0: 'Pendiente',
    1: 'Por cobrar',
    2: 'Cobrado',
    3: 'Cancelado'
}

