import React, { useContext, useState } from "react";
import { Box, FormHelperText, Radio } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import FormControl from '@material-ui/core/FormControl';
import TextField from '../../styled/StyledTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import OnboardingButtons from "./OnboardingButtons";
import is from "is_js";

const REQUIERED_FIELD_ERROR = 'Este campo es requerido.';

const Codes = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context } = props;
    const { styles: contextStyles, stepUtils, host } = useContext(context);
    const [code, setCode] = useState(host.referred_code || '');
    const [hasCode, setHasCode] = useState(true);

    const [errors, setErrors] = useState({});

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: '100%'
        },
        ...contextStyles
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (hasCode && is.empty(code)) errorsAcum.code = REQUIERED_FIELD_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    const getHostData = () => ({
        referred_code: hasCode ? code : ''
    });

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm codes">
            <div style={styles.onboardingTitle}>
                <span>Código de referidos</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Si fuiste referido por un miembro de Worknmates, ingresá el código aquí</div>
                    <FormControl style={styles.formControl}>
                        <RadioGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isMobile ? 'left' : 'space-evenly' }}
                            aria-label="code"
                            name="codes"
                            value={hasCode} onChange={event => setHasCode(event.target.value === 'true')}>

                            <FormControlLabel value={true} control={<Radio />} label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: '10px' }}>Tengo código</div>
                                    <TextField
                                        id="lastname"
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                        variant="outlined"
                                        type="text"
                                        error={!!errors.code}
                                        style={{ maxWidth: '120px' }}
                                        InputLabelProps={{ shrink: true, }} />
                                </div>
                            } />


                            <FormControlLabel value={false} control={<Radio />} label="No tengo código" />

                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true} style={{ textAlign: 'right' }}>Valide los datos ingresados</FormHelperText>}
        <OnboardingButtons stepUtils={stepUtils}
            nextFn={() => validateFormData() && stepUtils.saveHostAndNext(getHostData())}
            prevFn={() => stepUtils.previousStep()}
            closeFn={() => validateFormData() && stepUtils.saveHostAndClose(getHostData())} />
    </Box>;
};

export default Codes;