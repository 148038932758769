import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Dialog, DialogContent, DialogActions, RadioGroup, Radio, FormControl, TextField, FormHelperText, CircularProgress } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import OnboardingButtons from "./OnboardingButtons";
import DataService from "../../../services/DataService";
import is from "is_js";
import BookingService from "../../../services/BookingService";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const PAYMENT_METHOD_REQIRED_ERROR = 'Es necesario seleccionar al menos un método de pago.'

const Payment = ({ context, isOnboarding }) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { styles: contextStyles, stepUtils, refreshBooking, promoCodeData, setPromoCodeData } = useContext(context);
    const [paymentOptions, setPaymentOptions] = useState([])
    const [paymentTypeSelected, setPaymentTypeSelected] = useState('');
    const { user } = useAuth0();

    var store = require('store');
    const booking = store.get(`${user.sub}_new_booking`);

    const [errors, setErrors] = useState({});

    const [promoCode, setPromoCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [init, setInit] = useState(false);
    const [loadingPromoCode, setLoadingPromoCode] = useState(false);

    useEffect(() => {
        if (!init) {
            setInit(true);
            refreshBooking({ promocode_id: 0 });
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [init]);

    useEffect(() => {
        is.not.empty(promoCodeData) && setPromoCode(promoCodeData.coupon || '');
    }, [promoCodeData]);

    const addPromoCode = async () => {
        setLoadingPromoCode(true);
        setPromoCodeData({});
        setCodeError('');
        try {
            const resp = await BookingService.addPromoCode(promoCode);
            setPromoCodeData(resp.data);
            setPromoCode('');
            refreshBooking({ promocode_id: resp.data.id });
        } catch (e) {
            const errorCode = e.response.data.error;
            if (errorCode === 'PC02')
                setCodeError('El código ya fue utilizado.');
            else
                setCodeError('Código inválido o expirado.');
        } finally {
            setLoadingPromoCode(false);
        }
    }

    // get form data on mount
    useEffect(() => {
        let mounted = true;
        const getPaymentOptions = async () => {
            let resp = await DataService.getPaymentTypes();
            mounted && setPaymentOptions(resp.data);
        }
        getPaymentOptions();

        return () => mounted = false;
    }, []);

    const paymentSelected = (checked, id) => {
        setPaymentTypeSelected(id);
        refreshBooking({ payment_method_id: id });
    }

    const confirmBooking = async () => {
        const resp = await BookingService.create(booking);
        if (paymentTypeSelected === 2) {
            const { preference_url } = resp.data;
            if (preference_url) 
                window.location.href = preference_url;
            else
                stepUtils.nextStep();
        } else {
            stepUtils.nextStep();
        }
    }

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            padding: '10px',
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        paymentMethodOption: {
            border: '1px solid #D8D8D8',
            borderRadius: '3px',
            width: isMobile ? 'unset' : '450px',
            marginTop: '20px',
            display: 'flex',
            maxWidth: '94vw',
            minHeight: '67px',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        ...contextStyles
    }

    const validateFormData = () => {
        let errorsAcum = {};
        if (is.empty(paymentTypeSelected)) errorsAcum.payment = PAYMENT_METHOD_REQIRED_ERROR;

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    }

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <span>Pagos</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <div style={{ fontSize: '14px', color: '#383839', textAlign: 'left' }}>Realizá tus transacciones a través de una plataforma de pagos y cobros. Añadí las que más te convengan</div>
                <RadioGroup value={paymentTypeSelected} style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                    {paymentOptions.map(po =>
                        <div key={'pm_' + po.id} style={styles.paymentMethodOption}>
                            <Radio onChange={e => paymentSelected(e.target.checked, po.id)} value={po.id} />
                            <div style={{ display: 'flex', flexDirection: 'row', width: '350px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <PaymentMethodDisplay
                                    errors={errors}
                                    pmId={po.id}
                                    paymentTypesSelection={paymentTypeSelected}
                                    paymentSelected={paymentSelected} />
                            </div>
                        </div>
                    )}
                    <div style={{ fontSize: '14px', color: '#383839', padding: '20px 0', textAlign: 'left' }}>Se le enviará un correo electrónico al huésped para que coordinen el pago en efectivo</div>
                </RadioGroup>
            </Box>
        </Box>
        <Box>
            <div style={styles.onboardingTitle}>
                <span>Código Promocional</span>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>
            <FormControl error={!!codeError}>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <TextField
                        id="code"
                        // label="Code"
                        value={promoCode}
                        onChange={e => setPromoCode(e.target.value)}
                        style={{ marginLeft: '5px' }}
                        type="text"
                        variant="outlined"
                        InputLabelProps={{ shrink: true, }} />
                    <Button 
                        style={{ backgroundColor: '#6b2d87', color: '#fff', marginLeft: 10, padding: 14 }} 
                        onClick={(e) => { e.preventDefault(); addPromoCode() }}
                    >Aplicar</Button>
                    {loadingPromoCode ?
                        <CircularProgress size={25} style={{ marginLeft: 10 }} /> :
                        <>
                            {is.not.empty(codeError) && <CloseIcon style={{ color: 'red' }} fontSize="large" />}
                            {is.empty(codeError) && is.not.empty(promoCodeData) && <DoneIcon style={{ color: 'green' }} fontSize="large" />}
                        </>
                    }
                </Box>
                <FormHelperText>{codeError}</FormHelperText>
            </FormControl>
        </Box>
        {!!errors.payment && <Box style={{ color: 'red' }}>{errors.payment}</Box>}
        {isOnboarding &&
            <OnboardingButtons
                stepUtils={stepUtils}
                nextFn={() => validateFormData() && confirmBooking()}
                prevFn={() => stepUtils.previousStep()}
                closeFn={() => validateFormData() && stepUtils.updateBookingAndClose(booking)} />
        }
    </Box>;
};

export default Payment;

const PaymentMethodDisplay = ({ pmId, paymentTypesSelection, paymentSelected, errors }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [mpDialogOpen, setMpDialogOpen] = useState(false);

    let pmView = {
        1: <>
            <div>PAGO EN EFECTIVO</div>
            <img src={require('../../../assets/images/cash-logo.png')} alt="" style={{}} />
        </>,
        2: <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-around' }}>
            <MercadoPagoHelpDialog open={mpDialogOpen} setOpen={setMpDialogOpen} />
            <img src={require('../../../assets/images/mp-logo.png')} alt="" style={{ width: '91px', height: '24px', margin: '5px' }} />
        </div>
    }
    return pmView[pmId] || null;
}

export { PaymentMethodDisplay };

const MercadoPagoHelpDialog = ({ open, setOpen }) => {

    return <Dialog open={open} onClose={() => setOpen(false)} >
        <DialogContent>
            <div style={{ display: 'flex' }}>
                <Box style={{ border: '1px red solid', padding: '10px' }}>
                    <div style={{ color: 'red' }}>IMPORTANTE</div>
                    <div>Asegúrate de poner correctamente tu Client ID o mail, de lo contrario no podremos procesar los pagos y vos no podrás recibirlos.</div>
                </Box>
                <img src={require('../../../assets/images/mp-logo.png')} alt="" style={{ width: '91px', height: '24px', margin: '5px' }} />
            </div>
            <br />
            <Box>
                <b>Dónde encuentro mi Client ID?</b>
                <div>Ingresá a tu cuenta de MercadoPago y selecciona <a href="https://www.mercadopago.com/mla/account/credentials?type=basic" target="_blank" rel="noopener noreferrer" style={{ color: 'cadetblue' }}>Tu Negocio &gt; Configuraciones &gt; Credenciales</a></div>
                <br />
                <b>No tienes cuenta de MercadoPago?</b>
                <div>Créala gratis desde <a href="http://mercadopago.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'cadetblue' }}>www.mercadopago.com</a></div>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary" className="okMpButton">Cerrar</Button>
        </DialogActions>
    </Dialog>;
};
