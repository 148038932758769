export const HOST_STATUS = {
    PAYMENT_PENDING: 'payment-pending',
    PAYMENT_ERROR: 'payment-error',
    PENDING_APPROVAL: 'pending-approve',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING_REVIEW: 'pending-review',
    REVIEWED: 'reviewed',
    CANCELLED_BY_USER: 'cancelled-user',
};

export const USER_STATUS = {
    PAYMENT_PENDING: 'payment-pending',
    PAYMENT_ERROR: 'payment-error',
    PENDING_APPROVAL: 'pending-approval',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING_REVIEW: 'pending-review',
    REVIEWED: 'reviewed',
    CANCELLED_BY_USER: 'cancelled-user',
};
